import React, {useState, useEffect}             from 'react';
import BreadcrumSection     from "../../../commonSections/BreadcrumSection";
import AccrualsTableSection from "../sections/AccrualsTableSection";
import {permissionsHelper} from '../../../../utils/permissionsHelper'
import { LinearProgress } from '@material-ui/core'

const AccrualsListPage =()=> {

        const [permissions, setPermissions] = useState();

        useEffect(() => {
            (
                async() => {
                    setPermissions(await permissionsHelper.scopePermissions())
                }
            )()
        }, [])

        const breadcrumsChain = [
            ['Главная', '/main'],
        ];

        if(permissions)
        return (
            <React.Fragment>
                <BreadcrumSection
                    // createPredicate={this.props.permissions.find(_ => _.id === 1).value}
                    createPredicate={true}
                    h2="Начисления"
                    chain={breadcrumsChain}
                    buttonName="начисление"
                    href="accruals/create"
                />
                <AccrualsTableSection permissions={permissions}/>
            </React.Fragment>);
        else return  <LinearProgress/>
}

export default AccrualsListPage;

import React, { useCallback, useEffect, useMemo } from 'react'
import { MDBBadge, MDBBtn, MDBCardTitle, MDBCol, MDBRow, MDBIcon } from 'mdbreact'
import Select from 'react-select'
import withQueryParams from 'react-router-query-params'

import { objectService } from '../../../../services/objectService'
import { roomState, type1, type2, typesMatching } from '../../../../utils/RoomTypes'
import { deleteAllParams } from '../../../../utils/queryDeleteFunctions'
import { buildingService } from '../../../../services/buildingService'
import { floorService } from '../../../../services/floorService'
import InputDebounce from '../../../items/InputDebounce'
import { useSetState } from '../../../others/customHooks'
import DateInput from '../../../items/DateInput'
import RadioButtons from '../../../items/radio-btn'


const RoomsFilter = ({ setVisibleColumns, visibleColumns, filterVisible, queryParams, setQueryParams, setFilterVisible, ...props }) => {

    const [state, setState] = useSetState({
        objects: [],
        corps: [],
        floors: []
    })
    console.log('STATE RoomsFilter.js:138', state)
    const { objects, corps, floors } = state

    const { stateOptions, typeOneOptions } = useMemo(() => ({
        stateOptions: [noSelectedOption, ...Object.keys(roomState).map(key => ({ label: roomState[key], value: key }))],
        typeOneOptions: [noSelectedOption, ...Object.keys(type1).map(key => ({ label: type1[key], value: key }))]
    }), [])

    const typeTwoOptions = useMemo(() => {
        const { type1 } = queryParams
        if (type1) {
            const matchKeys = typesMatching[queryParams.type1]
            if (matchKeys) {
                const options = matchKeys.map(key => ({ label: type2[key], value: key }))
                return [noSelectedOption, ...options]
            }
        }
        return []
    }, [queryParams])

    const check = name => () => {
        setVisibleColumns({
            [name]: {
                ...visibleColumns[name],
                checked: !visibleColumns[name].checked
            }
        })
    }

    const setParam = useCallback((name, value) => {
        setQueryParams({ [name]: value })
    }, [setQueryParams])


    const setMultiParams = useCallback((name, value) => {
        const values = []
        if (value) value.forEach(val => {
            values.push(val.value)
        })
        setParam(name, values)
    }, [setParam])


    const getCorpOptions = useCallback((objectsId) => {
        //Проверяем query параметр, (если строка то одна обработка, если массив, то другая)
        if (typeof objectsId === 'string') {
            buildingService.getAllByObjectId(objectsId).then(_ => {
                //Провека, пришли ли данные из бека
                if (_) {
                    const options = _.map(option => ({ label: option.corpName, value: option.id }))
                    setState({ corps: options })
                } else {
                    console.error('Не удалось повторить')
                }
            })
        } else {
            Promise.all(objectsId.map(id => buildingService.getAllByObjectId(id)
                .then(_ => _.map(option => ({ label: option.corpName, value: option.id })))))
                .then(resAll => {
                    let options = []
                    resAll.forEach(res => {
                        options = [...options, ...res]
                    })
                    setState({ corps: options })
                })
        }
    }, [setState])

    const getFloorsOptions = useCallback((corpsId) => {
        //Проверяем query параметр, (если строка то одна обработка, если массив, то другая)
        if (typeof corpsId === 'string') {
            floorService.getAll(corpsId).then(_ => {
                //Провека, пришли ли данные из бека
                if (_) {
                    const options = _.map(option => ({ label: option.floorName, value: option.floorNumber }))
                    setState({ floors: options })
                }
            })
        } else {
            Promise.all(corpsId.map(id => floorService.getAll(id)
                .then(_ => _.map(option => ({ label: option.floorName, value: option.floorNumber })))))
                .then(resAll => {
                    let options = []
                    resAll.forEach(res => {
                        res.forEach(option => {
                            if (!options.some(_ => option.value === _.value)) options.push(option)
                        })
                    })
                    setState({ floors: options })
                })
        }
    }, [setState])

    useEffect(() => {
        objectService.getAll().then(_ => {
            const options = _.map(option => ({ label: option.name, value: Number(option.id) }))
            setState({ objects: options })
        })
    }, [setState])

    useEffect(() => {
        if (queryParams.objectsId) {
            const { objectsId } = queryParams
            getCorpOptions(objectsId)
        } else {
            setState({ corps: [] })
            setQueryParams({ corpsId: [] })
            setState({ floors: [] })
            setQueryParams({ floorNumbers: [] })
        }
        //eslint-disable-next-line
    }, [queryParams.objectsId])
    useEffect(() => {
        if (queryParams.corpsId) {
            const { corpsId } = queryParams
            getFloorsOptions(corpsId)
        } else {
            setState({ corps: [] })
            setQueryParams({ corpsId: [] })
            setState({ floors: [] })
            setQueryParams({ floorNumbers: [] })
        }
        //eslint-disable-next-line
    }, [queryParams.corpsId])



    return <>

        <div
            style={{
                display: (filterVisible && 'block') || 'none',
                transition: '.3s',
                marginBottom: filterVisible && '1rem'
            }}
        >

            <MDBCardTitle className="card-title d-flex justify-content-between">Фильтры
                <button
                    className="btn-no-styles"
                    style={{fontSize: 14}}
                    onClick={() => setFilterVisible(prev => !prev)}
                >
                    <span className="pr-2"><MDBIcon icon="angle-up"/></span>
                    Скрыть основные фильтры
                </button>

            </MDBCardTitle>
            <MDBRow>
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <Select
                        isMulti
                        className="border-danger"
                        placeholder="Выбрать объект"
                        value={fooFindMulti(objects, queryParams.objectsId)}
                        options={objects}
                        onChange={e => setMultiParams('objectsId', e)}
                    />
                </MDBCol>
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <Select
                        isMulti
                        isDisabled={!corps.length}
                        className="border-danger"
                        placeholder="Выбрать корпус"
                        value={(corps && fooFindMulti(corps, queryParams.corpsId)) || null}
                        options={corps}
                        onChange={e => setMultiParams('corpsId', e)}
                    />
                </MDBCol>
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <Select
                        isMulti
                        placeholder={'Выбрать этаж'}
                        isDisabled={!floors.length}
                        options={floors}
                        value={(floors && fooFindMulti(floors, queryParams.floorNumbers)) || null}
                        onChange={e => setMultiParams('floorNumbers', e)}
                    />
                </MDBCol>
            </MDBRow>
            <hr className="mt-0 mb-2"/>
            <MDBRow>
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <Select
                        placeholder={'Вид'}
                        options={typeOneOptions}
                        value={fooFind(typeOneOptions, queryParams.type1)}
                        onChange={e => setParam('type1', e.value)}
                    />
                </MDBCol>
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <Select
                        placeholder={'Тип'}
                        options={typeTwoOptions}
                        value={fooFind(typeTwoOptions, queryParams.type2)}
                        onChange={e => setParam('type2', e.value)}
                        isDisabled={!typeTwoOptions.length}
                    />
                </MDBCol>
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <Select
                        placeholder={'Состояние'}
                        options={stateOptions}
                        value={fooFind(stateOptions, queryParams.roomState)}
                        onChange={e => setParam('roomState', e.value)}
                    />
                </MDBCol>
            </MDBRow>
            <hr className="mt-0 mb-2"/>
            <MDBRow>
                {/*<MDBCol className="mb-3" sm={12} lg={6} xl={3}>
                    <InputDebounce
                        group
                        type="text"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        label="Название арендатора"
                        valueDefault={queryParams.inputRenterName || ''}
                        getValue={val => setParam('inputRenterName', val)}
                    />
                </MDBCol>*/}
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <InputDebounce
                        group
                        type="number"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        label="Площадь от"
                        valueDefault={queryParams.squareFrom || ''}
                        getValue={val => setParam('squareFrom', val)}
                    />
                </MDBCol>
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <InputDebounce
                        group
                        type="number"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        label="Площадь до"
                        valueDefault={queryParams.squareTo || ''}
                        getValue={val => setParam('squareTo', val)}
                    />
                </MDBCol>
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <InputDebounce
                        group
                        type="number"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        label="Сумма от"
                        valueDefault={queryParams.amountFrom || ''}
                        getValue={val => setParam('amountFrom', val)}
                    />
                </MDBCol>
                <MDBCol className="mb-2" sm={12} lg={6} xl={3}>
                    <InputDebounce
                        group
                        type="number"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        label="Сумма до"
                        valueDefault={queryParams.amountTo || ''}
                        getValue={val => setParam('amountTo', val)}
                    />
                </MDBCol>
                {/* <MDBCol className="mb-3" sm={12} lg={6} xl={3}>
                    <InputDebounce
                        group
                        type="text"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        label="Вид деятельности"
                        valueDefault={queryParams.inputKindActivity || ''}
                        getValue={val => setParam('inputKindActivity', val)}
                    />
                </MDBCol>*/}
            </MDBRow>
            <hr className="mt-0 mb-2"/>
            <MDBRow>
                <MDBCol className="mb-3" sm={12} lg={6}>
                    <div className="mt-2 mb-2">Начало действия договора</div>
                    <MDBRow>
                        <MDBCol sm={12} lg={6} className="d-flex mb-2 mt-1">
                            <div className="d-flex align-items-center mr-2">от</div>
                            <DateInput name="dateTimeSigningFrom"/>
                        </MDBCol>
                        <MDBCol sm={12} lg={6} className="d-flex mb-2 mt-1">
                            <div className="d-flex align-items-center mr-2">до</div>
                            <DateInput name="dateTimeSigningTo"/>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol className="mb-3" sm={12} lg={6}>
                    <div className="mt-2 mb-2">Конец действия договора</div>
                    <MDBRow>
                        <MDBCol sm={12} lg={6} className="d-flex mb-2 mt-1">
                            <div className="d-flex align-items-center mr-2">от</div>
                            <DateInput name="dateTimeCompletionFrom"/>
                        </MDBCol>
                        <MDBCol sm={12} lg={6} className="d-flex mb-2 mt-1">
                            <div className="d-flex align-items-center mr-2">до</div>
                            <DateInput name="dateTimeCompletionTo"/>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            <hr className="mt-0"/>
            <div>
                {Object.keys(visibleColumns).map(key => {
                    return (
                        <MDBBadge
                            key={key}
                            style={{ cursor: 'pointer' }}
                            pill
                            onClick={check(key)}
                            className="p-2 mr-2 mb-2 none-select"
                            color={visibleColumns[key].checked
                                ? (visibleColumns[key].color ?? 'primary')
                                : 'light'
                            }
                        >
                            {visibleColumns[key].label}
                        </MDBBadge>
                    )
                })}
            </div>
            <hr className=" d-sm-block d-md-none"/>
            <div className=" d-sm-block d-md-none">
                <MDBBtn
                    className="btn"
                    color="primary"
                    onClick={() => props.setFilterVisible(false)}
                    style={{ width: '100%' }}
                >Спрятать фильтры</MDBBtn>
            </div>

        </div>
        <div>
            <MDBRow>
                <MDBCol xs={12} md={6} lg={4}>
                    <InputDebounce
                        group
                        type="text"
                        containerClass="m-0 mb-2"
                        className="m-0"
                        outline
                        label="Поиск"
                        valueDefault={queryParams.inputRoomName || ''}
                        delay={300}
                        getValue={val => setParam('inputRoomName', val)}
                    />
                </MDBCol>
                <MDBCol xs={12} md={6} lg={4} className='pt-5'>
                    <RadioButtons
                        options={[
                            {
                                label: 'Все',
                                value: 'All'
                            },
                            {
                                label: 'С арендаторами',
                                value: 'WithContract'
                            },
                            {
                                label: 'Свободные',
                                value: 'WithoutContract'
                            }
                        ]}
                        onChange={value => setParam('madeFilters', value)}
                        value={queryParams.madeFilters}
                    />
                </MDBCol>

                <MDBCol xs={12} md={6} lg={4}
                        className="pt-2"
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-start',
                            fontSize: 14
                        }}>
                    {filterVisible
                        ? (
                            <MDBBadge
                                onClick={() => deleteAllParams(props.history)} color="primary"
                                style={{ cursor: 'pointer', fontSize: 14, lineHeight: '20px', fontWeight: 500 }}
                                pill
                            >
                                Сбросить фильтры
                            </MDBBadge>
                        ) : (
                            <button
                                className="btn-no-styles"
                                onClick={() => setFilterVisible(prev => !prev)}
                            >
                                <span className="pr-2"><MDBIcon icon="angle-down"/></span>
                                Показать все фильтры
                            </button>
                        )
                    }

                </MDBCol>
            </MDBRow>
        </div>
    </>
}
export default withQueryParams()(RoomsFilter)

const noSelectedOption = { label: 'Не выбран', value: undefined }

const fooFind = (options, findObj) => {
    return options.find(obj => Number(obj.value) === Number(findObj)) ?? null
}

const fooFindMulti = (options, obj) => {
    if (Array.isArray(obj)) {
        const arr = obj.map(el => Number(el))
        return options.filter(opt => arr.includes(opt.value))
    }
    return fooFind(options, obj)
}
import React, { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class MyModal extends Component {
    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        return (
            <>
                {this.props.component(this.toggle)}
                <MDBModal size={this.props.size} isOpen={this.state.modal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}>{this.props.title}</MDBModalHeader>
                    <MDBModalBody>
                        {this.props.children}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggle}>{this.props.cancelText || 'Закрыть'}</MDBBtn>
                        {typeof this.props.okBtn === 'function' && this.props.okBtn(this.toggle)}
                    </MDBModalFooter>
                </MDBModal>
            </>
        );
    }
}

export default MyModal;
import React, {useState} from 'react';
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow
}                        from "mdbreact";
import {
    Link
}                        from "react-router-dom";
import {
    useHistory
}                        from "react-router";
import {
    Grid,
    Paper,
    Tooltip
}                        from "@material-ui/core";


const dataFake = [
    {"columns": ["ДАТА и ВРЕМЯ ЗАПИСИ", "№ СЧЕТЧИКА", "НАЗВАНИЕ", "ВИД", "ЗНАЧЕНИЕ", "ЕД.ИЗМЕРЕНИЯ", "Потребление", "Тариф", "Сумма", "ПЕРИОД", "ГОД", "Примечание"]},
    {
        dateCreate       : "17.03.2023 18:57",
        utilityName      : "Холодное водоснаюжение",
        utilityMeter : {
            id: 1,
            serialNumber: "321654_ХВ_Счетчик_1",
            name: "Счетчик 1 в туалете на первом этаже",
            description: "321654_ХВ_Счетчик_1",
            lastValue: "321",
            lastValueDate: "26.02.2023 09:11",
        },
        value: 123

    }
]


const UtilitiesList = () => {
    const history = useHistory ()
    const [headerBreadcrumbs, setHeaderBreadcrumbs] = useState ("Журнал коммунальных услуг")

    return (
        <>
            <BreadCrumbs history={history} header={headerBreadcrumbs}/>
            <Grid container spacing={2}>
                <Grid item xs={6} md={12}>
                    <Paper elevation={3} className="pa-24">
                        <h4>Привет, я журанл коммунальных услуг</h4>
                        <p>Тут будет таблица с данными</p>
                        <br/>
                        <p>Раздел находится в разработке</p>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default UtilitiesList;


const BreadCrumbs = ({history, header}) => {
    const breadcrumbsChain = [['Главная', '/main']]
    let itemsChain = []
    let i = 0
    breadcrumbsChain.forEach (function (item) {
        i++
        itemsChain.push (<MDBBreadcrumbItem key={i}><Link to={item[1]}>{item[0]}</Link></MDBBreadcrumbItem>)
    })
    itemsChain.push (<MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>);
    itemsChain.unshift (<MDBBreadcrumbItem key={321}><span className="link_to_breadcrumbs" color="link"
                                                           onClick={history.goBack}>Назад</span></MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" className="pa-16">
                <MDBRow between>
                    <MDBCol className="d-flex align-items-center">
                        <Tooltip title="Добавить запись в журанале приборов учёта" placement="bottom">
                            <Link to="/utilities/new" className="mr-3">
                                <MDBBtn
                                    floating
                                    size="sm"
                                    className="z-depth-1"
                                    color="success"
                                >
                                    <MDBIcon icon="plus"/>
                                </MDBBtn>
                            </Link>
                        </Tooltip>
                        <MDBCardTitle tag="h5" className="mb-0">{header}</MDBCardTitle>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}

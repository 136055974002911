import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import BreadcrumSection from "../../commonSections/BreadcrumSection";
import { ownersService } from "../../../services/ownersService";
import { withRouter } from "react-router";
import OwnerSinglePage from "./pages/OwnerSinglePage";

const OwnerPageContainer = props => {
  const [owner, setOwner] = useState();
  const id = props.match.params.ownerId;

  const breadcrumsChain = [
    ["Главная", "/"],
    ["Собственники", "/owners"]
  ];

  useEffect(() => {
    ownersService.getById(id).then(_ => setOwner(_));
  }, [id]);

  if (owner) {
    return (
      <>
        <BreadcrumSection
          h2={owner.shortName || ""}
          chain={breadcrumsChain}
          buttonName={false}
          href="owners/create"
        />
        <OwnerSinglePage owner={owner} />
      </>
    );
  } else return <LinearProgress />;
};

export default withRouter(OwnerPageContainer);

import React, {useCallback, useEffect, useMemo, useState}                       from 'react'
import {connect}                                                                from 'react-redux'
import LinearProgress                                                           from '@material-ui/core/LinearProgress'
import {useSnackbar}                                                            from 'notistack'
import {MDBBreadcrumb, MDBBreadcrumbItem, MDBCard, MDBCardBody, MDBCol, MDBRow} from 'mdbreact'
import moment                                                                   from 'moment'
import {Link}                                                                   from 'react-router-dom'

import {roomState, type1, type2} from '../../../utils/RoomTypes'
import {useHistory, withRouter}  from 'react-router'
import {roomService}             from '../../../services/roomService'
import {objectService}           from '../../../services/objectService'
import {buildingService}         from '../../../services/buildingService'
import {floorService}            from '../../../services/floorService'
import FloorPlanner              from '../../floor-planner'
import {getImageUrl}             from '../../../utils/Others'

const RentalObjectPage = props => {
    const [room, setRoom]           = useState()
    const [floor, setFloor]         = useState()
    const [object, setObject]       = useState()
    const [building, setBuilding]   = useState()
    const [floorData, setFloorData] = useState()
    const [schema, setSchema]       = useState()
    const id                        = props.match.params.roomId
    const {enqueueSnackbar}         = useSnackbar()
    const history                   = useHistory()
    const floorId                   = useMemo(() => room?.floorId, [room])

    const includeInPlanner = useMemo(() => (Array.isArray(floorData) ? floorData?.some(item => item.id === room.id) : false), [floorData, room])

    const owners = useMemo(() => room?.contract?.contractOwnersShares ? room.contract.contractOwnersShares.map(item => ({
        id: item.id, name: item.owner.shortName, value: item.id
    })) : [], [room])

    const loadBuilding = useCallback(corpId => {
        buildingService.getById(corpId).then(_ => setBuilding(_))
    }, [])

    const loadObject = useCallback(objectId => {
        objectService.getById(objectId).then(_ => setObject(_))
    }, [])


    const loadRoom = useCallback(async () => {
        try {
            await roomService.getById(id).then(_ => {
                floorService.getById(_.floorId).then(floor => {
                    setFloor(floor)
                    if (floor.images.length) {
                        const {id, filePath} = floor.images[0]
                        setSchema({
                            id, url: getImageUrl(filePath)
                        })
                    }
                })
                //enqueueSnackbar('Помещение ' + id, {variant: 'success'})
                setRoom(_)
                loadBuilding(_.corpId)
                loadObject(_.objectId)
            })
        } catch (err) {
            enqueueSnackbar(err, {variant: 'error'})
        }
    }, [id, enqueueSnackbar, setRoom, loadBuilding, loadObject])

    useEffect(() => {
        loadRoom()
    }, [loadRoom])

    useEffect(() => {
        if (floorId) {
            floorService
                .getPlannerById(floorId)
                .then(data => {
                    if (data) {
                        setFloorData(data)
                    }
                })
                .catch(error => {
                    console.error({error})
                })
        }
    }, [floorId])

    if (!room) {
        return <LinearProgress/>
    }
    if (room && object && building && floor) {
        const contractDeadline = room.contract ? moment(room.contract.completionDateTime).format('DD.MM.YYYY') : null
        const ownersHTML       = owners ? owners.map(owner => {
            return (<React.Fragment key={owner.id}>
                <Link
                    className="rentalObject_inTable_data"
                    to={'/owners/' + owner.value}
                    key={owner.value}
                >
                    {owner.name}
                </Link>
                <br/>
            </React.Fragment>)
        }) : ' - '

        return (<React.Fragment>
            <BreadCrumbs object={object} room={room} building={building} history={history}/>
            <MDBRow className="mt-30">
                <MDBCol sm={12}>
                    <MDBCard>
                        {includeInPlanner && schema ? (<FloorPlanner
                            focusRoomId={room?.id}
                            editable={false}
                            data={floorData}
                            rooms={[]}
                            schema={schema}
                            floorId={room.floorId}
                        />) : (<MDBCardBody className="room_card_data text-center">
                            Объекта аренды нет схеме.{' '}
                            <Link to={`/floor/edit/${floorId}?lastRoomId=${room.id}`}>
                                Добавить
                            </Link>
                        </MDBCardBody>)}
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <MDBRow className="mt-30">
                <MDBCol md={6}>
                    <h4 className="mb-0"><Link className="btn btn-blue-grey btn-sm btn-floating mr-10" to={`/objects/${object.id}/buildings/${building.id}/floors/${floor.id}/update`}><i className="fas fa-wrench"/></Link>{room.floorName} - план</h4>
                </MDBCol>
                <MDBCol md={6} className='text-right'>
                    <Link className="btn btn-outline-success" to={`/rooms/create`} style={{borderRadius: '20px'}}><i
                        className='fa fa-plus mr-10'/> Добавить объект аренды</Link>
                </MDBCol>
            </MDBRow>
            <MDBRow className="mt-30">
                <MDBCol sm={12} lg={5}>
                    <MDBCard className={'room'}>
                        <MDBCardBody
                            style={{backgroundImage: `url(${room.images.length ? getImageUrl(room.images[0].filePath) : "/img/no_image.png"})`}}
                            className={'room_card_image'}
                        >
                                <span className="room_squre">
                                    {new Intl.NumberFormat('ru-RU', {maximumFractionDigits: 2}).format(room.square)}{' '}
                                    <span className="room_units">
                                        м<sup>2</sup>
                                    </span>
                                </span>
                            <span className="room_amountRent">
                                    {room.factuallyRents ? new Intl.NumberFormat('ru-RU', {maximumFractionDigits: 2}).format(room.factuallyRents) : new Intl.NumberFormat('ru-RU', {maximumFractionDigits: 2}).format(room.amountRent)}
                                <span className="room_units"> р.</span>
                                </span>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol sm={12} lg={7}>
                    <MDBCard style={{height: '100%'}} className={'room'}>
                        <MDBCardBody className={'room_card_data'}>
                            <MDBRow className="mb-30">
                                <MDBCol>
                                    <h2 className="rentalObject_header_2">
                                        {room.name}{' '}
                                        <Link to={'/rooms/update/' + room.id}>
                                            <i className="fas fa-wrench ml-10 update_icon"/>
                                        </Link>
                                    </h2>
                                </MDBCol>
                                <MDBCol className='text-right'>
                                    <Link
                                        className='btn btn-outline-primary btn-rounded no-shadow'
                                        to={'/rooms?corpsId=' + building.id + '&elementsCount=100&floorNumbers=' + floor.floorNumber + '&madeFilters=All&objectsId=' + object.id + '&page=1'}
                                    >{room.floorName}</Link>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol sm={12} lg={6}>
                                    <table className={'table room'}>
                                        <tbody>
                                        <tr>
                                            <td style={{width: '160px'}}>
                                                <i className="fa fa-briefcase mr-10"/>{' '}
                                                Арендатор
                                            </td>
                                            <td>
                                                {room.contract ? (<Link
                                                    to={'/renters/' + room.contract.company.id}
                                                >
                                                    {room.contract.company.shortName}
                                                </Link>) : ('Свободно')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: '160px'}}>
                                                <i className="fa fa-user mr-10"/>
                                                {owners.length > 0 ? 'Собственники' : 'Собственник'}
                                            </td>
                                            {room && <td>{ownersHTML}</td>}
                                        </tr>
                                        <tr>
                                            <td style={{width: '160px'}}>
                                                <i className="fas fa-file-alt mr-10"/>{' '}
                                                Договор
                                            </td>
                                            <td>
                                                {// TODO: Добавить возможность скачивания архива с докуентами по помещению на карточке помещения
                                                    room.contract ? (<Link
                                                        to={'/contracts/' + room.contract.id}
                                                    >
                                                        {room.contract.name}
                                                    </Link>) : ('Свободно')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: '160px'}}>
                                                <i className="fas fa-clock mr-10"/> Срок
                                                договора
                                            </td>
                                            <td>
                                                {room.contract ? contractDeadline : ' - '}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </MDBCol>
                                <MDBCol sm={12} lg={6}>
                                    <MDBRow>
                                        <MDBCol>
                                            <h3 className="room_card_header_2">
                                                {'Тип: ' + type1[room.type1] + ' - ' + type2[room.type2]}
                                            </h3>
                                            <h3 className="room_card_header_2">
                                                {'Состояние: ' + roomState[room.roomState]}
                                            </h3>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="mt-30">
                                        <MDBCol>
                                            <h3 className="room_card_header_2">
                                                {room.blockName}
                                            </h3>
                                            <div className="room_text_block">
                                                {room.blockDescription}
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>


        </React.Fragment>)
    }
    return <LinearProgress/>
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RentalObjectPage))


const BreadCrumbs     = ({object, building, room, history}) => {
    const breadcrumbsChain = [['Главная', '/main'], ['Объекты', '/objects']]
    let itemsChain         = []
    let i                  = 0
    breadcrumbsChain.forEach(function (item) {
        i++
        itemsChain.push(<MDBBreadcrumbItem key={i}>
            <Link to={item[1]}>{item[0]}</Link>
        </MDBBreadcrumbItem>)
    })
    itemsChain.push(<MDBBreadcrumbItem key={34345}>
        <Link to={"/objects/" + object.id}>{object.name}</Link>
    </MDBBreadcrumbItem>)
    itemsChain.push(<MDBBreadcrumbItem active key={0}>
        {building.corpName}
    </MDBBreadcrumbItem>)
    itemsChain.unshift(<MDBBreadcrumbItem key={321}>
        <span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span>
    </MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" style={{padding: 20}}>
                <MDBRow between>
                    <MDBCol size="4" className="d-flex align-items-center">
                        <div className='page-title 2rows'>
                            <h4>{room.name} <i
                                className="fas fa-file-download ml-10 rentalObject-icon-download"
                                onClick={() => getPdf(room, building, object)}
                            /></h4>
                            <h5 className='breadcrumb_secondary_name'>{object.name + ' - ' + building.corpName + ' - ' + room.floorName}</h5>

                        </div>
                    </MDBCol>
                    <MDBCol size="8" className="text-right room">
                        <h2 className="room_card_header_2">
                            {object.name + ' - ' + building.corpName}
                        </h2>
                        <h3 className="room_card_header_2">
                            <i className="fas fa-map-marker-alt mr-10"/> {object.cityName + ", " + object.streetName + ", " + object.houseNumber}
                        </h3>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}

function getPdf(room, building, object) {
    const a         = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)
    roomService.getPdfByRentalObject(room.id).then(res => {
        a.href     = window.URL.createObjectURL(res)
        a.download = 'Презентация №' + room.id + ' ' + object.name + ' - ' + building.corpName + ' - ' + room.name + '.pdf'
        a.click()
    })
}
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { roomState, type1, type2 } from '../../../../utils/RoomTypes'
import { objectService } from '../../../../services/objectService'
import { floorService } from '../../../../services/floorService'
import { getQuantityString } from '../../../../utils/Others'

const CompanyRoomsTableRow = props => {
    const { room, index } = props
    const [object, setObject] = useState()
    const [floor, setFloor] = useState()

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        (async() => {
            const object = await objectService.getById(room.objectId, {signal})
            if(object) setObject(object)
        })()

        return () => controller.abort()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        (async() => {
            const floor = await floorService.getById(room.objectId, {signal})
            if(floor) setFloor(floor)
        })()

        return () => controller.abort()
        // eslint-disable-next-line
    }, [])

    return (
        <tr key={room.id}>
            <td>{index + 1}</td>
            <td>
                <Link to={`/rooms/${room.id}`}>
                    {room.name}
                </Link>
            </td>
            <td>{getQuantityString(room.square, 'м²')}</td>
            <td>{getQuantityString((room.factuallyRents/room.square), 'р./м²')}</td>
            <td>{getQuantityString(room.factuallyRents)}</td>
            <td>{roomState[room.roomState]}</td>
            <td>{type1[Number(room.type1)]}</td>
            <td>{type2[Number(room.type2)]}</td>
            <td>
                <Link to={'/contracts/' + room.contract.id}>{room.contract.name} </Link>{' '}
            </td>
            <td>{object && <Link to={'/objects/' + object.id}> {object.name} </Link>}</td>
            <td>{floor && floor.floorNumber} </td>
        </tr>
    )
}
export default CompanyRoomsTableRow

import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import SmartTable from '../../../commonSections/SmartTable'
import Filter from './filter'
import EditBlockDropdown from '../../../others/EditBlockDropdown'
import { permissionsHelper } from '../../../../utils/permissionsHelper'
import TablePagination from '../../../commonSections/TablePagination'



const OwnersTable = ({setParam, pagination, queryParams, ...props}) => {
    const [permissions, setPermissions] = useState()
    const [showFullFilter, setShowFullFilter] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState(initialVisibleColumns);

    const { page, count, fullCount } = pagination

    const tableData = useMemo(() => ({
        columns: Object.keys(initialVisibleColumns).map(key => ({
            ...initialVisibleColumns[key],
            field: key
        })),
        rows: mapData(props.data, page, count, props.deleteFunc, permissions)
    }),[props, permissions, page, count]);


    useEffect(() => {
        (async () => {
            setPermissions(await permissionsHelper.scopePermissions())
        })()
    }, [])

    return <>
        <Filter {...{visibleColumns, setVisibleColumns, show: showFullFilter, setShow: setShowFullFilter, setParam, queryParams}}/>
        <SmartTable {...{ data: tableData, visibleColumns, isSortable: true }} />
        <TablePagination
            {...{
                page,
                count,
                fullCount,
                setCount: count => setParam('elementsCount', count),
                setPage: page => setParam('page', page)
            }}
        />
        </>

};

export default OwnersTable;

const columns = {
    num: {
        key: 'num',
        label: '#'
    },
    name: {
        key: 'name',
        label: 'Название'
    },
    inn: {
        key: 'inn',
        label: 'ИНН'
    },
    contactPerson : {
        key: 'contactPerson',
        label: 'Контактное лицо'
    },
    edit: {
        key: 'edit',
        label: 'Редактирование'
    }
}

const initialVisibleColumns = {
    [columns.num.key]: { checked: true, label: columns.num.label, width: 50, headAlign: 'center', bodyAlign: 'center'  },
    [columns.name.key]: { checked: true, label: columns.name.label, width: 500},
    [columns.inn.key]: { checked: true, label: columns.inn.label },
    [columns.contactPerson.key]: { checked: true, label: columns.contactPerson.label},
    [columns.edit.key]: { checked: false, label: columns.edit.label, color: 'red'}
}

const mapData = (data, page, elementsCount, deleteFunc, permissions) => {
    return data.map((item, index) => {
        return {
            [columns.num.key]: (Number(page) - 1) * Number(elementsCount) + index + 1,
            [columns.name.key]: <Name name={item.fullName} id={item.id} address={item.mailingAddress}/>,
            [columns.inn.key]: item.inn,
            [columns.contactPerson.key]: <Contact head={item.head} email={item.email} phone={item.phone}/>,
            [columns.edit.key]: <EditBlockDropdown
                permissions={permissions}
                deleteFunc={() => deleteFunc(item.id)}
                editLink={`/owners/update/${item.id}`}
                deleteLabel={`Вы действительно хотите удалить собственника ${item.fullName}?`}
                detailsLink={`/owners/${item.id}`}
            />
        }

    })
}

const Name = ({name, id, address}) => (
    <div>
        <Link to={`/owners/${id}`}>{name}</Link>
        {address && <SubTitle data={address} />}
    </div>
);

const Contact = ({head, email, phone}) => {
    const data = [email, phone].filter(i => !!i);
    return (
        <>
            <div>
                {head}
            </div>
            {(email || phone) && <SubTitle
                data={data.join(', ')}
            />}
        </>
    )
}


const SubTitle = ({data}) => <div style={{
    fontSize: 11,
    color: '#797979'
}}>{data}</div>
import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody
} from "mdbreact";

const OwnerSinglePage = props => {
  return (
    <MDBCard>
      <MDBRow>
        <MDBCol>
          <MDBCardBody>
            <MDBCardTitle>{props.owner.fullName}</MDBCardTitle>

            <MDBTable>
              <MDBTableBody>
                <tr>
                  <td>Вид деятельности</td>
                  <td colSpan={2}>{props.owner.kindActivity}</td>
                </tr>
                <tr>
                  <td>Юридический адрес:</td>
                  <td colSpan={2}>{props.owner.legalAddress}</td>
                </tr>
                <tr>
                  <td>Руководитель:</td>
                  <td>{props.owner.head}</td>
                  <td>{props.owner.phoneHead}</td>
                </tr>
                <tr>
                  <td>Главный бухгалтер:</td>
                  <td>{props.owner.chiefAccountant}</td>
                  <td>{props.owner.phoneChiefAccountant}</td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCol>
        <MDBCol></MDBCol>
      </MDBRow>
    </MDBCard>
  );
};

export default OwnerSinglePage;

import React, {useCallback, useEffect, useState} from "react";
import {useSnackbar}                             from "notistack";
import {useHistory}                              from "react-router";
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem,
    MDBCol,
    MDBIcon,
    MDBRow,
}                                                from "mdbreact";
import {objectService}                           from "../../../services/objectService";
import {LinearProgress}                          from "@material-ui/core";
import {Link, NavLink}                           from "react-router-dom";
import PhotoDefault                              from "../../../static/compayDefaultImage.jpg";


const ObjectP = (props) => {
    const [loading, setLoading]   = useState(true);
    const objectId                = props.match.params.objectId;

    //states
    const history           = useHistory();
    const {enqueueSnackbar} = useSnackbar();


    const [object, setObject] = useState();

    const loadObject   = useCallback(() => {
        objectService.getById(objectId).then(_ => {
            setObject(_)
            setLoading(false)
        })
    }, [])
    const objectDelete = () => {
        objectService.remove(objectId).then(_ => {
            history.goBack()
            enqueueSnackbar("Объект удалён", {
                variant: 'success'
            })
        })
    }

    useEffect(() => {
        setLoading(true)
        loadObject()
    }, [loadObject])

    if (loading) {
        return <LinearProgress/>
    }
    return (<>
        <Breadcrumbs
            object={object}
            href={"/objects/update/" + objectId}
            history={history}
            objectDelete={objectDelete}
        />
        <MDBRow>
            <Building object={object} history={history}/>
        </MDBRow>
    </>)
}
export default ObjectP

const Breadcrumbs = ({object, href, history, objectDelete}) => {
    const breadCrumbStyle = {
        fontSize: "0.8rem",
    };
    const chain           = [["Главная", "/"], ["Объекты", "/objects"],]
    let itemsChain        = chain.map((_, i) => {
        return (<MDBBreadcrumbItem key={i}><Link to={_[1]}>{_[0]}</Link></MDBBreadcrumbItem>);
    });
    itemsChain.push(<MDBBreadcrumbItem active key={159}>{object.name}</MDBBreadcrumbItem>);
    itemsChain.unshift(<MDBBreadcrumbItem key={321}>
        <span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span>
    </MDBBreadcrumbItem>);
    return (<>
        {chain.length > 0 && (<MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>)}
        <MDBCard className="mb-30 breadcrumb_object_card">
            <MDBCardBody id="breadcrumb" className="pa-24">
                <MDBRow between>
                    <MDBCol sm={9} className="d-flex align-items-center">
                        <Link to={href} className="mr-15 update_link"><MDBIcon icon="wrench" size="2x"/></Link>
                        <MDBCardTitle tag="h5" className="g-mb-0" style={{margin: 0}}>{object.name}</MDBCardTitle>
                    </MDBCol>
                    <MDBCol sm={3} className="text-right">
                        <MDBIcon icon="trash-alt" size="2x" onClick={() => {objectDelete()}}/>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard></>);
}

const Building = ({object, history}) => {
    return object.corps.map((building, index) => {

        // Считаем помещения в корпусе
        let roomCount = 0;
        if (building.floors.length !== 0) {
            building.floors.forEach((_) => {
                if (_.rooms.length !== 0) {
                    roomCount += _.rooms.length;
                }
            });
        }
        let i=0
        building.floors.forEach(floor => {if (floor.mapString !== "") i++})


        const redirectLink = `/rooms?&page=1&elementsCount=20&objectsId=${object.objectId}&corpsId=${building.id}`;
        return (<MDBCol md="3" key={index}>
            <MDBCard wide onClick={() => {history.push(`/objects/${object.id}/buildings/${building.id}`)}} className="card_building">
                {building.images.length !== 0 && (<MDBCarousel
                    activeItem={1}
                    length={building.images.length}
                    showControls={true}
                    showIndicators={true}
                >
                    <MDBCarouselInner>
                        <ImagesCarusel/>
                    </MDBCarouselInner>
                </MDBCarousel>)}

                {building.images.length === 0 && (<MDBCardImage
                    cascade
                    className="w-100"
                    style={{height: 200}}
                    src={PhotoDefault}
                    top
                />)}

                <MDBCardBody cascade className="text-center">
                    <NavLink to={redirectLink} className="text-muted">
                        <h5>Корпус</h5>
                    </NavLink>

                    <MDBCardTitle>
                        <NavLink to={redirectLink}>
                            <strong>
                                <span>{building.corpName}</span>
                            </strong>
                        </NavLink>
                    </MDBCardTitle>
                    <MDBRow>
                        <MDBCol className="text-left" sm="9">
                            <MDBCardText className="g-mb-0 g-mt-10">
                                {`Количество этажей: ${building.floors.length}`}
                            </MDBCardText>
                            <MDBCardText className="g-mb-0 g-mt-10">
                                {`Этажей с планировками: ${i}`}
                            </MDBCardText>
                            <MDBCardText className="g-ma-0">
                                {`Количество помещений: ${roomCount}`}
                            </MDBCardText>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>)
    })
}

const ImagesCarusel = building => {
    return building.images.length > 0 && building.images.map((img, index) => {
        let imageUrl = "https://" + img.filePath.split("/var/www/rentto/data/www/")[1];
        return (<MDBCarouselItem key={index} itemId={index}>
            <MDBCardImage
                cascade
                src={imageUrl}
                className="w-100"
                style={{height: 200}}
                top
            />
        </MDBCarouselItem>);
    })
}

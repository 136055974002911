import React, { useEffect, useState } from 'react'
import { MDBCol, MDBInput, MDBRow, MDBSelect } from 'mdbreact'
import DatePicker from '../../../../others/DatePicker'
import moment from 'moment'
import { accrualPeriod } from '../../../../../utils/AccrualPeriod'
import {compareToNumber} from "../../../../../utils/Others";

const defualtOnTypeTwo = [
    {
        text: 'Единоразовое',
        value: 0,
        checked: true
    }
]

export default ({ state, selectHandleChange, handleChange, setState }) => {
    const [periodOptions, setPeriodOptions] = useState([])
    useEffect(() => {
        generatePeriodOptions()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (Number(state.accrualType) === 2) {
            setPeriodOptions(defualtOnTypeTwo)
        } else if (periodOptions.length !== 0) {
            generatePeriodOptions()
        }
        // eslint-disable-next-line
    }, [state.accrualType, state.periodType])


    const generatePeriodOptions = () => {
        let periodOptions = []
        for (let key in accrualPeriod) {
            periodOptions.push({ text: accrualPeriod[key], value: key, checked:  compareToNumber(state.periodType, key)  })
        }
        setPeriodOptions(periodOptions)
    }


    return (
        <fieldset className="mb-4">
            <legend>Назначение</legend>
            <MDBRow>
                <MDBCol size="sm-4 mb-4">
                    <div className="grey-text">
                        <MDBInput
                            name="name"
                            label="Наименование начисления"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            onChange={e => handleChange('name', e)}
                            value={state.name}
                            containerClass="m-0 "
                            className="m-0"
                        />
                    </div>
                </MDBCol>
                <MDBCol size="sm-4  mb-4">
                    <div className="grey-text">
                        <MDBSelect
                            style={{ marginTop: 2, marginBottom: 0, height: 41 }}
                            name="periodType"
                            options={periodOptions}
                            value={state.periodType}
                            label="Период начисления"
                            getValue={e => selectHandleChange('periodType', e)}
                        />
                    </div>
                </MDBCol>
                <MDBCol size="sm-4  mb-4">
                    <div className="grey-text">
                        <DatePicker
                            label="Дата формирования"
                            style={{ marginTop: -7 }}
                            value={state.dateOfDelay}
                            onChange={date => setState({ dateOfDelay: moment(date) })}
                        />
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size="sm-12">
                    <div className="grey-text">
                        <MDBInput
                            name="description"
                            label="Краткое описание"
                            outline
                            group
                            type="textarea"
                            validate
                            error="wrong"
                            success="right"
                            onChange={e => handleChange('description', e)}
                            value={state.description}
                            containerClass="m-0"
                            className="m-0"
                        />
                    </div>
                </MDBCol>
            </MDBRow>
        </fieldset>
    )
}

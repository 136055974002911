import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBTooltip,
    MDBIcon
} from 'mdbreact'

class ModalPage extends Component {
    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        return (
            <>
                {this.props.children
                    ? this.props.children(this.toggle)
                    : <MDBTooltip placement="top">
                    <MDBBtn
                        className="m-0 z-depth-1"
                        floating
                        size="sm"
                        color="danger"
                        outline
                        onClick={this.toggle}
                    >
                        <MDBIcon icon="trash" style={{ color: '#ff3547', width: '100%' }}/>
                    </MDBBtn>
                    <span>Удалить</span>
                </MDBTooltip>}
                {
                    createPortal(<MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                        <MDBModalHeader toggle={this.toggle}>Подтвердите удаление</MDBModalHeader>
                        <MDBModalBody className="text-left">
                            {this.props.label}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.toggle}>Отмена</MDBBtn>
                            <MDBBtn
                                color="danger"
                                onClick={() => {
                                    this.props.delete()
                                    this.toggle()
                                }}
                            >
                                Удалить
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>,
                    document.body)
                }
            </>
        );
    }
}

export default ModalPage;

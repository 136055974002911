import React from 'react'
import { withRouter } from 'react-router'
import { LinearProgress } from '@material-ui/core'
import { MDBCol, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import moment from 'moment'
import { Link } from 'react-router-dom'

const CompanyContractsSection = ({ contracts }) => {
    if (contracts && contracts.length !== 0)
        return (
            <MDBCol>
                <MDBTable hover>
                    <MDBTableHead>
                        <tr>
                            <th>№</th>
                            <th>Номер договора</th>
                            <th>Собственник</th>
                            <th>Дата подписание договора</th>
                            <th>Начало действия</th>
                            <th>Начало действия</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {contracts.map((contract, index) => (
                            <tr key={'contract-of-renter-' + contract.id}>
                                <td>{index + 1}</td>
                                <td>
                                    <Link to={`/contracts/${contract.id}`}>{contract.name}</Link>
                                </td>
                                <td>
                                    { contract.contractOwnersShares.length ? contract.contractOwnersShares.map(({owner}, i) => {
                                        if(!owner){
                                            return null
                                        }
                                        const isLast = contract.contractOwnersShares.length - 1 === i;

                                        return (
                                            <React.Fragment key={owner.id}>
                                                <Link to={`/owners/${owner.id}`}>{owner.shortName}</Link>
                                                {!isLast && <>, </>}
                                            </React.Fragment>
                                        )
                                    }) : "-"}

                                </td>
                                <td>{moment(contract.signingDateTime).format('MM.DD.YYYY')}</td>
                                <td>{moment(contract.conclusionDateTime).format('MM.DD.YYYY')}</td>
                                <td>{moment(contract.completionDateTime).format('MM.DD.YYYY')}</td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCol>
        )
    if (contracts && contracts.length === 0)
        return <div className="d-flex justify-content-center"> На данный список договоров пуст</div>
    return <LinearProgress />
}

export default withRouter(CompanyContractsSection)

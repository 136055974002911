import React, {useEffect, useState} from 'react'
import {
    useDispatch, useSelector
}                                   from 'react-redux'
import LinearProgress               from '@material-ui/core/LinearProgress'
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardTitle, MDBCarousel, MDBCarouselInner,
    MDBCarouselItem,
    MDBCol,
    MDBIcon,
    MDBRow
}                                   from 'mdbreact'
import {objectService}              from '../../../../services/objectService'
import {setAllObjects}              from '../../../../store/objects/actions'
import {
    getImageUrl, getQuantityString
}                                   from '../../../../utils/Others'
import {useHistory}                 from 'react-router'
import {Link}                       from "react-router-dom";
import {Backdrop, Fade, Modal}      from "@material-ui/core";


const breadcrumsChain = [['Главная', '/']]

const ObjectsList = () => {
    //Redux
    const {allObjects, isLoading} = useSelector (state => ({
        allObjects    : state.objects.allObjects,
        isLoading     : state.objects.isLoading,
        currentObject : state.objects.currentObject,
        currentUser   : state.objects.currentUser,
        mainReportData: state.objects.mainReportData
    }))

    const [open, setOpen] = useState (false);
    const [images, setImages] = useState ([]);
    const modalOpen = images => {
        setImages (images)
        setOpen (true);
    }
    const modalClose = () => setOpen (false);

    //  Actions
    const dispatch = useDispatch ()

    // data fetching
    useEffect (() => {
        objectService.getAll ().then (res => {
            dispatch (setAllObjects (res))
        })
    }, [dispatch])

    return (<>
        {isLoading && <LinearProgress/>}
        <div className="row">
            <div className="col">
                <Breadcrums
                    createPredicate={true}
                    h2="Объекты"
                    chain={breadcrumsChain}
                    buttonName="Объект"
                    href="objects/create"
                    history={useHistory ()}
                />
            </div>
        </div>
        {   // TODO Не работает просмотр изображений, когда кликаешь на фотографию карточки объекта
            images && <TransitionsModal open={open} modalOpen={modalOpen} modalClose={modalClose} images={images}/>}

        {allObjects ? (allObjects.map (ob => {
            return (<div className="row" key={ob.id}>
                <div className="col">
                    <ObjectCard
                        id={ob.id}
                        key={ob.id}
                        name={ob.name}
                        cityName={ob.cityName}
                        streetName={ob.streetName}
                        houseNumber={ob.houseNumber}
                        images={ob.images}
                        objectAddition={ob.objectAddition}
                        modalOpen={modalOpen}
                    />
                </div>
            </div>)
        })) : (<LinearProgress/>)}
    </>)
}

export default ObjectsList

const Breadcrums = props => {

    const breadCrumStyle = {
        fontSize: "0.8rem",
    };

    const buttonName = props.buttonName;
    const header = props.h2;
    const chain = props.chain;
    const filterBtn = props.filterBtn;
    let itemsChain = [];
    let i = 0;
    chain.forEach (function (item) {
        i++;
        itemsChain.unshift (<MDBBreadcrumbItem key={321}>
            <span className="link_to_breadcrumbs" color="link" onClick={props.history.goBack}>Назад</span>
        </MDBBreadcrumbItem>);
        itemsChain.push (<MDBBreadcrumbItem key={i}>
            <Link to={item[1]}>{item[0]}</Link>
        </MDBBreadcrumbItem>);
    });
    itemsChain.push (<MDBBreadcrumbItem active key={0}>
        {header}
    </MDBBreadcrumbItem>);
    return (<>
        {props.chain.length > 0 && (<MDBBreadcrumb style={breadCrumStyle}>{itemsChain}</MDBBreadcrumb>)}
        <MDBCard className="mb-30">
            <MDBCardBody id="breadcrumb" className="pa-16">
                <MDBRow between>
                    <MDBCol size="9" className="d-flex align-items-center">
                        {buttonName && props.createPredicate && (<Link to={props.href} className="mr-3">
                            <MDBBtn
                                floating
                                size="sm"
                                className="z-depth-1"
                                color="success"
                            >
                                <MDBIcon icon="plus"/>
                            </MDBBtn>
                        </Link>)}
                        <MDBCardTitle tag="h5" className="g-mb-0" style={{margin: 0}}>
                            {header}
                        </MDBCardTitle>
                    </MDBCol>
                    {filterBtn && (<MDBCol className="d-flex justify-content-end align-items-center">
                        {filterBtn}
                    </MDBCol>)}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>);
}

const ObjectCard = props => {
    const {allSquare, technicalSquare, effectiveSquare, effectiveBusySquare, allCash} = props.objectAddition
    const efficiency = Math.round ((effectiveBusySquare / effectiveSquare) * 100)
    const history = useHistory ()
    const [corps, setCorps] = useState ()

    useEffect (() => {
        objectService.getById (props.id).then (res => setCorps (res.corps.length))
    }, [props.id])

    return (<>
        <MDBCard key={props.id} className="object_card mb-30">
            <div className="row">
                <div className="col-md-3 col-sm-12">
                    {props.images.length > 0 ? (<div
                        onClick={() => {props.modalOpen (props.images)}}
                        className="object__image"
                        style={{
                            backgroundImage: `url(${getImageUrl (props.images[0].filePath)})`
                        }}
                    />) : (<NoImagePlaceholder/>)}
                </div>
                <div className="col-md-9 col-sm-12" onClick={() => {history.push ('/objects/' + props.id)}}>
                    <div className="row">
                        <div className="col-md-5">
                            <div className={'description__col description__col--centered'}>
                                <h5 className="description__title">{props.name}</h5>
                                <span className={'description__amount'}>
                                    {getQuantityString (allSquare, '')}
                                    <span className="flag__sup-message">М<sup>2</sup></span>
                                </span>
                                <p className={'description__amount-tag'}>Общая площадь</p>
                                <p className={'description__adress'}>
                                    <span className={'text-uppercase'}>{props.cityName}</span>{' '}
                                    ,ул.
                                    <span className={'text-uppercase'}>
                                            {props.streetName}
                                        </span>{' '}
                                    ,д.
                                    <span className={'text-uppercase'}>
                                            {props.houseNumber}
                                        </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-2 py-16">
                            <div className="description__col">
                                {/*Здания*/}
                                <div className="description__info info">
                                    <div className="info__left-column">
                                        <i className="fas fa-city"/>
                                        <p className="info__text">Здания</p>
                                    </div>
                                    <p id="buildings" className="info__text">
                                        {props.objectAddition.buildingsCount}
                                    </p>
                                </div>

                                {/*Помещения*/}
                                <div className="description__info info">
                                    <div className="info__left-column">
                                        <i className="fas fa-sign-in-alt"/>
                                        <p className="info__text">Помещения</p>
                                    </div>
                                    <p id="buildings" className="info__text">
                                        {props.objectAddition.roomsCount}
                                    </p>
                                </div>

                                {/*Свободно*/}
                                <div className="description__info info">
                                    <div className="info__left-column">
                                        <i className="fas fa-user-tie"/>
                                        <p className="info__text">Свободно</p>
                                    </div>
                                    <p id="free" className="info__text">
                                        {props.objectAddition.freeRoomsCount}
                                    </p>
                                </div>

                                {/*Арендаторы*/}
                                <div className="description__info info">
                                    <div className="info__left-column">
                                        <i className="fas fa-briefcase"/>
                                        <p className="info__text">Арендаторы</p>
                                    </div>
                                    <p id="buildings" className="info__text">
                                        {props.objectAddition.rentersCount}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 py-16">
                            <div className="description__col description__col--centered ">
                                <section>
                                    <svg
                                        className="circle-chart"
                                        viewBox="0 0 33.83098862 33.83098862"
                                        width="130"
                                        height="130"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            className="circle-chart__background"
                                            stroke="#efefef"
                                            strokeWidth="2"
                                            fill="none"
                                            cx="16.91549431"
                                            cy="16.91549431"
                                            r="15.91549431"
                                        />
                                        <circle
                                            className="circle-chart__circle"
                                            stroke={efficiency > 80 ? '#3f9e37' : efficiency > 50 ? '#ffd065' : '#FF6875'}
                                            strokeWidth="2"
                                            strokeDasharray={efficiency ? efficiency + ',' + 100 : '0,100'}
                                            strokeLinecap="round"
                                            fill="none"
                                            cx="16.91549431"
                                            cy="16.91549431"
                                            r="15.91549431"
                                        />
                                        <g className="circle-chart__info">
                                            <text
                                                className="circle-chart__percent"
                                                x="16.91549431"
                                                y="15.5"
                                                alignmentBaseline="central"
                                                textAnchor="middle"
                                                fontSize="8"
                                            >
                                                {efficiency || 0}%
                                            </text>
                                            <text
                                                className="circle-chart__subline"
                                                x="16.91549431"
                                                y="20.5"
                                                alignmentBaseline="central"
                                                textAnchor="middle"
                                                fontSize="2"
                                            >
                                                {efficiency ? 'ЭФФЕКТИВНОСТЬ' : 'ДАННЫЕ НЕ КОРРЕКТНЫ'}
                                            </text>
                                        </g>
                                    </svg>
                                </section>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="description__area">
                                <div className="flag">
                                    <p className="flag__area">Полезная площадь</p>
                                    <h6 className="flag__weight">
                                        {getQuantityString (effectiveSquare, '')}
                                        <span className="flag__sup-message">
                                                М<sup>2</sup>
                                            </span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MDBCard>
    </>)
}

const NoImagePlaceholder = () => {
    return (<>
        <div className="no-image">
            <div className="no-image__text"/>
        </div>
    </>)
}

const TransitionsModal = ({open, modalClose, images}) => {

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={modalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 300,
                }}
            >
                <Fade in={open}>
                    <div className="object-card modal-image-layer" >
                        <ImageGallery imagesArray={images} cssClass={"mdbcarousel objects_list"}/>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

const ImageGallery = ({imagesArray}) => {
    const [indexPhoto, setIndexPhoto] = useState(0)
    const winWidth = window.innerWidth

    const toggleImage = toggle => {
        const change = toggle.x - winWidth/2

        if (change > 0) {
            indexPhoto===imagesArray.length - 1 ? setIndexPhoto(0) : setIndexPhoto(indexPhoto+1)
        }
        if (change < 0) {
            indexPhoto===0 ? setIndexPhoto(imagesArray.length - 1) : setIndexPhoto(indexPhoto-1)
        }
    }

    return (<div className='object-card image-layer'><ImagesCarusel img={imagesArray[indexPhoto]} winWidth ={winWidth} toggleImage={toggleImage}/></div>)
}

const ImagesCarusel = ({img, winWidth, toggleImage}) => {
    console.log ('Фотографии объекта', img)
    const imageUrl = getImageUrl (img.filePath);
    return (<>
        <div className='object-card image_gallery_preview'
             style={{backgroundImage: 'url(' + imageUrl + ')',}}
             onClick={e => {toggleImage({x:e.pageX, width:winWidth})}}
        />
    </>)
}
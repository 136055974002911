import React                              from 'react'
import {MDBBtn, MDBCol, MDBInput, MDBRow} from 'mdbreact'


//
// Форма создания объекта недвижимости
//

export default ({newObject, updateObject}) => {
    return (<>
        <MDBRow>
            <MDBCol className="object_update">
                <MDBInput
                    autoComplete="off"
                    name="name"
                    label="Название"
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    value={newObject.name || ''}
                    getValue={e => updateObject('name', e)}
                />
            </MDBCol>

        </MDBRow>
        <MDBRow>
            <MDBCol>
                <MDBRow>
                    <MDBCol size={'md-6'}>
                        <MDBInput
                            autoComplete="off"
                            label="Город"
                            name="cityName"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={newObject.cityName || ''}
                            getValue={e => updateObject('cityName', e)}
                        />
                    </MDBCol>
                    <MDBCol size={'md-4'}>
                        <MDBInput
                            autoComplete="off"
                            label="Улица"
                            name="streetName"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={newObject.streetName || ''}
                            getValue={e => updateObject('streetName', e)}
                        />
                    </MDBCol>
                    <MDBCol size={'md-2'}>
                        <MDBInput
                            autoComplete="off"
                            label="№ дома"
                            name="houseNumber"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={newObject.houseNumber || ''}
                            getValue={e => updateObject('houseNumber', e)}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    </>)
}

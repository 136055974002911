export const SET_ALL_FLOORS_OF_BUILDING = 'SET_ALL_FLOORS_OF_BUILDING';
export const setAllFloorsOfBuilding = floors => ({
  type   : SET_ALL_FLOORS_OF_BUILDING,
  payload: floors
});

export const SET_FlOOR_NAME = 'SET_FlOOR_NAME';
export const setFloorName = FloorName => ({
  type   : SET_FlOOR_NAME,
  payload: FloorName
});

export const SET_FlOOR_NUMBER = 'SET_FlOOR_NUMBER';
export const setFloorNumber = FloorNumber => ({
  type   : SET_FlOOR_NUMBER,
  payload: FloorNumber
});

export const SET_ACTIVE_FLOOR = 'SET_ACTIVE_FLOOR';
export const setActiveFloor = activeFloor => ({
  type   : SET_ACTIVE_FLOOR,
  payload: activeFloor
});
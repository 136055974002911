import React from 'react'
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow
}                      from 'mdbreact'
import { useSnackbar } from 'notistack'

import BreadcrumSection   from '../../commonSections/BreadcrumSection'
import CompanyForm        from '../../forms/company'
import { companyService } from '../../../services/companyService'
import {Link}             from "react-router-dom";
import {useHistory}       from "react-router";

const CreateCompany = ({ history }) => {

    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = async (values) => {
        try{
           await  companyService.create(JSON.stringify({ ...defaultData, ...values }))
            enqueueSnackbar('Арендатор успешно создан', {
                variant: 'success'
            })
            history.goBack()
        } catch (err) {
            enqueueSnackbar('Ошибка при создании', {
                variant: 'error'
            })
        }
    }


    return <>
        <Breadcrums
            createPredicate={true}
            h2="Добавить компанию"
            buttonName="Арендатор"
            href="/renters/create"
            history={useHistory ()}
        />
        <MDBCard>
            <MDBCardBody>
                <CompanyForm onSubmit={onSubmit}/>
            </MDBCardBody>
        </MDBCard>
    </>
}

export default CreateCompany

const Breadcrums = props => {

    const breadCrumStyle = {
        fontSize: "0.8rem",
    };
    const chain = [
        ['Главная', '/main'],
        ['Арендаторы', '/renters'],
    ]
    const header = props.h2;
    let itemsChain = [];
    let i = 0;

    chain.forEach (function (item) {
        i++;
        itemsChain.push (<MDBBreadcrumbItem key={i}><Link to={item[1]}>{item[0]}</Link></MDBBreadcrumbItem>);
    });
    itemsChain.push (<MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>);
    itemsChain.unshift (<MDBBreadcrumbItem key={321}><span className="link_to_breadcrumbs" color="link" onClick={props.history.goBack}>Назад</span></MDBBreadcrumbItem>);

    return (<>
        {chain.length > 0 && (<MDBBreadcrumb style={breadCrumStyle}>{itemsChain}</MDBBreadcrumb>)}
        <MDBCard className="mb-30">
            <MDBCardBody id="breadcrumb" className="pa-16">
                <MDBRow between>
                    <MDBCol size="9" className="d-flex align-items-center">
                        <MDBCardTitle tag="h5" className="g-mb-0" style={{margin: 0}}>{header}</MDBCardTitle>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>);
}

const defaultData = {
    fullName: '',
    shortName: '',
    legalAddress: '',
    actualAddress: '',
    mailingAddress: '',
    phone: '',
    fax: '',
    email: '',
    site: '',
    head: '',
    phoneHead: '',
    chiefAccountant: '',
    phoneChiefAccountant: '',
    createDate: new Date().toISOString().substr(0, 10),
    certificateEgpul: '',
    certificateDate: new Date().toISOString().substr(0, 10),
    certificateNumber: '',
    certificateIssued: '',
    certificateOgrn: '',
    inn: '',
    kpp: '',
    okpo: '',
    kindActivity: '',
    checkingAccount: '',
    correspondentAccount: '',
    bank: '',
    bik: '',
    okogu: '',
    okato: '',
    okved: '',
    fullNameContract: ''
}
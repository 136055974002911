import React from 'react'
import { LinearProgress } from '@material-ui/core'
import { MDBBadge, MDBCol, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import moment from 'moment'
import { accrualType } from '../../../../utils/AccrualType'

const ScoreCompanySection = ({ scores }) => {
    if (scores && scores.length !== 0)
        return (
            <MDBCol>
                <MDBTable hover>
                    <MDBTableHead>
                        <tr>
                            <th>№</th>
                            <th>Номер</th>
                            <th>Дата создания</th>
                            <th>Даты закрытия</th>
                            <th>Статус</th>
                            <th>Тип начисления</th>
                            <th>Описание</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {scores.map((score, index) => (
                            <tr key={'score-of-renter-' + score.id}>
                                <td>{index + 1}</td>
                                <td>{score.number}</td>
                                <td>{moment(score.createdDate).format('DD.MM.YYYY')}</td>
                                <td>{moment(score.closeDate).format('DD.MM.YYYY')}</td>
                                <td>
                                    {(score.isPayed && (
                                        <MDBBadge style={{ fontSize: 14 }} color={'success'}>
                                            Оплачено
                                        </MDBBadge>
                                    )) || (
                                        <MDBBadge style={{ fontSize: 14 }} color={'danger'}>
                                            Неоплачено
                                        </MDBBadge>
                                    )}
                                </td>
                                <td>{accrualType[score.accrualType]}</td>
                                <td>{score.description}</td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCol>
        )
    if (scores && scores.length === 0)
        return (
            <div className="d-flex justify-content-center">
                {' '}
                На данный момент нет активных счетов.
            </div>
        )
    return <LinearProgress />
}

export default ScoreCompanySection

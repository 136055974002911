import React, { useEffect,  useReducer } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBModalFooter, MDBRow } from 'mdbreact'
import Select from 'react-select'

import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import { companyService } from '../../../../../services/companyService'
import { withRouter } from 'react-router'
import { withSnackbar } from 'notistack'
import { incomeService } from '../../../../../services/incomeService'

function useSetState(initialState) {
    const [state, setState] = useReducer(
        (state, newState) => ({
            ...state,
            ...newState
        }),
        initialState
    )

    return [state, setState]
}

const IncomeUpdateSection = props => {
    const [state, setState] = useSetState({
        isLoading: false,
        companiesOptions: [],
        description: '',
        companyId: '',
        incomeDate: moment().format('YYYY-MM-DD'),
        amount: ''
    })
    const id = props.match.params.incomeId;
    
    useEffect(() => {
        incomeService.getById(id).then(_ => {
            setState({
                description: _.description,
                incomeDate: moment(_.incomeDate).format("YYYY-MM-DD"),
                amount: _.amount,
                companyId: _.company.id
            })
        })
        //eslint-disable-next-line
    }, [id])

    useEffect(() => {
        companyService.getAll().then(_ => {
            const options = _.map(renter => ({
                value: renter.fullName,
                label: renter.fullName,
                id: renter.id
            }))
            setState({ companiesOptions: options })
        })
    //eslint-disable-next-line
    }, [])

    const submit = () => {
        if (state.companyId === '' || state.description === '' || state.amount === '') {
            props.enqueueSnackbar('Заполните все поля', { variant: 'warning' })
            return
        }
        setState({ isLoading: true })

        const model = JSON.stringify({
            description: state.description,
            incomeDate: state.incomeDate,
            companyId: state.companyId,
            amount: state.amount
        })
        incomeService.update(id, model).then(
            _ => {
                props.enqueueSnackbar('Поступление успешно обновлено', { variant: 'success' })
                props.history.push('/income')
            },
            error => {
                props.enqueueSnackbar(error, { variant: 'error' })
                setState({ isLoading: false })
            }
        )
    }

    return (
        <>
            <MDBCard>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol size="sm-4">
                            <div className="grey-text">
                                <MDBInput
                                    label="Дата поступления"
                                    group
                                    type="date"
                                    validate
                                    error="wrong"
                                    success="right"
                                    onChange={e => setState({ incomeDate: e.target.value })}
                                    value={state.incomeDate}
                                />
                            </div>
                        </MDBCol>

                        <MDBCol size="sm-4">
                            <div className="grey-text">
                                <MDBInput
                                    label="Описание"
                                    group
                                    type="text"
                                    validate
                                    error="wrong"
                                    success="right"
                                    onChange={e => setState({ description: e.target.value })}
                                    value={state.description}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol size="sm-4">
                            <div className="grey-text">
                                <MDBInput
                                    label="Сумма поступлении"
                                    group
                                    type="number"
                                    validate
                                    error="wrong"
                                    success="right"
                                    onChange={e => setState({ amount: e.target.value })}
                                    value={state.amount}
                                />
                            </div>
                        </MDBCol>
                        <MDBCol size="sm-4" className="form-group">
                            <div className="md-form form-group">
                                <Select
                                    style={{ border: 'none' }}
                                    onChange={e => setState({ companyId: e.id })}
                                    options={state.companiesOptions}
                                    placeholder="Выбрать арендатора"
                                    value={state.companiesOptions.find(c => c.id === state.companyId)}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>

                <MDBModalFooter>
                    {state.isLoading && <CircularProgress size={30} />}
                    <MDBBtn
                        outline
                        color="blue-grey"
                        disabled={state.isLoading}
                        onClick={props.history.goBack}
                    >
                        Назад
                    </MDBBtn>
                    <MDBBtn disabled={state.isLoading} outline onClick={submit} color="success">
                        Сохранить
                    </MDBBtn>
                </MDBModalFooter>
            </MDBCard>
        </>
    )
}

export default withSnackbar(withRouter(IncomeUpdateSection))

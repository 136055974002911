import React, { Component } from 'react'
import NotFoundPage from '../../errors/NotFoundPage'
import CompanySinglePage from './pages/CompanySinglePage'
import { companyService } from '../../../services/companyService'
import LinearProgress from '@material-ui/core/LinearProgress'
import BreadcrumSection from '../../commonSections/BreadcrumSection'

const breadcrumsChain = [
    ['Главная', '/'],
    ['Арендаторы', '/renters']
]

class CompanyPageContainer extends Component {
    state = {
        companyData: null
    }

    componentDidMount() {
        const { organisationId } = this.props.match.params
        if (organisationId) {
            companyService.getById(organisationId).then(responseCompanyData => {
                this.setState({ companyData: responseCompanyData })
            })
        }
    }

    render() {
        const companyId = parseInt(this.props.match.params.organisationId)
        const match = this.props.match

        if (isNaN(companyId)) {
            return <NotFoundPage />
        }
        const { companyData } = this.state
        if (!companyData) {
            return <LinearProgress />
        }
        return (
            <React.Fragment>
                <BreadcrumSection
                    h2={companyData.shortName ? companyData.shortName : ''}
                    chain={breadcrumsChain}
                    buttonName={false}
                    href="building/create"
                />
                <CompanySinglePage match={match} companyData={this.state.companyData} />
            </React.Fragment>
        )
    }
}

export default CompanyPageContainer

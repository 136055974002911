import {
    COMPANY_SET_IS_CREATED_STATUS,
    COMPANY_SET_ALL
} from './actions';

const defaultState = {
    allCompanies: null,
    isCreated : false
};

export const companyReducer = (state = defaultState, action) => {
    switch (action.type) {
        case COMPANY_SET_ALL: {
            return {
                ...state,
                allCompanies: action.payload
            };
        }
        case COMPANY_SET_IS_CREATED_STATUS: {
            return {
                ...state,
                isCreated: action.payload
            };
        }
        default:
            return state;
    }
};

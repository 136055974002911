import React, {Component} from 'react';
import {setAllObjects} from "../../../store/objects/actions";
import {connect} from "react-redux";
import {
    MDBCard,
    MDBCardBody,
} from "mdbreact";
import BreadcrumSection from "../../commonSections/BreadcrumSection";
import {objectService} from "../../../services/objectService";
import CreateBuildingFormSection from "./sections/CreateBuildingFormSection";
import LinearProgress from "@material-ui/core/LinearProgress";
import {buildingService} from "../../../services/buildingService";

class BuildingCreateContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            object: null,
            building: null,
        };
        objectService.getAll().then(_ => {
            const object = this.findArrayElementById(_, props.match.params.objectId);
            if(props.match.params.buildingId)
                buildingService.getById(props.match.params.buildingId).then(corp => {
                this.setState({
                    object: object,
                    building: corp,
                    isLoading: false
                });
            });
            else{
                this.setState({
                    object: object,
                    isLoading: false
                });
            }

        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.object !== nextState.object ||
            this.state.building !== nextState.building;
    }

    findArrayElementById(array, property) {
        return array.find(element => {
            return element.id === Number(property);
        });
    }

    render() {
        let breadcrumsChain = [
            ['Главная', '/'],
            ['Объекты', '/objects'],
        ];

        return (
            <React.Fragment>
                {this.state.object &&
                <BreadcrumSection
                    chain={breadcrumsChain}
                    h2={(this.state.building ? "Редактирование" : "Создание") + " корпуса для объекта " + this.state.object.name}
                />}
                <MDBCard>
                    <MDBCardBody>
                        {this.state.isLoading && <LinearProgress/>}
                        {this.state.object &&
                        <CreateBuildingFormSection setAllObjects={this.props.setAllObjects} objectId={this.state.object.id} building={this.state.building}/>}
                    </MDBCardBody>
                </MDBCard>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        allObjects: state.objects.allObjects,
    };
};

const mapDispatchToProps = {
    setAllObjects,
};
export default connect(mapStateToProps, mapDispatchToProps)(BuildingCreateContainer);

import React, {Component} from 'react';
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Link} from "react-router-dom";

class EmployeesTableSection extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.allUsers !== nextProps.allUsers;
    }

    render() {
        let users = this.props.allUsers;
        if (users) {
            let i = 0;
            users = users.map(function (user) {
                i++;
                const date = new Date(user.user.birthDate);
                let options = {
                    year: 'numeric', month: 'long', day: 'numeric',
                };
                const userID = user.id;
                const URLPath = "/employees/" + userID;
                return (
                    <tr key={user.id}>
                        <td>{i}</td>
                        <td><Link to={URLPath}>{user.user.surname + ' ' + user.user.name + ' ' + user.user.patronymic}</Link></td>
                        <td>{user.position}</td>
                        <td>{date.toLocaleString("ru", options)}</td>
                        <td>{user.user.userName}</td>
                        <td>{user.user.mobilePhone}</td>
                        <td>{user.user.phone}</td>
                    </tr>
                );
            });
        }
        return (
            <MDBRow className="mb-4">
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardHeader>
                                Пользователи
                            </MDBCardHeader>
                            <MDBTable hover>
                                <MDBTableHead>
                                    <tr>
                                        <th>№</th>
                                        <th>ФИО</th>
                                        <th>Должность</th>
                                        <th>Дата рождения</th>
                                        <th>Email</th>
                                        <th>Телефон</th>
                                        <th>Мобильный</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>{users}</MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>)
    }
}

export default EmployeesTableSection;


export const permissionsTypes = {
    scope
};

export function scope() {
    return [
        {
            id: 1,
            name: "Создание арендатора",
            value: false
        },
        {
            id: 2,
            name: "Просмотр арендаторов объекта",
            value: false
        },
        {
            id: 3,
            name: "Редактирование арендаторов",
            value: false
        },
        {
            id: 4,
            name: "Удаление арендатора",
            value: false
        },
        {
            id: 5,
            name: "Пометка на удаление арендатора",
            value: false
        },
        {
            id: 6,
            name: "Назначение арендатора на помещения",
            value: false
        },
        {
            id: 7,
            name: "Просмотр документов арендатора",
            value: false
        },
        {
            id: 8,
            name: "Редактирование документов(сканы) арендатора",
            value: false
        },
        {
            id: 9,
            name: "Измение статуса оплаты арендатором",
            value: false
        },
        {
            id: 10,
            name: "Ответ на обращение",
            value: false
        },
        {
            id: 11,
            name: "Смена статуса обращения",
            value: false
        },
        {
            id: 12,
            name: "Просмотр финансовых отчетов по объекту",
            value: false
        },
        {
            id: 13,
            name: "Просмотр финансовых отчетов по арендатору",
            value: false
        },
        {
            id: 14,
            name: "Просмотр финансовых отчетов по компании",
            value: false
        },
        {
            id: 15,
            name: "Просмотр финансовых отчетов по всем компаниям",
            value: false
        },
        {
            id: 16,
            name: "Просмотр информации по мероприятиям",
            value: false
        },
        {
            id: 17,
            name: "Добавление пользователей",
            value: false
        },
        {
            id: 18,
            name: "Редактирование пользователей",
            value: false
        },
        {
            id: 19,
            name: "Просмотр пользователей",
            value: false
        },
        {
            id: 20,
            name: "Редактирование ролей",
            value: false
        }
    ]
}

import React, { useEffect, useState } from 'react'
import { MDBCard, MDBCardBody } from 'mdbreact'
import { useSnackbar } from 'notistack'
import { LinearProgress } from '@material-ui/core'

import BreadcrumSection from '../../commonSections/BreadcrumSection'
import OwnersForm from './forms/main'
import { ownersService } from '../../../services/ownersService'
import { clean } from '../../../utils/Others'

const UpdateOwner = ({ match }) => {

    const breadcrumsChain = [
        ['Главная', '/main'],
        ['Собственники', '/owners']
    ]
    const { ownerId } = match.params
    const { enqueueSnackbar } = useSnackbar()
    const [owner, setOwner] = useState()

    const onSubmit = async (values) => {
        try {
            const model = clean({ ...defaultData, ...values })
            await ownersService.update(ownerId, JSON.stringify(model))
            enqueueSnackbar('Собсвтенник успешно обновлен', {
                variant: 'success'
            })
        } catch (err) {
            enqueueSnackbar('Ошибка при обновлении', {
                variant: 'error'
            })
        }
    }

    useEffect(() => {
        if (ownerId) {
            ownersService.getById(ownerId).then(_ => {
                const owner = {}
                Object.keys(_).forEach(key => {
                    owner[key] =_[key] || '';
                })
                setOwner(owner)
            })
        }
    }, [ownerId])

    return <>
        <BreadcrumSection
            h2={'Редактирование собсвтенника'}
            chain={breadcrumsChain}
        />
        {owner
            ? <MDBCard>
                <MDBCardBody>
                    <OwnersForm values={owner} onSubmit={onSubmit}/>
                </MDBCardBody>
            </MDBCard>
            : <LinearProgress/>}
    </>
}

export default UpdateOwner

const defaultData = {
    fullName: '',
    shortName: '',
    legalAddress: '',
    actualAddress: '',
    mailingAddress: '',
    phone: '',
    fax: '',
    email: '',
    site: '',
    head: '',
    phoneHead: '',
    chiefAccountant: '',
    phoneChiefAccountant: '',
    createDate: new Date().toISOString().substr(0, 10),
    certificateEgpul: '',
    certificateDate: new Date().toISOString().substr(0, 10),
    certificateNumber: '',
    certificateIssued: '',
    certificateOgrn: '',
    inn: '',
    kpp: '',
    okpo: '',
    kindActivity: '',
    checkingAccount: '',
    correspondentAccount: '',
    bank: '',
    bik: '',
    okogu: '',
    okato: '',
    okved: '',
    fullNameContract: ''
}
import React, { useEffect, useState } from 'react'
import BreadcrumSection from '../../../commonSections/BreadcrumSection'
import UpdateContractSection from '../sections/update/UpdateContractSection'
import { contractService } from '../../../../services/contractService'
import { LinearProgress } from '@material-ui/core'


const UpdateContractPage = ({ id }) => {

    const [contract, setContract] = useState(null);

    const breadcrumsChain = [
        ['Главная', '/main'],
        ['Договора', '/contracts']
    ];

    useEffect(() => {
        contractService.getById(id).then(_ => setContract(_));
    }, [id])


    return (
        <React.Fragment>
            <BreadcrumSection
                // createPredicate={this.props.permissions.find(_ => _.id === 1).value}
                createPredicate={false}
                h2="Редактирование договора"
                chain={breadcrumsChain}
                buttonName="договор"
                href="contracts/create"
            />
            {contract ? <UpdateContractSection contract={contract}/> : <LinearProgress/> }

        </React.Fragment>
    )
}

export default UpdateContractPage

import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { Line, Text } from 'react-konva'

import { getQuantityString } from '../../../utils/Others'

import Point from './point'

const Shape = ({
    points: initialPoints,
    x,
    y,
    onChangePosition,
    active,
    setActive,
    onTransform,
    onAddPoint,
    scale,
    center,
    title,
    square,
    draggable,
    isFocused,
    deletePoint,
    onChangeMovingState,
    setHoverObject,
                   push,
    id,
    ...props
}) => {
    const coords = { x, y }
    const [points, setPoints] = useState(initialPoints)
    const [isDragging, setIsDragging] = useState(false)
    const ref = useRef(null)
    const vertices = useMemo(() => {
        let result = []
        for (let i = 0; i < points.length; i += 2) {
            result.push({
                x: points[i],
                y: points[i + 1]
            })
        }
        if (x !== undefined && y !== undefined) {
            result = result.map(item => ({ x: item.x + x, y: item.y + y }))
        }
        return result
    }, [points, x, y])

    const strokeCoords = useMemo(() => {
        const result = []
        for (let i = 0; i < points.length; i += 2) {
            result.push([
                points[i],
                points[i + 1],
                points[points.length <= i + 2 ? 0 : i + 2],
                points[points.length <= i + 3 ? 1 : i + 3]
            ])
        }
        return result
    }, [points])

    const onClickStroke = useCallback(
        ({ evt }, i) => {
            const { x: canvasX, y: canvasY } = evt.path[1].getBoundingClientRect()
            const { x: mouseX, y: mouseY } = evt
            onAddPoint(
                {
                    x: (mouseX - canvasX) / scale - (x ?? 0),
                    y: (mouseY - canvasY) / scale - (y ?? 0)
                },
                i
            )
        },
        [onAddPoint, x, y, scale]
    )

    useEffect(() => {
        setPoints(initialPoints)
    }, [initialPoints])

    return (
        <>
            {!isDragging && center && title && (
                <Text
                    width={200}
                    align="center"
                    text={title}
                    x={center.x}
                    y={center.y}
                    offsetX={100}
                    offsetY={15}
                />
            )}
            {!isDragging && center && square ? (
                <Text
                    width={200}
                    align="center"
                    text={getQuantityString(square, 'м²')}
                    x={center.x}
                    y={center.y}
                    offsetX={100}
                />
            ) : null}
            <Line
                {...props}
                x={x}
                y={y}
                points={points}
                ref={ref}
                closed
                stroke="black"
                strokeWidth={2 / scale}
                fill={isFocused ? '#f443368c' : active ? '#66bb6a50' : '#dce77550'/*todo: Цвет на схемах находится тут*/}
                draggable={draggable && !active}
                onClick={draggable
                    ? setActive
                    : () => {
                        push(`/rooms/${id}`)
                    }}
                onDragStart={() => {
                    setIsDragging(true)
                    onChangeMovingState(true)
                }}
                onDragEnd={event => {
                    const { x, y } = event.target.attrs
                    onChangePosition(x, y)
                    setIsDragging(false)
                    onChangeMovingState(false)
                }}
                onMouseOver={() => setHoverObject(false)} // TODO: В этом месте назначается активное помещение при проведении мышки над помещением по планеровке
                onMouseOut={() => setHoverObject(true)}
            />
            {active &&
                strokeCoords.map((points, i) => (
                    <Line
                        key={'stroke-line-' + i}
                        x={x}
                        y={y}
                        stroke="#00000000"
                        strokeWidth={4 / scale}
                        points={points}
                        onClick={event => onClickStroke(event, i)}
                    />
                ))}
            {active &&
                vertices.map(({ x, y }, i) => (
                    <Point
                        key={'point-' + i}
                        x={x}
                        y={y}
                        strokeWidth={2 / scale}
                        radius={4 / scale}
                        color="white"
                        stroke="black"
                        draggable
                        onDragMove={event => {
                            const { attrs } = event.target
                            const newPoints = [...points]
                            newPoints[i * 2] = coords.x ? attrs.x - coords.x : attrs.x
                            newPoints[i * 2 + 1] = coords.y ? attrs.y - coords.y : attrs.y
                            setPoints(newPoints)
                        }}
                        onDragEnd={() => onTransform(points)}
                        onContextMenu={() => deletePoint(i)}
                    />
                ))}
        </>
    )
}

export default Shape

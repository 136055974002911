// Forms inputs changes
export const REGISTRATION_CHANGE_LOGIN       = 'REGISTRATION_CHANGE_LOGIN';
export const REGISTRATION_CHANGE_PASSWORD    = 'REGISTRATION_CHANGE_PASSWORD';
export const REGISTRATION_CHANGE_NAME        = 'REGISTRATION_CHANGE_NAME';
export const REGISTRATION_CHANGE_SURNAME     = 'REGISTRATION_CHANGE_SURNAME';
export const REGISTRATION_CHANGE_PATRONYMIC  = 'REGISTRATION_CHANGE_PATRONYMIC';
export const REGISTRATION_CHANGE_BIRTHDATE   = 'REGISTRATION_CHANGE_BIRTHDATE';
export const REGISTRATION_CHANGE_PHONE       = 'REGISTRATION_CHANGE_PHONE';
export const REGISTRATION_CHANGE_MOBILEPHONE = 'REGISTRATION_CHANGE_MOBILEPHONE';
export const REGISTRATION_CHANGE_USER        = 'REGISTRATION_CHANGE_USER';

// Form submit event
export const REGISTRATION_SUBMIT_EVENT       = 'REGISTRATION_SUBMIT_EVENT';

export const setLogin = (login) => ({
  type: REGISTRATION_CHANGE_LOGIN,
  payload: login
});

export const setPassword = (password) => ({
  type: REGISTRATION_CHANGE_PASSWORD,
  payload: password
});

export const setName = (name) => ({
  type: REGISTRATION_CHANGE_NAME,
  payload: name
});

export const setSurname = (surname) => ({
  type: REGISTRATION_CHANGE_SURNAME,
  payload: surname
});

export const setPatronymic = (patronymic) => ({
  type: REGISTRATION_CHANGE_PATRONYMIC,
  payload: patronymic
});

export const setBirthDate = (birthDate) => ({
  type: REGISTRATION_CHANGE_BIRTHDATE,
  payload: birthDate
});

export const setPhone = (phone) => ({
  type: REGISTRATION_CHANGE_PHONE,
  payload: phone
});

export const setMobilePhone = (mobilePhone) => ({
  type: REGISTRATION_CHANGE_MOBILEPHONE,
  payload: mobilePhone
});

export const onUserChange = (user) => ({
  type: REGISTRATION_CHANGE_USER,
  payload: user
});

export const SubmitEvent = (user) => ({ // ??? не понятно что возвращает ?
  type: REGISTRATION_SUBMIT_EVENT,
  payload: user
});
import React from 'react';
import {addHours, addDays} from 'date-fns';
import locale from 'date-fns/locale/ru';
import FullCalendar from "mdb-full-calendar";

class CalendarSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [
                {
                    id: '1',
                    title: 'Проведение генеральной уборки',
                    startDate: addHours(addDays(new Date(), 0), 8),
                    endDate: addHours(addDays(new Date(), 2), 20),
                    color: 'primary'
                }
            ]
        }
    }

    taskChanged(e) {
        console.log(e);
    }


    render() {

        return (
            <React.Fragment>
                <FullCalendar
                    listFormat="HH:mm"
                    onChange={e => this.taskChanged(e)}
                    weekFormat="HH:mm"
                    locale={locale}
                    addTaskTitle="Добавить мероприятие"
                    controlLabels={['За сегодня', 'За месяц', 'За неделю', 'Список']}
                    alertModalMsg="Дата завершения должна быть после даты начала!"
                    confirmDeleteMsg="Вы уверены, что хотите удалить это мероприятие?"
                    editTaskTitle="Изменить мероприятие"
                    modalControlLabels={['Удалить', 'Закрыть', 'Добавить']}
                    modalFormLabels={['Описание', 'Начало', 'Конец', 'Цвет']}
                    startDate={new Date()}
                    tasks={this.state.tasks}
                />
            </React.Fragment>);
    }
}

export default CalendarSection;

import React, { useEffect, useState } from 'react'
import { incomeService } from '../../../services/incomeService'
import IncomesPageContainer from './IncomesPageContainer'
import { LinearProgress } from '@material-ui/core'
import { permissionsHelper } from '../../../utils/permissionsHelper'

const IncomeContainer = () => {
    const [incomes, setIncomes] = useState()
    const [permissions, setPermissions] = useState()

    useEffect(() => {
        ;(async () => setPermissions(await permissionsHelper.scopePermissions()))()
    }, [])

    useEffect(() => {
        incomeService.getAll().then(_ => setIncomes(_))
    }, [])
    if (incomes && permissions)
        return <IncomesPageContainer incomes={incomes} permissions={permissions} />
    else return <LinearProgress />
}

export default IncomeContainer

import React, { useEffect, useReducer } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBModalFooter } from 'mdbreact'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Redirect } from 'react-router'
import { companyService } from '../../../../services/companyService'
import { accrualService } from '../../../../services/accrualService'
import { contractService } from '../../../../services/contractService'
import 'moment/locale/ru'
import SelectRenterBlocks from '../blocks/createAccrualBlocks/SelectRenterBlocks'
import AssigntmentAccrualBlock from '../blocks/createAccrualBlocks/AssigntmentAccrualBlock'
import FinancialInformationBlock from '../blocks/createAccrualBlocks/FinancialInformationBlock'


//custom hook
function useSetState(initialState) {
    const [state, setState] = useReducer(
        (state, newState) => ({
            ...state,
            ...newState
        }),
        initialState
    )

    return [state, setState]
}

const CreateAccrualSection = props => {
    const currentUser = JSON.parse(window.localStorage.getItem('currentUser'))

    //state, copy this.state
    const [state, setState] = useSetState({
        isRedirect: false,
        isLoading: false,
        periodTypeIsDisabled : false,
        allCompaniesOptions: [],
        renterContractsOptions: [],
        name: '',
        accrualType: undefined,
        description: '',
        unitsMeasure: undefined,
        count: '',
        amount: '',
        price: "",
        periodType: undefined,
        dateOfDelay: new Date(),
        paymentPriority: 50,
        renter: undefined,
        employeeId: currentUser.id,
        contract: undefined,
    })

    //take all company and set options for renter select form, after component mounted
    useEffect(() => {
        companyService.getAll().then(_ => {
            const companyOptions = _.map(company => {
                return { value: company.id, label: company.fullName, id: company.id }
            })
            setState({ allCompaniesOptions: companyOptions })
        })
        //eslint-disable-next-line
    }, [])



    //on change renter take new contracts by renterId and reselect contracts options for contracts select form
    useEffect(() => {
        setState({ contract: null })
        if (state.renter && state.renter.id) {
            contractService.getByOrganisationId(state.renter.id).then(_ => {
                const renterContractsOptions = _.map(contract => {
                    return {
                        value: contract.id,
                        label: contract.name,
                        id: contract.id
                    }
                })
                setState({ renterContractsOptions })
            })
        }
        //eslint-disable-next-line
    }, [state.renter])

    //submit form
    const formSubmit = async () => {
        if (state.renter === null || state.contract === null) {
            props.enqueueSnackbar('Заполните все поля', {
                variant: 'warning'
            })
            return
        }
        const accrualJSON = buildModel()
        setState({ isLoading: true })
        await accrualService.create(accrualJSON)
        props.enqueueSnackbar('Начисление успешно создано', {
            variant: 'success'
        })
        setState({ isRedirect: true })
    }

    //build model for request
    const buildModel = () => {
        return JSON.stringify({
            name: state.name,
            accrualType: Number(state.accrualType),
            description: state.description,
            unitsMeasure: state.unitsMeasure,
            count: state.count,
            amount: state.amount,
            periodType: state.periodType,
            dateOfDelay: state.dateOfDelay,
            paymentPriority: state.paymentPriority,
            companyId: state.renter.id,
            employeeId: state.employeeId,
            contractId: state.contract.id
        })
    }


    const handleChange = (name, event) => {
        setState({ [name]: event.target.value })
    }

    const selectChange = (name, selectedOption) => {
        setState({ [name]: selectedOption })
    }

    const selectAccrualType = type => {
        setState({ accrualType: type })
    }

    const selectRenterHandleChange = (name, selectedOption) => {
        setState({ contact: null, renterContracts: null, [name]: selectedOption })
    }



    const selectHandleChange = (name, selectedOption) => {
        if (state[name] !== Number(selectedOption)) {
            setState({ [name]: Number(selectedOption) })
        }
    }



    if (state.isRedirect) return <Redirect to="/accruals" />
    return (
        <MDBCard>
            <MDBCardBody>
                <form>
                    <SelectRenterBlocks {...{ state, selectRenterHandleChange, selectChange, setState }} />

                    <AssigntmentAccrualBlock
                        {...{ state, selectHandleChange, handleChange, setState, updatePage: false  }}
                    />
                    <FinancialInformationBlock
                        {...{
                            state,
                            selectAccrualType,
                            setState,
                            selectHandleChange,
                            handleChange
                        }}
                    />
                </form>
            </MDBCardBody>

            <MDBModalFooter>
                {state.isLoading && <CircularProgress size={30} />}
                <MDBBtn
                    outline
                    color="blue-grey"
                    disabled={state.isLoading}
                    onClick={() => {
                        setState({ isRedirect: true })
                    }}
                >
                    Назад
                </MDBBtn>
                <MDBBtn
                    disabled={state.isLoading}
                    outline
                    onClick={e => formSubmit(e)}
                    color="success"
                >
                    Сохранить
                </MDBBtn>
            </MDBModalFooter>
        </MDBCard>
    )
}

export default withSnackbar(CreateAccrualSection)

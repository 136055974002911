export const SET_IS_FORM_CREATE_EMPTY_BOOL = 'SET_IS_FORM_CREATE_EMPTY_BOOL';
export const setIsFromCreateEmpty = status => ({
  type   : SET_IS_FORM_CREATE_EMPTY_BOOL,
  payload: status
});

export const SET_IS_CORP_CREATED_BOOL = 'SET_IS_CORP_CREATED_BOOL';
export const setCorpCreated = status => ({
  type   : SET_IS_CORP_CREATED_BOOL,
  payload: status
});

export const CHANGE_ADD_CORP_FORM_STATUS_BOOL = 'CHANGE_ADD_CORP_FORM_STATUS_BOOL';
export const toggleAddCorpForm = status => ({
  type   : CHANGE_ADD_CORP_FORM_STATUS_BOOL,
  payload: status
});

export const SET_iS_LOADING_BOOL = 'SET_iS_LOADING_BOOL';
export const setIsLoading = status => ({
  type   : SET_iS_LOADING_BOOL,
  payload: status
});

export const CORPS_CHANGE_NAME_TEXT = 'CORPS_CHANGE_NAME_TEXT';
export const setCorpNameText = corpName => ({
  type: CORPS_CHANGE_NAME_TEXT,
  payload: corpName
});

export const CORPS_CHANGE_COORDINATES_TEXT = 'CORPS_CHANGE_COORDINATES_TEXT';
export const setCorpCoordinatesText = coordinates => ({
  type: CORPS_CHANGE_COORDINATES_TEXT,
  payload: coordinates
});

export const CORPS_SET_CURRENT_BUILDING = 'CORPS_SET_CURRENT_BUILDING';
export const setCurrentBuilding = building => ({
  type: CORPS_SET_CURRENT_BUILDING,
  payload: building
});
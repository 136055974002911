import React, {Component} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBRow
} from 'mdbreact';
import {Bar, Pie} from 'react-chartjs-2';

class ChartSection1 extends Component {

    findArrayElementById(array, id) {
        return array.find((element) => {
            return element.id === id;
        })
    }


    render() {
        const data = this.props.data.data;
        let colors = this.props.colors;
        let datasetsBar = [];
        data.reportByObjects.forEach(object => {
            let color = this.findArrayElementById(colors, object.id).color;
            datasetsBar.push({
                label: object.objectName,
                data: [object.allSquareByType, object.usefulSquare, object.technicalSquare, object.generalSquare],
                backgroundColor: color,
                borderWidth: 1
            });


        });

        const dataBar = {
            labels: ['Общая площадь', 'Полезная площадь', 'Места общего пользования', 'Технические'],
            datasets: datasetsBar
        };

        const barChartOptions = {
            responsive: true, maintainAspectRatio: false, scales: {
                xAxes: [{
                    barPercentage: 1, gridLines: {
                        display: true, color: 'rgba(0, 0, 0, 0.1)'
                    }
                }], yAxes: [{
                    gridLines: {
                        display: true, color: 'rgba(0, 0, 0, 0.1)'
                    }, ticks: {
                        beginAtZero: true
                    }
                }]
            }
        };

        let labels = [];
        let datasets = [];
        let backgroundColor = [];
        let hoverBackgroundColor = [];

        data.reportByObjects.forEach(object => {
            let color = this.findArrayElementById(colors, object.id).color;

            labels.push(object.objectName);
            datasets.push(object.amountIncome);
            backgroundColor.push(color);
            hoverBackgroundColor.push(color);
        });

        const dataPie = {
            labels: labels,
            datasets: [{
                data: datasets,
                backgroundColor: backgroundColor,
                hoverBackgroundColor: hoverBackgroundColor
            }]
        };

        return (<MDBRow className="mb-4">
            <MDBCol md="8" className="mb-4">
                <MDBCard className="mb-4">
                    <MDBCardBody>
                        <Bar data={dataBar} height={500} options={barChartOptions}/>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol md="4" className="mb-4">
                <MDBCard className="mb-4">
                    <MDBCardHeader>Доля дохода</MDBCardHeader>
                    <MDBCardBody>
                        <Pie data={dataPie} height={300} options={{responsive: true}}/>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>)
    }
}

export default ChartSection1;


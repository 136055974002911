import React from 'react'
import { MDBCol, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import { withSnackbar } from 'notistack'
import { LinearProgress } from '@material-ui/core'
import CompanyRoomsTableRow from '../helpers/CompanyRoomsTableRow'

class OccupiedRoomsCompanySection extends React.Component {
    render() {
        let tableItems = this.props.companyRooms
        if (tableItems.length === 0) {
            return <div className="d-flex justify-content-center">Нет занятых помещений</div>
        }
        if (this.props.companyRooms) {
            return (
                <React.Fragment>
                    <MDBCol>
                        <MDBTable hover>
                            <MDBTableHead>
                                <tr>
                                    <th>№</th>
                                    <th>Название</th>
                                    <th>Площадь</th>
                                    <th>Арендная ставка</th>
                                    <th>Аренда</th>
                                    <th>Состояние</th>
                                    <th>Назначение</th>
                                    <th>Вид</th>
                                    <th>Договор </th>
                                    <th>Объект</th>
                                    <th>Этаж</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {tableItems.map((room, i) => (
                                    <CompanyRoomsTableRow
                                        room={room}
                                        index={i}
                                        key={'company-room-' + i}
                                    />
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </React.Fragment>
            )
        } else return <LinearProgress />
    }
}

export default withSnackbar(OccupiedRoomsCompanySection)

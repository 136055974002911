export const USER_CHANGE_CURRENT_USER_OBJECT = 'USER_CHANGE_CURRENT_USER_OBJECT';
export const USER_CHANGE_IS_LOGGED_IN_BOOL = 'USER_CHANGE_IS_LOGGED_IN_BOOL';
export const USER_CHANGE_IS_ADMIN_BOOL = 'USER_CHANGE_IS_ADMIN_BOOL';
export const USER_CHANGE_TOKEN_TEXT = 'USER_CHANGE_TOKEN_TEXT';
export const USER_LOGOUT_BOOL = 'USER_LOGOUT_BOOL';


export const setCurrentUser = (currentUser) => ({ // currentUser:object
    type: USER_CHANGE_CURRENT_USER_OBJECT,
  payload: currentUser
});

export const setIsAdmin = (isAdmin) => ({ // isAdmin:bool
  type: USER_CHANGE_IS_ADMIN_BOOL,
  payload: isAdmin
});

export const setToken = (token) => ({ // token:string
  type: USER_CHANGE_TOKEN_TEXT,
  payload: token
});

export const setIsLoggedIn = (isLoggedIn) => ({ // isLoggedIn:bool
  type: USER_CHANGE_IS_LOGGED_IN_BOOL,
  payload: isLoggedIn
});

export const logout = () => ({ // isLoggedIn:bool
  type: USER_LOGOUT_BOOL,
  payload: true
});

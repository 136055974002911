import React                from 'react';
import BreadcrumSection     from "../../commonSections/BreadcrumSection";
import CreateAccrualSection from "./sections/CreateAccrualSection";

class CreateAccrualPage extends React.Component {

    componentDidMount () {
    }

    render () {
        const breadcrumsChain = [['Главная', '/main'], ['Начисления', '/accruals'],
        ];

        return (<React.Fragment>
            <BreadcrumSection
                createPredicate={false}
                h2="Создание начисления"
                chain={breadcrumsChain}
                buttonName="начисление"
                href="accruals/create"
            />
            <CreateAccrualSection/>
        </React.Fragment>);
    }
}

export default CreateAccrualPage;

import {handleResponse} from '../helpers';
import {apiUrl} from "../utils/ConnectionStrings";

export const reportService = {
    getMainReport,
    getAllObjectReport,
    getCorpsStatsByObjectId,
    getIndexPageReport
};

function getMainReport() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "reports/get-main-report", requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

function getAllObjectReport() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "reports/get-all-objects-report", requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

function getCorpsStatsByObjectId(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "reports/get-corp-report-by-object-id/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

function getIndexPageReport() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "reports/get-index-page-report/", requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

import React from 'react'
import IncomesList from './pages/IncomesList'
import BreadcrumSection from '../../commonSections/BreadcrumSection'

const IncomesPageContainer = ({ ...rest }) => {
    const breadcrumsChain = [['Главная', '/']]

    return (
        <>
            <BreadcrumSection
                createPredicate={true}
                h2={'Поступления'}
                chain={breadcrumsChain}
                buttonName={'поступление'}
                href="/income/create"
            />
            <IncomesList {...rest} />
        </>
    )
}
export default IncomesPageContainer

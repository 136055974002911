import React from 'react'
import { MDBCol, MDBRow } from 'mdbreact';


const ComingSoonPage =  () => {
  return (
    <React.Fragment>
      <div className="full">
        <MDBRow className="bad-gateway-row">
          <MDBCol md="8">
            <h1>Раздел недосутпен</h1>
            <h3>Данный раздел находится сейчас в разработке и появится в скором будущем</h3>
          </MDBCol>
          <MDBCol md="4">
            <img alt="Error 404" className="img" src="https://steemitimages.com/0x0/https://cdn.steemitimages.com/DQmeAgKMYViFsibrUGS4BFjxAbdHfUL3RVd6KUH8G15Gieh/seriously.gif"/>
          </MDBCol>
        </MDBRow>
      </div>
    </React.Fragment>
  )
}

export default ComingSoonPage;

import React, { useCallback, useEffect, useMemo } from 'react'
import { MDBCard } from 'mdbreact'
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { LinearProgress } from '@material-ui/core'
import withQueryParams from 'react-router-query-params'

import { contractService } from '../../../../services/contractService'
import SmartTable from '../../../commonSections/SmartTable'
import TablePagination from '../../../commonSections/TablePagination'
import Indicator from '../../../others/Indicator'
import indicatorColor from '../../../../utils/indicatorColor'
import { useSetState } from '../../../others/customHooks'
import ContractFilterBlock from './ContractFiltersSection'
import queryString from '../../../../utils/queryString'
import { initialPagination } from '../../../../static/constants'
import { getDateFormat } from '../../../../utils/Others'
import EditBlockDropdown from '../../../others/EditBlockDropdown'

const defaultParams = {
    //default query params
    keys: {
        page: {
            default: 1,
            validate: value => Number(value) > 0 && Number.isInteger(Number(value))
        },
        elementsCount: {
            default: 20,
            validate: value => Number(value) > 0 && Number.isInteger(Number(value))
        }
    }
}

const TableCard = props => {
    //table state
    const [contractsData, setContractsData] = useSetState(initialPagination)
    //columns state
    const [visibleColumns, setVisibleColumns] = useSetState(initialColumnsState)
    //query params
    const filters = props.queryParams
    const { enqueueSnackbar } = useSnackbar()
    //function take data and save in table state
    const getData = useCallback(() => {
        //take data with query params
        contractService.getByPage(queryString(filters)).then(res => {
            setContractsData(mapResponse(res))
        })
    }, [filters, setContractsData])

    const deleteContract = useCallback((id) => {
        contractService.remove(id).then(
            _ => {
                enqueueSnackbar('Договор успешно удален', { variant: 'success' })
                getData()
            },
            error => {
                enqueueSnackbar(error, { variant: 'error' })
            }
        )
    }, [enqueueSnackbar, getData])

    //table manage functions
    const setPage = n => {
        props.setQueryParams({ page: n })
    }
    const setCount = n => {
        props.setQueryParams({ elementsCount: n })
    }

    const data = useMemo(() => contractsData.data ? {
        columns: mapColumns(visibleColumns),
        rows: mapRows(contractsData, deleteContract, props.permissions)
    } : null, [contractsData, deleteContract, props.permissions, visibleColumns])


    // request on page load and reset again on change query params
    useEffect(getData, [props.queryParams])

    if (!data) {
        return <LinearProgress/>
    }

    return (
        <>
            <MDBCard className="p-4">
                <ContractFilterBlock
                    filterVisible={props.filterVisible}
                    setFilterVisible={props.setFilterVisible}
                    setVisibleColumns={setVisibleColumns}
                    visibleColumns={visibleColumns}
                    filters={filters}
                />
                <SmartTable data={data} visibleColumns={visibleColumns}/>
                <TablePagination
                    {...{
                        page: contractsData.page,
                        count: contractsData.count,
                        fullCount: contractsData.fullCount,
                        setCount,
                        setPage
                    }}
                />
            </MDBCard>
        </>
    )

}

export default withQueryParams(defaultParams)(TableCard)

const initialColumnsState = {
    '#': { checked: true, label: '#', width: 50 },
    name: { label: 'Номер договора', checked: true },
    signingDateTime: { checked: true, label: 'Дата подписания', headAlign: 'center', bodyAlign: 'center' },
    conclusionDateTime: { label: 'Начало действия', checked: true, headAlign: 'center', bodyAlign: 'center' },
    completionDateTime: { label: 'Конец действия', checked: true, headAlign: 'center', bodyAlign: 'center' },
    company: { label: 'Арендатор', checked: true },
    owner: { label: 'Собственник', checked: false },
    sumSquare: { label: 'Суммарная площадь', checked: true },
    kindActivity: { label: 'Деятельность ярендатора', checked: false },
    inn: { label: 'ИНН арендатора', checked: false },
    edit: { label: 'Редактирование', checked: false }
}

const mapRows = (contractsData, deleteContract, permissions) => {
    return contractsData.data.map((contract, index) => {
        return {
            '#': (
                <div className="d-flex justify-content-center align-items-center"
                     style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%' }}>
                    {(contractsData.page - 1) * contractsData.count + index + 1}{' '}
                    <Indicator
                        color={contract.entityStatus === 0 ? indicatorColor(contract.completionDateTime) : 'grey'}/>
                </div>
            ),
            name: <Link to={`/contracts/${contract.id}`}>{contract.name} </Link>,
            signingDateTime: getDateFormat(contract.signingDateTime),
            conclusionDateTime: getDateFormat(contract.conclusionDateTime),
            completionDateTime: getDateFormat(contract.completionDateTime),
            company: (
                contract.company
                    ? <Link to={`/renters/${contract.company.id}`}>{contract.company.shortName}</Link>
                    : '-'
            ),
            owner: contract.contractOwnersShares.length
                ? contract.contractOwnersShares.map((item, i) => {
                    const isLast = contract.contractOwnersShares.length - 1 === i;
                    return <React.Fragment key={`owner-${item.ownerId}`} >
                        <Link to={`/owners/${item.ownerId}`}>{item.ownerName}</Link>
                        {!isLast && <>, </>}
                    </React.Fragment>
                })
                : '-',
            sumSquare: (
                <div className="d-flex justify-content-center">
                    {' '}
                    {contract.rooms.reduce((sum, cont) => sum + cont.square, 0).toFixed(2)} м²
                </div>
            ),
            kindActivity: contract.owner ? contract.owner.kindActivity : '-',
            inn: contract.owner ? contract.owner.inn : '-',
            edit: (
                <EditBlockDropdown
                    relative
                    permissions={permissions}
                    detailsLink={`/contracts/${contract.id}`}
                    editLink={`/contracts/update/${contract.id}`}
                    deleteLabel={`Вы действительно хотите удалить договор ${contract.name}?`}
                    deleteFunc={() => deleteContract(contract.id)}
                />
            )
        }
    })
}

const mapColumns = (visibleColumns) =>
    Object.keys(visibleColumns).map(key => ({
        label: visibleColumns[key].label,
        field: key,
        width: visibleColumns[key].width
    }))

const mapResponse = (res) => ({
    fullCount: res.fullCount,
    data: res.data,
    count: res.elementCount,
    page:
        Math.ceil(res.fullCount / res.elementCount) < res.page
            ? Math.ceil(res.fullCount / res.elementCount)
            : res.page
})

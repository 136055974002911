import React from 'react'
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBSelect } from 'mdbreact'
import { range } from '../../utils/Others'

const TablePagination = props => {
    const { page, count, fullCount, setCount, setPage, showSelectCount = true } = props
    const onChangeCount = e => {
        const changedData = Number(e)
        if (changedData !== count) setCount(Number(e))
    }

    const changePage = n => () => {
        if (n !== page) {
            setPage(n)
        }
    }

    const options = [
        { text: 10, value: 10, checked: count === 10 },
        { text: 20, value: 20, checked: count === 20 },
        { text: 50, value: 50, checked: count === 50 },
        { text: 100, value: 100, checked: count === 100 },
        { text: 300, value: 300, checked: count === 300 },
        { text: 500, value: 500, checked: count === 500 }
    ]

    const pageCount = fullCount ? Math.ceil(fullCount / count) : 0

    let pages
    if (pageCount > 8) {
        if (page < 4) {
            pages = range(1, 8)
        } else if (page > pageCount - 4) {
            pages = range(pageCount - 7, pageCount + 1)
        } else {
            pages = range(page - 3, page + 4)
        }
    } else {
        pages = range(1, pageCount + 1)
    }
    const activePage = pageCount < page ? [...pages].reverse()[0] : page
    return (
        <MDBCol>
            <div className="d-flex justify-content-between align-center">
                <div className="d-flex align-items-center">
                   {
                       showSelectCount &&
                       <>
                           <label className="mr-2">Количетсво элементов на странице: </label>
                           <MDBSelect
                               style={{ width: 50 }}
                               getValue={onChangeCount}
                               className="m-0"
                               options={options}
                           />
                       </>
                   }
                </div>
                <div>
                {(pageCount > 1 && (
                    <MDBPagination>
                        <MDBPageItem disabled={props.page === 1} onClick={changePage(1)}>
                            <MDBPageNav aria-label="Previous">
                                <span aria-hidden="true">Первая</span>
                            </MDBPageNav>
                        </MDBPageItem>
                        {pages.map(page => (
                            <MDBPageItem
                                onClick={changePage(page)}
                                key={'pag-' + page}
                                active={page === activePage}
                            >
                                <MDBPageNav>
                                    {page}{' '}
                                    {page === activePage && (
                                        <span className="sr-only">(current)</span>
                                    )}
                                </MDBPageNav>
                            </MDBPageItem>
                        ))}
                        <MDBPageItem
                            onClick={changePage(pageCount)}
                            disabled={props.page === pageCount}
                        >
                            <MDBPageNav aria-label="Previous">
                                <span aria-hidden="true">Последняя</span>
                            </MDBPageNav>
                        </MDBPageItem>
                    </MDBPagination>
                ))}
                </div>
            </div>
        </MDBCol>
    )
}

export default TablePagination

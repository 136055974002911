import React                                  from 'react'
import Maintenance                            from "./pages/maintenance";
import {Route, Switch}                        from "react-router-dom";
import {MDBCard, MDBCardBody, MDBCol, MDBRow} from "mdbreact";
import {Contents}                             from "./contents";
import ObjectsHelp                            from "./pages/ObjectsHelp";
import BuildingsHelp                          from "./pages/BuildingsHelp";
import FloorsHelp                             from "./pages/FloorsHelp";
import GetStartedHelp                         from "./pages/GetStartedHelp";

export default function Help () {


    return (<>

        <MDBRow>
            <MDBCol sm="12" lg="8">
                <MDBCard>
                    <MDBCardBody>
                        <Switch>
                            <Route exact path="/help" component={GetStartedHelp}/>
                            <Route exact path="/help/objects" component={BuildingsHelp}/>
                            <Route exact path="/help/objects/buildings" component={FloorsHelp}/>
                            <Route exact path="/help/objects/buildings/floors" component={ObjectsHelp}/>
                            <Route exact path="/help/objects/buildings/floors/rooms" component={ObjectsHelp}/>
                        </Switch>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol sm="12" lg="4">
                <Contents />
            </MDBCol>
        </MDBRow>


        <Maintenance/>
    </>);
}

import React, {Component} from 'react';
import {connect} from "react-redux";
import AdminPanelPage from "./pages/AdminPanelPage";
import {permissionsHelper} from "../../../utils/permissionsHelper";
import LinearProgress from "@material-ui/core/LinearProgress";

class AdminPanelContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: []
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    async componentDidMount() {
        this.setState({permissions: await permissionsHelper.scopePermissions()})
    }

    render() {
        if (this.state.permissions.length === 0)
            return (<LinearProgress/>);
        return (
            <React.Fragment>
                <AdminPanelPage
                    permissions={this.state.permissions}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanelContainer);

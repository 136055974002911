import React from 'react'
import AuthSection from '../sections/AuthSection'
import Layout from '../Layout'

const AuthPage = () => (
    <Layout title={'Авторизация:'}>
        <AuthSection/>
    </Layout>
)

export default AuthPage

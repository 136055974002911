export const AUTH_CHANGE_EMAIL_TEXT = 'AUTH_CHANGE_EMAIL_TEXT';
export const setEmailText = (email) => ({
  type: AUTH_CHANGE_EMAIL_TEXT,
  payload: email
});

export const AUTH_CHANGE_PASSWORD_TEXT = 'AUTH_CHANGE_PASSWORD_TEXT';
export const setPasswordText = (password) => ({
  type: AUTH_CHANGE_PASSWORD_TEXT,
  payload: password
});

export const AUTH_SUBMIT_EVENT = 'AUTH_SUBMIT_EVENT';
export const SubmitEvent = (currentUser) => ({
  type: AUTH_SUBMIT_EVENT,
  payload: currentUser
});
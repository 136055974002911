import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useSnackbar } from 'notistack'
import { MDBCard, MDBCardBody, MDBCol, MDBRow, MDBTable, MDBTableBody } from 'mdbreact'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { contractService } from '../../../services/contractService'
import { useHistory, withRouter } from 'react-router'
import BreadcrumSection from '../../commonSections/BreadcrumSection'
import EditButton from '../../commonSections/EditButton'
import { numberWithSpaces } from '../../../helpers/NumberWithSpaces'
import ContractSinglePageArchivedRoomsTable from './blocks/ContractSinglePageArchivedRoomsTable'
import ContractRoomsTable from './blocks/ContractSinglePageRoomsTable'
import FilesSystem from '../../items/files-system'
import Pie from '../../items/pie'

const ContactPage = props => {
    const [contract, setContract] = useState()
    const id = props.match.params.contractId

    const breadcrumsChain = [
        ['Главная', '/main'],
        ['Договора', '/contracts']
    ]
    const history = useHistory()

    const { enqueueSnackbar } = useSnackbar()

    const sharePeiOptions = useMemo(() =>
            contract ? contract.contractOwnersShares.map(item => ({
                name: item.owner.shortName,
                value: item.sharePercentage
            })) : []
        , [contract])

    const showPieChart = useMemo(() => contract?.contractOwnersShares.length > 1, [contract]);

    const tableOptions = useMemo(() => contract ? getTableOptions(contract): [], [contract]);

    const loadContract = useCallback(() => {
        contractService.getById(id).then(_ => {
            setContract(_)})
    }, [id])

    const addDocuments = useCallback(async (files) => {
        try {
            const formData = new FormData();
            files.forEach(file => formData.append('File', file))
            await contractService.addDocuments(id, formData)
            enqueueSnackbar('Файлы успешно добавлены', { variant: 'success' })
            loadContract()
        } catch (err) {
            enqueueSnackbar(err, { variant: 'error' })
        }
    }, [id, enqueueSnackbar, loadContract])

    const deleteDocument = useCallback(async (docId) => {
        try {
            await contractService.removeDocument(id, docId)
            enqueueSnackbar('Файл удален', { variant: 'success' })
            loadContract()
        } catch (err) {
            enqueueSnackbar(err, { variant: 'error' })
        }
    }, [id, loadContract, enqueueSnackbar])

    useEffect(() => {
        loadContract()
    }, [loadContract])
    if (!contract) {
        return <LinearProgress/>
    }
    return (
        <React.Fragment>
            <BreadcrumSection
                // createPredicate={this.props.permissions.find(_ => _.id === 1).value}
                createPredicate={false}
                h2={'Договор № ' + contract.name}
                chain={breadcrumsChain}
                buttonName="договор"
                href="contracts/create"
                filterBtn={<EditButton onClick={() => {
                    history.push(`/contracts/update/${contract.id}`)
                }}/>}
            />
            <>
                <MDBRow>
                    <MDBCol sm={12} lg={9}>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size="6">
                                        <MDBTable className="contract-table">
                                            <MDBTableBody>
                                                {tableOptions.slice(0, showPieChart ? 8 : 4)}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBCol>
                                    <MDBCol size="6">
                                        {showPieChart
                                            ? <Pie data={sharePeiOptions}/>
                                            : <MDBTable className="contract-table">
                                                <MDBTableBody>
                                                    {tableOptions.slice(4, 8)}
                                                </MDBTableBody>
                                            </MDBTable>
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol sm={12} lg={3}>
                        <MDBCard style={{ height: '100%' }}>
                            <MDBCardBody>
                                <MDBRow style={{ height: '100%' }}>
                                    <MDBCol style={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div>
                                            <div className="mb-4"
                                                 style={{ textAlign: 'center', fontSize: 20 }}>Документы
                                            </div>
                                            {contract.documents.length
                                                ? contract.documents.map(item => (
                                                    <File key={`${id}-${item.id}`} file={item} contractId={id}/>
                                                ))
                                                : (
                                                    <div className="mt-5"
                                                         style={{ textAlign: 'center', color: 'rgb(109 115 125)' }}>
                                                        Список документов пуст. Нажмите "Редактировать", чтобы добавить
                                                        файлы.
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <FilesSystem
                                            documents={contract.documents}
                                            addDocuments={addDocuments}
                                            deleteDocument={deleteDocument}
                                        />
                                    </MDBCol>

                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

                {contract.rooms && contract.rooms.length !== 0 && (
                    <MDBCard className="mt-4">
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    {contract.entityStatus === 1 &&
                                    <ContractSinglePageArchivedRoomsTable rooms={contract.contractArchives}/>}
                                    <ContractRoomsTable rooms={contract.rooms}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                )}

                {contract.contractArchives && contract.contractArchives.length !== 0 && contract.entityStatus === 1 && (
                    <MDBCard className="mt-4">
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <ContractSinglePageArchivedRoomsTable rooms={contract.contractArchives}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                )}
            </>
        </React.Fragment>
    )

}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser
    }
}

const mapDispatchToProps = {}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ContactPage))


const File = ({ file, contractId }) => {
    const loadFile = useCallback(() => {
        contractService.getDocument(contractId, file.id, file.fileName)
    }, [file, contractId])
    return <div>
        <button onClick={loadFile} className="btn-no-styles none-break-word">
            {file.fileName || ''}
        </button>
    </div>
}

const Row = ({ label, value }) => (
    <tr>
        <td>
            {label}
        </td>
        <td>
            {value}
        </td>
    </tr>
)

const getTableOptions = (contract) => [
    <Row
        label="Номер договора:"
        value={contract.name}
    />,
    <Row
        label="Дата подписания"
        value={moment(contract.signingDateTime).format(
            'DD.MM.YYYY'
        )}
    />,
    <Row
        label="Начало действия"
        value={moment(contract.conclusionDateTime).format(
            'DD.MM.YYYY'
        )}
    />,
    <Row
        label="Конец действия"
        value={moment(contract.completionDateTime).format(
            'DD.MM.YYYY'
        )}
    />,
    <Row
        label="Арендатор"
        value={contract.company ? <Link
            to={'/renters/' + contract.company.id}
        >
            {contract.company.shortName}{' '}
        </Link> : '-'}
    />,

    <Row
        label="Сумма аренды"
        value={<>
            {numberWithSpaces(
                contract.rooms.reduce(
                    (sum, r) => r.factuallyRents ? sum + r.factuallyRents : sum + r.amountRents,
                    0
                )
            )} р.
        </>}
    />,
    <Row
        label="Суммараная площадь аренды"
        value={<>
            {numberWithSpaces(
                contract.rooms.reduce(
                    (sum, r) => sum + r.square,
                    0
                )
            )} м²
        </>}
    />,
    contract?.contractOwnersShares.length <= 1 ?<Row
        label="Собственник"
        value={contract?.contractOwnersShares ? <Link to={'/owners/' + contract.contractOwnersShares[0].owner.id}>
            {contract?.contractOwnersShares[0].owner.shortName}
        </Link> : '-'}
    /> : null
]
import moment from 'moment'

export default (completionDateTime) => {
    const diffDays = completionDateTime ? moment(completionDateTime).diff(moment(), 'days') : "none";
    let color;
    if(Number.isInteger(diffDays)){
        if(diffDays < 0) color = "danger"
        else if(diffDays >= 0 && diffDays < 31) color = "warning"
        else if(diffDays >= 31) color = "success"
    } else color = "blue-grey"

    return color
}
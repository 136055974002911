import React, { Component } from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import { Link } from "react-router-dom";

const breadCrumStyle = {
  position: "absolute",
  top: "-17px",
  left: "50%",
  padding: "0 15px",
  borderRadius: "0",
  boxShadow: "0 0 10px #CCC",
  marginLeft: "-180px",
  fontSize: "1rem",
  lineHeight: "2.25rem",
};

class BreadcrumSection extends Component {
  render() {
    const buttonName = this.props.buttonName;
    const header = this.props.h2;
    const chain = this.props.chain;
    const filterBtn = this.props.filterBtn;
    let itemsChain = [];
    let i = 0;
    chain.forEach(function (item) {
      i++;
      itemsChain.push(<MDBBreadcrumbItem key={i}><Link to={item[1]}>{item[0]}</Link></MDBBreadcrumbItem>);
    });
    itemsChain.push(
      <MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>
    );
    return (
      <MDBCard className="mb-3">
        <MDBCardBody id="breadcrumb" style={{ padding: 20 }}>
          {this.props.chain.length > 0 && (
            <MDBBreadcrumb style={breadCrumStyle}>{itemsChain}</MDBBreadcrumb>
          )}
          <MDBRow between>
            <MDBCol size="9" className="d-flex align-items-center">
              {buttonName && this.props.createPredicate && (
                <Link to={this.props.href} className="mr-3">
                    <MDBBtn
                      floating
                      size="sm"
                      className="z-depth-1"
                      color="success"
                    >
                      <MDBIcon icon="plus" />
                    </MDBBtn>
                </Link>
              )}
              <MDBCardTitle tag="h5" className="g-mb-0" style={{ margin: 0 }}>
                {header}
              </MDBCardTitle>
            </MDBCol>
            {filterBtn && (
              <MDBCol className="d-flex justify-content-end align-items-center">
                {filterBtn}
              </MDBCol>
            )}
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default BreadcrumSection;

import React                     from 'react'
import {Route, Switch}           from 'react-router-dom'
import ObjectPage                from '../components/containers/objects/index'
import CompanyPageContainer      from '../components/containers/company/CompanyPageContainer'
import AuthPage                  from '../components/containers/auth/pages/AuthPage'
import RegisterPage              from '../components/containers/auth/pages/RegisterPage'
import PasswordRecoveryPage      from '../components/containers/auth/pages/PasswordRecoveryPage'
import EmployeesContainer        from '../components/containers/employees/EmployeesContainer'
import UserPageContainer         from '../components/containers/user/UserPageContainer'
import ObjectUpdate              from '../components/containers/objects/update/update'
import ObjectCreate              from '../components/containers/objects/create/create'
import CompanyEmployeesContainer from '../components/containers/company/CompanyEmployeesContainer'
import RentalObjectPage          from '../components/containers/room/index'
import EmployeeCreateContainer   from '../components/containers/employees/EmployeeCreateContainer'
import AdminPanelContainer       from '../components/containers/admin-panel/AdminPanelContainer'
import RoleContainer             from '../components/containers/admin-panel/RoleManageContainer'
import UsersManageContainer      from '../components/containers/admin-panel/UsersManageContainer'
import ComingSoonPage            from '../components/errors/CommingSoonPage'
import BuildingCreateContainer   from '../components/containers/buidings/BuildingCreateContainer'
import AppealsContainer          from '../components/containers/appeals/AppealsContainer'
import AppealsCreateContainer    from '../components/containers/appeals/AppealsCreateContainer'
import AppealContainer           from '../components/containers/appeals/AppealContainer'
import EventsContainer           from '../components/containers/events/EventsContainer'
import ContractListContainer     from '../components/containers/contracts/ContractListContainer'
import CreateContractContainer   from '../components/containers/contracts/CreateContractContainer'
import UpdateContractContainer   from '../components/containers/contracts/UpdateContractContainer'
import ContractPage              from '../components/containers/contracts'
import AccrualListContainer      from '../components/containers/accruals/AccrualListContainer'
import CreateAccrualContainer    from '../components/containers/accruals/pages/CreateAccrualContainer'
import AccrualPageContainer      from '../components/containers/accruals/AccrualPageContainer'
import UpdateAccrualContainer    from '../components/containers/accruals/UpdateAccrualContainer'
import OwnerPageContainer        from '../components/containers/owners_old/OwnerPageContainer'
import OwnersList                from '../components/containers/owners/list';
import ScoreContainer            from '../components/containers/scores/ScoreContainer'
import IncomeContainer           from '../components/containers/income/IncomeContainer'
import IncomeCreatePage          from '../components/containers/income/pages/IncomeCreatePage'
import IncomeUpdatePage          from "../components/containers/income/pages/IncomeUpdatePage";
import RoomsPageContainer        from '../components/containers/rooms/RoomsPageContainer'
import CreateRoomPage            from '../components/containers/rooms/pages/CreateRoomPage'
import RentersContainer          from '../components/containers/renters'
import Docs                      from "../components/containers/help/index";
import CreateOwner               from '../components/containers/owners/create';
import UpdateOwner               from '../components/containers/owners/update'
import CreateCompany             from '../components/containers/company_new/create'
import UpdateRenter              from '../components/containers/company_new/update'
import FloorEdit                 from '../components/containers/floors/edit';
import ObjectsList               from '../components/containers/objects/list'
import {BuildingPage}            from "../components/containers/buidings";
import GroupedRoomsPageContainer from "../components/containers/grouped_rooms/GroupedRoomsPageContainer";
import UtilitiesList             from "../components/containers/utilities/UtilitiesList";
import CreateUtility             from "../components/containers/utilities/CreateUtility";


export const Login = (
    <Switch>
        <Route path="/auth" exact component={AuthPage}/>
        <Route path="/register/:guid" exact component={RegisterPage}/>
        <Route path="/password-recovery" exact component={PasswordRecoveryPage}/>
        <Route component={AuthPage}/>
    </Switch>
)

export const Object = () => (
    <Switch>
        <Route path="/objects/update/:objectId" component={ObjectUpdate}/>
        <Route path="/objects/create" component={ObjectCreate}/>
        <Route path="/objects/:objectId" component={Buildings}/>
        <Route exact path="/objects" component={ObjectsList}/>
    </Switch>
)

export const Buildings = () => (
    <Switch>
        {/* Страница помещения */}
        <Route path="/room/:roomId" component={RentalObjectPage}/>
        {/* Редактирование корпусов */}
        <Route path="/objects/:objectId/buildings/update/:buildingId" component={BuildingCreateContainer}/>

        {/* Создание корпуса */}
        <Route path="/objects/:objectId/buildings/create" component={BuildingCreateContainer}/>

        {/* Страница этажа */}
        <Route path="/objects/:objectId/buildings/:buildingId/floors" component={Floors}/>

        {/* Страница корпуса */}
        <Route path="/objects/:objectId/buildings/:buildingId" component={BuildingPage}/>

        {/* Список корпусов (Страница объекта)*/}
        <Route path="/objects/:objectId" component={ObjectPage}/>
    </Switch>
)

export const Floors = () => (
    <Switch>
        <Route exact path="/objects/:objectId/buildings/:buildingId/floors/:floorId/update" component={FloorEdit}/>
    </Switch>
)
//
// export const Floor = () => (
//     <Switch>
//         <Route exact path="/floor/edit/:id" component={FloorEdit} />
//     </Switch>
// )


export const Rooms = () => (
    <Switch>
        <Route exact path="/rooms" component={RoomsPageContainer}/>
        <Route path="/rooms/create" component={CreateRoomPage}/>
        <Route path="/rooms/update/:roomId" component={CreateRoomPage}/>
        <Route path="/rooms/:roomId" component={RentalObjectPage}/>
    </Switch>
)

export const RoomGroups = () => (
    <Switch>
        <Route path="/groups" component={GroupedRoomsPageContainer}/>
        {/*<Route path="/rooms/create" component={CreateRoomPage}/>*/}
        {/*<Route path="/rooms/update/:roomId" component={CreateRoomPage}/>*/}
        {/*<Route path="/rooms/:roomId" component={RentalObjectPage}/>*/}

    </Switch>
)


export const Owners = () => (
    <Switch>
        <Route exact path="/owners" component={OwnersList}/>
        <Route path="/owners/create" component={CreateOwner}/>
        <Route path="/owners/update/:ownerId" component={UpdateOwner}/>
        <Route path="/owners/:ownerId" component={OwnerPageContainer}/>
    </Switch>
)

export const Renters = () => (
    <Switch>
        <Route exact path="/renters" component={RentersContainer}/>
        <Route path="/renters/create" component={CreateCompany}/>
        <Route path="/renters/update/:organisationId" component={UpdateRenter}/>
        <Route path="/renters/:organisationId/employees" component={CompanyEmployeesContainer}/>
        <Route path="/renters/:organisationId" component={CompanyPageContainer}/>
    </Switch>
)

export const Employees = () => (
    <Switch>
        <Route exact path="/employees" component={EmployeesContainer}/>
        <Route path="/employees/create" component={EmployeeCreateContainer}/>
        <Route path="/employees/:employeesId" component={UserPageContainer}/>
    </Switch>
)

export const Contracts = () => (
    <Switch>
        <Route exact path="/contracts" component={ContractListContainer}/>
        <Route exact path="/contracts/create" component={CreateContractContainer}/>
        <Route path="/contracts/update/:contractId" component={UpdateContractContainer}/>
        <Route exact path="/contracts/:contractId" component={ContractPage}/>
    </Switch>
)

export const Accruals = () => (
    <Switch>
        <Route exact path="/accruals" component={AccrualListContainer}/>
        <Route path="/accruals/create" component={CreateAccrualContainer}/>
        <Route exact path="/accruals/:accrualId" component={AccrualPageContainer}/>
        <Route path="/accruals/update/:accrualId" component={UpdateAccrualContainer}/>
    </Switch>
)

export const Appeals = () => (
    <Switch>
        <Route exact path="/appeals" component={AppealsContainer}/>
        <Route path="/appeals/create" component={AppealsCreateContainer}/>
        <Route path="/appeals/:appealId" component={AppealContainer}/>
    </Switch>
)

export const AdminPanel = () => (
    <Switch>
        <Route exact path="/admin-panel" component={AdminPanelContainer}/>
        <Route path="/admin-panel/roles-manage" component={RoleContainer}/>
        <Route path="/admin-panel/users-manage" component={UsersManageContainer}/>
        <Route path="/admin-panel/objects-manage" component={ComingSoonPage}/>
        <Route path="/admin-panel/settings" component={ComingSoonPage}/>
    </Switch>
)

export const Events = () => (
    <Switch>
        <Route exact path="/events" component={EventsContainer}/>
    </Switch>
)

export const Scores = () => (
    <Switch>
        <Route path="/scores" component={ScoreContainer}/>
    </Switch>
)

export const Utilities = () => (
    <Switch>
        {/* Журнал коммунальных услуг */}
        <Route path="/utilities/new" component={CreateUtility}/>

        {/* Журнал коммунальных услуг */}
        <Route path="/utilities" component={UtilitiesList}/>

        {/* Журнал коммунальных услуг */}
        {/*<Route path="/utilities/:id/update" component={UpdateUtility}/>*/}


        {/* Тарифы коммунальных услуг */}
        <Route path="/utilities/rates" component={ScoreContainer}/>

        {/* Список счетчиков */}
        <Route path="/utilities/meters" component={ScoreContainer}/>

        {/* Карточка счетчика */}
        <Route path="/utilities/meter/:number" component={ScoreContainer}/>

        {/* Карточка счетчика */}
        <Route path="/utilities/meter/create" component={ScoreContainer}/>

        {/* Карточка счетчика */}
        <Route path="/utilities/meter/:number/update" component={ScoreContainer}/>

        {/* Список тарифов */}
        <Route path="/utilities/rates" component={ScoreContainer}/>


    </Switch>
)



export const Income = () => (
    <Switch>
        <Route exact path="/income" component={IncomeContainer}/>
        <Route path="/income/create" component={IncomeCreatePage}/>
        <Route path="/income/update/:incomeId" component={IncomeUpdatePage}/>
    </Switch>
)
import React, { useState, useEffect } from 'react'
import { withRouter, useParams } from 'react-router'
import { LinearProgress } from '@material-ui/core'

import BreadcrumSection from '../../commonSections/BreadcrumSection'
import { accrualService } from '../../../services/accrualService'
import AccrualSinglePage from './pages/AccrualSinglePage'

const AccrualPageContainer = props => {
    const [error, setError] = useState()
    const [accrual, setAccrual] = useState()
    const id = useParams().accrualId;

    useEffect(() => {
        accrualService.getById(id).then(
            _ => setAccrual(_),
            er => setError(er)
        )
    }, [id])

    let breadcrumsChain = [
        ['Главная', '/'],
        ['Начисления', '/accruals']
    ]
    if (accrual)
        return (
            <>
                <BreadcrumSection
                    h2={accrual.name}
                    chain={breadcrumsChain}
                    buttonName={false}
                    href="accruals/create"
                />
                <AccrualSinglePage accrual={accrual}/>
            </>
        )
    if (error) return <div className="text-center h3">Ошибка<br/> {error}</div>
    return <LinearProgress />
}

export default withRouter(AccrualPageContainer)

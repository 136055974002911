import React, {useState}                                                                      from 'react';
import {MDBBreadcrumb, MDBBreadcrumbItem, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow} from "mdbreact";
import {Link}                                                                                 from "react-router-dom";
import {useHistory}                                                                           from "react-router";
import {Grid, Paper, TextField}                                                               from "@material-ui/core";
import {Autocomplete}                                                                         from "@material-ui/lab";


const CreateUtility = () => {
    const history = useHistory ()
    const [headerBreadcrumbs, setHeaderBreadcrumbs] = useState ("Добавить запись в журнал коммунальных услуг")

    return (
        <>
            <BreadCrumbs history={history} header={headerBreadcrumbs}/>
            <Grid container spacing={2}>
                <Grid item xs={6} md={12}>
                    <Paper elevation={3} className="pa-24">
                        <h4>Создание записи в журнале коммунальных услуг</h4>
                        <p>Тут будет форма для добавления записи по расходам по коммунальным и бытовым услугам</p>
                        <br/>
                        <p>Раздел находится в разработке</p>
                        <CreateForm/>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default CreateUtility;

const BreadCrumbs = ({history, header}) => {
    const breadcrumbsChain = [['Главная', '/main'], ['Журнал коммунальных услуг', '/utilities'],]
    let itemsChain = []
    let i = 0
    breadcrumbsChain.forEach (function (item) {
        i++
        itemsChain.push (<MDBBreadcrumbItem key={i}><Link to={item[1]}>{item[0]}</Link></MDBBreadcrumbItem>)
    })
    itemsChain.push (<MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>);
    itemsChain.unshift (<MDBBreadcrumbItem key={321}><span className="link_to_breadcrumbs" color="link"
                                                           onClick={history.goBack}>Назад</span></MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" className="py-32">
                <MDBRow between>
                    <MDBCol className="d-flex align-items-center">
                        <MDBCardTitle tag="h5" className="mb-0">{header}</MDBCardTitle>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}

const CreateForm = ({}) => {

    const options = ['Option 1', 'Option 2'];

    const [value, setValue] = useState (useState (options[0]))
    const [newValue, setNewValue] = useState ("")
    const [inputValue, setInputValue] = React.useState ('');

    return (<>

        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setValue (newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue (newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            sx={{width: 300}}
            renderInput={(params) => <TextField {...params} label="Controllable"/>}
        />

    </>)
}
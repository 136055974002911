import React, { Component, useEffect, useState } from 'react'
import {
    MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput,
    MDBModalFooter, MDBRow, MDBTable, MDBTableBody, MDBTableHead,
    MDBIcon
} from 'mdbreact'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Redirect } from 'react-router'
import Select from 'react-select'
import moment from 'moment'

import { companyService } from '../../../../../services/companyService'
import { contractService } from '../../../../../services/contractService'
import { ownersService } from '../../../../../services/ownersService'
import { roomService } from '../../../../../services/roomService'
import RoomsSmallTable from '../../../../commonSections/tables/small-rooms-table'
import { getQuantityString } from '../../../../../utils/Others'
import FileInput from '../../../../items/file-input'

class CreateContractSection extends Component {
    constructor(props) {
        super(props)
        let today = moment(new Date().toISOString()).format('YYYY-MM-DD')
        let todayWithMonths = moment(today).add(11, 'M').format('YYYY-MM-DD')
        this.state = {
            isRedirect: false,
            activeStep: 0,
            isLoading: false,
            name: '',
            signingDateTime: today,
            conclusionDateTime: today,
            completionDateTime: todayWithMonths,
            renter: null,
            selectedRooms: [],
            rooms: [],
            inputValue: '',
            owner: [],
            files: [],
            parts: {},
            ownersOptions: [],
            rentersOptions: []
        }

        this.handleChange = this.handleChange.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState
    }


    formSubmit = async (event) => {
        if (this.state.renter === null || this.state.selectedRooms.length === 0 || this.state.name === '') {
            this.props.enqueueSnackbar('Заполните все поля', { variant: 'warning' })
            return
        }
        const partsSum = Object.values(this.state.parts).reduce((acc, item) => acc + Number(item || 0), 0);
        if(partsSum !== 100){
            this.props.enqueueSnackbar('В сумма всех долей собственников должно быть 100%', { variant: 'warning' })
            return
        }

        const modelJson = this.buildModel()
        this.setState({ isLoading: true })
        await contractService.create(modelJson).then(_ => {
            this.props.enqueueSnackbar('Договор успешно создан', { variant: 'success' })
            this.setState({ isRedirect: true })
        }, error => {
            this.setState({ isLoading: false })
            this.props.enqueueSnackbar(error, { variant: 'warning' })
        })


    }

    setRoomsFactuallyRent = (roomId, value) => {
        const selectedRooms = [...this.state.selectedRooms];
        const roomIndex = selectedRooms.findIndex(item => item.id === roomId);
        if(roomIndex || roomIndex === 0){
            selectedRooms[roomIndex].factuallyRents = Number(value).toString();
            this.setState({selectedRooms})
        }
    }

    buildModel = () => {
        const formData = new FormData()
        formData.append('name', this.state.name)
        formData.append('signingDateTime', this.state.signingDateTime)
        formData.append('completionDateTime', this.state.completionDateTime)
        formData.append('companyId', this.state.renter.id)
        Object.keys(this.state.parts).forEach((id, i) => {
            formData.append(`ContractOwnersShares[${i}].OwnerId`, id);
            formData.append(`ContractOwnersShares[${i}].SharePercentage`, this.state.parts[id]);
        })
        this.state.selectedRooms.forEach((_, i) => {
            formData.append(`Rooms[${i}].RoomId`, _.id);
            formData.append(`Rooms[${i}].FactuallyRents`, _.factuallyRents);
        })
        this.state.files.forEach(_ => {
            formData.append('Documents', _)
        })
        return formData
    }

    handleChange(name, event) {
        this.setState({ [name]: event.target.value })
    };

    componentDidMount() {
        companyService.getAll().then(_ => {
            const options = _.map(item => ({ value: item.fullName, label: item.fullName, id: item.id }));
            this.setState({rentersOptions: options })
        })
        roomService.getAllRooms().then(_ => this.setState({ rooms: _ }))
        ownersService.getAll().then(_ => {
            const options = _.map(item => ({ value: item.id, label: item.shortName, id: item.id }))
            this.setState({ ownersOptions: options })
        })
    }

    selectHandleChange(name, selectedOption) {
        this.setState({ [name]: selectedOption })
    };

    removeRoomById = id => {
        this.setState(state => ({ selectedRooms: state.selectedRooms.filter(item => item.id !== id) }))
    }

    render() {
        if (this.state.isRedirect)
            return (
                <Redirect to="/contracts"/>
            )
        const { rentersOptions, ownersOptions } = this.state;

        const selectedOwners = ownersOptions.reduce((acc, item) => {
            if(this.state.owner.some(_ => _.id === item.id)){
                return [...acc, {
                    name: item.label,
                    id: item.id
                }]
            }
            return acc
        }, []);
        return (
            <MDBCard>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol lg={4} md={6}>
                            <div className="grey-text">
                                <MDBInput
                                    name="name"
                                    label="Номер договора"
                                    group
                                    type="text"
                                    validate
                                    error="wrong"
                                    success="right"
                                    onChange={(e) => this.handleChange('name', e)}
                                    value={this.state.name}
                                />
                            </div>
                            <div className="grey-text">
                                <MDBInput
                                    name="conclusionDateTime"
                                    label="Начало действия"
                                    group
                                    type="date"
                                    validate
                                    error="wrong"
                                    success="right"
                                    onChange={(e) => this.handleChange('conclusionDateTime', e)}
                                    value={this.state.conclusionDateTime}
                                />
                            </div>
                            <Select
                                value={this.state.renter}
                                onChange={e => this.selectHandleChange('renter', e)}
                                options={rentersOptions}
                                placeholder="Выбрать арендатора"
                            />
                        </MDBCol>
                        <MDBCol lg={4} md={6}>
                            <div className="grey-text">
                                <MDBInput
                                    name="signingDateTime"
                                    label="Дата подписание договора"
                                    group
                                    type="date"
                                    validate
                                    error="wrong"
                                    success="right"
                                    onChange={(e) => this.handleChange('signingDateTime', e)}
                                    value={this.state.signingDateTime}
                                />
                            </div>
                            <div className="grey-text">
                                <MDBInput
                                    name="completionDateTime"
                                    label="Конец действия"
                                    group
                                    type="date"
                                    validate
                                    error="wrong"
                                    success="right"
                                    onChange={(e) => this.handleChange('completionDateTime', e)}
                                    value={this.state.completionDateTime}
                                />
                            </div>
                            <Select
                                isMulti
                                value={this.state.owner || []}
                                onChange={e => this.selectHandleChange('owner', e)}
                                options={ownersOptions}
                                placeholder="Выбрать собственника"
                            />
                        </MDBCol>
                        <MDBCol lg={4} md={6}>
                            {
                                selectedOwners.length
                                    ? <>
                                        <div className="mb-4">Доли</div>

                                        {selectedOwners.map(item => (
                                            <Part
                                                key={item.id}
                                                name={item.name}
                                                value={this.state.parts[item.id] || 0}
                                                onChange={value => {
                                                    this.setState(({parts}) => {
                                                        return {parts: {...parts, [item.id]: value}}
                                                    })
                                                }}
                                            />
                                        ))}

                                        <div
                                            className="mt-4"
                                            style={{
                                                fontSize: 12,
                                                borderTop: '1px solid #747474',
                                                color: '#747474',
                                                textAlign: 'right'
                                            }}
                                        >
                                             В сумме должно быть 100%
                                        </div>
                                    </>
                                    : null
                            }

                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size={12} className="mt-3">
                            Добавить файлы
                        </MDBCol>
                        <MDBCol md={12} lg={8} className="mt-3">
                            <FileInput
                                value={this.state.files}
                                onChange={files => this.setState({ files })}
                                label="Перетащите сюда нужные файлы, либо нажмите на это окошко"
                            />
                        </MDBCol>
                    </MDBRow>
                    {!!this.state.selectedRooms.length &&
                    <MDBRow>
                        <MDBCol size={12} className="mt-3">
                            Выбранные помещения
                        </MDBCol>
                        <MDBCol md={12} lg={8}>
                            <SelectedRoomsTable
                                rooms={this.state.selectedRooms}
                                onRemove={this.removeRoomById}
                                setRoomsFactuallyRent={this.setRoomsFactuallyRent}
                            />
                        </MDBCol>
                    </MDBRow>
                    }
                    <MDBRow>
                        <MDBCol size={12} className="mt-3">
                            Выбрать помещение
                        </MDBCol>
                        <MDBCol md={12} lg={8}>
                            <RoomsSmallTable
                                disabledWithContracts
                                selectedRoomsId={this.state.selectedRooms.map(item => item.id)}
                                onAdd={room =>
                                    this.setState(state => (
                                        {
                                            selectedRooms: [
                                                ...state.selectedRooms,
                                                {
                                                    ...room,
                                                    factuallyRents: room.amountRent
                                                }
                                            ]
                                        }
                                        )
                                    )}
                                onRemove={this.removeRoomById}
                            />
                        </MDBCol>
                    </MDBRow>

                </MDBCardBody>

                <MDBModalFooter>
                    {this.state.isLoading && <CircularProgress size={30}/>}
                    <MDBBtn
                        outline
                        color="blue-grey"
                        disabled={this.state.isLoading}
                        onClick={() => {
                            this.setState({ isRedirect: true })
                        }}>
                        Назад
                    </MDBBtn>
                    < MDBBtn
                        disabled={this.state.isLoading}
                        outline
                        onClick={(e) => this.formSubmit(e)
                        }
                        color="success">
                        Сохранить
                    </MDBBtn>
                </MDBModalFooter>
            </MDBCard>
        )
    }
}

export default withSnackbar(CreateContractSection)


const SelectedRoomsTable = ({ rooms, onRemove, setRoomsFactuallyRent }) =>
    <MDBTable className="table dataTable  table-striped smart-table table--small-padding">
        <MDBTableHead>
            <tr>
                <th>#</th>
                <th>Название</th>
                <th>Плановая аренда</th>
                <th>Фактическая аренда</th>
                <th>Площадь</th>
                <th/>
            </tr>
        </MDBTableHead>
        <MDBTableBody>
            {rooms.map((room, index) => (
                <tr key={room.id}>
                    <td>{index + 1}</td>
                    <td>{room.name}</td>
                    <td>{getQuantityString(room.amountRent)}</td>
                    <td>
                        <FactAmountRent
                            type='number'
                            className="btn-no-styles"
                            value={room.factuallyRents}
                            onChange={({target}) => setRoomsFactuallyRent(room.id, target.value)}
                        />
                    </td>
                    <td>{getQuantityString(room.square, 'м²')}</td>
                    <td align="right">
                        <button onClick={() => onRemove(room.id)} className="btn-no-styles">
                            <MDBIcon far size="lg" icon="trash-alt"/>
                        </button>
                    </td>
                </tr>
            ))}
        </MDBTableBody>
    </MDBTable>

const FactAmountRent = (props) => {
    const [active, setActive] = useState(false)
    useEffect(() => {
        return () => setActive(false)
    }, [])
    return (
        <div className="d-flex">
            <div
                style={{
                    width: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: active ? '#4285F4' : 'black'
                }}
            >
                <span style={{ flex: 'none', color: active ? '#4285F4' : 'black', marginRight: 4 }}>
                    <MDBIcon icon='edit'/>
                </span>
                <input
                    {...props}
                    onFocus={(e) => {
                        setActive(true)
                        typeof props.onChange === 'function' && props.onChange(e)
                    }}
                    onBlur={(e) => {
                        setActive(false)
                        typeof props.onBlur === 'function' && props.onBlur(e)
                    }}
                />
                р.
            </div>
        </div>
    )
}

const Part = ({name, value, onChange}) => {
    const [active, setActive] = useState(false)

    return (
        <div className="d-flex justify-content-between align-center mt-3">
            <div>{name}</div>
            <div className="d-flex align-center" style={{
                width: 'auto',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid',
                borderColor: active ? '#4285F4' : 'black'
            }}>
                <input
                    className="btn-no-styles"
                    type='number'
                    value={value}
                    onFocus={() => {
                        setActive(true)
                    }}
                    onBlur={() => {
                        setActive(false)
                    }}
                    onChange={e => {
                        const value = Number(e.target.value);
                        if(value < 101 && value > -1 && typeof onChange === 'function'){
                            onChange(Number(value).toString());
                        }}
                    }
                    style={{width: 80}}
                />
               <span style={{color: active ? '#4285F4' : 'black'}}>%</span>
            </div>
        </div>
    )
}
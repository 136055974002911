import React, {Component} from 'react';
import {connect} from "react-redux";
import {setCurrentUser} from "../../../store/users/user/actions";
import {setAllUsers} from "../../../store/users/actions";
import UserProfilePage from "./pages/UserProfilePage";
import {employeeService} from "../../../services/employeeService";
import {LinearProgress} from "@material-ui/core";

let userID = '';

class UserPageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: null
        };
        this.setCurrentUser = this.setCurrentUser.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.allUsers !== nextProps.allUsers ||
            this.props.currentUser !== nextProps.currentUser ||
            this.state.selectedUser !== nextState.selectedUser;
    }

    updateSelectedUser = (userID) => {
        employeeService.getById(userID).then(_ => {
            this.setState({selectedUser: _});
        });
    };

    getAllUsers = () => {
        employeeService.getAll().then(_ => {
            this.props.setAllUsers(_);
        });
    };

    setCurrentUser(user) {
        this.props.setCurrentUser(user);
        this.setState({selectedUser: user});
        this.getAllUsers();
    }

    render() {
        if (!this.props.allUsers) this.getAllUsers();
        if (this.props.match.params) {
            userID = parseInt(this.props.match.params.employeesId);
            if (!this.state.selectedUser) this.updateSelectedUser(userID);
        }
        if (isNaN(userID))
            return (<LinearProgress/>);



        return (
            <React.Fragment>
                <UserProfilePage
                    selectedUser={this.state.selectedUser}
                    setCurrentUser={this.setCurrentUser}
                    currentUser={this.props.currentUser}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        allUsers: state.users.allUsers,
        currentUser: state.user.currentUser,
        token: state.user.token,
    };
};

const mapDispatchToProps = {
    setCurrentUser,
    setAllUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPageContainer);

import React, {Component} from 'react';
import {connect} from "react-redux";
import ContractListPage from "./pages/ContractListPage";
import {permissionsHelper} from "../../../utils/permissionsHelper";
import {LinearProgress}    from "@material-ui/core";



class ContractListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: []

        };
    }
    async componentDidMount() {
        this.setState({permissions: await permissionsHelper.scopePermissions()})
    }
    render() {
        if(this.state.permissions.length !== 0)
        return (
            <ContractListPage
                permissions={this.state.permissions}
            />
        )
        else return <LinearProgress />
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContractListContainer);

import React, {useState, useRef, useCallback, useEffect, useMemo} from 'react'
import {Stage, Layer}                                             from 'react-konva'
import cloneDeep                                                  from 'lodash/cloneDeep'
import Select                                                     from 'react-select'
import {MDBBtn, MDBModalBody, MDBModalFooter, MDBModalHeader}     from 'mdbreact'
import classNames                                                 from 'classnames'
import withQueryParams                                            from 'react-router-query-params'
import {useHistory}                                               from 'react-router'

import Shape                            from './components/shape'
import Point                            from './components/point'
import useHistoryState                  from '../../hooks/history-state'
import {ReactComponent as SaveIcon}     from '../../static/icons/i-save.svg'
import {ReactComponent as TrashIcon}    from '../../static/icons/i-trash.svg'
import {ReactComponent as OrthoIcon}    from '../../static/icons/i-compress.svg'
import {ReactComponent as UndoIcon}     from '../../static/icons/i-undo.svg'
import {ReactComponent as RedoIcon}     from '../../static/icons/i-redo.svg'
import {ReactComponent as EyeIcon}      from '../../static/icons/i-eye.svg'
import Modal                            from '../modal'
import {floorService}                   from '../../services/floorService'
import {getImageUrl, getQuantityString} from '../../utils/Others'
import NewModal                         from '../commonSections/new-modal'
import CursorTip                        from '../items/cursor-tip'
import {roomService}                    from '../../services/roomService'


const HEIGHT = 500
const WIDTH = 1585

const Main = ({
                  data,
                  onSave,
                  rooms,
                  editable,
                  focusRoomId,
                  floorId,
                  schema: defaultSchema,
                  queryParams,
                  setActiveRoom
              }) => {
    const [drawMode, setDrawMode] = useState (false)
    const [orthogonalMode, setOrthogonalMode] = useState (false)
    const [tempPoints, setPoints] = useState ([])
    const [objects, setObjects, ...stateHistory] = useHistoryState (data ?? [])
    const [file, setFile] = useState (null)
    const ref = useRef (null)
    const layerRef = useRef (null)
    const [scale, setScale] = useState (1)
    const [size, setSize] = useState ({width: WIDTH, height: 'auto'})
    const [imageUrl, setImageUrl] = useState (defaultSchema?.url ?? '')
    const [canSave, setCanSave] = useState (false)
    const [active, setActive] = useState ()
    const [createModalOpen, setCreateModalOpen] = useState (false)
    const [selectedCreateRoom, setSelectedCreateRoom] = useState ()
    const [showTitle, setShowTitle] = useState (true)
    const [showSquare, setShowSquare] = useState (true)
    const [schema, setSchema] = useState (defaultSchema)
    const [hoverRoom, setHoverRoom] = useState ()
    const [roomsData, setRoomsData] = useState ([])
    const [someObjectMoving, setSomeObjectMoving] = useState (false)
    const {push} = useHistory ()
    const imageRef = useRef (null)

    const roomsIds = useMemo (() => objects.map (item => item.id.toString ()).join (','), [objects])

    const fromRoom = useMemo (() => {
        const roomId = queryParams.lastRoomId
        if (roomId) {
            return rooms.find (item => item.id.toString () === roomId)
        }
    }, [queryParams.lastRoomId, rooms])

    const roomsOptions = useMemo (
        () =>
            rooms
                .filter (room => !objects.some (obj => obj.id === room.id))
                .map (item => ({
                    label: item.name,
                    value: item.id
                })),
        [rooms, objects]
    )

    const showCursorTip = useMemo (() => {
        if (!hoverRoom || someObjectMoving) {
            return false
        }

        if (active) {
            return active !== hoverRoom.id
        }

        return true
    }, [hoverRoom, active, someObjectMoving])

    const addPoint = useCallback ((x, y, lastPoint, ortMode) => {
        if (lastPoint && ortMode) {
            let dx = 0.0
            let dy = 0.0
            lastPoint.x > x ? dx = lastPoint.x - x : dx = x - lastPoint.x
            lastPoint.y > y ? dy = lastPoint.y - y : dy = y - lastPoint.y
            if (dx > dy) {y = lastPoint.y}
            else {x = lastPoint.x}
        }
        setPoints (prev => [...prev, {x, y}])
    }, [])

    const addObject = useCallback (
        createRoom => {
            const xPoints = tempPoints.map (({x}) => x)
            const yPoints = tempPoints.map (({y}) => y)

            const minX = Math.min (...xPoints)
            const minY = Math.min (...yPoints)

            const center = findCenter (xPoints, yPoints)

            const points = tempPoints.map (item => ({
                x: item.x - minX,
                y: item.y - minY
            }))

            setObjects ([
                ...objects,
                {
                    ...(selectedCreateRoom ?? createRoom),
                    x     : minX,
                    y     : minY,
                    center,
                    points: transformPoints (points)
                }
            ])
            setPoints ([])
            setDrawMode (false)
            setCanSave (true)
        },
        [tempPoints, setObjects, objects, selectedCreateRoom]
    )

    const onTransform = useCallback (
        (id, points) => {
            const result = [...objects]

            const index = objects.findIndex (item => item.id === id)
            if (~index) {
                const obj = result[index]

                const xPoints = points.reduce (
                    (acc, item, index) => (index % 2 === 0 ? [...acc, item + obj.x] : acc),
                    []
                )
                const yPoints = points.reduce (
                    (acc, item, index) => (index % 2 !== 0 ? [...acc, item + obj.y] : acc),
                    []
                )

                const center = findCenter (xPoints, yPoints)

                result[index] = {...obj, points, center}
                setObjects (result)
            }
            setCanSave (true)
        },
        [objects, setObjects]
    )

    const changePosition = useCallback (
        (x, y, id) => {
            const objectsClone = cloneDeep (objects)
            const changeItemIndex = objectsClone.findIndex (item => item.id === id)
            if (~changeItemIndex) {
                const deltaX = objects[changeItemIndex].x - x
                const deltaY = objects[changeItemIndex].y - y
                objectsClone[changeItemIndex].x = x
                objectsClone[changeItemIndex].y = y
                if (objectsClone[changeItemIndex].center) {
                    objectsClone[changeItemIndex].center = {
                        x: objectsClone[changeItemIndex].center.x - deltaX,
                        y: objectsClone[changeItemIndex].center.y - deltaY
                    }
                }
                setObjects (objectsClone)
            }
            setCanSave (true)
        },
        [objects, setObjects]
    )

    const addObjectPoint = useCallback (
        ({x, y}, i, id) => {
            const objectsClone = cloneDeep (objects)
            const changeItemIndex = objectsClone.findIndex (item => item.id === id)
            if (~changeItemIndex) {
                objectsClone[changeItemIndex].points.splice (i * 2 + 2, 0, x, y)
                setObjects (objectsClone)
            }
            setCanSave (true)
        },
        [objects, setObjects]
    )

    const defineSizes = useCallback (() => {
        if (imageRef.current && ref.current) {
            const {naturalWidth, naturalHeight} = imageRef.current
            const height = (WIDTH * naturalHeight) / naturalWidth
            if (!isNaN (height)) {
                setSize ({width: WIDTH, height})
            }
        }
    }, [imageRef])

    const onRemove = useCallback (() => {
        setObjects (objects.filter (item => item.id !== active))
        setCanSave (true)
        setActiveRoom (undefined)
    }, [objects, active, setObjects, setActiveRoom])

    const deleteSchemaImage = useCallback (() => {
        if (imageUrl && schema) {
            floorService.removeImage (floorId, schema.id).then (() => {
                setImageUrl ('')
            })
        }
    }, [schema, floorId, imageUrl])

    const deletePoint = useCallback (
        (index, objectId) => {
            const clone = JSON.parse (JSON.stringify (objects))
            const objectIndex = clone.findIndex (item => item.id === objectId)
            if (objectIndex !== -1) {
                if (clone[objectIndex].points.length <= 6) {
                    clone.splice (objectIndex, 1)
                }
                else {
                    clone[objectIndex].points.splice (index * 2, 2)
                }
                setObjects (clone)
            }
        },
        [objects, setObjects]
    )

    const addNewObject = useCallback (() => {
        if (fromRoom) {
            addObject (fromRoom)
            window.history.pushState (null, null, window.location.pathname)
        }
        else {
            setCreateModalOpen (true)
        }
    }, [fromRoom, addObject])

    const setNewPoint = ({evt}) => {
            const {x, y} = evt
            const {x: offsetX, y: offsetY} = ref.current.getBoundingClientRect () // Деструктуризация объекта, который получаем из getBoundingClientRect () при этом меняем базовые наименования x на offsetX, а y на offsetY
            const {scrollTop, scrollLeft} = ref.current
            addPoint ((x - offsetX + scrollLeft) / scale, (y - offsetY + scrollTop) / scale, tempPoints[tempPoints.length-1], orthogonalMode)
        }

    const onClickFirst = useCallback (() => {
        addNewObject ()
        setTimeout (() => setPoints (prev => prev.slice (0, prev.length - 1)))
    }, [addNewObject])

    const setHoverObject = useCallback (
        (clear, id) => {
            if (clear) {
                setHoverRoom (undefined)
            }
            else if (roomsData?.length) {
                const room = roomsData.find (item => item.id === id)
                setHoverRoom (room)
            }
        },
        [roomsData]
    )

    useEffect (() => {
        (async () => {
            if (roomsIds) {
                const data = await roomService.getRoomsByIds (roomsIds.split (','))
                if (data) {
                    setRoomsData (data)
                }
            }
        }) ()
    }, [roomsIds])

    useEffect (() => {
        if (typeof setActiveRoom === 'function') {
            setActiveRoom (active)
        }
    }, [active, setActiveRoom])

    useEffect (() => {
        if (!drawMode) {
            setPoints ([])
        }
    }, [drawMode])

    useEffect (() => {
        defineSizes ()
        window.addEventListener ('resize', defineSizes)
        return () => window.removeEventListener ('resize', defineSizes)
    }, [defineSizes])

    useEffect (() => {
        defineSizes ()
    }, [defineSizes, imageUrl])

    useEffect (() => {
        if (file) {
            floorService
                .saveFloorSchemaImg (floorId, file)
                .then (res => {
                    if (res.images.length) {
                        const {id, filePath} = res.images[0]
                        const url = getImageUrl (filePath)
                        setSchema ({id, url})
                        setImageUrl (url)
                    }
                })
                .then (() => setTimeout (defineSizes, 200))
                .catch (() => {})
        }
    }, [file, floorId, defineSizes])

    useEffect (() => {
        setObjects (data ?? [])
        setTimeout (defineSizes, 500)
        // eslint-disable-next-line
    }, [data])

    useEffect (() => {
        if (ref.current) {
            ref.current.oncontextmenu = event => {
                event.preventDefault ()
            }
        }
    }, [ref])

    return (
        <div>
            <Controls
                {...{
                    drawMode,
                    setDrawMode,
                    orthogonalMode,
                    setOrthogonalMode,
                    tempPoints,
                    addObject     : addNewObject,
                    setPoints,
                    scale,
                    setScale,
                    file,
                    setFile,
                    disabled      : !imageUrl,
                    onSave        : () => {
                        onSave (objects)
                        setCanSave (false)
                    },
                    stateHistory,
                    canSave,
                    active,
                    onRemove,
                    showSquare,
                    showTitle,
                    setShowSquare,
                    setShowTitle,
                    editable,
                    onRemoveSchema: deleteSchemaImage
                }}
            />
            <div
                ref={ref}
                style={{
                    height  : size.height === 'auto' ? HEIGHT : size.height,
                    overflow: 'auto'
                }}
            >
                {imageUrl && objects ? (
                    <div
                        style={{
                            width             : size.width === 'auto' ? size.width : size.width * scale,
                            height            : size.height === 'auto' ? size.height : size.height * scale,
                            position          : 'relative',
                            backgroundSize    : 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat  : 'no-repeat'
                        }}
                    >
                        <div
                            style={{
                                top      : 0,
                                position : 'absolute',
                                transform: `scale(${scale})`
                            }}
                        >
                            {imageUrl && (
                                <img
                                    ref={imageRef}
                                    style={{
                                        top          : 0,
                                        position     : 'absolute',
                                        pointerEvents: 'none',
                                        width        : size.width,
                                        height       : size.height
                                    }}
                                    src={imageUrl}
                                    alt="schema"
                                />
                            )}
                        </div>
                        <CursorTip
                            active={showCursorTip}
                            tip={hoverRoom ? <Tip obj={hoverRoom}/> : null}
                        >
                            <Stage
                                width={size.width * scale}
                                height={size.height * scale}
                                scaleX={scale}
                                scaleY={scale}
                                onClick={drawMode ? setNewPoint : undefined}
                            >
                                <Layer ref={layerRef}>
                                    {tempPoints?.length > 0 &&
                                        tempPoints.map (({x, y}, i) => (
                                            <Point
                                                key={i}
                                                x={x}
                                                y={y}
                                                color={i === 0 ? 'orange' : 'green'}
                                                strokeWidth={2 / scale}
                                                radius={6 / scale}
                                                onClick={i === 0 ? onClickFirst : undefined}
                                            />
                                        ))}
                                    {objects &&
                                        objects.map (obj => (
                                            <Shape
                                                push={push}
                                                isFocused={focusRoomId === obj.id}
                                                draggable={editable}
                                                {...obj}
                                                title={showTitle ? obj.name : undefined}
                                                square={showSquare ? obj.square : undefined}
                                                active={active === obj.id}
                                                setActive={() =>
                                                    setActive (
                                                        active === obj.id ? undefined : obj.id
                                                    )
                                                }
                                                key={obj.id}
                                                points={obj.points}
                                                onChangePosition={(x, y) =>
                                                    changePosition (x, y, obj.id)
                                                }
                                                onTransform={points => onTransform (obj.id, points)}
                                                onAddPoint={(point, i) =>
                                                    addObjectPoint (point, i, obj.id)
                                                }
                                                scale={scale}
                                                center={obj.center}
                                                deletePoint={pointIndex =>
                                                    deletePoint (pointIndex, obj.id)
                                                }
                                                setHoverObject={clear =>
                                                    setHoverObject (clear, obj.id)
                                                }
                                                onChangeMovingState={setSomeObjectMoving}
                                            />
                                        ))}
                                </Layer>
                            </Stage>
                        </CursorTip>
                    </div>
                ) : (
                     <ImageInput onChange={e => setFile (e.target.files[0])}/>
                 )}
            </div>

            {/* Всплывашка для привязки объекта аренды к схеме */}
            <Modal isOpen={createModalOpen} onChange={setCreateModalOpen}>
                <MDBModalHeader>Выберите объект аренды</MDBModalHeader>
                <MDBModalBody className="text-left">
                    <Select
                        placeholder="Выбрать..."
                        options={roomsOptions}
                        onChange={({value}) => {
                            const room = rooms.find (item => item.id === value)
                            if (room) {
                                setSelectedCreateRoom (room)
                            }
                        }}
                        value={
                            selectedCreateRoom
                            ? roomsOptions.find (item => item.id === selectedCreateRoom.id)
                            : undefined
                        }
                    />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn
                        onClick={() => {
                            setCreateModalOpen (false)
                            setPoints ([])
                            setDrawMode (false)
                        }}
                    >
                        Отмена
                    </MDBBtn>
                    <MDBBtn
                        color="primary"
                        disabled={!selectedCreateRoom}
                        onClick={() => {
                            addObject ()
                            setCreateModalOpen (false)
                        }}
                    >
                        Добавить
                    </MDBBtn>
                </MDBModalFooter>
            </Modal>
        </div>
    )
}

Main.defaultProps = {
    editable: true
}

export default withQueryParams () (Main)

const transformPoints = coords => coords.reduce ((acc, item) => [...acc, item.x, item.y], [])

const Controls = ({
                      drawMode,
                      setDrawMode,
                      orthogonalMode,
                      setOrthogonalMode,
                      tempPoints,
                      addObject,
                      setPoints,
                      scale,
                      setScale,
                      file,
                      setFile,
                      disabled,
                      onSave,
                      stateHistory,
                      canSave,
                      active,
                      onRemove,
                      showSquare,
                      showTitle,
                      setShowSquare,
                      setShowTitle,
                      editable,
                      onRemoveSchema
                  }) => {
    const [goPrev, goNext, canGetPrev, canGetNext] = stateHistory

    const [showChangeModal, setShowChangeModal] = useState (false)
    return (
        <>
            {/* Панель с элекментами управления */}
            <div
                className="floor-planner__controls"
                style={{
                    padding: editable ? undefined : 0
                }}
            >
                {editable && (
                    <>
                        <div className="floor-planner__controls-side">
                            <button
                                disabled={!canSave}
                                onClick={onSave}
                                className="my-btn my-btn--primary mr-2"
                            >
                                <span className="my-btn__icon">
                                    <SaveIcon/>
                                </span>
                                Сохранить
                            </button>
                            <button
                                onClick={() => setDrawMode (prev => !prev)}
                                className="my-btn my-btn--green mr-2"
                                disabled={disabled}
                            >
                                <span className="my-btn__icon">
                                    <SaveIcon/>
                                </span>
                                {drawMode ? 'Отменить' : 'Новая область'}
                            </button>
                            <button
                                disabled={!canGetPrev}
                                onClick={goPrev}
                                className="my-btn my-btn--transparent mr-2"
                            >
                                <span className="my-btn__icon my-btn__icon--no-margin">
                                    <UndoIcon/>
                                </span>
                            </button>
                            <button
                                disabled={!canGetNext}
                                onClick={goNext}
                                className="my-btn my-btn--transparent mr-2"
                            >
                                <span className="my-btn__icon my-btn__icon--no-margin">
                                    <RedoIcon/>
                                </span>
                            </button>
                            {drawMode && <button
                                className={`my-btn ${orthogonalMode ? "my-btn--primary" : "my-btn--transparent"} mr-2`}
                                onClick={()=>setOrthogonalMode(!orthogonalMode)}
                            >
                                <span className="my-btn__icon"><OrthoIcon/></span>
                                90&deg;
                            </button>}
                            {/*
                             <button disabled={disabled} className="my-btn my-btn--transparent mr-2">
                             <span className="my-btn__icon">
                             <LockIcon />
                             </span>
                             Заблокировать
                             </button> */}
                            {tempPoints.length > 0 && (
                                <>
                                    <button
                                        className="my-btn my-btn--transparent mr-2"
                                        onClick={() => setPoints ([])}
                                    >
                                        <span className="my-btn__icon">
                                            <TrashIcon/>
                                        </span>
                                        Очистить точки
                                    </button>

                                    <button
                                        onClick={addObject}
                                        className="my-btn my-btn--primary mr-2"
                                    >
                                        <span className="my-btn__icon">
                                            <SaveIcon/>
                                        </span>
                                        Создать
                                    </button>
                                </>
                            )}
                            {active && (
                                <button
                                    className="my-btn my-btn--transparent mr-2"
                                    onClick={onRemove}
                                >
                                    <span className="my-btn__icon">
                                        <TrashIcon/>
                                    </span>
                                    Удалить
                                </button>
                            )}
                        </div>
                        <div className="floor-planner__controls-side">
                            <button
                                disabled={disabled}
                                className="my-btn my-btn--transparent mr-2"
                                onClick={() => setShowChangeModal (true)}
                            >
                                <span className="my-btn__icon">
                                    <EyeIcon/>
                                </span>
                                Изменить схему
                            </button>
                            {/* <button disabled={disabled} className="my-btn my-btn--transparent mr-2">
                             <span className="my-btn__icon">
                             <EyeIcon />
                             </span>
                             Фон
                             </button> */}
                            <button
                                onClick={() => setShowTitle (!showTitle)}
                                className={classNames (
                                    'my-btn my-btn--transparent mr-2',
                                    showTitle && 'my-btn--primary'
                                )}
                            >
                                <span className="my-btn__icon">
                                    <EyeIcon/>
                                </span>
                                Название
                            </button>
                            <button
                                onClick={() => setShowSquare (!showSquare)}
                                className={classNames (
                                    'my-btn my-btn--transparent mr-2',
                                    showSquare && 'my-btn--primary'
                                )}
                            >
                                <span className="my-btn__icon">
                                    <EyeIcon/>
                                </span>
                                Площадь
                            </button>
                        </div>
                    </>
                )}
                {!disabled && (
                    <div
                        style={{
                            position      : 'absolute',
                            top           : '100%',
                            width         : '100%',
                            height        : 0,
                            paddingRight  : 16,
                            left          : 0,
                            display       : 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <div className="d-flex" style={{paddingTop: 16}}>
                            <button
                                disabled={scale === 1}
                                className="my-btn my-btn--transparent"
                                onClick={() => setScale (prev => (prev * 10 - 1) / 10)}
                            >
                                -
                            </button>
                            <span style={{width: 42, display: 'flex', justifyContent: 'center'}}>
                                {scale}
                            </span>
                            <button
                                disabled={scale === 3}
                                className="my-btn my-btn--transparent"
                                onClick={() => setScale (prev => (prev * 10 + 1) / 10)}
                            >
                                +
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {/* Всплывашка для обнолвения схемы-подложки при нажатии на кнопку "Изменить схему */}
            <NewModal
                title="Изменить избражение схемы"
                show={showChangeModal}
                onChange={show => setShowChangeModal (show)}
                controls={[
                    <MDBBtn
                        key="cancel"
                        size="md"
                        color="secondary"
                        onClick={() => setShowChangeModal (false)}
                    >
                        Отмена
                    </MDBBtn>,
                    <MDBBtn
                        key="ok"
                        size="md"
                        color="danger"
                        onClick={() => {
                            onRemoveSchema ()
                            setShowChangeModal (false)
                        }}
                    >
                        Удалить изображение схемы
                    </MDBBtn>
                ]}
            >
                Чтобы изменить изображение схемы, вам нужно удалить текущее изображение. Удаленное
                изображение больше не будет доступно, но при этом схемы объектов сохранятся.
            </NewModal>
        </>
    )
}

const ImageInput = ({onChange}) => {
    return (
        <div
            style={{
                position       : 'relative',
                height         : '100%',
                backgroundColor: 'rgb(245 245 245)',
                display        : 'flex',
                alignItems     : 'center',
                justifyContent : 'center'
            }}
        >
            Перетащите сюда схему
            <input
                type="file"
                style={{
                    position: 'absolute',
                    top     : 0,
                    width   : '100%',
                    left    : 0,
                    right   : 0,
                    bottom  : 0,
                    opacity : 0
                }}
                onChange={onChange}
            />
        </div>
    )
}

const Tip = ({obj}) => {
    const {name, square, factuallyRents, amountRent, contract} = obj

    const owners = useMemo (() => {
        if (contract) {
            return contract.contractOwnersShares.map (item => item.owner.shortName).join (', ')
        }
    }, [contract])

    if (!obj) {
        return null
    }

    return (
        <>
            <div className="cursor-tip__header">
                <div>
                    <div className="cursor-tip__header-title">{name}</div>
                    <div className="cursor-tip__header-desc">Объект аренды</div>
                </div>
                <div className="d-flex flex-column justify-between">
                    <div className="cursor-tip__header-square text-right">
                        {getQuantityString (square, 'м²')}
                    </div>
                    <div className="cursor-tip__header-price text-right">
                        {getQuantityString (factuallyRents ? factuallyRents : amountRent)}
                    </div>
                </div>
            </div>
            {contract && (
                <div className="cursor-tip__body">
                    <div className="cursor-tip__body-item">
                        <div className="cursor-tip__body-item__name">
                            {contract.company.shortName}
                        </div>
                        <div className="cursor-tip__body-item__desc">Арендатор</div>
                    </div>
                    {owners && (
                        <div className="cursor-tip__body-item">
                            <div className="cursor-tip__body-item__name">{owners}</div>
                            <div className="cursor-tip__body-item__desc">Собственник(-и)</div>
                        </div>
                    )}
                    <div className="cursor-tip__body-item">
                        <div className="cursor-tip__body-item__name">{contract.name}</div>
                        <div className="cursor-tip__body-item__desc">Договор</div>
                    </div>
                    <div className="cursor-tip__body-item">
                        <div className="cursor-tip__body-item__name">
                            {new Date (contract.completionDateTime).toLocaleDateString ()}
                        </div>
                        <div className="cursor-tip__body-item__desc">Срок аренды</div>
                    </div>
                    <div className="cursor-tip__body-item">
                        <div className="cursor-tip__body-item__name">
                            {getQuantityString (Number (contract.cash))}
                        </div>
                        <div className="cursor-tip__body-item__desc">Баланс арендатора</div>
                    </div>
                    <div className="cursor-tip__body-item">
                        <div className="cursor-tip__body-item__name">
                            {contract.company.kindActivity}
                        </div>
                        <div className="cursor-tip__body-item__desc">Вид деятельности</div>
                    </div>
                </div>
            )}
        </>
    )
}

const findCenter = (xPoints, yPoints) => {
    const maxX = Math.max (...xPoints)
    const maxY = Math.max (...yPoints)
    const minX = Math.min (...xPoints)
    const minY = Math.min (...yPoints)

    return {
        x: minX + (maxX - minX) / 2,
        y: minY + (maxY - minY) / 2
    }
}

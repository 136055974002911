import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { LinearProgress } from '@material-ui/core'

import SmartTable from '../../../commonSections/SmartTable'
import EditBlockDropdown from '../../../others/EditBlockDropdown'

const RetnersTable = ({ rentersData, filters, visibleColumns, permissions, deleteRow }) => {

    const data = useMemo(() => {
        const rows = rentersData.data ? rentersData.data.map((row, index) => {
            return {
                '#': (Number(rentersData.page) - 1) * Number(filters.elementsCount) + index + 1,
                name: <Link to={`renters/${row.id}`}>{row.shortName}</Link>,
                kindActivity: row.kindActivity,
                inn: row.inn,
                manager: row.head,
                managerPhone: row.phoneHead,
                managerEmail: '',
                booker: row.chiefAccountant,
                bookerPhone: row.phoneChiefAccountant,
                bookerEmail: '',
                edit: <EditBlockDropdown
                        relative
                        permissions={permissions}
                        detailsLink={`/renters/${row.id}`}
                        editLink={`/renters/update/${row.id}`}
                        deleteLabel={`Вы действительно хотите удалить арендатора ${row.fullName}?`}
                        deleteFunc={() => deleteRow(row.id)}
                    />
            }
        }) : []

        const columns = Object.keys(visibleColumns).map(key => ({
            label: visibleColumns[key].label,
            field: key,
            width: visibleColumns[key].width
        }))
        return {
            rows,
            columns
        }
    }, [rentersData.data, deleteRow, filters.elementsCount, permissions, rentersData.page, visibleColumns])

    if (!rentersData.data) return <LinearProgress/>

    return <SmartTable data={data} visibleColumns={visibleColumns}/>

}

export default RetnersTable
import React, {Component}                    from 'react';
import {MDBCard, MDBCardBody, MDBCardHeader} from 'mdbreact';

class StatisticRoomCard extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.rooms !== nextProps.rooms;
    }

    render() {
        return (
            <MDBCard>
                <MDBCardHeader>
                    Арендаторы с дебиторской задолженностью
                </MDBCardHeader>
                <MDBCardBody>
                    Арендаторы
                </MDBCardBody>
            </MDBCard>
        )
    }
}

export default StatisticRoomCard;


import React from "react";

class CommonInformationProfileSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {

        return (
            <React.Fragment>
                <p className="text-center g-mt-30">
                    На данный момент нет информации для отображения
                </p>
            </React.Fragment>
        );
    }
}


export default CommonInformationProfileSection;

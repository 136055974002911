import React from 'react'
import moment from 'moment'
import withQueryParams from 'react-router-query-params'

import DatePicker from '../others/DatePicker'

const DateInput = withQueryParams()(({name, queryParams, setQueryParams}) => {
    const defaultDate = moment(queryParams[name] ? queryParams[name] : 'not time');
    return <DatePicker
        className="m-0 room-date-picker"
        value={defaultDate._isValid ? new Date(queryParams[name]) : null}
        placeholder="__.__._____"
        onChange={(date) => {
            if (date && date.toString() !== 'Invalid Date') setQueryParams({ [name]: moment(date).format('YYYY-MM-DD') })
        }}
    />
});

export default DateInput
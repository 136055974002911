import React, {Component} from 'react';
import {
    MDBRow,
    MDBCol
} from "mdbreact";
import LinearProgress from "@material-ui/core/LinearProgress";
import ProfileSection from "../sections/ProfileSection";
import ManipulationProfileSection from "../sections/ManipulationProfileSection";

class UserProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.selectedUser === null)
            return (<LinearProgress/>);

        return (
            <React.Fragment>
                <MDBRow>
                    <MDBCol size="3">
                        <ProfileSection
                            currentUser={this.props.currentUser}
                            token={this.props.token}
                            selectedUser={this.props.selectedUser}/>
                    </MDBCol>
                    <MDBCol size="9">
                        <ManipulationProfileSection
                            selectedUser={this.props.selectedUser}
                            token={this.props.token}
                            setCurrentUser={this.props.setCurrentUser}
                            currentUser={this.props.currentUser}/>
                    </MDBCol>
                </MDBRow>
            </React.Fragment>
        );
    }
}

export default UserProfilePage;

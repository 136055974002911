import React, { useState } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBModalFooter } from 'mdbreact'
import BasicInfoBlock from '../blocks/BasicInfoBlock'
import FinancialInformationBlock from '../blocks/FinancialInformationBlock'
import ImageInfo from '../blocks/ImageInfo'
import { useSetState } from '../../../others/customHooks'
import { withRouter } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withSnackbar } from 'notistack'
import { roomService } from '../../../../services/roomService'


const CreateRoomSection = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const { room } = props
    console.log("room ", room ? room.type2 : room)
    const [state, setState] = useSetState({
        name: (room && room.name) || '',
        roomState: (room && room.roomState) || 0,
        objectId: (room && room.objectId) || null,
        corpId: (room && room.corpId) || null,
        floor: (room && room.floorId) || null,
        managerId: (room && room.manager && room.manager.id) || 0,
        type1: (room && room.type1) || 0,
        type2: (room && room.type2) || 0,
        type3: (room && room.type3 )|| 0,
        blockName: (room && room.blockName) || 'Описание помещения',
        blockDescription: (room && room.blockDescription) || '',
        count: (room && room.square) || 0,
        unitsMeasure: (room && room.unitsMeasure ) || null,
        rents: (room && room.rents) || 0,
        amountRent: (room && room.amountRent) || 0,
        file: []
    })

    const handleChange = (name, value) => {
        setState({ [name]: value })
    }

    const formSubmit = async e => {
        if (state.name === '') {
            props.enqueueSnackbar('Заполните наименование', { variant: 'warning' })
            return
        }
        if (state.type2 === null) {
            props.enqueueSnackbar('Выберите тип объекта аренды', { variant: 'warning' })
            return
        }
        if (state.floor === null) {
            props.enqueueSnackbar('Выберите этаж', { variant: 'warning' })
            return
        }

        e.preventDefault()
        setIsLoading(true)

        let formData = new FormData()
        formData.append('Name', state.name)
        formData.append('Square', state.count)
        formData.append('Rents', state.rents)
        formData.append('AmountRent', Number(state.amountRent))
        formData.append('BlockName', state.blockName)
        formData.append('BlockDescription', state.blockDescription)
        formData.append('RoomState', state.roomState)
        formData.append('type1', state.type1)
        formData.append('type2', state.type2)
        formData.append('type3', state.type3)
        formData.append('unitsMeasure', state.unitsMeasure)
        formData.append('managerEmployeeId', state.managerId)
        Array.from(state.file).forEach(_ => formData.append('images', _))

        if (props.type === 'create') {
            await roomService.create(state.floor, formData).then(_ => {
                props.enqueueSnackbar('Помещение успешно создано', { variant: 'success' })
                setIsLoading(false)
                props.history.goBack()
            }, error => {
                props.enqueueSnackbar(error.message, { variant: 'error' })
                setIsLoading(false)
            })
        } else {
            roomService.update(props.id, formData).then(_ => {
                    props.enqueueSnackbar('Помещение успешно отредактировано', { variant: 'success' })
                    setIsLoading(false)
                    props.history.goBack()
                }, error => {
                    props.enqueueSnackbar(error.message, { variant: 'error' })
                    setIsLoading(false)
                }
            )

        }
    }



    return <MDBCard>
        <MDBCardBody>
            <form>
                <BasicInfoBlock {...{ state, handleChange, isLoading }}/>
                <FinancialInformationBlock {...{ state, handleChange, isLoading }}/>
                <ImageInfo value={state.file} onChange={files => setState({file: files})}/>
            </form>
        </MDBCardBody>
        <MDBModalFooter>
            {isLoading && <CircularProgress size={30}/>}
            <MDBBtn
                outline
                color="blue-grey"
                disabled={isLoading}
                onClick={() => props.history.goBack()}
            >
                Назад
            </MDBBtn>
            <MDBBtn
                disabled={isLoading}
                onClick={e => formSubmit(e)}
                outline
                color="success"
            >
                Сохранить
            </MDBBtn>
        </MDBModalFooter>
    </MDBCard>
}

export default withRouter(withSnackbar(CreateRoomSection))

import React, {Component} from 'react';
import './App.css';
import  './styles/index.css';
import {Switch, Route} from 'react-router-dom';
import RoutesWithNavigation from './components/RoutesWithNavigation';
import AuthPage from "./components/containers/auth/pages/AuthPage";
import RegisterPage from "./components/containers/auth/pages/RegisterPage";
import PasswordRecoveryPage from "./components/containers/auth/pages/PasswordRecoveryPage";
import {logout, setCurrentUser, setIsAdmin, setIsLoggedIn, setToken} from "./store/users/user/actions";
import {connect} from "react-redux";
import ResetPasswordPage from './components/containers/auth/pages/ResetPasswordPage'

class App extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props !== nextProps;
    }

    render() {
        return (
            <Switch>
                <Route path='/auth' exact component={AuthPage}/>
                <Route path='/register/:guid' exact component={RegisterPage}/>
                <Route path='/password-recovery' exact component={PasswordRecoveryPage}/>
                <Route path='/password-recovery/:token' component={ResetPasswordPage}/>
                <RoutesWithNavigation
                    currentUser={this.props.currentUser}
                    isAdmin={this.props.isAdmin}
                    token={this.props.token}
                    isLoggedIn={this.props.isLoggedIn}
                    setCurrentUser={this.props.setCurrentUser}
                    setIsAdmin={this.props.setIsAdmin}
                    setToken={this.props.setToken}
                    setIsLoggedIn={this.props.setIsLoggedIn}
                    logout={this.props.logout}
                />
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        isAdmin: state.user.isAdmin,
        token: state.user.token,
        isLoggedIn: state.user.isLoggedIn,
    };
};

const mapDispatchToProps = {
    setCurrentUser,
    setIsAdmin,
    setToken,
    setIsLoggedIn,
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

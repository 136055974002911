import React                 from 'react';
import EmployeesTableSection from "../sections/EmployeesTableSection";
import EmployeesListFilter   from "../sections/EmployeesListFilter";
import BreadcrumSection      from "../../../commonSections/BreadcrumSection";

class EmployeesPage extends React.Component {

    render() {
        const breadcrumsChain = [
            ['Главная', '/main'],
        ];

        return (
            <React.Fragment>
                <BreadcrumSection
                    createPredicate={false}
                    h2="Пользователи"
                    chain={breadcrumsChain}
                    buttonName="Пользователя"
                    href="employees/create"/>
                <EmployeesListFilter usersListFilter={this.props.usersListFilter}/>
                <EmployeesTableSection
                    allUsers={this.props.allUsers}
                />
            </React.Fragment>);
    }
}

export default EmployeesPage;

import React, {Component}                                                from 'react'
import {NavLink}                                                         from "react-router-dom";
import {MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBRow} from 'mdbreact';

class AdminPanelPage extends Component {
    render() {
        return (
            <React.Fragment>
                <MDBRow className="mb-4">
                    {this.props.permissions.find(_ => _.id === 20).value &&
                    <MDBCol xl="4" sm="12" className="mb-r">
                        <MDBCard>
                            <NavLink to="/admin-panel/roles-manage">
                                <MDBCardBody>
                                    <MDBCardTitle>Роли и права доступа</MDBCardTitle>
                                    <MDBCardText>
                                        В этом разделе можно настроить индивидуальные права доступа для каждого
                                        из
                                        сотрудников, либо объединить несколько сотрудников в одну группу для
                                        более
                                        лугкого управления доступом
                                    </MDBCardText>
                                </MDBCardBody>
                            </NavLink>
                        </MDBCard>
                    </MDBCol>}

                    {this.props.permissions.find(_ => _.id === 19).value &&
                    <MDBCol xl="4" sm="12" className="mb-r">
                        <MDBCard>
                            <NavLink to="/admin-panel/users-manage">
                                <MDBCardBody>
                                    <MDBCardTitle>Пользователи</MDBCardTitle>
                                    <MDBCardText>
                                        Тут вы можете просматривать всех пользователей системы, блокивать их и
                                        редактировать
                                    </MDBCardText>
                                </MDBCardBody>
                            </NavLink>
                        </MDBCard>
                    </MDBCol>}

                    {/*<MDBCol xl="4" sm="12" className="mb-r">*/}
                    {/*    <MDBCard>*/}
                    {/*        <NavLink to="/admin-panel/objects-manage">*/}
                    {/*            <MDBCardBody>*/}
                    {/*                <MDBCardTitle>Управление объектами</MDBCardTitle>*/}
                    {/*                <MDBCardText>*/}
                    {/*                    Управление объектами, корпусами и помещениями и их настройка*/}
                    {/*                </MDBCardText>*/}
                    {/*            </MDBCardBody>*/}
                    {/*        </NavLink>*/}
                    {/*    </MDBCard>*/}
                    {/*</MDBCol>*/}

                    {/*<MDBCol xl="4" sm="12" className="mb-r">*/}
                    {/*    <MDBCard>*/}
                    {/*        <NavLink to="/admin-panel/settings">*/}
                    {/*            <MDBCardBody>*/}
                    {/*                <MDBCardTitle>Настройки</MDBCardTitle>*/}
                    {/*                <MDBCardText>*/}
                    {/*                    Остальные параметры, не вошедшие в предыдущие пункты*/}
                    {/*                </MDBCardText>*/}
                    {/*            </MDBCardBody>*/}
                    {/*        </NavLink>*/}
                    {/*    </MDBCard>*/}
                    {/*</MDBCol>*/}
                </MDBRow>
                <MDBRow>
                    {this.props.permissions.find(_ => _.id === 20).value &&
                    <MDBCol className="mb-r">
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle className="text-primary">Справочники</MDBCardTitle>
                                <MDBCardText>
                                    <MDBRow>
                                        <MDBCol size="3">
                                            <div className="list-group">
                                                <button type="button"
                                                        className="list-group-item list-group-item-action active">Виды
                                                    коммунальных
                                                </button>
                                                <button type="button"
                                                        className="list-group-item list-group-item-action">Типы
                                                    помещений
                                                </button>
                                            </div>
                                        </MDBCol>
                                        <MDBCol size="9">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Наименование</th>
                                                    <th scope="col">Единицы измерения</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>Mark</td>
                                                    <td>Otto</td>
                                                </tr>
                                                <tr>
                                                    <td>Jacob</td>
                                                    <td>Thornton</td>
                                                </tr>
                                                <tr>
                                                    <td>@twitter</td>
                                                    <td>@twitter</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>}
                </MDBRow>
            </React.Fragment>

        );
    }
}

export default AdminPanelPage;

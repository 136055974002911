import React from 'react'
import { MDBCol, MDBInput, MDBRow } from 'mdbreact'
import Select from 'react-select'

export default ({state, handleChange, isLoading}) => {

    const onChangeCount = input => {
        const count = Number(input)
        handleChange("count", count)
        if(state.rents) handleChange("amountRent", (count * Number(state.rents)).toFixed(2))
    }

    const onChangeRents = input => {
        const rents = Number(input)
        handleChange("rents", rents)
        if(state.count) handleChange("amountRent", (rents * Number(state.count)).toFixed(2))
    }

    const unitsOptions = [ {label: 'м²', value: 0}, {label: 'шт.', value: 1}, {label: 'ед.', value: 2}]

    return <fieldset className="mb-4">
        <legend>Финансовая информация</legend>
        <MDBRow>
            <MDBCol className="mb-3" sm={12} md={6} lg={3} >
                <div className="grey-text">
                    <MDBInput
                        disabled={isLoading}
                        name="name"
                        label="Количество"
                        group
                        type="number"
                        validate
                        error="wrong"
                        success="right"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        getValue={onChangeCount}
                        valueDefault={state.count || ""}
                    />
                </div>
            </MDBCol>
            <MDBCol className="mb-3" sm={12} md={6} lg={3} >
                <div className="grey-text">
                    <MDBInput
                        disabled={isLoading}
                        name="name"
                        label="Арендная ставка"
                        group
                        type="number"
                        validate
                        error="wrong"
                        success="right"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        valueDefault={state.rents || ""}
                        getValue={onChangeRents}
                    />
                </div>
            </MDBCol>
            <MDBCol className="mb-3" sm={12} md={6} lg={3} >
                <Select
                    isDisabled={isLoading}
                    placeholder="Единицы измерения"
                    options={unitsOptions}
                    //TODO: Необходимо исправить, кода будет в БД
                    value={unitsOptions.find(op => Number(op.value) === 0)}
                />
            </MDBCol>
            <MDBCol className="mb-3" sm={12} md={6} lg={3} >
                <div className="grey-text">
                    <MDBInput
                        disabled={isLoading}
                        name="name"
                        label="Сумма аренды"
                        group
                        type="number"
                        validate
                        error="wrong"
                        success="right"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        value={state.amountRent || ""}
                        getValue={e => handleChange("amountRent", e)}
                    />
                </div>
            </MDBCol>
        </MDBRow>
    </fieldset>
}
import React, { Component } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import {
    MDBBadge,
    MDBCard,
    MDBCardBody,
    MDBDataTable
} from 'mdbreact'
import { withRouter } from 'react-router-dom'

import { accrualType } from '../../../../utils/AccrualType'
import { accrualService } from '../../../../services/accrualService'
import { accrualUnit } from '../../../../utils/AccrualUnit'
import { accrualPeriod } from '../../../../utils/AccrualPeriod'
import EditBlock from '../../../others/EditBlock'
import { getDateFormat, getQuantityString } from '../../../../utils/Others'

class AccrualsTableSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accruals: null,
            linkToRedirect: '',
            updatedCompanyId: null
        }

        accrualService.getAll(this.props.token).then(
            _ => {
                this.setState({ accruals: _ })
                return true
            },
            error => {
            }
        )
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.allCompanies !== nextProps.allCompanies || this.state !== nextState
    }

    removeAccrual = id => {
        this.setState({ accruals: this.state.accruals.filter(ac => ac.id !== id) })
    }

    render() {
        let accruals = this.state.accruals
        let tableItems = []
        if (accruals) {
            //TODO: в рендере нельзя делать такие вычисления, переделать с использованием memo https://ru.reactjs.org/docs/react-api.html#reactmemo
            let count = 0
            tableItems = accruals.map(accrual => {
                count++
                const URLPath = 'accruals/' + accrual.id

                // Строки таблицы заполняются здесь
                return {
                    number: count,
                    name: (
                        <div>
                            <NavLink to={URLPath}>
                                <span>{accrual.name}</span>
                            </NavLink>
                            <br/>
                            <span className="type-badge red">
                                {accrualType[accrual.accrualType]}
                            </span>
                            <br/>
                            <span className="small-text_table">{accrual.description}</span>
                        </div>
                    ),
                    amount: (
                        <div>
                            {getQuantityString(accrual.amount)}
                            <br/>
                            <MDBBadge color={'primary'}>{accrual.paymentPriority}</MDBBadge>
                        </div>
                    ),
                    company: (
                        <div>
                            <NavLink to={'/renters/' + accrual.company.id}>
                                {accrual.company.fullName}
                            </NavLink>
                            <br/>
                            <span className="small-text_table">{accrual.company.kindActivity}</span>
                        </div>
                    ),
                    count: (
                        <div>
                            {accrual.count}{' '}
                            <span className="small-text_table">
                                {accrualUnit[accrual.unitsMeasure]}
                            </span>
                        </div>
                    ),
                    dateOfDelay: getDateFormat(accrual.dateOfDelay), //moment(new Date(accrual.dateOfDelay).toISOString().format('YYYY-MM-DD')),
                    employee: `${accrual.employee.user.surname} ${accrual.employee.user.name}`,
                    periodType: accrualPeriod[accrual.periodType],
                    edit: (
                        <EditBlock
                            permissions={this.props.permissions}
                            deleteLabel={`Вы действительно хотите удалить начисление ${accrual.name}?`}
                            editLink={`/accruals/update/${accrual.id}`}
                            relative
                            deleteFunc={() => {
                                accrualService.remove(accrual.id).then(
                                    _ => {
                                        this.props.enqueueSnackbar(
                                            'Начисление успешно удалено',
                                            {
                                                variant: 'success'
                                            }
                                        )
                                        this.removeAccrual(accrual.id)
                                    },
                                    error => {
                                        this.props.enqueueSnackbar(error, {
                                            variant: 'error'
                                        })
                                    }
                                )
                            }}
                        />
                    )
                }
            })
        }

        const data = {
            columns: [
                {
                    label: '№',
                    field: 'number',
                    sort: 'asc'
                },
                {
                    label: 'Название',
                    field: 'name',
                    sort: 'asc'
                },
                {
                    label: 'Сумма',
                    field: 'amount',
                    sort: 'asc'
                },
                {
                    label: 'Арендатор',
                    field: 'company',
                    sort: 'asc'
                },
                {
                    label: 'Кол-во',
                    field: 'count',
                    sort: 'asc'
                },
                {
                    label: 'Дата',
                    field: 'dateOfDelay',
                    sort: 'asc'
                },
                {
                    label: 'Менеджер',
                    field: 'employee',
                    sort: 'asc'
                },
                {
                    label: 'Период',
                    field: 'periodType',
                    sort: 'asc'
                },
                {
                    label: 'Управление',
                    field: 'edit',
                    sort: 'asc',
                    width: 200
                }
            ],
            rows: tableItems
        }

        if (this.state.linkToRedirect !== '') {
            return <Redirect push to={this.state.linkToRedirect}/>
        }
        return (
            <MDBCard>
                <MDBCardBody id="accruals-table">
                    <React.Fragment>
                        <MDBDataTable
                            striped
                            bordered
                            small
                            infoLabel={['Показано от', 'до', 'из', 'записей']}
                            entriesLabel="Количество записей"
                            paginationLabel={['Предыдущая', 'Следующая']}
                            searchLabel="Поиск"
                            data={data}
                        />
                    </React.Fragment>
                </MDBCardBody>
            </MDBCard>
        )
    }
}

export default withRouter(withSnackbar(AccrualsTableSection))

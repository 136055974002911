import React, {Component} from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBRow
} from "mdbreact";
import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {authenticationService} from "../../../../services/authService";
import { withSnackbar } from 'notistack';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

class EmployeeCreateFormSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emails: [],
            emailInput: "",
            error: null
        };

        this.sendInvitations = this.sendInvitations.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.emails !== nextState.emails ||
            this.state.emailInput !== nextState.emailInput ||
            this.state.error !== nextState.error;

    }

    FullNameChange = (event) => {
        this.setState({emailInput: event.target.value});
    };

    onObjectCreateFormSubmit = (event) => {
        if (this.state.emailInput === "") {
                this.props.enqueueSnackbar("Поле не может быть пустым", {variant: 'warning'});
        } else {
            const {emails} = this.state;
            let tempArr = [...emails];
            tempArr.push(this.state.emailInput);

            this.setState({
                emails: tempArr,
                emailInput: "",
                error: null
            });
        }

        event.preventDefault();
    };

    handleDelete = chipToDelete => () => {
        let array = [...this.state.emails];
        let index = array.indexOf(chipToDelete);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({emails: array});
        }
    };

    sendInvitations() {
        authenticationService.sendInvitations(JSON.stringify({email: this.state.emails})).then(_ => {
            }, error => {
            this.props.enqueueSnackbar(error, {variant: 'success'});
        });
    }


    render() {
        const classes = useStyles;
        let count = 0;
        const chips = this.state.emails.map(_ => {
            count++;
            let icon;

            return (
                <Chip
                    key={count}
                    icon={icon}
                    label={_}
                    onDelete={this.handleDelete(_)}
                    className={classes.chip}
                />);
        });


        return (
            <div>

                <form onSubmit={this.onObjectCreateFormSubmit}>
                    <h5>Введите адреса электронной почты на которые необходимо выслать приглашение для присоединения к
                        порталу</h5>
                    <div>
                        <MDBRow>
                            <MDBCol>
                                <div className="grey-text">
                                    <MDBInput
                                        autoComplete="off"
                                        label="E-mail"
                                        icon="bookmark"
                                        group
                                        validate
                                        error="wrong"
                                        success="right"
                                        className={'form-control'}
                                        name="name"
                                        type="text"
                                        onChange={this.FullNameChange}
                                        value={this.state.emailInput}
                                    />
                                </div>
                                <MDBBtn
                                    outline color="primary"
                                    type="submit">
                                    Добавить
                                </MDBBtn>

                                <MDBBtn
                                    outline color="success"
                                    onClick={this.sendInvitations}>
                                    Отправить
                                </MDBBtn>

                                {this.state.error &&
                                <div className={'alert alert-danger'}>{this.state.error}</div>
                                }
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            {chips}
                        </MDBRow>
                    </div>
                </form>
            </div>)
    }
}

export default withSnackbar(EmployeeCreateFormSection);

import {roleService} from "../services/roleService";
import {permissionsTypes} from "./PermissionsTypes";

export const permissionsHelper = {
    scopePermissions,
};

async function scopePermissions() {
    let employee = JSON.parse(localStorage.getItem('currentUser'));
    if(employee === null) return permissionsTypes.scope();
    let userRoles = [];
    await roleService.getUserRoles(employee.id).then(_ => {
        userRoles = _;
    });

    let permissions = permissionsTypes.scope();
    await roleService.getAll().then(allRoles => {
        userRoles.forEach(_ => {
            let role = allRoles.find(opt => opt.name === _);
            role.rolePermissions.forEach(permission => {
                let perm = permissions.find(opt => opt.id === permission.permissionId);
                perm.value = permission.value ? permission.value : perm.value;
            });
        })
    });
    return permissions;
}


import React from 'react'
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow
}                      from 'mdbreact'
import { useSnackbar } from 'notistack'

import BreadcrumSection  from '../../commonSections/BreadcrumSection'
import { ownersService } from '../../../services/ownersService'
import CompanyForm       from '../../forms/company'
import {useHistory}      from "react-router";
import {Link}            from "react-router-dom";

const CreateOwner = ({ history }) => {

    const breadcrumsChain = [
        ['Главная', '/main'],
        ['Собственники', '/owners']
    ]

    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = async (values) => {
        try{
           await  ownersService.create(JSON.stringify({ ...defaultData, ...values }))
            enqueueSnackbar('Собсвтенник успешно создан', {
                variant: 'success'
            })
            history.goBack()
        } catch (err) {
            console.log({err});
            enqueueSnackbar('Ошибка при создании', {
                variant: 'error'
            })
        }
    }


    return <>
        <div className="row">
            <div className="col">
                <Breadcrums
                    createPredicate={true}
                    h2="Добавить собственника"
                    buttonName="Добавить собственника"
                    href="/owners/create"
                />
            </div>
        </div>
        <MDBCard>
            <MDBCardBody>
                <CompanyForm onSubmit={onSubmit}/>
            </MDBCardBody>
        </MDBCard>
    </>
}

export default CreateOwner

const breadcrumsChain = [
    ['Главная', '/'],
    ['Собственники', '/owners'],
]

const Breadcrums = props => {

    const breadCrumStyle = {
        fontSize: "0.8rem",
    };
    const history=useHistory ()

    const header = props.h2;
    let itemsChain = [];
    let i = 0;

    breadcrumsChain.forEach (function (item) {
        i++;
        itemsChain.push (<MDBBreadcrumbItem key={i}><Link to={item[1]}>{item[0]}</Link></MDBBreadcrumbItem>);
    });
    itemsChain.push (<MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>);
    itemsChain.unshift (<MDBBreadcrumbItem key={i+1}><span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span></MDBBreadcrumbItem>);

    return (<>
        {itemsChain.length > 0 && (<MDBBreadcrumb style={breadCrumStyle}>{itemsChain}</MDBBreadcrumb>)}
        <MDBCard className="mb-30">
            <MDBCardBody id="breadcrumb" className="pa-24">
                <MDBRow between>
                    <MDBCol className="d-flex align-items-center">
                        <MDBCardTitle tag="h5" className="g-mb-0" style={{margin: 0}}>
                            {header}
                        </MDBCardTitle>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>);
}


const defaultData = {
    fullName: '',
    shortName: '',
    legalAddress: '',
    actualAddress: '',
    mailingAddress: '',
    phone: '',
    fax: '',
    email: '',
    site: '',
    head: '',
    phoneHead: '',
    chiefAccountant: '',
    phoneChiefAccountant: '',
    createDate: new Date().toISOString().substr(0, 10),
    certificateEgpul: '',
    certificateDate: new Date().toISOString().substr(0, 10),
    certificateNumber: '',
    certificateIssued: '',
    certificateOgrn: '',
    inn: '',
    kpp: '',
    okpo: '',
    kindActivity: '',
    checkingAccount: '',
    correspondentAccount: '',
    bank: '',
    bik: '',
    okogu: '',
    okato: '',
    okved: '',
    fullNameContract: ''
}
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import DashboardPage
    from '../components/containers/dashboard/pages/DashboardPage'
import NotFoundPage from '../components/errors/NotFoundPage'
import ProfileContainer
                                                                                        from '../components/containers/profile/ProfileContainer'
import {
    AdminPanel,
    Appeals,
    Contracts,
    Employees,
    Login,
    Object,
    Renters,
    Rooms
}           from './RoutesAdditional'
import ReportsContainer
            from '../components/containers/reports/ReportsContainer'
import Help from "../components/containers/help";

class MainRoutes extends React.Component {

    render() {
        let body
        if (!this.props.isLoggedIn)
            body = Login
        else
            body = <Switch>
                <Route path='/main' exact component={DashboardPage}/>
                <Route path='/reports' exact component={ReportsContainer}/>
                <Route path='/objects' component={Object}/>
                <Route path='/rooms' component={Rooms}/>
                <Route path='/groups' component={()=><h1>Привет</h1>}/>
                <Route path="/renters" component={Renters}/>
                <Route path='/employees' component={Employees}/>
                <Route path='/contracts' component={Contracts}/>
                <Route path='/appeals' component={Appeals}/>
                {/*<Route path='/events' component={Events}/>*/}
                <Route path='/profile' exact component={ProfileContainer}/>
                <Route path='/admin-panel' component={AdminPanel}/>
                <Route path='/help' component={Help}/>
                <Route path='/404' exact component={NotFoundPage}/>
            </Switch>

        return (
            body
        )
    }
}

export default MainRoutes

import {
    USERS_FILTER_LIST_IS_ON_BOOL,
    USERS_FILTER_LIST_IS_OFF_BOOL, USERS_SET_ALL, EMPLOYEES_SET_ALL
} from './actions';

const defaultState = {
    usersListFilter: false,
    allUsers: null,
    allEmployees: null
};

export const usersReducer = (state = defaultState, action) => {
    switch (action.type) {

        case USERS_FILTER_LIST_IS_ON_BOOL:
            return {...state, usersListFilter: action.payload};

        case USERS_FILTER_LIST_IS_OFF_BOOL:
            return {...state, usersListFilter: action.payload};

        case USERS_SET_ALL: {
            return {...state, allUsers: action.payload};
        }

        case EMPLOYEES_SET_ALL: {
            return {...state, allEmployees: action.payload};
        }


        default:
            return state;
    }
};

import React from 'react';
import {connect} from "react-redux";
import {setAllUsers} from "../../../store/users/actions";
import {setCurrentUser} from "../../../store/users/user/actions";
import EventsPage from "./page/EventsPage";

class EventsContainer extends React.Component {

       render() {
        return (
            <EventsPage/>

        );
    }

}

const mapStateToProps = state => {
    return {
        allUsers: state.users.allUsers,
        usersListFilter: state.users.usersListFilter,
        token: state.user.currentUser.token,
        currentUser: state.user.currentUser
    };
};

const mapDispatchToProps = {
    setAllUsers,
    setCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsContainer);

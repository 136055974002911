import React, {Component}                    from 'react';
import {MDBCard, MDBCardBody, MDBCardHeader} from 'mdbreact';
import {Link}                                from "react-router-dom";

class StatisticUserCard extends Component {
    constructor(props) {
        super(props);
        let users = this.props.newUsers;
        let usersRows = users ? users.map(user => {
            let date = new Date(user.registrationDate);
            return (<tr key={user.id}>
                    <td style={{width: 250}} className="dashboardUserstableTd">
                        <Link to={`/employees/${user.id}`}><span>{user.surname + " " + user.name}</span></Link>
                    </td>
                    <td>Дата регистрации {date.toLocaleDateString()}</td>
                </tr>
            );
        }) : null;
        this.state = {
            users    : users,
            usersRows: usersRows
        };

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    render() {
        return (
            <MDBCard className="h-100 overflow-y">
                <MDBCardHeader className="dashboardCardHeader">
                    Новые сотрудники
                </MDBCardHeader>
                <MDBCardBody>
                    <table className="table">
                        <tbody>
                        {this.state.usersRows ? this.state.usersRows : null}
                        </tbody>
                    </table>
                </MDBCardBody>
            </MDBCard>
        )
    }
}

export default StatisticUserCard;


import React, {Component} from 'react';
import {connect}          from "react-redux";
import CreateContractPage from "./pages/CreateContractPage";

class CreateContractContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <CreateContractPage/>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateContractContainer);

import {authenticationService} from '../services/authService';

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                authenticationService.logout();
                window.location.reload();
            }
            const error = (data && data.Value) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

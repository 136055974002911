import React from 'react'
import Layout from "../Layout"
import ResetPasswordSection from '../sections/ResetPasswordSection'


const ResetPasswordPage = ({location}) =>
    <Layout title="Обновлении пароля">
        <ResetPasswordSection token={location.pathname.slice('/password-recovery/'.length)}/>
    </Layout>

export default ResetPasswordPage;
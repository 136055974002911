import React, {Component} from "react";
import {
  MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBInput, MDBRow
} from 'mdbreact';

class EmployeesListFilter extends Component{

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.usersListFilter!==nextProps.usersListFilter;
  }

  render () {
    const usersListFilter = this.props.usersListFilter;

    return (usersListFilter && <MDBCard className="h-100 mb-5">
        <MDBCardHeader color="primary-color" tag="h4">
          Фильтр поиска сотрудников
        </MDBCardHeader>
        <MDBCardBody>
          <form>
            <div className="grey-text">
              <MDBInput
                label="e-mail"
                icon="envelope"
                group
                type="email"
                validate
                error="wrong"
                success="right"
              />
              <MDBInput
                label="пароль"
                icon="lock"
                group
                type="password"
                validate
              />
            </div>
            <div className="text-center">
              <MDBRow>
                <MDBCol>
                  <MDBBtn color="success" size="lg">НАЙТИ</MDBBtn>
                </MDBCol>
              </MDBRow>
            </div>
          </form>
        </MDBCardBody>
      </MDBCard>)
  }
}

export default EmployeesListFilter;

import React                                                  from "react";
import {MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {withSnackbar}                                         from "notistack";
import {LinearProgress}                                       from "@material-ui/core";

class EmployeesCompanySection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let users = this.props.allUsers;
        if (users) {
            let i = 0;
            users = users.map(function (user) {
                i++;
                const date = new Date(user.user.birthDate);
                let options = {
                    year: 'numeric', month: 'long', day: 'numeric',
                };
                // const userID = user.id;
                // const URLPath = "/employees/" + userID;
                return (
                    <tr key={user.id}>
                        <td>{i}</td>
                        {/*<td><Link to={URLPath}>{user.user.surname + ' ' + user.user.name + ' ' + user.user.patronymic}</Link></td>*/}
                        <td>{user.user.surname + ' ' + user.user.name + ' ' + user.user.patronymic}</td>
                        <td>{date.toLocaleString("ru", options)}</td>
                        <td>{user.user.userName}</td>
                        <td>{user.user.mobilePhone}</td>
                        <td>{user.user.phone}</td>
                    </tr>
                );
            });
        } else return (<LinearProgress/>);
        if(users.length === 0) return <div className="d-flex justify-content-center">Список пуст </div>
        return (
            <React.Fragment>
                <div>

                </div>
                <MDBRow>
                    <MDBCol>

                        <MDBTable hover>
                            <MDBTableHead>
                                <tr>
                                    <th>№</th>
                                    <th>ФИО</th>
                                    <th>Дата рождения</th>
                                    <th>Email</th>
                                    <th>Телефон</th>
                                    <th>Мобильный</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>{users}</MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>
            </React.Fragment>
        );
    }
}


export default withSnackbar(EmployeesCompanySection);

import React          from 'react'
import { withRouter } from 'react-router'
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBRow
}                     from 'mdbreact'
import {Contents}     from "../contents";

const Maintenance = () => {

    return (<>

    </>)
}

export default withRouter (Maintenance)

import {
    USER_CHANGE_CURRENT_USER_OBJECT,
    USER_CHANGE_IS_ADMIN_BOOL,
    USER_CHANGE_IS_LOGGED_IN_BOOL,
    USER_CHANGE_TOKEN_TEXT,
    USER_LOGOUT_BOOL,
} from './actions';

const defaultState = {
    currentUser: null,
    token: null,
    isAdmin: false,
    isLoggedIn: false,
};

export const userReducer = (state = defaultState, action) => {
    switch (action.type) {

        case USER_CHANGE_CURRENT_USER_OBJECT:
            localStorage.setItem('currentUser', JSON.stringify(action.payload));
            return {...state, currentUser: action.payload};

        case USER_CHANGE_IS_ADMIN_BOOL:
            return {...state, isAdmin: action.payload};

        case USER_CHANGE_TOKEN_TEXT:
            localStorage.setItem('token', action.payload);
            return {...state, token: action.payload};

        case USER_CHANGE_IS_LOGGED_IN_BOOL:
            return {...state, isLoggedIn: action.payload};

        case USER_LOGOUT_BOOL: {
            state = defaultState;
            return {...state};
        }
        default:
            return state;
    }
}

import React, {Component}                                                                     from 'react'
import {MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBInput, MDBModalFooter, MDBRow} from "mdbreact";
import {withSnackbar}                                                                         from "notistack";
import CircularProgress
                                                                                              from "@material-ui/core/CircularProgress";
import {renterService}                                                                        from "../../../../services/renterService";
import {Redirect}                                                                             from "react-router";

class CreateRenterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login      : "",
            password   : "",
            name       : "",
            surname    : "",
            patronymic : "",
            position   : "",
            birthDate  : new Date().toISOString().substr(0, 10),
            phone      : "",
            mobilePhone: "",
            isLoading  : false,
            isRedirect  : false
        };

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    onStateFieldChange(name, e) {
        this.setState({[name]: e.target.value});
    }

    redirectTo() {
        this.props.setCreateMode (false);
    }


    async createUser(e) {
        e.preventDefault();
        if (this.state.password === "" || this.state.login === "" || this.state.name === "" || this.state.surname === "" || this.state.patronymic === "") {
            this.props.enqueueSnackbar("Заполните поля отмеченные *", {variant: 'warning'});
            return;
        }

        let message = {
            password: this.state.password,
            companyId: this.props.companyId,
            position: this.state.position,
            user    : {
                userName   : this.state.login,
                name       : this.state.name,
                surname    : this.state.surname,
                patronymic : this.state.patronymic,
                phone      : this.state.phone,
                mobilePhone: this.state.mobilePhone,
                birthDate  : this.state.birthDate,
            }
        };

        await renterService.create(JSON.stringify(message)).then(_ => {
            this.props.enqueueSnackbar("Пользователь успешно создан", {variant: 'success'});
            renterService.getByOrganisationId(this.props.companyId).then(users => {
                this.props.setUsers(users);
                this.props.setCreateMode(false);
            })

        }, error => {
            this.props.enqueueSnackbar(error, {variant: 'error'});
        });
    }


    render() {

        if (this.state.isRedirect) {
            return (<Redirect to={"/renters/" + this.props.companyId}/>);
        }

        return (

            <MDBCard>
                <MDBRow>
                    <MDBCol>
                        <MDBCardBody>
                            <MDBCardTitle
                                className="view view-cascade d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                Создание контактного лица арендатора

                            </MDBCardTitle>

                            <form>
                                <div className="form-row">
                                    <div className="col">
                                        <MDBInput
                                            label="Имя*"
                                            group
                                            validate
                                            error="wrong"
                                            success="right"
                                            name="name"
                                            type="text"
                                            className={'form-control'}
                                            onChange={e => this.onStateFieldChange("name", e)}
                                            value={this.state.name}
                                        />
                                    </div>
                                    <div className="col">
                                        <MDBInput
                                            label="Фамилия*"
                                            group
                                            validate
                                            error="wrong"
                                            success="right"
                                            name="surname"
                                            type="text"
                                            className={'form-control'}
                                            onChange={e => this.onStateFieldChange("surname", e)}
                                            value={this.state.surname}
                                        />
                                    </div>
                                    <div className="col">
                                        <MDBInput
                                            label="Отчество*"
                                            group
                                            validate
                                            error="wrong"
                                            success="right"
                                            name="patronymic"
                                            type="text"
                                            className={'form-control'}
                                            onChange={e => this.onStateFieldChange("patronymic", e)}
                                            value={this.state.patronymic}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col">
                                        <MDBInput
                                            label="E-mail*"
                                            group
                                            validate
                                            error="wrong"
                                            success="right"
                                            className={'form-control'}
                                            type="text"
                                            onChange={e => this.onStateFieldChange("login", e)}
                                            value={this.state.login}
                                        />
                                    </div>
                                    <div className="col">
                                        <MDBInput
                                            label="Пароль*"
                                            group
                                            validate
                                            error="wrong"
                                            autoComplete="new-password"
                                            success="right"
                                            name="password"
                                            type="password"
                                            className={'form-control'}
                                            onChange={e => this.onStateFieldChange("password", e)}
                                            value={this.state.password}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col">
                                        <MDBInput
                                            label="Дата Рождения"
                                            valueDefault={this.state.birthDate}
                                            group
                                            validate
                                            error="wrong"
                                            success="right"
                                            name="birthDate"
                                            type="date"
                                            className={'form-control'}
                                            onChange={e => this.onStateFieldChange("birthDate", e)}
                                            value={this.state.birthDate}
                                        />
                                    </div>
                                    <div className="col">
                                        <MDBInput
                                            label="Должность"
                                            group
                                            validate
                                            error="wrong"
                                            success="right"
                                            name="Position"
                                            type="text"
                                            className={'form-control'}
                                            onChange={e => this.onStateFieldChange("position", e)}
                                            value={this.state.position}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col">
                                        <MDBInput
                                            label="Телефон"
                                            group
                                            validate
                                            error="wrong"
                                            success="right"
                                            name="phone"
                                            type="text"
                                            className={'form-control'}
                                            onChange={e => this.onStateFieldChange("phone", e)}
                                            value={this.state.phone}
                                        />
                                    </div>
                                    <div className="col">
                                        <MDBInput
                                            label="Мобильный"
                                            group
                                            validate
                                            error="wrong"
                                            success="right"
                                            name="mobilePhone"
                                            type="text"
                                            className={'form-control'}
                                            onChange={e => this.onStateFieldChange("mobilePhone", e)}
                                            value={this.state.mobilePhone}
                                        />
                                    </div>
                                </div>
                                <MDBModalFooter>
                                    {this.state.isLoading && <CircularProgress size={30}/>}
                                    <MDBBtn
                                        outline
                                        color="blue-grey"
                                        disabled={this.state.isLoading}
                                        onClick={() => this.redirectTo('/renters' + this.props.companyId)}>
                                        Назад
                                    </MDBBtn>
                                    <MDBBtn
                                        disabled={this.state.isLoading}
                                        outline
                                        type="submit"
                                        onClick={e => this.createUser(e)}
                                        color="success">
                                        Сохранить
                                    </MDBBtn>
                                </MDBModalFooter>
                            </form>


                        </MDBCardBody>
                    </MDBCol>
                </MDBRow>
            </MDBCard>
        );
    }
}


export default withSnackbar(CreateRenterPage);

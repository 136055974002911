import {
  SET_ACTIVE_FLOOR,
  SET_ALL_FLOORS_OF_BUILDING
} from "./actions";
import {SET_FlOOR_NUMBER}           from "./actions";
import {SET_FlOOR_NAME}             from "./actions";


const defaultState = {
  allFloorsOfBuilding: [],
  FloorName: null,
  FloorNumber: null,
  activeFloor: null
};

export const floorsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALL_FLOORS_OF_BUILDING: {
      return {
        ...state,
        allFloorsOfBuilding: action.payload
      };
    }

    case SET_FlOOR_NAME: {
      return {
        ...state,
        FloorName: action.payload
      };
    }

    case SET_FlOOR_NUMBER: {
      return {
        ...state,
        FloorNumber: action.payload
      };
    }

    case SET_ACTIVE_FLOOR: {
      return {
        ...state,
        activeFloor: action.payload
      };
    }

    default:
      return state;
  }
};
import React from 'react'
import UpdateContractPage from './pages/UpdateContractPage'

const UpdateContractContainer = ({ match }) => {
    const { contractId } = match.params;
    return (
        <UpdateContractPage id={contractId}/>
    )

}


export default UpdateContractContainer

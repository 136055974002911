import React, { useCallback, useState } from 'react'
import { MDBBtn, MDBIcon } from 'mdbreact'

import MyModal from '../../commonSections/modal'
import FileInput from '../../others/FileInput'

const FilesSystem = ({ documents, addDocuments, deleteDocument }) => {
    const [files, setFiles] = useState([])

    const onAddDocuments = useCallback(async () => {
        await addDocuments(files)
        setFiles([])
    }, [addDocuments, files])

    return <>
        <MyModal
            title="Управление файлами"
            component={(onClick) => (<MDBBtn onClick={onClick}>Редакировать</MDBBtn>)}
        >
            {documents.map(document => <FileItem
                key={document.id}
                document={document}
                onDelete={async () => await deleteDocument(document.id)}
            />)}
            {documents.length ? <hr/> : null}
            <FileInput
                value={files}
                onChange={setFiles}
            />
            <div className="d-flex justify-end">
                {files.length > 0 && <MDBBtn onClick={onAddDocuments} size='sm'>Добавить файлы</MDBBtn>}
            </div>
        </MyModal>
    </>
}

export default FilesSystem

const FileItem = ({ document, onDelete }) => {
    return (
        <div className="d-flex align-center mt-2">
            <div className="flex-1">
                {document.fileName}
            </div>
            <MyModal
                title="Удалить файл"
                cancelText="Отменить"
                component={(onClick) => (
                    <button onClick={onClick} className="btn-no-styles">
                        <MDBIcon floating icon="times"/>
                    </button>
                )}
                okBtn={(closeModal) => (
                    <MDBBtn
                        color="danger"
                        onClick={async () => {
                            await onDelete()
                            closeModal()
                        }}
                    >
                        Удалить
                    </MDBBtn>
                )}
            >
                Подтвердите, что хотите удалить файл {document.fileName}.
            </MyModal>
        </div>
    )
}

import React from 'react'

import FileInput from '../../../items/file-input'

export default ({ value, onChange }) => {
    return <fieldset>
        <legend>Выбрать изображение</legend>
        {/*<MDBFileupload defaultFileSrc='https://mdbootstrap.com/img/Photos/Others/images/89.jpg' />*/}
        <FileInput value={value} onChange={onChange}
                   label="Перетащите сюда нужную фотографию, либо нажмите на это окошко"/>

    </fieldset>
}
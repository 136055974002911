import React, {Component}                                  from 'react';
import {MDBCard, MDBCardBody, MDBCardText, MDBCol, MDBRow} from 'mdbreact';
import {numberWithSpaces}                                  from "../../../../helpers/NumberWithSpaces";


class AdminCardSection1 extends Component {

    findArrayElementById(array, id) {
        return array.find((element) => {
            return element.id === id;
        })
    }

    render() {
        const data = this.props.data.data;
        let colors = this.props.colors;

        //TODO: в рендере нельзя делать такие вычисления, переделать с использованием memo https://ru.reactjs.org/docs/react-api.html#reactmemo
        const filling = data.reportByObjects.map(object => {
            let color = this.findArrayElementById(colors, object.id).color;
            return (
                <MDBCardBody key={object.id}>
                    <div className="progress">
                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar"
                             role="progressbar"
                             style={{width: object.ratioTotalToBusySquarePercent + "%", backgroundColor: color}}/>
                    </div>
                    <MDBCardText>
                        {object.objectName} ({isFinite(object.ratioTotalToBusySquarePercent) ? object.ratioTotalToBusySquarePercent : 0}%)
                    </MDBCardText>
                </MDBCardBody>
            );
        });


        //TODO: в рендере нельзя делать такие вычисления, переделать с использованием memo https://ru.reactjs.org/docs/react-api.html#reactmemo
        const yields = data.reportByObjects.map(object => {
            let color = this.findArrayElementById(colors, object.id).color;
            return (
                <MDBCardBody key={object.id}>
                    <div className="progress">
                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25"
                             className="progress-bar" role="progressbar"
                             style={{width: object.ratioTotalToBusyAmountPercent + "%", backgroundColor: color}}/>
                    </div>
                    <MDBCardText>{object.objectName} ({isFinite(object.ratioTotalToBusyAmountPercent) ? object.ratioTotalToBusyAmountPercent : 0}%)</MDBCardText>
                </MDBCardBody>
            );
        });


        return (
            <MDBRow className="mb-4">
                <MDBCol xl="4" sm="12" className="mb-r">
                    <MDBCard className="cascading-admin-card">
                        <div className="admin-up">
                            <div className="data">
                                <p className="text-uppercase">Заполнение</p>
                                <h4>
                                    <strong>{numberWithSpaces(Math.round(data.filling))} м<sup>2</sup></strong>
                                </h4>
                            </div>
                        </div>
                        {filling}
                    </MDBCard>
                </MDBCol>
                <MDBCol xl="4" sm="12" className="mb-r">
                    <MDBCard className="cascading-admin-card">
                        <div className="admin-up">
                            <div className="data">
                                <p className="text-uppercase">Доходность</p>
                                <h4>
                                    <strong>{numberWithSpaces(Math.round(data.yield))} р.</strong>
                                </h4>
                            </div>
                        </div>
                        {yields}
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        )
    }

}

export default AdminCardSection1;


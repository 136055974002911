import queryString from 'query-string'

export const deleteQueryParam = (name, history) => {
    const { location } = history
    const params = { ...queryString.parse(location.search) }
    delete params[name]
    const to = history.createHref({
        pathname: location.pathname,
        search: queryString.stringify(params)
    })
    history.push(to)
}

export const deleteAllParams = (history) => {
    const { location } = history
    const to = history.createHref({
        pathname: location.pathname
    })
    history.push(to)
}
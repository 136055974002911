import {
  OBJECT_CHANGE_BUILDING_TEXT,
  OBJECT_CHANGE_CITY_TEXT,
  OBJECT_CHANGE_CONSTRUCTION_TEXT,
  OBJECT_CHANGE_NAME_TEXT,
  OBJECT_CHANGE_STREET_TEXT,
  OBJECT_CREATE_FORM_SUBMIT,
  OBJECT_SET_IS_LOADING_BOOL,
  OBJECT_SET_CURRENT_OBJECT,
  OBJECTS_SET_MAIN_REPORT_DATA,
  OBJECT_SET_ALL_OBJECTS,

} from './actions';

const defaultState = {
  allObjects            : null,
  name                  : '',
  city                  : '',
  street                : '',
  building              : '',
  construction          : '',
  object                : '',
  addCorpFromStatus     : false,
  isSendDataToCreateCorp: false,
  isCreated             : false,
  currentObject         : '',
  mainReportData         : {}
};

export const objectsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OBJECT_SET_ALL_OBJECTS: {
      return {
        ...state,
        allObjects: action.payload
      };
    }
    case OBJECT_CHANGE_NAME_TEXT:
      return {
        ...state,
        name: action.payload
      };
    case OBJECT_CHANGE_CITY_TEXT:
      return {
        ...state,
        city: action.payload
      };
    case OBJECT_CHANGE_STREET_TEXT:
      return {
        ...state,
        street: action.payload
      };
    case OBJECT_CHANGE_BUILDING_TEXT:
      return {
        ...state,
        building: action.payload
      };
    case OBJECT_CHANGE_CONSTRUCTION_TEXT:
      return {
        ...state,
        construction: action.payload
      };
    case OBJECT_CREATE_FORM_SUBMIT:
      return {
        ...state,
        object: action.payload
      };
    case OBJECT_SET_IS_LOADING_BOOL:
      return {
        ...state,
        isLoading: action.payload
      };

    case OBJECT_SET_CURRENT_OBJECT:
    return {
      ...state,
      currentObject: action.payload
    };
    case OBJECTS_SET_MAIN_REPORT_DATA:
    return {
      ...state,
      mainReportData: action.payload
    };



    default:
      return state;
  }
};

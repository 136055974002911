import React from "react";
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    MDBModalFooter
} from "mdbreact";
import moment from "moment";
import {userService} from "../../../../services/userService";
import {withSnackbar} from "notistack";
import {employeeService} from "../../../../services/employeeService";
import CircularProgress from "@material-ui/core/CircularProgress";

class UpdateProfileSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: props.currentUser.user.userName,
            position: props.currentUser.position,
            name: props.currentUser.user.name,
            surname: props.currentUser.user.surname,
            patronymic: props.currentUser.user.patronymic,
            birthDate: moment(new Date(props.currentUser.user.birthDate).toISOString()).format('YYYY-MM-DD'),
            phone: props.currentUser.user.phone,
            mobilePhone: props.currentUser.user.mobilePhone,
            isUpdateLoading: false,
            file: null,
            inputText: "Выберите файл",
            profileIsChanged: false
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.currentUser !== nextProps.currentUser ||
            this.state.isUpdateLoading !== nextState.isUpdateLoading ||
            this.state.login !== nextState.login ||
            this.state.name !== nextState.name ||
            this.state.surname !== nextState.surname ||
            this.state.patronymic !== nextState.patronymic ||
            this.state.birthDate !== nextState.birthDate ||
            this.state.phone !== nextState.phone ||
            this.state.position !== nextState.position ||
            this.state.mobilePhone !== nextState.mobilePhone ||
            this.state.profileIsChanged !== nextState.profileIsChanged ||
            this.state.inputText !== nextState.inputText;
    }

    saveEditedProfile = async (event) => {
        if (this.state.file) {
            await userService.uploadProfilePhoto(this.props.currentUser.user.id, this.state.file).then(_ => {
                this.props.enqueueSnackbar('Фотография загружена', {variant: 'success'});
            }, error => {
                this.props.enqueueSnackbar(error.message, {variant: 'error'});
            });
        }
        if (this.state.profileIsChanged) {
            await employeeService.update(this.props.currentUser.id, JSON.stringify({
                position: this.state.position,
                user: {
                    name: this.state.name,
                    surname: this.state.surname,
                    patronymic: this.state.patronymic,
                    birthDate: this.state.birthDate,
                    phone: this.state.phone,
                    mobilePhone: this.state.mobilePhone,
                    userName: this.state.login,
                }

            })).then(updated => {
                this.props.enqueueSnackbar('Данные обновлены', {variant: 'success'});
            }, error => {
                this.props.enqueueSnackbar(error, {variant: 'error'});
            });
        }
        await employeeService.getById(this.props.currentUser.id).then(_ => {
            this.props.setCurrentUser(_);
        });
        this.setState({
            isUpdateLoading: false,
            profileIsChanged: false,
            file: null,
            inputText: "Выберите файл"
        });
    };

    onFieldChange(event, field) {
        this.setState({
            [field]: event.target.value,
            profileIsChanged: true
        });
    }

    setFile(e) {
        this.setState({
            file: e.target.files[0],
            inputText: e.target.files[0].name
        });
    }

    render() {

        return (
            <React.Fragment>
                <MDBRow>
                    <MDBCol>
                        <MDBInput
                            label="Имя"
                            name="name"
                            type="text"
                            onChange={e => this.onFieldChange(e, "name")}
                            value={this.state.name}
                        />
                        <MDBInput
                            label="Фамилия"
                            name="surname"
                            type="text"
                            onChange={e => this.onFieldChange(e, "surname")}
                            value={this.state.surname}
                        />
                        <MDBInput
                            label="Отчество"
                            name="patronymic"
                            type="text"
                            onChange={e => this.onFieldChange(e, "patronymic")}
                            value={this.state.patronymic}
                        />

                        <MDBInput
                            label="E-mail"
                            type="text"
                            disabled={true}
                            onChange={e => this.onFieldChange(e, "login")}
                            value={this.state.login}
                        />
                    </MDBCol>
                    <MDBCol>
                        <MDBInput
                            label="Должность"
                            valueDefault={this.state.position}
                            name="position"
                            onChange={e => this.onFieldChange(e, "position")}
                            value={this.state.position}
                        />
                        <MDBInput
                            label="Дата Рождения"
                            valueDefault={this.state.birthDate}
                            name="birthDate"
                            type="date"
                            onChange={e => this.onFieldChange(e, "birthDate")}
                            value={this.state.birthDate}
                        />
                        <MDBInput
                            label="Телефон"
                            name="phone"
                            type="text"
                            onChange={e => this.onFieldChange(e, "phone")}
                            value={this.state.phone}
                        />
                        <MDBInput
                            label="Мобильный"
                            name="mobilePhone"
                            type="text"
                            onChange={e => this.onFieldChange(e, "mobilePhone")}
                            value={this.state.mobilePhone}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="6">
                        Загрузить фото профиля:
                        <div className="input-group custom-file">
                            <input
                                type="file"
                                onChange={e => this.setFile(e)}
                                className="custom-file-input"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01"
                            />
                            <label className="custom-file-label" htmlFor="inputGroupFile01">
                                {this.state.inputText}
                            </label>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBModalFooter>
                    {this.state.isUpdateLoading && <CircularProgress size={30}/>}
                    <MDBBtn onClick={e => {
                        this.setState({isUpdateLoading: true});
                        this.saveEditedProfile(e);
                    }}
                            disabled={this.state.isUpdateLoading}
                            outline
                            color="success">Сохранить</MDBBtn>
                </MDBModalFooter>
            </React.Fragment>
        );
    }
}


export default withSnackbar(UpdateProfileSection);

import React, { useEffect, useMemo, useState } from 'react'
import {LinearProgress} from "@material-ui/core";
import CreateRoomSection from '../sections/CreateRoomSection'
import {roomService} from "../../../../services/roomService";
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow
}                   from "mdbreact";
import {Link}       from "react-router-dom";
import {useHistory} from "react-router";

const CreateRoomPage = props => {
    const [room, setRoom] = useState();
    const id = props.match.params.roomId
    const isUpdateMode = useMemo(() => props.location.pathname.includes('update'), [props.location])

    useEffect(() => {
        if(id) roomService.getById(id).then(res => {
            setRoom(res)
        })
    }, [id])
    if(!id || room) return (
        <>
            <div className="row">
                <div className="col">
                    <BreadCrumbs header="Добавить объект аренды" />
                </div>
            </div>
            <CreateRoomSection
                type={isUpdateMode ? "update" : "create"}
                room={room}
                id={room && room.id}
            />
        </>
    )

    return <LinearProgress/>

}

export default CreateRoomPage

const BreadCrumbs = ({header}) => {
    const breadcrumbsChain = [
        ['Главная', '/main'],
        ['Объекты аренды', '/rooms'],
    ]
    const history = useHistory()
    let itemsChain         = []
    let i                  = 0
    breadcrumbsChain.forEach(function (item) {
        i++
        itemsChain.push(<MDBBreadcrumbItem key={i}><Link to={item[1]}>{item[0]}</Link></MDBBreadcrumbItem>)
    })
    itemsChain.push (<MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>);
    itemsChain.unshift(<MDBBreadcrumbItem key={321}><span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span></MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" className="pa-24">
                <MDBRow between>
                    <MDBCol className="d-flex align-items-center">
                        <MDBCardTitle tag="h5" className="mb-0">{header}</MDBCardTitle>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}

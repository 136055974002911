import React                                  from 'react'
import {MDBCard, MDBCardBody, MDBCol, MDBRow} from 'mdbreact'
import {Link}                                 from "react-router-dom";

export const Contents = () => {

    return (<>
        <MDBCard style={{height: '100%'}}>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                        {<div className="h5-responsive text-center mb-2">Содержание документации</div>}
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        {<div>
                            <ul className="list-unstyled">
                                <li className="p-1"><Link to={"/help"}><strong>Начало работы</strong></Link></li>
                                <li className="p-1">
                                    <Link to={"/help/objects"}><strong>Работа с объектами</strong></Link>
                                    <ul className="list-unstyled ml-3">
                                        <li className="p-1">
                                            <Link to={"#object"}>Объект недвижимости</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={"#create"}>Создание объекта недвижимости</Link></li>
                                                <li className="p-1"><Link to={"#list"}>Список объектов недвижимости</Link></li>
                                                <li className="p-1"><Link to={"#single"}>Карточка объекта недвижимости</Link></li>
                                                <li className="p-1"><Link to={"#update"}>Редактирование объекта недвижимости</Link></li>
                                                <li className="p-1"><Link to={"#delete"}>Удаление объекта недвижимости</Link></li>
                                            </ul>
                                        </li>
                                        <li className="p-1">
                                            <Link to={""}>Корпус/Здание</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Список корпусов</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление в систему корпуса</Link>
                                                </li>
                                                <li className="p-1"><Link to={""}>Карточка корпуса</Link></li>
                                                <li className="p-1"><Link to={""}>Редактирование корпуса</Link></li>
                                                <li className="p-1"><Link to={""}>Удаление корпуса</Link></li>
                                            </ul>
                                        </li>
                                        <li className="p-1">
                                            <Link to={""}>Объект аренды </Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Список объектов аренды</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление в систему объекта
                                                    аренды</Link></li>
                                                <li className="p-1"><Link to={""}>Карточка объекта аренды</Link></li>
                                                <li className="p-1"><Link to={""}>Редактирование объекта аренды</Link>
                                                </li>
                                                <li className="p-1"><Link to={""}>Удаление объекта аренды</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="p-1"><Link to={""}><strong>Работа с арендаторами</strong></Link>
                                    <ul className="list-unstyled ml-3">
                                        <li className="p-1">
                                            <Link to={""}>Собственниик</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Список собственников</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление в систему
                                                    собственника</Link></li>
                                                <li className="p-1"><Link to={""}>Карточка собственника</Link></li>
                                                <li className="p-1"><Link to={""}>Редактирование собственника</Link>
                                                </li>
                                                <li className="p-1"><Link to={""}>Удаление собственника</Link></li>
                                            </ul>
                                        </li>
                                        <li className="p-1">
                                            <Link to={""}>Арендатор</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Список арендаторов</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление в систему арендатора</Link>
                                                </li>
                                                <li className="p-1"><Link to={""}>Карточка арендатора</Link></li>
                                                <li className="p-1"><Link to={""}>Редактирование арендатора</Link></li>
                                                <li className="p-1"><Link to={""}>Удаление арендатора</Link></li>
                                            </ul>
                                        </li>
                                        <li className="p-1">
                                            <Link to={""}>Договор </Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Список договоров</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление в сиситему договора</Link>
                                                </li>
                                                <li className="p-1"><Link to={""}>Карточка договора</Link></li>
                                                <li className="p-1"><Link to={""}>Редактирование договора</Link></li>
                                                <li className="p-1"><Link to={""}>Удаление договора</Link></li>
                                            </ul>
                                        </li>
                                        <li className="p-1 text-black-50">
                                            <Link className="text-black-50" to={""}>Обращения арендаторов</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1 "><Link className="text-black-50" to={""}>Создание
                                                    обращения арендатора</Link></li>
                                                <li className="p-1 "><Link className="text-black-50" to={""}>Список
                                                    обращений</Link></li>
                                                <li className="p-1 "><Link className="text-black-50" to={""}>Редактирование
                                                    договора</Link></li>
                                                <li className="p-1 "><Link className="text-black-50" to={""}>Удаление
                                                    договора</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="p-1"><Link to={""}><strong>Работа с финнасами</strong></Link>
                                    <ul className="list-unstyled ml-3">
                                        <li className="p-1">
                                            <Link to={""}>Начисление финансов</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Список начислений</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление в систему начисления</Link>
                                                </li>
                                                <li className="p-1"><Link to={""}>Карточка начисления</Link></li>
                                                <li className="p-1"><Link to={""}>Редактирование начисления</Link></li>
                                                <li className="p-1"><Link to={""}>Удаление начисления</Link></li>
                                            </ul>
                                        </li>
                                        <li className="p-1">
                                            <Link to={""}>Счет на оплату</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Список счетов</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление в систему счета</Link></li>
                                                <li className="p-1"><Link to={""}>Карточка счета</Link></li>
                                                <li className="p-1"><Link to={""}>Редактирование счета</Link></li>
                                                <li className="p-1"><Link to={""}>Удаление счета</Link></li>
                                            </ul>
                                        </li>
                                        <li className="p-1">
                                            <Link to={""}>Поступления финансов</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Список поступлений</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление в систему
                                                    поступлений</Link></li>
                                                <li className="p-1"><Link to={""}>Карточка поступления</Link></li>
                                                <li className="p-1"><Link to={""}>Редактирание поступления</Link></li>
                                                <li className="p-1"><Link to={""}>Удаление поступления</Link></li>
                                            </ul>
                                        </li>

                                    </ul>
                                </li>
                                <li className="p-1"><Link to={""}><strong>Отчеты</strong></Link></li>
                                <li className="p-1"><Link to={""}><strong>Администрирование программой</strong></Link>
                                    <ul className="list-unstyled ml-3">
                                        <li className="p-1">
                                            <Link to={""}>Сотрудники</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Список сотрудников</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление в систему сотрудника</Link>
                                                </li>
                                                <li className="p-1"><Link to={""}>Карточка сотрудника</Link></li>
                                                <li className="p-1"><Link to={""}>Редактирование сотрудника</Link></li>
                                                <li className="p-1"><Link to={""}>Удаление сотрудника</Link></li>
                                            </ul>
                                        </li>
                                        <li className="p-1">
                                            <Link to={""}>Управление правами доступа к информации</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Роли и права доступа</Link></li>
                                                <li className="p-1"><Link to={""}>Системные роли</Link></li>
                                                <li className="p-1"><Link to={""}>Роли доступа к объектам</Link></li>
                                                <li className="p-1"><Link to={""}>Добавление новой роли</Link></li>
                                                <li className="p-1"><Link to={""}>Удаление роли</Link></li>
                                            </ul>
                                        </li>
                                        <li className="p-1">
                                            <Link to={""}>Справочники</Link>
                                            <ul className="list-unstyled ml-3">
                                                <li className="p-1"><Link to={""}>Добавление и редактирование справочной
                                                    информации</Link></li>
                                            </ul>
                                        </li>


                                    </ul>
                                </li>

                            </ul>
                        </div>}
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Layer, Stage}                                    from 'react-konva'
import withQueryParams                                   from 'react-router-query-params'
import {useHistory}                                      from 'react-router'

import Shape            from './components/shape'
import Point            from './components/point'
import {LinearProgress} from "@material-ui/core";

const HEIGHT = 500
const WIDTH  = 1585

const Main = ({
    floor, setActiveRoom
}) => {
    const [tempPoints, setPoints]         = useState([])
    const [objects, setObjects]           = useState(floor.floorData)
    const [active, setActive]           = useState()
    const [imageLoaded, setImageLoaded] = useState(false)
    const ref                             = useRef(null)
    const layerRef                        = useRef(null)
    const [scale, setScale]               = useState(0.45)
    const [size, setSize]                 = useState({width: WIDTH, height: 0})
    const [imageUrl, setImageUrl]         = useState(floor.schema)
    const {push}                          = useHistory()
    const imageRef                        = useRef(null)

    const defineSizes = useCallback(() => {
        if (imageRef.current && ref.current) {
            const {naturalWidth, naturalHeight} = imageRef.current
            const height                        = (WIDTH * naturalHeight) / naturalWidth

            if (!isNaN(height)) {
                setSize({width: WIDTH, height:height})
            }

        }
    }, [imageRef, imageLoaded])

    useEffect(() => {
        imageRef.current.onload = () => {
            setImageLoaded(true)
        }
    }, [imageRef])

    useEffect(() => {
        if (typeof setActiveRoom === 'function') {
            console.log("setActiveRoom", active)
            setActiveRoom(active)
        }
    }, [active, setActiveRoom])

    useEffect(() => {
        defineSizes()
        window.addEventListener('resize', defineSizes)
        return () => window.removeEventListener('resize', defineSizes)
    }, [defineSizes])

    useEffect(() => {
        defineSizes()
    }, [defineSizes, imageUrl])

    //useEffect(() => {
    //    if (ref.current) {
    //        ref.current.oncontextmenu = event => {
    //            event.preventDefault()
    //        }
    //    }
    //}, [ref])
    //if(imagesLoaded)
    return (<div>
        <div
            ref={ref}
            style={{
                //height: size.height === 0 ? HEIGHT : size.height,
                overflow: 'auto'
            }}
        >
            {imageUrl && objects && (<div style={{
                width             : size.width === 'auto' ? size.width : size.width * scale,
                height            : size.height === 0 ? size.height : size.height * scale,
                position          : 'relative',
                backgroundSize    : 'contain',
                backgroundPosition: 'center',
                backgroundRepeat  : 'no-repeat'
            }}>
                <div style={{
                    top: 0, position: 'absolute', transform: `scale(${scale})`
                }}>
                    {!imageLoaded && <LinearProgress/>}
                    {
                        imageUrl && (<img
                        ref={imageRef}
                        style={{
                            top: 0, position: 'absolute', pointerEvents: 'none', width: size.width, height: size.height
                        }}
                        src={imageUrl}
                        alt="schema"
                    />)}
                </div>
                <Stage
                    width={size.width * scale}
                    height={size.height * scale}
                    scaleX={scale}
                    scaleY={scale}
                >
                    <Layer ref={layerRef}>
                        {tempPoints?.length > 0 && tempPoints.map(({x, y}, i) => (<Point
                            key={i}
                            x={x}
                            y={y}
                            color={i === 0 ? 'orange' : 'green'}
                            strokeWidth={2 / scale}
                            radius={6 / scale}

                        />))}
                        {objects && objects.map(obj => (<Shape
                            push={push}
                            {...obj}
                            active={active === obj.id}
                            setActive={() => {setActive(active === obj.id ? undefined : obj.id)}}
                            key={obj.id}
                            points={obj.points}
                            scale={scale}
                            center={obj.center}
                        />))}
                    </Layer>
                </Stage>
            </div>)}
        </div>
    </div>)
    //return <LinearProgress/>
}

Main.defaultProps = {
    editable: true
}

export default withQueryParams()(Main)

const findCenter = (xPoints, yPoints) => {
    const maxX = Math.max(...xPoints)
    const maxY = Math.max(...yPoints)
    const minX = Math.min(...xPoints)
    const minY = Math.min(...yPoints)

    return {
        x: minX + (maxX - minX) / 2, y: minY + (maxY - minY) / 2
    }
}

import { handleResponse } from "../helpers";
import { apiUrl } from "../utils/ConnectionStrings";

export const contractService = {
  create,
  update,
  remove,
  getAll,
  getById,
  getByOrganisationId,
  getByPage,
  renew,
  terminate,
  addDocuments,
  getDocument,
  removeDocument,
  getByOwnersIds
};

function create(model) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      'Accept': 'application/json',
    },
    body: model
  };

  return fetch(apiUrl + "contract/", requestOptions)
    .then(handleResponse)
    .then(_ => {
      return _;
    });
}

function update(id, model) {
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      'Accept': 'application/json',
    },
    body: model
  };
  return fetch(apiUrl + "contract/" + id, requestOptions)
    .then(handleResponse)
    .then(_ => {
      return _;
    })
}

function remove(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(apiUrl + "contract/" + id, requestOptions)
    .then(handleResponse)
    .then(_ => {
      return _;
    });
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(apiUrl + "contract/", requestOptions)
    .then(handleResponse)
    .then(_ => {
      return _;
    });
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(apiUrl + "contract/" + id, requestOptions)
    .then(handleResponse)
    .then(_ => {
      return _;
    });
}

function getByOrganisationId(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };

  return fetch(`${apiUrl}contract/get-by-companyId/${id}`, requestOptions)
    .then(handleResponse)
    .then(_ => _);
}

function getByPage(params) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(`${apiUrl}contract/page?${params}`, requestOptions)
      .then(handleResponse)
      .then(_ => {
        return _;
      });
}

function renew(params) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(`${apiUrl}contract/renew-contract/` + params, requestOptions)
      .then(handleResponse)
      .then(_ => {
        return _;
      });
}

function terminate(params) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(`${apiUrl}contract/terminate-contract/` + params, requestOptions)
      .then(handleResponse)
      .then(_ => {
        return _;
      });
}

function addDocuments(id, documents) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      'Accept': 'application/json',
    },
    body: documents
  };

  return fetch(`${apiUrl}contract/${id}/add-document`, requestOptions)
      .then(handleResponse)
      .then(_ => {
        return _;
      });
}

export function getDocument(contractId, documentId, docName) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
    },
  };
  return fetch(`${apiUrl}contract/${contractId}/${documentId}`, requestOptions)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = docName;
          a.click();
        });
      });
}

function removeDocument(contractId, documentId) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(`${apiUrl}contract/${contractId}/${documentId}`, requestOptions)
      .then(handleResponse)
      .then(_ => {
        return _;
      });
}

function getByOwnersIds(ownerIds) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(ownerIds)
  };
  return fetch(apiUrl + "contract/get-by-many-id", requestOptions)
      .then(handleResponse)
      .then(_ => _);
}
import React, {Component}                              from 'react';
import {MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow} from 'mdbreact';
import {numberWithSpaces}                              from "../../../../helpers/NumberWithSpaces";
import { reportService }                               from "../../../../services/reportService";

class DashboardCardsSection extends Component {
    constructor (props) {
        super (props);
        this.state = {
            allSquare:null,
            busySquarePercent:null,
            posibleAmountIncome:null
        };

        reportService.getAllObjectReport().then(_ => {
            this.setState({
                allSquare:_.allSquare,
                busySquarePercent:_.busySquarePercent,
                posibleAmountIncome:_.posibleAmountIncome,
            });
        });
    }
    render() {
        const allSquare = this.state.allSquare;
        const busySquarePercent = this.state.busySquarePercent;
        const posibleAmountIncome = this.state.posibleAmountIncome;
        return (<React.Fragment>
            <MDBRow className="mb-4">
                <MDBCol md="4" className="mb-3">
                    <MDBCard color="success-color" className="classic-admin-card">
                        <MDBCardBody>
                            <div className="float-right">
                                <MDBIcon far icon="money-bill-alt"/>
                            </div>
                            <p className="white-text">ОБЩИЙ ДОХОД</p>
                            {posibleAmountIncome && <h4><strong>{numberWithSpaces(posibleAmountIncome.toFixed(2)) + " р."}</strong></h4>}
                        </MDBCardBody>
                        {/*<div className="progress">*/}
                        {/*    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25"*/}
                        {/*         className="progress-bar bg grey darken-3" role="progressbar" style={{width: "25%"}}/>*/}
                        {/*</div>*/}
                    </MDBCard>
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                    <MDBCard color="primary-color" className="classic-admin-card">
                        <MDBCardBody>
                            <div className="float-right">
                                <MDBIcon icon="chart-pie"/>
                            </div>
                            <p className="white-text">ОБЩАЯ ПЛОЩАДЬ</p>
                            {allSquare && <h4><strong>{numberWithSpaces(allSquare.toFixed(2)) + " м"}<sup>2</sup></strong></h4>}
                        </MDBCardBody>
                        {/*<div className="progress">*/}
                        {/*    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25"*/}
                        {/*         className="progress-bar bg grey darken-3" role="progressbar"*/}
                        {/*         style={{width: "25%"}}/>*/}
                        {/*</div>*/}
                    </MDBCard>
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                    <MDBCard color="warning-color" className="classic-admin-card">
                        <MDBCardBody>
                            <div className="float-right">
                                <MDBIcon icon="chart-line"/>
                            </div>
                            <p className="white-text">ВЫПОЛНЕНИЕ ПЛАНА</p>
                            {busySquarePercent && <h4><strong>{busySquarePercent.toFixed(2)} %</strong></h4>}
                        </MDBCardBody>
                        {/*<div className="progress">*/}
                        {/*    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="75"*/}
                        {/*         className="progress-bar bg grey darken-3" role="progressbar"*/}
                        {/*         style={{width: "75%"}}/>*/}
                        {/*</div>*/}
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </React.Fragment>)
    }
}

export default DashboardCardsSection;


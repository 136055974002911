import React, { useState, useEffect } from 'react'
import withQueryParams from 'react-router-query-params'

import RoomsTableSection                                                        from './sections/RoomsTableSection'
import { permissionsHelper }                                                    from '../../../utils/permissionsHelper'
import { LinearProgress }                                                       from '@material-ui/core'
import { roomService }                                                          from '../../../services/roomService'
import { useSetState }                                                          from '../../others/customHooks'
import TableContext                                                             from '../../../context/TableContext'
import { pagination }                                                                            from '../../../static/enums'
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow
} from "mdbreact";
import {Link}                                                                                    from "react-router-dom";
import {useHistory}                                                             from "react-router";
import queryString                                                              from "querystring";

const defaultParams = {
    keys: {
        page: {
            default: 1,
            validate: value => Number(value) > 0
        },
        elementsCount: {
            default: 100,
            validate: value => Number(value) > 0
        },
        madeFilters: {
            default: 'All',
            validate: value => !!value
        }
    }
}

const RoomsPageContainer = props => {
    const [filterVisible, setFilterVisible] = useState(false)
    const [permissions, setPermissions] = useState()
    const [loading, setLoading] = useState(false)
    const [roomsData, setRoomsData] = useSetState({
        fullCount: undefined,
        data: undefined,
        count: undefined,
        page: undefined,
    })
    const history = useHistory()

    const getData = async () => {
        setLoading(true)
        console.log('RoomsPageContainer:47 - Выбраны следующие фильтры:', props.queryParams)
        //Получаем объекты аренды по странице
        const res = await roomService.getByPage(queryString.stringify(props.queryParams)).then(_ => _)
        console.log('RoomsPageContainer:50 - Получены данные с сервера:', res)

        setRoomsData(mapRes(res))
        setLoading(false)
    }


    const setPage = n => {
        props.setQueryParams({ page: n })
    }
    //Todo: вынести настройку количества элементов на странице в LocalStorage
    const setCount = n => {
        props.setQueryParams({ elementsCount: n })
    }

    const setParam = (name, value) => {
        props.setQueryParams({ [name]: value })
    }

    const setSortBy = (name) => {
        props.setQueryParams({
            [pagination.sortBy]: name,
            [pagination.isDesending]: false
        })
    }

    useEffect(() => {
        (async () => {
            const permissions = await permissionsHelper.scopePermissions()
            setPermissions(permissions)
        })()
    }, [])

    useEffect(() => {
        getData()
        //eslint-disable-next-line
    }, [props.queryParams])

    if (permissions) return (
        <TableContext.Provider
            value={{
                roomsData,
                permissions,
                setPage,
                setCount,
                getData,
                loading,
                filters: props.queryParams,
                setSortBy,
                setDesending: (isDesending) => setParam(pagination.isDesending, isDesending),
                filterVisible,
                setFilterVisible,
            }}
        >
            <BreadCrumbs history={history} roomsData={roomsData} header="Объекты аренды" buttonName="Добавить объект аренды" href="/rooms/create"/>
            <RoomsTableSection />
        </TableContext.Provider>
    )
    return <LinearProgress/>
}

export default withQueryParams(defaultParams)(RoomsPageContainer)

const mapRes = (res) => {
    //TODO: данные необходимо получить с сервера, а не считать их тут вручную.
    const total = {totalFactuallyRents: 0, totalSquare: 0}
    res.data.forEach( room => {
        total.totalFactuallyRents += room.factuallyRents
        total.totalSquare += room.square
    })

    return {
        fullCount: res.fullCount,
        total: total,
        data: mapData(res.data),
        count: res.elementCount,
        page:
            Math.ceil(res.fullCount / res.elementCount) < res.page
                ? Math.ceil(res.fullCount / res.elementCount)
                : res.page
    }
}

const mapData = data => data.map( ({
            name,
            objectId,
            corpId,
            id,
            objectName,
            corpName,
            floorName,
            square,
            amountRent,
            roomState,
            type1,
            type2,
            contract,
            factuallyRents,
            rents
        }) => ({
            name,
            objectId,
            corpId,
            id,
            objectName,
            corpName,
            floorName,
            square,
            amountRent,
            roomState,
            factuallyRents,
            rents,
            type1,
            type2,
            renter: contract && contract.company && {
                name: contract.company.fullName,
                id: contract.company.id
            },
            kindActivity: contract && contract.company && contract.company.kindActivity,
            owners: contract ? contract.contractOwnersShares.map(({owner}) => ({
                id: owner.id,
                name: owner.shortName
            })) : [],
            owner: contract && contract.owner && {
                name: contract.owner.fullName,
                id: contract.owner.id
            }
        }))


const BreadCrumbs     = ({history, roomsData, header, buttonName, href}) => {
    const breadcrumbsChain = [['Главная', '/main']]
    let itemsChain         = []
    let i                  = 0
    breadcrumbsChain.forEach(function (item) {
        i++
        itemsChain.push(<MDBBreadcrumbItem key={i}><Link to={item[1]}>{item[0]}</Link></MDBBreadcrumbItem>)
    })
    itemsChain.push (<MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>);
    itemsChain.unshift(<MDBBreadcrumbItem key={321}><span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span></MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" className="pa-16">
                <MDBRow between>
                    <MDBCol className="d-flex align-items-center">
                        {buttonName && (<Link to={href} className="mr-3">
                            <MDBBtn
                                floating
                                size="sm"
                                className="z-depth-1"
                                color="success"
                            >
                                <MDBIcon icon="plus"/>
                            </MDBBtn>
                        </Link>)}
                        <MDBCardTitle tag="h5" className="mb-0">{header}</MDBCardTitle>
                    </MDBCol>
                    {roomsData?.fullCount && <MDBCol className="d-flex justify-content-end align-items-center">
                        <MDBCardTitle tag="h5" className="mb-0">{`${roomsData?.count >= roomsData?.fullCount ? roomsData?.fullCount : roomsData?.count} из ${roomsData?.fullCount} объектов аренды`}</MDBCardTitle>
                    </MDBCol>}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}

import React, { Component } from 'react';
import { connect }          from "react-redux";
import NotFoundPage         from "../../errors/NotFoundPage";
import LinearProgress       from "@material-ui/core/LinearProgress";
import BreadcrumSection     from "../../commonSections/BreadcrumSection";
import AppealsCreatePage    from "./pages/AppealsCreatePage";

class AppealsCreateContainer extends Component {

    findArrayElementById (array, property) {
        return array.find ((element) => {
            return element.id === property;
        })
    }

    render () {
        // let companyId, match = '';
        // let companyData = null;
        // if (this.props.match.params) {
        //     companyId = parseInt(this.props.match.params.organisationId);
        //     match = this.props.match;
        // }
        if (false) {
            return <NotFoundPage/>;
        }


        if (false) {
            return (<LinearProgress/>)
        }

        let breadcrumsChain = [
            ['Главная', '/'],
        ];

        return (
            <React.Fragment>
                <BreadcrumSection
                    createPredicate={false}
                    h2="Добавить обращение арендатора"
                    chain={breadcrumsChain}
                    buttonName="Обращение"
                    href="appeal/create"
                />
                <AppealsCreatePage/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export default connect (mapStateToProps, mapDispatchToProps) (AppealsCreateContainer);

import {handleResponse} from '../helpers';
import {apiUrl} from "../utils/ConnectionStrings";

export const userService = {
    uploadProfilePhoto
};


function uploadProfilePhoto(id, file) {
    const formData = new FormData();
    formData.append('body', file);
    const uploadRequestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json'
        },
        body: formData
    };
    return fetch(apiUrl + 'user/upload-image/' + id, uploadRequestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}


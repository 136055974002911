import React from 'react'
import {
    MDBCol,
    MDBTable, MDBTableBody
} from 'mdbreact'
import { withSnackbar } from 'notistack'

class RequisitesCompanySection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        let companyData = this.props.companyData
        return (
            <React.Fragment>
                <MDBCol size='9'>
                    <MDBTable>
                        <MDBTableBody>
                            <tr>
                                <td className="td-label">Фактический адрес:</td>
                                <td className="td-value">{companyData.actualAddress}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Почтовый адрес:</td>
                                <td className="td-value">{companyData.mailingAddress}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Телефон:</td>
                                <td className="td-value">{companyData.phone}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Факс:</td>
                                <td className="td-value">{companyData.fax}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Электронный адрес:</td>
                                <td className="td-value">{companyData.email}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Сайт:</td>
                                <td className="td-value">{companyData.site}</td>
                            </tr>

                            <tr>
                                <td className="td-label">Дата создания:</td>
                                <td className="td-value">{new Date(companyData.createDate).toLocaleString('ru', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Свидетельство ЕГРЮЛ:</td>
                                <td className="td-value">{companyData.certificateEgpul}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Дата получаения свидетельства:</td>
                                <td className="td-value">{new Date(companyData.certificateDate).toLocaleString('ru', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Номер свидетельства:</td>
                                <td className="td-value">{companyData.certificateNumber}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Свидетельство выдано:</td>
                                <td className="td-value">{companyData.certificateIssued}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Свидетельство ОРГН:</td>
                                <td className="td-value">{companyData.certificateOgrn}</td>
                            </tr>
                            <tr>
                                <td className="td-label">ИНН:</td>
                                <td className="td-value">{companyData.inn}</td>
                            </tr>
                            <tr>
                                <td className="td-label">КПП:</td>
                                <td className="td-value">{companyData.kpp}</td>
                            </tr>
                            <tr>
                                <td className="td-label">ОКПО:</td>
                                <td className="td-value">{companyData.okpo}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Вид деятельности:</td>
                                <td className="td-value">{companyData.kindActivity}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Расчетный счет:</td>
                                <td className="td-value">{companyData.checkingAccount}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Корреспондентский счет:</td>
                                <td className="td-value">{companyData.correspondentAccount}</td>
                            </tr>
                            <tr>
                                <td className="td-label">Банк:</td>
                                <td className="td-value">{companyData.bank}</td>
                            </tr>
                            <tr>
                                <td className="td-label">БИК:</td>
                                <td className="td-value">{companyData.bik}</td>
                            </tr>
                            <tr>
                                <td className="td-label">ОКОГУ:</td>
                                <td className="td-value">{companyData.okogu}</td>
                            </tr>
                            <tr>
                                <td className="td-label">ОКАТО:</td>
                                <td className="td-value">{companyData.okato}</td>
                            </tr>
                            <tr>
                                <td className="td-label">ОКВЭД:</td>
                                <td className="td-value">{companyData.okved}</td>
                            </tr>
                            <tr>
                                <td className="td-label">ФИО договор:</td>
                                <td className="td-value">{companyData.fullNameContract}</td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>
            </React.Fragment>
        )
    }
}


export default withSnackbar(RequisitesCompanySection)

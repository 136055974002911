import {handleResponse} from '../helpers';
import {apiUrl} from "../utils/ConnectionStrings";

export const renterService = {
    create,
    update,
    remove,
    getAll,
    getById,
    getByOrganisationId,
    rentersByPage
};

function create(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };

    return fetch(apiUrl + "renters/", requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}


function update(id, message) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "renters/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}


function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "renters/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "renters/", requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + "renters/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getByOrganisationId(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + "renters/get-by-organisation-id/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}
function rentersByPage(params) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}companies/page?${params}` , requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}


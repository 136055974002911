import { handleResponse } from "../helpers";
import { apiUrl } from "../utils/ConnectionStrings";

export const floorService = {
  create,
  update,
  remove,
  getAll,
  getById,
  getPlannerById,
  savePlannerById,
  saveFloorSchemaImg,
  removeImage,
};

function create(id, floorModel) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
    body: floorModel,
  };
  return fetch(apiUrl + "floors/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function update(id, floorModel) {
  console.log(id, floorModel)
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      // "Content-Type": "application/json", если вместо объекта FormData отправляется JSON
    },
    body: floorModel,
  };
  return fetch(apiUrl + "floors/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function getAll(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  };
  return fetch(apiUrl + "floors/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function getById(id, params) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    ...params,
  };
  return fetch(apiUrl + "floors/get-floor-by-id/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function remove(floorId) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  };

  return fetch(apiUrl + "floors/" + floorId, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function getPlannerById(id, params) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    ...params,
  };
  return fetch(apiUrl + "floorplanner/get-floor-map/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function savePlannerById(id, floorModel) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      floorId: id,
      message: JSON.stringify(floorModel),
    }),
  };
  return fetch(apiUrl + "floorplanner/save-floor-map", requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function saveFloorSchemaImg(id, file) {
  const formData = new FormData();
  formData.append("body", file);
  const uploadRequestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
    body: formData,
  };
  return fetch(`${apiUrl}floors/upload-images/${id}`, uploadRequestOptions)
    .then(handleResponse)
    .then((_) => _);
}

function removeImage(id, imageId) {
  const uploadRequestOptions = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
  };
  return fetch(
    `${apiUrl}floors/remove-images/${id}/${imageId}`,
    uploadRequestOptions
  )
    .then(handleResponse)
    .then((_) => _);
}

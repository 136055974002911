import React, { useRef, useEffect, useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';


const CursorTip = ({ children, active, tip }) => {
    const elementRef = useRef(null);
    const [tipDiv, setTipDiv] = useState(null);

    const updateTipPosition = useCallback((x, y) =>{
        if (tipDiv && active) {
            const windowWidth = window.innerWidth;
            const divWidth = tipDiv.offsetWidth;
            const possibleOffsetRight = x + 8 + divWidth + 16 < windowWidth;
            const possibleOffsetLeft = x - 8 - divWidth - 16 >= 0;
            if (possibleOffsetRight) {
                tipDiv.style.cssText = `top: ${y}px; left: ${x + 8}px;`;
            } else if (possibleOffsetLeft) {
                tipDiv.style.cssText = `top: ${y}px; right: ${windowWidth - x + 8}px;`;
            } else {
                tipDiv.style.cssText = `top: ${y + 8}px; left: 16px;`;
            }
            return tipDiv
        }
    }, [tipDiv, active])

    const showTip = useCallback(() => {
        const { clientX, clientY } = window.event;
        if(clientX && clientY){
            const newTipDiv = updateTipPosition(clientX, clientY);
            if(newTipDiv){
                document.body.appendChild(newTipDiv);
                return;
            }
        }
        if (!tipDiv) return;
        document.body.appendChild(tipDiv);

    }, [tipDiv, updateTipPosition]);

    const moveTip = useCallback(
        ev => updateTipPosition(ev.clientX, ev.clientY),
        [updateTipPosition]
    );

    const hideTip = useCallback(() => {
        if (!tipDiv) return;
        if (document.body.contains(tipDiv)) {
            document.body.removeChild(tipDiv);
        }
    }, [tipDiv]);

    useEffect(() => {
        if(active){
            showTip()
        } else {
            hideTip();
        }
    }, [active, showTip, hideTip])

    useEffect(() => {
        if (tipDiv) return;
        const div = document.createElement('div');
        div.className = 'cursor-tip';
        const inner = ReactDOMServer.renderToStaticMarkup(tip);
        div.innerHTML = inner;
        setTipDiv(div);
    }, [tip, tipDiv, setTipDiv]);

    useEffect(() => {
        if (!tipDiv) return;
        const inner = ReactDOMServer.renderToStaticMarkup(tip);
        tipDiv.innerHTML = inner;
    }, [tip, tipDiv]);

    useEffect(() => {
        const { current } = elementRef;
        if (!current){
            return;
        }
        current.addEventListener('mouseover', showTip);
        current.addEventListener('mousemove', moveTip);
        current.addEventListener('mouseleave', hideTip);
        return () => {
            current.removeEventListener('mouseover', showTip);
            current.removeEventListener('mousemove', moveTip);
            current.removeEventListener('mouseleave', hideTip);
            hideTip();
        };
    }, [elementRef, showTip, moveTip, hideTip]);

    return <div className="tip" ref={elementRef}>{children}</div>;
};

export default CursorTip;

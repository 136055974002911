import React from 'react'
import BreadcrumSection from '../../commonSections/BreadcrumSection'
import ScoreTable from "./pages/ScoreTable";


const ScorePageContainer = ({scores, setScores}) => {
    const breadcrumsChain = [['Главная', '/']]
    return (
        <>
            <BreadcrumSection
                h2={'Счета'}
                chain={breadcrumsChain}
                buttonName={false}
                href="owners"
            />
            <ScoreTable scores={scores} setScores={setScores}/>
        </>
    )
}

export default ScorePageContainer

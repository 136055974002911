import React from 'react'

import { MDBInput } from 'mdbreact'

const InputDebounce = ({getValue, delay=300, ...rest}) =>{

    const debFoo = debounce(delay)

    return <MDBInput
        getValue={val =>{
            debFoo(() => {
                getValue(val)
            })
        }}
        {...rest}
    />
}

export default InputDebounce

function debounce(wait, immediate) {
    var timeout;

    return function executedFunction(func) {
        var context = this;
        var args = arguments;

        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

import React, {useState} from "react";
import {useSnackbar}     from "notistack";
import {useHistory}      from "react-router";
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSpinner,
}                       from "mdbreact";
import ObjectCreateForm from "../forms/objectCreateForm";
import objectClone      from "lodash/cloneDeep";
import {objectService}  from "../../../../services/objectService";
import {Link}           from "react-router-dom";
import {LinearProgress} from "@material-ui/core";

const Buildings = ({buildings, updateBuilding, deleteBuilding}) => {
    return buildings.map((building, buildingArrayIndex) =>{
        return <BuildingsFields
            key={buildingArrayIndex}
            building={building}
            buildingArrayIndex={buildingArrayIndex}
            updateBuilding={updateBuilding}
            deleteBuilding={deleteBuilding}
        />
    })
}

const BuildingsFields = ({ building, buildingArrayIndex, updateBuilding, deleteBuilding}) => {
    return (<>
        <MDBCard className={"object_update mb-30"}>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol md={10}>
                        <MDBInput
                            className="mb-0"
                            label="Название корпуса"
                            name="buildingName"
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={building.corpName}
                            getValue={e => updateBuilding('corpName', e, buildingArrayIndex)}
                        />
                    </MDBCol>
                    <MDBCol className="text-right">
                        <button className="object_update button_delete"
                                onClick={() => deleteBuilding(buildingArrayIndex)}
                        ><i className="fa fa-trash-alt"/> Удалить
                        </button>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}


const ObjectCreate = (props) => {
    const [loading, setLoading] = useState(false);
    const objectId              = props.match.params.objectId;
    //states
    const history               = useHistory();
    const {enqueueSnackbar}     = useSnackbar();
    let breadcrumsChain         = [["Главная", "/"], ["Объекты", "/objects"],];

    const [buildings, setBuildings] = useState({corps: []});
    const [newObject, setNewObject] = useState({
        name: "", CityName: "", streetName: "", houseNumber: "", promotionalName: "",
    });
    let buildingsCreatedCount = 0;

    // Изменение объекта
    const updateObject   = (name, value) => {
        setNewObject({...newObject, [name]: value})
    }
    // Изменение корпуса
    const updateBuilding = (name, value, buildingArrayIndex) => {
        const b                     = objectClone(buildings.corps)
        b[buildingArrayIndex][name] = value
        setBuildings({corps: b})
    }
    // Удаление корпуса
    const deleteBuilding = (buildingArrayIndex) => {
        const b = objectClone(buildings.corps)
        b.splice(buildingArrayIndex, 1)
        setBuildings({corps: b})
    }

    // Добавление корпуса
    const buildingCreate = buildings => {
        const b = objectClone(buildings.corps)

        const newBuilding = {
            corpName: "", coordinates: "",// отмечаем для дальнейшего создания на сервере
            floors  : [{buildingId: null, floorId: null, floorName: "", floorNumber: 1, promotionalName: ""}]
        }
        b.push(newBuilding)
        setBuildings({corps: b})
    }


    // ОТПРАВКА ДАННЫХ НА СЕРВЕР

    const createObject = _ => {

        let objectFormData = new FormData()
        objectFormData.set('Name', _.name)
        objectFormData.set('CityName', _.cityName)
        objectFormData.set('StreetName', _.streetName)
        objectFormData.set('HouseNumber', _.houseNumber)
        objectFormData.set('PromotionalName', _.promotionalName)

        setLoading(true)
        objectService.create(objectFormData).then(_ => {

            buildings.corps.forEach(b => {createBuilding(_[0].id, b)})
            setLoading(false)
            //TODO: Backend: При создании объекта прилетают все объекты, нужно возвращать один, который создали
        })
    }

    const createBuilding = (objectId, corp) => {

        let buildingFormData = new FormData()
        buildingFormData.set('corpName', corp.corpName)
        buildingFormData.set('coordinates', corp.coordinates) // TODO: Сделать отображение корпуса на карте
        console.log(buildings.corps.length, buildingsCreatedCount)
        buildingsCreatedCount++;
        if(buildings.corps.length !== buildingsCreatedCount) {
            objectService.createCorp(objectId,buildingFormData).then(_ => {
            })
        }
        else {
            enqueueSnackbar('Объект создан', { variant: 'success' })
            history.push('/objects')
        }
    }
    // -------------------------
    // TODO: Исправить Breadcrumbs на странице создания объекта
    const Breadcrumbs = ({history}) => {
        const breadCrumbStyle = {
            fontSize: "0.8rem",
        };
        const chain           = [["Главная", "/"], ["Объекты", "/objects"],]
        let itemsChain        = chain.map((_, i) => {
            return (<MDBBreadcrumbItem key={i}><Link to={_[1]}>{_[0]}</Link></MDBBreadcrumbItem>);
        });
        itemsChain.push(<MDBBreadcrumbItem active key={159}>Создание объекта</MDBBreadcrumbItem>);
        itemsChain.unshift(<MDBBreadcrumbItem key={321}>
            <span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span>
        </MDBBreadcrumbItem>);
        return (<>
            {chain.length > 0 && (<MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>)}
            <MDBCard className="mb-30 breadcrumb_object_card">
                <MDBCardBody id="breadcrumb" className="pa-24">
                    <MDBRow between>
                        <MDBCol sm={9} className="d-flex align-items-center">
                            <MDBCardTitle tag="h5" className="g-mb-0" style={{margin: 0}}>Создание объекта</MDBCardTitle>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard></>);
    }
    //------------------------------------------------------------------------------------------------------------------

    return (<>
        <Breadcrumbs history={history}/>
        <MDBRow>
            <MDBCol lg="6">
                <MDBCard className="object_update mb-30">
                    <MDBCardBody>
                        {newObject && <ObjectCreateForm
                            {...{
                                newObject, updateObject, buildingCreate, updateBuilding, deleteBuilding, loading,
                            }}
                        />}

                        <MDBRow middle end>
                            <MDBCol>
                                <MDBRow end middle>
                                    {loading && <MDBSpinner small green/>}
                                    <MDBBtn onClick={history.goBack} color="link">
                                        Отменить
                                    </MDBBtn>
                                    <MDBBtn
                                        id="create-btn"
                                        onClick={() => createObject(newObject)}
                                        disabled={!(newObject.name && newObject.cityName && newObject.streetName && !loading)}
                                        color="success"
                                    >
                                        Сохранить
                                    </MDBBtn>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
                <MDBRow className="mb-30">
                    <MDBCol>
                        <MDBBtn
                            floating
                            size="sm"
                            className="btn_building_create"
                            color="success"
                            onClick={() => buildingCreate(buildings)}
                        >
                            <MDBIcon icon="plus"/>
                        </MDBBtn>
                        <h3 className="object_update block_title">Корпусы</h3>
                    </MDBCol>
                </MDBRow>
                {buildings.corps.length > 0 && <Buildings
                    buildings={buildings.corps}
                    updateBuilding={updateBuilding}
                    deleteBuilding={deleteBuilding}
                />}
            </MDBCol>
        </MDBRow>
    </>);
};
export default ObjectCreate;

import React, {Component} from 'react';
import {connect} from "react-redux";
import {setCurrentUser} from "../../../store/users/user/actions";
import UserProfilePage from "../user/pages/UserProfilePage";

class ProfileContainer extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.currentUser !== nextProps.currentUser;
    }

    setCurrentUser(user) {
        this.props.setCurrentUser(user);
    }

    render() {
        return (
            <React.Fragment>
                <UserProfilePage
                    currentUser={this.props.currentUser}
                    token={this.props.token}
                    setCurrentUser={this.setCurrentUser}
                    selectedUser={this.props.currentUser}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        allUsers: state.user.allUsers,
        currentUserPermissions: state.auth.password,
        token: state.user.token
    };
};

const mapDispatchToProps = {
    setCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);

import React, { useState } from 'react'
import BreadcrumSection from '../../../commonSections/BreadcrumSection'
import ContractNewTableSection from '../sections/ContractNewTableSection'
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow
}                   from "mdbreact";
import {Link}       from "react-router-dom";
import {useHistory} from "react-router";

const ContractListPage = (props) => {
    const [filterVisible, setFilterVisible] = useState(false)


    return (
        <React.Fragment>
            <BreadCrumbs header="Договоры" buttonName="Добавить договор аренды" href="/contracts/create"/>
            <ContractNewTableSection permissions={props.permissions} setFilterVisible={setFilterVisible} filterVisible={filterVisible} />
        </React.Fragment>
    )
}

export default ContractListPage

const BreadCrumbs     = ({header, buttonName, href}) => {
    const breadcrumbsChain = [['Главная', '/main']]
    const history = useHistory()
    let itemsChain         = []
    let i                  = 0
    breadcrumbsChain.forEach(function (item) {
        i++
        itemsChain.push(<MDBBreadcrumbItem key={i}><Link to={item[1]}>{item[0]}</Link></MDBBreadcrumbItem>)
    })
    itemsChain.push (<MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>);
    itemsChain.unshift(<MDBBreadcrumbItem key={321}><span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span></MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" className="pa-16">
                <MDBRow between>
                    <MDBCol className="d-flex align-items-center">
                        {buttonName && (<Link to={href} className="mr-3">
                            <MDBBtn
                                floating
                                size="sm"
                                className="z-depth-1"
                                color="success"
                            >
                                <MDBIcon icon="plus"/>
                            </MDBBtn>
                        </Link>)}
                        <MDBCardTitle tag="h5" className="mb-0">{header}</MDBCardTitle>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}

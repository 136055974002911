import React  from 'react'
import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBMask, MDBRow } from 'mdbreact'
import Logo from '../../../static/background_logo.jpg'

const divStyle = {
    height: '100%',
    width: '100%',
    backgroundImage: `url(${Logo})`,
    backgroundSize: 'cover',
    position: 'absolute'
}

const maskStyle = {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    position: 'absolute'
}

const AuthLayout = ({ children, title }) => {

    return (
        <div style={divStyle} className='classic-form-page' id='login'
        >
            <MDBMask
                className='d-flex justify-content-center align-items-center'
                overlay='stylish-strong'
                style={maskStyle}
            >
                <MDBContainer fluid className="p-5">
                    <MDBRow around>
                        <MDBCol lg="4" className="mb-5">
                            <MDBCard>
                                <MDBCardBody>
                                    <div className='form-header blue-gradient'>
                                        <h3>
                                            <MDBIcon
                                                icon='user'
                                                className='mt-2 mb-2 text-white'
                                            />{' '}
                                            {title}
                                        </h3>
                                    </div>
                                    {children}
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBMask>
        </div>
    )
}

export default AuthLayout

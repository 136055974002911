import React, {Component} from 'react';
import RegistrationSection from "../sections/RegistrationSection";
import Layout from '../Layout'

class RegisterPage extends Component {
    render() {
        let guid = null;
        if (this.props.match.params.guid) {
            guid = this.props.match.params.guid;
        }
        return (
            <Layout title="Регистрация">
                <RegistrationSection guid={guid}/>
            </Layout>
        );
    }
}

export default RegisterPage;

import React, {Component} from 'react';
import {connect} from "react-redux";
import {roleService} from "../../../services/roleService";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {MDBCard} from "mdbreact";
import {objectRoleService} from "../../../services/objectRoleService";
import LinearProgress from "@material-ui/core/LinearProgress";
import RoleManagePage from "./pages/RoleManage/SystemRoles/RoleManagePage";
import CreateRolePage from "./pages/RoleManage/SystemRoles/CreateRolePage";
import ObjectRoleManagePage from "./pages/RoleManage/ObjectRoles/ObjectRoleManagePage";
import CreateObjectRolePage from "./pages/RoleManage/ObjectRoles/CreateObjectRolePage";

class RoleManageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: null,
            objectRoles: null,
            isCreateMode: false,
            isUpdateMode: false,
            updateRole: null,
            activeTab: 0
        };

        this.setCreateMode = this.setCreateMode.bind(this);
        this.setUpdateMode = this.setUpdateMode.bind(this);
        this.setRoles = this.setRoles.bind(this);
        this.setObjectRoles = this.setObjectRoles.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, newValue) {
        this.setState({
            activeTab: newValue,
            isCreateMode: false,
            isUpdateMode: false,
            updateRole: null,
        })
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.roles !== nextState.roles ||
            this.state.objectRoles !== nextState.objectRoles ||
            this.state.isUpdateMode !== nextState.isUpdateMode ||
            this.state.updateRole !== nextState.updateRole ||
            this.state.activeTab !== nextState.activeTab ||
            this.state.isCreateMode !== nextState.isCreateMode;
    }

    setCreateMode(bool) {
        this.setState({
            isCreateMode: bool
        });
    }

    setUpdateMode(bool, role) {
        this.setState({
            isUpdateMode: bool,
            updateRole: role
        });
    }

    setRoles(newRoles) {
        this.setState({roles: newRoles})
    }

    setObjectRoles(newRoles) {
        this.setState({objectRoles: newRoles})
    }

    componentDidMount() {
        roleService.getAll().then(_ => {
            this.setRoles(_);
        });
        objectRoleService.getAll().then(_ => {
            this.setObjectRoles(_);
        })
    }

    render() {
        if (!this.state.roles) return (<LinearProgress/>);
        return (
            <React.Fragment>
                <MDBCard>
                    <Tabs
                        value={this.state.activeTab}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary">
                        <Tab value={0} label="Системные роли"/>
                        <Tab value={1} label="Роли доступа к объектам"/>
                    </Tabs>
                </MDBCard>

                {this.state.activeTab === 0 && !this.state.isCreateMode && !this.state.isUpdateMode &&
                <RoleManagePage
                    setCreateMode={this.setCreateMode}
                    setUpdateMode={this.setUpdateMode}
                    roles={this.state.roles}
                    setRoles={this.setRoles}/>}

                {this.state.activeTab === 0 && this.state.isCreateMode && !this.state.isUpdateMode &&
                <CreateRolePage
                    setCreateMode={this.setCreateMode}
                    setRoles={this.setRoles}/>}


                {/*{this.state.activeTab === 0 && !this.state.isCreateMode && this.state.isUpdateMode &&*/}
                {/*<UpdateRolePage*/}
                {/*    setUpdateMode={this.setUpdateMode}*/}
                {/*    updateRole={this.state.updateRole}*/}
                {/*    roles={this.state.roles}*/}
                {/*    setRoles={this.setRoles}/>}*/}




                {this.state.activeTab === 1 && !this.state.isCreateMode && !this.state.isUpdateMode &&
                <ObjectRoleManagePage
                    setCreateMode={this.setCreateMode}
                    roles={this.state.objectRoles}
                    setRoles={this.setObjectRoles}/>}

                {this.state.activeTab === 1 && this.state.isCreateMode &&
                <CreateObjectRolePage
                    setCreateMode={this.setCreateMode}
                    setRoles={this.setObjectRoles}/>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RoleManageContainer);

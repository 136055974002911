import React from 'react';
import BreadcrumSection from "../../../commonSections/BreadcrumSection";
import CalendarSection from "../sections/CalendarSection";

class EventsPage extends React.Component {

    render() {
        const breadcrumsChain = [
            ['Главная', '/main'],
        ];

        return (
            <React.Fragment>
                <BreadcrumSection
                    createPredicate={false}
                    h2="Мероприятия"
                    chain={breadcrumsChain}
                    buttonName="мероприятие"
                    href="event/create"/>

                <CalendarSection/>
            </React.Fragment>);
    }
}

export default EventsPage;

import React from 'react'
import Layout from '../Layout'
import PasswordRecoverySection from "../sections/PasswordRecoverySection";

const AuthPage = () => (
    <Layout title={'Восстановление пароля'}>
        <PasswordRecoverySection/>
    </Layout>
)

export default AuthPage

export const COMPANY_CREATE_FORM_SUBMIT = 'COMPANY_CREATE_FORM_SUBMIT';
export const COMPANY_SET_IS_CREATED_STATUS = 'COMPANY_SET_IS_CREATED_STATUS';
export const COMPANY_SET_IS_LOADING_BOOL = 'COMPANY_SET_IS_LOADING_BOOL';
export const COMPANY_GET_ALL = 'COMPANY_GET_ALL';
export const COMPANY_SET_ALL = 'COMPANY_SET_ALL';

export const setAllCompanies = (allCompanies) => ({
    type: COMPANY_SET_ALL,
    payload: allCompanies
});

export const setIsCreated = (boolState) => ({
    type: COMPANY_SET_IS_CREATED_STATUS,
    payload: boolState
});




import React, { Component } from 'react'
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBIcon,
    MDBRow
} from 'mdbreact'
import { numberWithSpaces } from '../../../../helpers/NumberWithSpaces'

class TableSection extends Component {
    render() {
        const data = this.props.data.data

        //TODO: в рендере нельзя делать такие вычисления, переделать с использованием memo https://ru.reactjs.org/docs/react-api.html#reactmemo
        const tableCards = data.reportByObjects.map(object => {
            const effective = (object.usefulSquare / object.totalArea) * 100
            return (
                <div key={object.id} className="insideCard">
                    <div className="f-item i_name">{object.objectName}</div>
                    <div className="f-item i_area">
                        <span className="icon_item icon_1"><MDBIcon icon="city" size="2x"/></span>
                        <span
                            className="span-element position-1">{numberWithSpaces(Math.round(object.totalArea))} м<sup>2</sup></span>
                    </div>
                    <div className="f-item i_square">
                        <span className="icon_item icon_2">{effective.toFixed(0)} %</span>
                        <span
                            className="span-element position-2">{numberWithSpaces(Math.round(object.usefulSquare))} м<sup>2</sup></span>
                    </div>
                    <div className="f-item i_income">
                        <span className="icon_item icon_3"><MDBIcon icon="arrow-alt-circle-down" size="2x"/></span>
                        <span
                            className="span-element position-3">{numberWithSpaces(Math.round(object.amountIncome))} р.</span>
                    </div>
                </div>
            )
        })
        return (
            <MDBRow className="mb-4">
                <MDBCol md="12">
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardHeader>
                                Отчетность по всем объектам
                            </MDBCardHeader>
                            {tableCards}
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>)
    }
}

export default TableSection
export const USERS_FILTER_LIST_IS_ON_BOOL  = 'USERS_FILTER_LIST_IS_ON_BOOL';
export const USERS_FILTER_LIST_IS_OFF_BOOL = 'USERS_FILTER_LIST_IS_OFF_BOOL';
export const USERS_SET_ALL = 'USERS_SET_ALL';
export const EMPLOYEES_SET_ALL = 'EMPLOYEES_SET_ALL';


export const setUsersListFilterOn = () => ({ // UsersListFilter:bool
  type: USERS_FILTER_LIST_IS_ON_BOOL,
  payload: true
});

export const setUsersListFilterOff = () => ({ // UsersListFilter:bool
  type: USERS_FILTER_LIST_IS_OFF_BOOL,
  payload: false
});

export const setAllUsers = (allUsers) => ({ // UsersListFilter:bool
  type: USERS_SET_ALL,
  payload: allUsers
});
export const setAllEmployees = (allUsers) => ({
  type: EMPLOYEES_SET_ALL,
  payload: allUsers
});

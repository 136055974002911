import React, {useCallback, useContext, useMemo} from 'react'
import {LinearProgress}                          from '@material-ui/core'
import {useSnackbar}                             from 'notistack'
import {Link}                                    from 'react-router-dom'
import {MDBCard, MDBCardBody}                    from 'mdbreact'

import {useSetState}             from '../../../others/customHooks'
import {roomService}             from '../../../../services/roomService'
import {roomState, type1, type2} from '../../../../utils/RoomTypes'
import SmartTable                from '../../../commonSections/SmartTable'
import TablePagination           from '../../../commonSections/TablePagination'
import RoomsFilter               from './RoomsFilter'
import Indicator                 from '../../../others/Indicator'
import indicatorColor            from '../../../../utils/indicatorColor'
import {getQuantityString}       from '../../../../utils/Others'
import TableContext              from '../../../../context/TableContext'
import EditBlockDropdown         from '../../../others/EditBlockDropdown'
import {numberWithSpaces}        from "../../../../helpers/NumberWithSpaces";

const RoomsTableSection = () => {
    const {
              roomsData, setCount, setPage, filters, getData, permissions, filterVisible, setFilterVisible
          } = useContext(TableContext)
    console.log('roomsData', roomsData)
    const [visibleColumns, setVisibleColumns] = useSetState(initialVisibleColumns)

    const {enqueueSnackbar}                   = useSnackbar()

    const deleteRooms                         = useCallback((id) => () => {
        roomService.remove(id).then(_ => {
            enqueueSnackbar('Договор успешно удален', {variant: 'success'})
            getData()
        }, error => {
            enqueueSnackbar(error, {variant: 'error'})
        })
    }, [enqueueSnackbar, getData])

    // Подготовка данных для таблицы
    // В data попадают все колонки таблицы, в независимости от поля checked, хотя по логике скрытые колонки не должны отображаться
    const data = useMemo(() => ({
        columns  : Object.keys(visibleColumns).map(key => ({
            ...visibleColumns[key], field: key
        })),
        rows     : roomsData.data ? mapRows(roomsData.data, roomsData.page, filters.elementsCount, permissions, deleteRooms, roomsData.total) : []
    }), [roomsData, visibleColumns, filters.elementsCount, permissions, deleteRooms])

    console.log('sections/RoomsTableSection:46 ','Подготовка данных для таблицы', data)
    if (!roomsData.data) {
        return <LinearProgress/>
    }

    return (<>
            <MDBCard>
                <MDBCardBody>
                    <RoomsFilter
                        filterVisible={filterVisible}
                        setFilterVisible={setFilterVisible}
                        setVisibleColumns={setVisibleColumns}
                        visibleColumns={visibleColumns}
                        filters={filters}
                    />
                    <SmartTable {...{data, visibleColumns, isSortable: true}} />
                </MDBCardBody>
                <TablePagination
                    {...{
                        page: roomsData.page, count: roomsData.count, fullCount: roomsData.fullCount, setCount, setPage
                    }}
                />
            </MDBCard>
        </>)
}

export default RoomsTableSection

const initialVisibleColumns = {
    '#'         : {checked: true, label: '#', width: 50},
    name        : {checked: true, label: 'Название помещения', sortName: 'name'},
    objectCorp  : {checked: true, label: 'Объект / Корпус'},
    floor       : {checked: false, label: 'Этаж'},
    square      : {checked: true, label: 'Площадь', headAlign: 'center', bodyAlign: 'right', sortName: 'square'},
    sum         : {checked: true, label: 'Аренда', headAlign: 'center', bodyAlign: 'right', sortName: 'amountRent'},
    rent        : {
        checked  : true,
        label    : 'Арендная ставка',
        headAlign: 'center',
        bodyAlign: 'right',
        sortName : 'amountRent'
    },
    state       : {checked: true, label: 'Состояние', sortName: 'roomState'},
    owner       : {checked: false, label: 'Сосбственник(-и)'},
    kindActivity: {checked: false, label: 'Вид активноcти'},
    renter      : {checked: false, label: 'Арендатор'},
    type1       : {checked: false, label: 'Вид', sortName: 'type1'},
    type2       : {checked: false, label: 'Тип', sortName: 'type2'},
    edit        : {checked: false, label: 'Редактирование', color: 'red'}
}

const mapRows = (data, page, elementsCount, permissions, deleteRooms, total) => {
    const rows = data.map((room, index) => ({
            '#':<div className="d-flex justify-content-center align-items-center" style={{position: 'absolute', left: 0, top: 0, height: '100%', width: '100%'}}>
                {(Number(page) - 1) * Number(elementsCount) + index + 1}
                <Indicator color={(room.contract && room.contract.entityStatus === 0) ? indicatorColor(room.contract.completionDateTime) : 'grey'}/>
            </div>,
            name:(<Link to={`/rooms/${room.id}`}>{room.name}</Link>),
            objectCorp              : <>
                <Link to={`/objects/${room.objectId}`}>
                    {room.objectName}
                </Link> /
                {' '}<Link to={`/objects/${room.objectId}/buildings/${room.corpId}`}>
                {room.corpName}
            </Link>
            </>,
            floor                   : room.floorName,
            square                  : getQuantityString(room.square, 'м²'),
            sum                     : `${getQuantityString(room.amountRent)}${room.factuallyRents ? ` > ${getQuantityString(room.factuallyRents)}` : ''}`,
            rent                    : room.factuallyRents ? getQuantityString(room.factuallyRents/room.square, 'р./м²') : getQuantityString(room.rents, 'р./м²'),
            state                   : roomState[Number(room.roomState)],
            owner                   : room.owners.length ? room.owners.map((owner, i) => {
                const isLast = room.owners.length - 1 === i;
                return <React.Fragment key={`owner-${owner.id}`}>
                    <Link to={`/owners/${owner.id}`}>{owner.name}</Link>
                    {!isLast && <>, </>}
                </React.Fragment>
            }) : '-',
            kindActivity            : room.kindActivity,
            renter                  : room.renter && <Link to={`/renters/${room.renter.id}`}>{room.renter.name}</Link>,
            type1                   : type1[Number(room.type1)],
            type2                   : type2[Number(room.type2)],
            edit                    : (<EditBlockDropdown
                relative
                permissions={permissions}
                detailsLink={`/rooms/${room.id}`}
                editLink={`/rooms/update/${room.id}`}
                deleteLabel={`Вы действительно хотите удалить объект аренды ${room.name}?`}
                deleteFunc={deleteRooms(room.id)}
            />)
        })
    )
    rows.push({
        '#': null,
        name: null,
        objectCorp: null,
        floor: null,
        square: <><span className='rental-object-list total-row-of-table total-square'>{getQuantityString(total.totalSquare, 'м²')}</span></>,
        sum: <><span className='rental-object-list total-row-of-table total-factually-rents'>{getQuantityString(total.totalFactuallyRents, 'р.')}</span></>,
        rent: null,
        state: null,
        owner: null,
        kindActivity: null,
        renter: null,
        type1: null,
        type2: null,
        edit: null,
    })
    return rows
}
import React from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBModalFooter, MDBRow} from "mdbreact";
import {appealService} from "../../../../services/appealService";
import {withSnackbar} from "notistack";
import {Redirect} from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FilePond, registerPlugin} from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);



class AppealsCreatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createdDateTime: null,
            topic: "",
            content: "",
            roomId: 1,
            rowsAppeals: null,
            isLoading: false,
            isRedirect: false,
            files: []
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    getAllRoomsByRenterId(id) {
        //TODO добавить метод на беке
    }

    async sendAppeal(e) {
        e.preventDefault();
        if(this.state.topic === "" || this.state.content === ""){
            this.props.enqueueSnackbar("Заполните поля помеченные *", {variant: 'warning'});
            return;
        }
        this.setState({isLoading: true});
        let message = {
            createdDateTime: new Date().toISOString().substr(0, 10),
            topic: this.state.topic,
            content: this.state.content,
            roomId: 1
        };
        let newAppealId = null;
        await appealService.create(JSON.stringify(message)).then(_ => {
            newAppealId = _.id;
            this.props.enqueueSnackbar("Обращение зарегистрировано", {variant: 'success'});
            if(this.state.files.length === 0) this.setState({isRedirect: true});
        }, error => {
            this.props.enqueueSnackbar(error, {variant: 'error'});
        });

        if(this.state.files.length !== 0)
        {
            await appealService.uploadImages(newAppealId, this.state.files).then(_ => {
                this.props.enqueueSnackbar("Все фотографии успешно загружены", {variant: 'success'});
                this.setState({isRedirect: true});
            }, error => {
                this.props.enqueueSnackbar(error, {variant: 'error'});
            });
        }

        this.setState({isLoading: false});
    }

    changeAppeal(name, e) {
        this.setState({[name]: e.target.value});
    }

    render() {
       if (this.state.isRedirect)
            return (<Redirect to="/appeals"/>);

        return (
            <React.Fragment>
                <MDBCard >
                    <MDBCardBody>
                            <MDBRow>
                                <MDBCol sm="6">
                                    <div className="grey-text">
                                        <MDBInput
                                            label="Тема обращения*"
                                            name="topic"
                                            value={this.state.topic}
                                            onChange={e => this.changeAppeal("topic", e)}
                                            icon="tag"
                                            group
                                            type="text"
                                            validate
                                            error="wrong"
                                            success="right"
                                        />
                                        <MDBInput
                                            type="textarea"
                                            name="content"
                                            onChange={e => this.changeAppeal("content", e)}
                                            value={this.state.content}
                                            rows="3"
                                            label="Текст обращения*"
                                            icon="pencil-alt"
                                        />
                                    </div>

                                </MDBCol>
                                <MDBCol middle>
                                    <FilePond
                                        files={this.state.files}
                                        allowMultiple={true}
                                        maxFiles={5}
                                        labelIdle="Перетащите сюда нужные фотографии, не больше 5, либо нажмите на это окошко"
                                        onupdatefiles={fileItems => {
                                            this.setState({
                                                files: fileItems.map(fileItem => fileItem.file)
                                            });
                                        }}
                                    />

                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBModalFooter>
                                    {this.state.isLoading && <CircularProgress size={30}/>}
                                    <MDBBtn
                                        outline color="blue-grey"
                                        disabled={this.state.isLoading}
                                        onClick={() => {
                                            this.setState({isRedirect: true})
                                        }}>
                                        Назад
                                    </MDBBtn>
                                    <MDBBtn
                                        onClick={e => this.sendAppeal(e)}
                                        outline
                                        type="submit"
                                        color="success">Сохранить</MDBBtn>
                                </MDBModalFooter>

                            </MDBRow>

                    </MDBCardBody>
                </MDBCard>
            </React.Fragment>)
            ;
    }


}

export default withSnackbar(AppealsCreatePage);

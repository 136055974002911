import { useCallback, useMemo, useState } from 'react'

export default function useHistoryState(initialState) {
    const [state, setState] = useState(initialState)
    const [stateHistory, setStateHistory] = useState({})

    const [currentState, setCurrentState] = useState()

    const changeState = useCallback(
        state => {
            const key = Date.now()
            setState(state)
            setStateHistory(prev => {
                const keys = Object.keys(prev)
                const result = {}
                for (let k of keys) {
                    if (+k <= +currentState) {
                        result[k] = prev[k]
                    }
                }
                return { ...result, [key]: state }
            })
            setCurrentState(key)
        },
        [currentState]
    )

    const canGetPrev = useMemo(() => {
        const keys = Object.keys(stateHistory)
        if (keys.length > 1) {
            return Math.min(...keys.map(Number)) !== Number(currentState)
        }
        return false
    }, [stateHistory, currentState])

    const canGetNext = useMemo(() => {
        const keys = Object.keys(stateHistory)
        if (keys.length > 1) {
            return Math.max(...keys.map(Number)) !== Number(currentState)
        }
        return false
    }, [stateHistory, currentState])

    const goPrev = useCallback(() => {
        if (canGetPrev) {
            const keys = Object.keys(stateHistory)
            keys.sort((a, b) => a - b)
            const index = keys.findIndex(item => Number(item) === Number(currentState))
            if(~index){
                const findedKey = keys[index - 1]
                const newState = stateHistory[findedKey];
                if(newState){
                    setState(newState);
                    setCurrentState(findedKey)
                }
            }
        }
    }, [canGetPrev, currentState, stateHistory])

    const goNext = useCallback(() => {
        if (canGetNext) {
            const keys = Object.keys(stateHistory)
            keys.sort((a, b) => a - b)
            const index = keys.findIndex(item => +item === +currentState)
            if(~index){
                const findedKey = keys[index + 1]
                const newState = stateHistory[findedKey];
                if(newState){
                    setState(newState);
                    setCurrentState(findedKey)
                }

            }
        }
    }, [canGetNext, currentState, stateHistory])


    return [state, changeState, goPrev, goNext, canGetPrev, canGetNext]
}

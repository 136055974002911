import React from 'react';

import {authenticationService} from '../../../../services/authService';
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBRow
} from "mdbreact";
import {Redirect} from "react-router";
import {
    onUserChange,
    setBirthDate,
    setLogin, setMobilePhone,
    setName,
    setPassword,
    setPatronymic, setPhone,
    setSurname, SubmitEvent
} from '../../../../store/registration/actions'
import { setCurrentUser, setIsLoggedIn } from '../../../../store/users/user/actions'
import { connect } from 'react-redux'

let currentDate;

export class RegistrationSection extends React.Component {
    errors = false;

    constructor(props) {
        super(props);
        this.state = {
            accessError: null
        };

        let date = new Date();
        currentDate = date.toISOString().substr(0, 10);
        this.props.setBirthDate(currentDate);

        if (authenticationService.currentUserValue) {
            this.props.history.push('/');
        }

        this.onLoginChange = this.onLoginChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onSurnameChange = this.onSurnameChange.bind(this);
        this.onPatronymicChange = this.onPatronymicChange.bind(this);
        this.onBirthDateChange = this.onBirthDateChange.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.onMobilePhoneChange = this.onMobilePhoneChange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    componentDidMount() {
        if (this.props.guid) {
            authenticationService.checkInvitations(this.props.guid).then(_ => {
                this.setState({accessError: _.message})
            }, error => {
            });
        }
    }

    buildRegisterModel = () => {
        let registerModel = JSON.stringify({
            login: this.props.login,
            password: this.props.password,
            name: this.props.name,
            surname: this.props.surname,
            patronymic: this.props.patronymic,
            birthDate: this.props.birthDate,
            phone: this.props.phone,
            mobilePhone: this.props.mobilePhone,
        });
        return registerModel;
    };

    onLoginChange(event) {
        this.props.setLogin(event.target.value);
    }

    onPasswordChange(event) {
        this.props.setPassword(event.target.value);
    }

    onNameChange(event) {
        this.props.setName(event.target.value);
    }

    onSurnameChange(event) {
        this.props.setSurname(event.target.value);
    }

    onPatronymicChange(event) {
        this.props.setPatronymic(event.target.value);
    }

    onBirthDateChange(event) {
        this.props.setBirthDate(event.target.value);
    }

    onPhoneChange(event) {
        this.props.setPhone(event.target.value);
    }

    onMobilePhoneChange(event) {
        this.props.setMobilePhone(event.target.value);
    }

    IsLoggedIn(user) {
        if (user) this.props.setIsLoggedIn(true);
    }

    onSubmitForm(event) {
        authenticationService.register(this.buildRegisterModel()).then(user => {
                this.props.setCurrentUser(user);
                this.props.onUserChange(user);
                this.IsLoggedIn(user);
                return (<Redirect to="/main"/>); // TODO: Не корректно работает Redirect - при регистрации пользователя не происходит редирект на главную страницу.
            },
            error => {
            });
        event.preventDefault();
    }

    render() {

        return (
            <React.Fragment>
                {this.state.accessError === "True" && <form onSubmit={this.onSubmitForm}>
                    <MDBInput
                        label="E-mail"
                        group
                        validate
                        error="wrong"
                        success="right"
                        className={'form-control'}
                        type="text"
                        onChange={this.onLoginChange}
                        value={this.props.login}
                    />
                    <MDBInput
                        label="Пароль"
                        group
                        validate
                        error="wrong"
                        autoComplete="new-password"
                        success="right"
                        name="password"
                        type="password"
                        className={'form-control'}
                        onChange={this.onPasswordChange}
                        value={this.props.password}
                    />
                    <div className="form-row">
                        <div className="col">
                            <MDBInput
                                label="Имя"
                                group
                                validate
                                error="wrong"
                                success="right"
                                name="name"
                                type="text"
                                className={'form-control'}
                                onChange={this.onNameChange}
                                value={this.props.name}
                            />
                        </div>
                        <div className="col">
                            <MDBInput
                                label="Фамилия"
                                group
                                validate
                                error="wrong"
                                success="right"
                                name="surname"
                                type="text"
                                className={'form-control'}
                                onChange={this.onSurnameChange}
                                value={this.props.surname}
                            />
                        </div>
                    </div>
                    <MDBInput
                        label="Отчество"
                        group
                        validate
                        error="wrong"
                        success="right"
                        name="patronymic"
                        type="text"
                        className={'form-control'}
                        onChange={this.onPatronymicChange}
                        value={this.props.patronymic}
                    />
                    <MDBInput
                        label="Дата Рождения"
                        valueDefault={currentDate}
                        group
                        validate
                        error="wrong"
                        success="right"
                        name="birthDate"
                        type="date"
                        className={'form-control'}
                        onChange={this.onBirthDateChange}
                        value={this.props.birthDate}
                    />
                    <div className="form-row">
                        <div className="col">
                            <MDBInput
                                label="Телефон"
                                group
                                validate
                                error="wrong"
                                success="right"
                                name="phone"
                                type="text"
                                className={'form-control'}
                                onChange={this.onPhoneChange}
                                value={this.props.phone}
                            />
                        </div>
                        <div className="col">
                            <MDBInput
                                label="Мобильный"
                                group
                                validate
                                error="wrong"
                                success="right"
                                name="mobilePhone"
                                type="text"
                                className={'form-control'}
                                onChange={this.onMobilePhoneChange}
                                value={this.props.mobilePhone}
                            />
                        </div>
                    </div>
                    <MDBRow>
                        <MDBCol>
                            <div className='text-center'>
                                <MDBBtn
                                    className="rounded btn blue-gradient"
                                    type="submit">
                                    ЗАРЕГИСТРИРОВАТЬСЯ
                                </MDBBtn>
                            </div>
                        </MDBCol>
                        {this.error &&
                        <div className={'alert alert-danger'}>{this.error}</div>
                        }
                    </MDBRow>
                </form>}
                {this.state.accessError === "False" &&
                <p className="h4 mb-4">К сожалению, ссылка больше недоступна</p>}
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        login: state.registration.login,
        password: state.registration.password,
        name: state.registration.name,
        surname: state.registration.surname,
        patronymic: state.registration.patronymic,
        birthDate: state.registration.birthDate,
        phone: state.registration.phone,
        mobilePhone: state.registration.mobilePhone,
        user: state.user.user,
        isLogged: state.isLogged,

    };
};

const mapDispatchToProps = {
    setLogin,
    setPassword,
    setName,
    setSurname,
    setPatronymic,
    setBirthDate,
    setPhone,
    setMobilePhone,
    SubmitEvent,
    onUserChange,
    setIsLoggedIn,
    setCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationSection);
import React             from 'react';
import {connect}         from "react-redux";
import {setIsCreated}    from "../../../../store/company/actions";
import CreateAccrualPage from "../CreateAccrualPage";

class CreateAccrualContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: []
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    render() {
        return <CreateAccrualPage />
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        isAdmin    : state.user.isAdmin,
    };
};

const mapDispatchToProps = {
    setIsCreated
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccrualContainer);

import React from 'react'

const RadioButtons = ({options, value, onChange}) => {
    options.forEach(_ => {
        if(_.value === value) {
            _.active = 'activeRadio'
        }
        else _.active = ''
    })

    return (options.map( item => (

        <button
        type='button'
        name='busy'
        key={item.label}
        onClick={() => item.value !== value && onChange(item.value)}
        className={'btn btn-primary btn-outline-primary no-shadow btn-rounded smart-table_filters ' + item.active}
        >{item.label}</button>)
    ))
}

export default RadioButtons;
export function compareToNumber(a, b) {
    return Number(a) === Number(b)
}

export function range(start, end, step) {
    let arr = [],
        len = 0
    step = step === undefined ? 1 : step
    if (arguments.length === 1) {
        len = start
        start = 1
        end = start
    } else {
        start = start === undefined ? 1 : start
        end = end === undefined ? 1 : end
        len = end - start
    }

    var i = 0
    while (i < len) {
        arr.push(start + i * step)

        i += 1
    }

    return arr
}

/**
 * Функция возвращает правильный формат числовым значениям
 * @param count - само число
 * @param unit - единица измерения (рубли, м²) поуполчания 'р.'
 * @returns {string}
 */
export const getQuantityString = (count, unit = 'р.') => {
    if (!count) {
        return `0,00 ${unit}`
    }
    try {
        const pr = Number(count)
        if (isNaN(pr)) {
            return `0,00 ${unit}`
        }
        return (
            pr.toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) +
            ` ${unit}`
        )
    } catch (error) {
        console.error(error)
        return `${count} ${unit}`
    }
}
export const getNumber = count => {
    if (!count) {
        return `0,00`
    }
    try {
        const pr = Number(count)
        if (isNaN(pr)) {
            return `0,00`
        }
        return pr.toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    } catch (error) {
        console.error(error)
        return `${count}`
    }
}

export const getDateFormat = date =>
    date
        ? new Date(date).toLocaleDateString('ru', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
          })
        : ''

export const clean = obj => {
    for (let propName in obj) {
        if (obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName]
        }
    }
    return obj
}

export const getImageUrl = url => 'https://' + url.split('/var/www/rentto/data/www/')[1]

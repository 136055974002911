import React, {useEffect, useState} from 'react'
import withQueryParams              from 'react-router-query-params'
import queryString                  from "querystring"

import BreadcrumSection    from '../../commonSections/BreadcrumSection'
import {permissionsHelper} from '../../../utils/permissionsHelper'
import {LinearProgress}    from '@material-ui/core'
import RentersFilters      from './components/Filters'
import {useSetState}       from '../../others/customHooks'
import RetnersTable        from './components/Table'
import {renterService}     from '../../../services/renterService'

import {useSnackbar}          from 'notistack'
import {companyService}       from '../../../services/companyService'
import TablePagination        from '../../commonSections/TablePagination'
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow
}                   from 'mdbreact'
import {useHistory} from "react-router";
import {Link}       from "react-router-dom";

const defaultParams = {
    keys: {
        page         : {default: 1, validate: value => Number(value) > 0},
        elementsCount: {default: 50, validate: value => Number(value) > 0}
    }
}

const RentersContainer = ({queryParams, setQueryParams}) => {
    const [permissions, setPermissions]     = useState()
    const [filterVisible, setFilterVisible] = useState(false)
    const [rentersData, setRentersData]     = useSetState({
        fullCount: undefined,
        data: undefined
    })

    const setParam = (name, value) => setQueryParams({[name]: value})

    const {enqueueSnackbar} = useSnackbar()

    const [visibleColumns, setVisibleColumns] = useSetState({
        '#'         : {checked: true, label: '#', width: 50},
        name        : {checked: true, label: 'Название'},
        kindActivity: {checked: true, label: 'Вид деятельности'},
        inn         : {checked: true, label: 'ИНН'},
        manager     : {checked: true, label: 'Руководитель'},
        managerPhone: {checked: false, label: 'Телефон руководителя'},
        managerEmail: {checked: false, label: 'Email руководителя'},
        booker      : {checked: true, label: 'Бухгалтер'},
        bookerPhone : {checked: false, label: 'Телефон Бухгалтера'},
        bookerEmail : {checked: false, label: 'Email Бухгалтера'},
        edit        : {checked: true, label: "Редактирование"}

        // square: { checked: true, label: 'Площадь', headAlign: 'center', bodyAlign: 'right' },
        // sum: {checked: true, label: 'Сумма', headAlign: 'center', bodyAlign: 'right' },
        // contractStart: { checked:true, label: 'Начала действия Договора' },
        // contractEnd: { checked: true, label: 'Окончания действия Договора ' },
        // owner: { checked: true, label: 'Собственник' },

    })

    const getData = () => {
        renterService.rentersByPage(queryString.stringify(queryParams)).then(res => {
            setRentersData({
                fullCount: res.fullCount,
                data     : res.data,
                count    : res.elementCount,
                page     : Math.ceil(res.fullCount / res.elementCount) < res.page ? Math.ceil(res.fullCount / res.elementCount) : res.page
            })
        })
    }


    const deleteRow = id => () => {
        companyService.remove(id).then(_ => {
            enqueueSnackbar('Договор успешно удален', {variant: 'success'})
            getData()
        }, error => {
            enqueueSnackbar(error, {variant: 'error'})
        })
    }

    useEffect(() => {
        (async () => {
            setPermissions(await permissionsHelper.scopePermissions())
        })()
    }, [])

    useEffect(getData, [queryParams])

    // TODO: Подумать как загружать страничку при отсутствии данных
    if (!permissions) {
        return <LinearProgress/>
    }

    return (<>
        <div className="row">
            <div className="col">
                <Breadcrums
                    createPredicate={true}
                    h2="Арендаторы"
                    buttonName="Добавить арендатора"
                    href="renters/create"

                />
            </div>
        </div>
        <MDBCard>
            <MDBCardBody>
                <RentersFilters
                    {...{filterVisible, setFilterVisible, visibleColumns, setVisibleColumns}}
                />
                <RetnersTable
                    {...{
                        rentersData, filters: queryParams, visibleColumns, permissions, deleteRow
                    }}
                />
                <TablePagination
                    {...{
                        page     : rentersData.page,
                        count    : rentersData.count,
                        fullCount: rentersData.fullCount,
                        setCount : count => setParam('elementsCount', count),
                        setPage  : page => setParam('page', page)
                    }}
                />
            </MDBCardBody>
        </MDBCard>
    </>)
}

export default withQueryParams(defaultParams)(RentersContainer)

const breadcrumsChain = [['Главная', '/']]

const Breadcrums = props => {

    const breadCrumStyle = {
        fontSize: "0.8rem",
    };
    const history=useHistory ()

    const buttonName = props.buttonName;
    const header = props.h2;
    const chain = props.chain;
    const filterBtn = props.filterBtn;
    let itemsChain = [];
    let i = 0;
    breadcrumsChain.forEach (function (item) {
        i++;
        itemsChain.push (<MDBBreadcrumbItem key={i}>
            <Link to={item[1]}>{item[0]}</Link>
        </MDBBreadcrumbItem>);
    });
    itemsChain.push (<MDBBreadcrumbItem active key={0}>
        {header}
    </MDBBreadcrumbItem>);
    itemsChain.unshift (<MDBBreadcrumbItem key={i+1}>
        <span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span>
    </MDBBreadcrumbItem>);
    return (<>
        {itemsChain.length > 0 && (<MDBBreadcrumb style={breadCrumStyle}>{itemsChain}</MDBBreadcrumb>)}
        <MDBCard className="mb-30">
            <MDBCardBody id="breadcrumb" className="pa-16">
                <MDBRow between>
                    <MDBCol size="9" className="d-flex align-items-center">
                        {buttonName && props.createPredicate && (<Link to={props.href} className="mr-3">
                            <MDBBtn
                                floating
                                size="sm"
                                className="z-depth-1"
                                color="success"
                            >
                                <MDBIcon icon="plus"/>
                            </MDBBtn>
                        </Link>)}
                        <MDBCardTitle tag="h5" className="g-mb-0" style={{margin: 0}}>
                            {header}
                        </MDBCardTitle>
                    </MDBCol>
                    {filterBtn && (<MDBCol className="d-flex justify-content-end align-items-center">
                        {filterBtn}
                    </MDBCol>)}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>);
}
import React, {Component} from 'react';
import ChartSection1      from './ChartSection1';
import TableSection1      from "./TableSection1";
import AdminCardSection1  from "./AdminCardSection1";
import {reportService}    from "../../../../services/reportService";
import LinearProgress     from "@material-ui/core/LinearProgress";

class ReportsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        };

        reportService.getMainReport().then(_ => {
            this.setState({data: _})
        })

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.data !== nextState.data;
    }

    componentDidMount() {

    }

    render() {
        if (!this.state.data) {
            return (
                <LinearProgress/>
            )
        }
        let colors = [];
        this.state.data.reportByObjects.forEach(object => {
            let rnd = parseInt(Math.random() * (255 - 3) + 3);
            let rnd2 = parseInt(Math.random() * (255 - 3) + 3);
            let rnd3 = parseInt(Math.random() * (255 - 3) + 3);
            let color = "rgba(" + rnd + ", " + rnd2 + ", " + rnd3 + ", 0.6)";

            colors.push({
                id   : object.id,
                color: color
            });
        });

        return (

            <React.Fragment>
                <TableSection1 data={this.state}/>
                <AdminCardSection1 data={this.state} colors={colors}/>
                <ChartSection1 data={this.state} colors={colors}/>
            </React.Fragment>

        )
    }

}

export default ReportsPage;

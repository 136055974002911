export const roomState = {
    0: 'Не определен',
    1: 'Непригодное',
    2: 'Черновая',
    3: 'Требует ремонта',
    4: 'Удовлетворительное',
    5: 'Хорошее',
    6: 'Новое',
};
export const type1 = {
    0: 'Не определен',
    1: 'Полезное',
    2: 'Общего пользования',
    3: 'Техническое',
    4: 'Административное',
    5: 'Объект аренды'
};
export const type2 = {
    0: 'Торговое',// полезное
    1: 'Офисное',// полезное
    2: 'Склад',// полезное

    3: 'Лифт',// Общего пользования
    4: 'Лестничная клетка',// Общего пользования
    5: 'Коридор',// Общего пользования
    6: 'Тамбур',// Общего пользования
    7: 'Санузел',// Общего пользования
    8: 'Душ',// Общего пользования

    9 : 'Тепловой узел', //Техническое
    10: 'Венткамера', //Техническое
    11: 'Насосная', //Техническое
    12: 'Электрощитовая', //Техническое
    13: 'КУИ', //Техническое
    14: 'Тех.помещение', //Техническое

    15: 'Комната охраны', //административное
    16: 'Подсобная', //административное
    17: 'Офис', //административное

    18: 'Фасад здания', // объект аренды
    19: 'Рекламное место', // объект аренды
    20: 'Место на кровле', // объект аренды
    21: 'Стена', // объект аренды
    22: 'Парковочное место', // объект аренды
    23: 'Открытая площадка', // объект аренды
    24: 'Не определен', // не определен

};
export const type3 = {
    0: 'Не определен',
    1: 'В аренде',
    2: 'Свободно',
    3: 'Забронировано',
    4: 'Не сдается',
};

export const typesMatching = { // сопоставление Type1 и Type2
    0: [24],                          // Не определен
    1: [0, 1, 2],                     // Полезное
    2: [3, 4, 5, 6, 7, 8],            // Общего пользования
    3: [9, 10, 11, 12, 13, 14],       // Техническое
    4: [15, 16, 17],                  // Административное
    5: [18, 19, 20, 21, 22, 23, 24]   // Объект аренды
};

import React, {useCallback, useEffect, useState}                                              from 'react'
import withQueryParams                                                                        from 'react-router-query-params'
import {MDBBreadcrumb, MDBBreadcrumbItem, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow} from 'mdbreact'
import {LinearProgress}                                                                       from '@material-ui/core'
import {Link}                                                                                 from 'react-router-dom'
import FloorPlanner                                                                           from '../../floor-planner'
import {floorService}                                                                         from '../../../services/floorService'
import {getImageUrl}                                                                          from '../../../utils/Others'
import {ReactComponent as IconUp}                                                             from '../../../static/icons/i-up.svg'
import {useHistory}                                                                           from "react-router";
import {objectService}                                                                        from "../../../services/objectService";
import {buildingService}                                                                      from "../../../services/buildingService";

const EditFloor = ({match, queryParams}) => {
    const id = match.params.floorId
    const buildingId = match.params.buildingId
    const objectId   = match.params.objectId
    const [data, setData]           = useState()
    const [floor, setFloor]           = useState({})
    const [object, setObject]           = useState()
    const [building, setBuilding]           = useState()
    const [loading, setLoading]     = useState(true)
    const [rooms, setRooms]         = useState([])
    const [schema, setSchema]       = useState(undefined)
    const [room, setRoom]           = useState()
    const [roomsData, setRoomsData] = useState()
    const history    = useHistory()

    const onSave = useCallback(data => {
        floorService.savePlannerById(id, data)
    }, [id])

    const setActiveRoom = useCallback(id => {
        if (roomsData && id) {
            setRoom(roomsData.find(item => item.id === id))
        } else {
            setRoom(undefined)
        }
    }, [roomsData])

    const loadData = useCallback(async () => {
        try {
            setLoading(true)
            const data = await floorService.getById(id)
            setFloor(data)
            objectService.getById(objectId,'').then(_ => {
                setObject(_)
            })
            buildingService.getById(buildingId).then(_ => {
                setBuilding(_)
            })
            let rooms  = []
            if (data?.rooms.length) {
                setRoomsData(data.rooms)
                rooms = data.rooms.map(item => ({
                    id: item.id, square: item.square, name: item.name
                }))

            }
            if (queryParams.lastRoomId) {
                setRoom(data.rooms.find(item => item.id.toString() === queryParams.lastRoomId))
            }
            if (data?.images) {
                const {id, filePath} = data.images[0]
                setSchema({
                    id, url: getImageUrl(filePath)
                })
            }
            if (data.mapString) {
                const mapData = JSON.parse(data.mapString)
                if (Array.isArray(mapData)) {
                    setData(mapData.map(item => {
                        const room = rooms.find(i => i.id === item.id)
                        return room ? {...room, ...item} : item
                    }))
                } else {
                    throw new Error('Что то не так с данными схемы')
                }
            }
            setRooms(rooms)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [id, queryParams.lastRoomId])

    useEffect(() => {
        loadData()
    }, [loadData])

    return (<>
        {object && building && <BreadCrumbs history={history} object={object} building={building} floor={floor}/>}
            {loading ? (<LinearProgress/>) : (<MDBRow>
                    <MDBCol>
                        <MDBCard className="mb-3" >
                            <MDBCardBody
                                style={{
                                    padding: 20, display: 'flex', lineHeight: '28px', justifyContent: 'space-between'
                                }}
                            >
                                Редактирование схемы этажа
                                {room && (<div className="d-flex">
                                        <Link className="d-flex align-center" to={`/rooms/${room.id}`}>
                                            <span className="link-forward-icon"> <IconUp/></span>
                                            {room.name}
                                        </Link>
                                    </div>)}
                            </MDBCardBody>
                            <FloorPlanner
                                data={data}
                                onSave={onSave}
                                rooms={rooms}
                                floorId={id}
                                schema={schema}
                                setActiveRoom={setActiveRoom}
                            />
                        </MDBCard>
                    </MDBCol>
                </MDBRow>)}
        </>)
}

export default withQueryParams()(EditFloor)

const BreadCrumbs = ({object, building, floor, history}) => {
    console.log('src/components/containers/floors/edit.js:125', floor )
    const breadcrumbsChain = [['Главная', '/main'], ['Объекты', '/objects']]
    let itemsChain         = []
    let i                  = 0
    breadcrumbsChain.forEach(function (item) {
        i++
        itemsChain.push(<MDBBreadcrumbItem key={i}>
            <Link to={item[1]}>{item[0]}</Link>
        </MDBBreadcrumbItem>)
    })
    itemsChain.push(<MDBBreadcrumbItem key={34345}>
        <Link to={"/objects/" + object.id}>{object.name}</Link>
    </MDBBreadcrumbItem>)
    itemsChain.push(<MDBBreadcrumbItem active key={34346}>
        <Link to={`/objects/${object.id}/buildings/${building.id}`}>{building.corpName}</Link>
    </MDBBreadcrumbItem>)
    itemsChain.push(<MDBBreadcrumbItem key={34347}>
        <Link to={`/rooms?corpsId=${building.id}&elementsCount=100&floorNumbers=${floor.floorNumber}&madeFilters=All&objectsId=${object.id}&page=1`}>{floor.floorName}</Link>
    </MDBBreadcrumbItem>)
    itemsChain.unshift(<MDBBreadcrumbItem key={34348}>
        <span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span>
    </MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" style={{padding: 20}}>
                <MDBRow between>
                    <MDBCol size="6" className="d-flex align-items-center">
                        <Link to='/rooms/create' className='btn btn-outline-success btn-rounded floor-update-scheme ruonded-success-create-btn'>
                            <i className="fa fa-plus"/>
                        </Link>
                        <MDBCardTitle tag="h3" className="g-mb-0" style={{margin: 0}}>{`${building.corpName} - ${floor.floorName}`}</MDBCardTitle>
                    </MDBCol>
                    <MDBCol size="6" className="text-right room">
                        <h2 className="room_card_header_2">{object.name}</h2>
                        <h3 className="room_card_header_2">
                            <i className="fas fa-map-marker-alt mr-10"/> {object.cityName + ", " + object.streetName + ", " + object.houseNumber}
                        </h3>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}

import React, {Component} from 'react';
import {connect} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import BreadcrumSection from "../../commonSections/BreadcrumSection";
import AppealsPage from "./pages/AppealsPage";
import {userType} from "../../../utils/UserTypes";
import {permissionsHelper} from "../../../utils/permissionsHelper";

class AppealsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: []
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    async componentDidMount() {
        this.setState({permissions: await permissionsHelper.scopePermissions()})
    }

    render() {
        if (this.state.permissions.length === 0)
            return (<LinearProgress/>);

        let breadcrumsChain = [
            ['Главная', '/'],
        ];

        return (
            <React.Fragment>
                <BreadcrumSection
                    createPredicate={userType[this.props.currentUser.user.userType] === "renter"}
                    h2="Обращения арендаторов"
                    chain={breadcrumsChain}
                    buttonName="Обращение"
                    href="/appeals/create"
                />
                <AppealsPage
                    permissions={this.state.permissions}
                    currentUser={this.props.currentUser}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppealsContainer);

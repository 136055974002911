import React from 'react'
import { FilePond } from 'react-filepond'

const FileInput = ({value, onChange, label, styles}) =>
    <FilePond
        files={value}
        style={{cursor: "pointer", ...styles}}
        allowMultiple={true}
        labelIdle={label}
        onupdatefiles={fileItems => {
            onChange(fileItems.map(fileItem => fileItem.file))
        }}
    />

export default FileInput;
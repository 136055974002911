import React, { useState, useEffect } from 'react'
import { MDBCol, MDBTable, MDBTableBody, MDBTableHead, MDBAlert } from 'mdbreact'
import { LinearProgress } from '@material-ui/core'
import { contractService } from '../../../../services/contractService'
import { getQuantityString } from '../../../../utils/Others'
import {numberWithSpaces} from '../../../../helpers/NumberWithSpaces'

const AccrualRoomsTable = ({ contract, setAmount, setCount }) => {
    const [rooms, setRooms] = useState()
    const [sum, setSum] = useState(0)
    const [squareSum, setSquareSum] = useState(0)

    useEffect(() => {
        if (contract) {
            contractService.getById(contract.id).then(res => {
                setRooms(res.rooms)
                const sum = res.rooms.reduce((sum, room) => room.factuallyRents ? sum + room.factuallyRents : sum + room.amountRent, 0)
                const count = res.rooms.reduce((sum, room) => sum + room.square, 0)
                setAmount(sum)
                setSum(sum)
                setCount(Math.ceil(count))
                setSquareSum(count)
            })
        } else setRooms(undefined)
        // eslint-disable-next-line
    }, [contract])

    if (rooms && contract)
        return (
            <MDBCol size="sm-12">
                <MDBTable bordered hover>
                    <MDBTableHead>
                        <tr>
                            <th className="text-center">№</th>
                            <th className="text-center">Название</th>
                            <th className="text-center">Площадь</th>
                            <th className="text-center">Арендная ставка</th>
                            <th className="text-center">Фактическая аренда</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {rooms.map((room, i) => (
                            <tr key={'room-' + room.id}>
                                <td align="center">{i + 1}</td>
                                <td align="center">{room.name}</td>
                                <td align="center">{getQuantityString(room.square, 'м²')}</td>
                                <td align="center">{getQuantityString(room.factuallyRents ? room.factuallyRents/room.square : room.rents, 'р./м²')}</td>
                                <td align="center">{getQuantityString(room.factuallyRents ? room.factuallyRents : room.amountRent)}</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={2}>
                                <div className="h5 pr-5"><b>Сумма:</b></div>
                            </td>
                            <td align="center"><div className="h5 pr-5"><b>{numberWithSpaces(squareSum)} м²</b></div></td>
                            <td align="center"></td>
                            <td align="center"><div className="h5 pr-5"><b>{numberWithSpaces(sum)} р.</b></div></td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </MDBCol>
        )
    if (contract)
        return (
            <MDBCol size="sm-12">
                <LinearProgress />
            </MDBCol>
        )
    return (
        <MDBCol size="sm-12">
            <MDBAlert color="warning">Выберите договор</MDBAlert>
        </MDBCol>
    )
}

export default AccrualRoomsTable

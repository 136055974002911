
export const SET_IS_LOADING_BOOL = 'SET_IS_LOADING_BOOL';
export const setIsLoading = status => ({
  type   : SET_IS_LOADING_BOOL,
  payload: status
});

export const SET_CURRENT_URL_STRING = 'SET_CURRENT_URL_STRING';
export const setCurrentURL = status => ({
  type   : SET_CURRENT_URL_STRING,
  payload: status
});

import React, { useEffect, useReducer } from 'react'
import { MDBCol, MDBInput, MDBRow, MDBSelect } from 'mdbreact'
import AccrualRoomsTable from '../AccrualRoomsTable'
import { accrualType } from '../../../../../utils/AccrualType'
import { accrualUnit } from '../../../../../utils/AccrualUnit'
import { compareToNumber } from '../../../../../utils/Others'

function useSetState(initialState) {
    const [state, setState] = useReducer(
        (state, newState) => ({
            ...state,
            ...newState
        }),
        initialState
    )
    return [state, setState]
}

export default ({ state, selectAccrualType, setState, selectHandleChange, handleChange }) => {
    const [options, setOptions] = useSetState({
        accrualTypeOptions: [],
        unitsMeasureOptions: []
    })

    const generateAccTypeOptions = () => {
        let typeOptions = []
        for (let key in accrualType) {
            typeOptions.push({
                text: accrualType[key],
                value: key,
                checked: compareToNumber(key, state.accrualType)
            })
        }
        setOptions({ accrualTypeOptions: typeOptions })
    }

    const generateAccUnitOptions = () => {
        let unitsOptions = []
        for (let key in accrualUnit) {
            unitsOptions.push({
                text: accrualUnit[key],
                value: key,
                checked: compareToNumber(key, state.unitsMeasure)
            })
        }
        setOptions({ unitsMeasureOptions: unitsOptions })
    }

    useEffect(() => {
        generateAccTypeOptions()
        generateAccUnitOptions()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        // setState({price: "", count: "", amount: ""})
        if (Number(state.accrualType) === 2) {
            setOptions({ unitsMeasureOptions: [{ value: 4, text: '%', checked: true }] })
        } else if (options.unitsMeasureOptions.length !== 0) {
            generateAccUnitOptions()
        }
        //eslint-disable-next-line
    }, [state.accrualType])

    const setAmount = e => {
        const value = Number(e.target.value)
        setState({ price: value })
        if (state.count) {
            if (Number(state.accrualType) === 2) {
                setState({ amount: (Number(state.count) * value) / 100 })
            } else setState({ amount: Number(state.count) * value })
        }
    }

    const setCount = e => {
        const value = Number(e.target.value)
        setState({ count: value })
        if (state.price) {
            if (Number(state.accrualType) === 2) {
                setState({ amount: (Number(state.price) * value) / 100 })
            } else setState({ amount: Number(state.price) * value })
        }
    }

    const setAccrualType = e => {
        if (!compareToNumber(e, state.accrualType)) {
            setState({ count: '', amount: '', price: '' })
            selectAccrualType(e)
        }
    }

    return (
        <fieldset>
            <legend>Финансовая информация</legend>
            <MDBRow>
                <MDBCol size="sm-4">
                    <div className="grey-text">
                        <MDBSelect
                            name="accrualType"
                            options={options.accrualTypeOptions}
                            value={state.accrualType}
                            style={{ margin: 0 }}
                            selected="Выберите тип начисления"
                            label="Выберите тип начисления"
                            getValue={setAccrualType}
                            className="m-0"
                        />
                    </div>
                </MDBCol>
                {(state.accrualType && Number(state.accrualType) === 1 && (
                    <AccrualRoomsTable
                        contract={state.contract}
                        setAmount={amount => setState({ amount })}
                        setCount={count => setState({ count })}
                    />
                )) ||
                    ((state.accrualType || Number(state.accrualType) === 0) && (
                        <>
                            <MDBCol size="sm-4">
                                <div className="grey-text p-relative">
                                    <MDBInput
                                        name="count"
                                        label="Количество"
                                        group
                                        type="text"
                                        validate
                                        error="wrong"
                                        success="right"
                                        onChange={setCount}
                                        value={state.count}
                                        containerClass="m-0"
                                        className="m-0"
                                    />
                                   {Number(state.accrualType) === 2 && <div className='p-absolute' style={{position: 'absolute', top: 11, right: 15, color: 'black'}}> %</div>}

                                </div>
                            </MDBCol>
                            {Number(state.accrualType) !== 2 && <MDBCol size="sm-4">
                                <div className="grey-text">
                                    <MDBSelect
                                        name="unitsMeasure"
                                        style={{ margin: 0 }}
                                        value={state.unitsMeasure}
                                        getValue={e => selectHandleChange('unitsMeasure', e)}
                                        options={options.unitsMeasureOptions}
                                        selected="Единицы измерения"
                                        label="Единицы измерения"
                                        className="m-0"
                                        disabled={false}
                                    />
                                </div>
                            </MDBCol>}

                            <MDBCol size="sm-4">
                                <div className="grey-text">
                                    <MDBInput
                                        name="price"
                                        label={
                                            (Number(state.accrualType) === 2 && 'Доход') || 'Цена'
                                        }
                                        group
                                        type="number"
                                        validate
                                        error="wrong"
                                        success="right"
                                        onChange={setAmount}
                                        value={state.price}
                                        containerClass="m-0"
                                        className="m-0"
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol size="sm-4">
                                <div className="grey-text">
                                    <MDBInput
                                        name="amount"
                                        label="Сумма"
                                        group
                                        type="text"
                                        validate
                                        error="wrong"
                                        success="right"
                                        onChange={e => handleChange('amount', e)}
                                        value={state.amount}
                                        containerClass="m-0"
                                        className="m-0"
                                    />
                                </div>
                            </MDBCol>
                        </>
                    ))}
            </MDBRow>
        </fieldset>
    )
}

import React, { useEffect, useState } from 'react'
import { MDBCard, MDBCardBody } from 'mdbreact'
import { useSnackbar } from 'notistack'
import { LinearProgress } from '@material-ui/core'

import BreadcrumSection from '../../commonSections/BreadcrumSection'
import CompanyForm from '../../forms/company'
import { clean } from '../../../utils/Others'
import { companyService } from '../../../services/companyService'

const UpdateRenter = ({ match }) => {

    const breadcrumsChain = [
        ['Главная', '/main'],
        ['Арендаторы', '/renters']
    ]
    const { organisationId } = match.params
    const { enqueueSnackbar } = useSnackbar()
    const [renter, setRenter] = useState()

    const onSubmit = async (values) => {
        try {
            const model = clean({ ...defaultData, ...values })
            await companyService.update(organisationId, JSON.stringify(model))
            enqueueSnackbar('Арендатор успешно обновлен', {
                variant: 'success'
            })
        } catch (err) {
            enqueueSnackbar('Ошибка при обновлении', {
                variant: 'error'
            })
        }
    }

    useEffect(() => {
        if (organisationId) {
            companyService.getById(organisationId).then(_ => {
                const renter = {}
                Object.keys(_).forEach(key => {
                    renter[key] =_[key] || '';
                })
                setRenter(renter)
            })
        }
    }, [organisationId])

    return <>
        <BreadcrumSection
            h2={'Редактирование собсвтенника'}
            chain={breadcrumsChain}
        />
        {renter
            ? <MDBCard>
                <MDBCardBody>
                    <CompanyForm values={renter} onSubmit={onSubmit}/>
                </MDBCardBody>
            </MDBCard>
            : <LinearProgress/>}
    </>
}

export default UpdateRenter

const defaultData = {
    fullName: '',
    shortName: '',
    legalAddress: '',
    actualAddress: '',
    mailingAddress: '',
    phone: '',
    fax: '',
    email: '',
    site: '',
    head: '',
    phoneHead: '',
    chiefAccountant: '',
    phoneChiefAccountant: '',
    createDate: new Date().toISOString().substr(0, 10),
    certificateEgpul: '',
    certificateDate: new Date().toISOString().substr(0, 10),
    certificateNumber: '',
    certificateIssued: '',
    certificateOgrn: '',
    inn: '',
    kpp: '',
    okpo: '',
    kindActivity: '',
    checkingAccount: '',
    correspondentAccount: '',
    bank: '',
    bik: '',
    okogu: '',
    okato: '',
    okved: '',
    fullNameContract: ''
}
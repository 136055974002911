import React from 'react'
import IncomeUpdateSection from "../sections/update/IncomeUpdateSection";
import BreadcrumSection from "../../../commonSections/BreadcrumSection";

const IncomeUpdatePage = () => {
    let breadcrumsChain = [
        ['Главная', '/'],
        ['Поступления', '/income']
    ]
    return <>
        <BreadcrumSection
            createPredicate={false}
            h2="Редактирование поступления"
            chain={breadcrumsChain}
            buttonName="Поступление"
            href="appeal/create"
        />
        <IncomeUpdateSection/></>
}
export default IncomeUpdatePage;
import { handleResponse } from "../helpers";
import { apiUrl } from "../utils/ConnectionStrings";

export const objectService = {
  create,
  update,
  remove,
  getAll,
  getById,
  uploadPhotos,
  createCorp,
  updateCorp,
  deleteCorp
};

function create(objectModel) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
    body: objectModel,
  };
  return fetch(apiUrl + "objects/", requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    })
    .then(getAll);
}

function update(id, objectModel) {
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
    body: objectModel,
  };
  return fetch(apiUrl + "objects/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    })
    .then(getAll);
}

function createCorp(id, objectModel) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
    body: objectModel,
  };
  return fetch(apiUrl + "corps/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function updateCorp(id, objectModel) {
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
    body: objectModel,
  };
  return fetch(apiUrl + "corps/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function deleteCorp (id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
    body: "",
  };
  return fetch(apiUrl + "corps/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}


function uploadPhotos(id, files) {
  const formData = new FormData();
  Array.from(files).forEach((_) => formData.append("body", _));
  const uploadRequestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
    body: formData,
  };
  return fetch(apiUrl + "objects/upload-images/" + id, uploadRequestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function getById(id, params) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    ...params,
  };
  return fetch(apiUrl + "objects/" + id, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function remove(objectId) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  };

  return fetch(apiUrl + "objects/" + objectId, requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  };
  return fetch(apiUrl + "objects/", requestOptions)
    .then(handleResponse)
    .then((_) => {
      return _;
    });
}

import React                from 'react';
import {connect}            from "react-redux";
import {setIsCreated}       from "../../../store/company/actions";
import AccrualsListPage     from "./pages/AccrualsListPage";

class AccrualListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: []
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    render() {
        return <AccrualsListPage/>
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        isAdmin    : state.user.isAdmin,
    };
};

const mapDispatchToProps = {
    setIsCreated
};

export default connect(mapStateToProps, mapDispatchToProps)(AccrualListContainer);

import React, {useEffect, useMemo, useState}                                    from 'react';
import {MDBBreadcrumb, MDBBreadcrumbItem, MDBCard, MDBCardBody, MDBCol, MDBRow} from "mdbreact";
import {Link}                                                                   from "react-router-dom";
import {useHistory}                                                             from "react-router";
import {groupService}                                                           from "../../../services/groupService";
import {useSetState}                                                            from "../../others/customHooks";
import SmartTable                                                               from "../../commonSections/SmartTable";

import Indicator                                                                from "../../others/Indicator";
import indicatorColor                                                           from "../../../utils/indicatorColor";
import {getQuantityString}                                                      from "../../../utils/Others";
import {roomState, type1, type2}                                                from "../../../utils/RoomTypes";
import EditBlockDropdown                                                        from "../../others/EditBlockDropdown";


const GroupedRoomsPageContainer = () => {
    const history = useHistory ()
    const [groups, setGroups] = useState (null)
    const [visibleColumns, setVisibleColumns] = useSetState (initialVisibleColumns)
    const [total, setTotal] = useState({maxSquareValue: 0})



    useEffect (() => {
            groupService.getAll ().then (_ => {
                const total = {maxSquareValue: 0}
                _.forEach(group => {
                    total.maxSquareValue += group?.maxSquareValue
                })
                setGroups (_)
                setTotal (total)
            })
        },
        [])
    console.log ('grouped_rooms/GroupedRoomsPageContainer.js:14', groups)

    return (<>

        <BreadCrumbs history={history} groups={groups}/>
        {groups?.length>0 && <GroupedRoomsTable groups={groups} visibleColumns={visibleColumns}/>}

    </>);
};

export default GroupedRoomsPageContainer;

const GroupedRoomsTable = ({
                               groups,
                               visibleColumns

                           }) => {
    console.log('GroupedRoomsPageContainer.js:52', groups)
    const List = ({groups}) => groups.map(g => <li>{`${g.name} - ${g.description} - ${g.maxSquareValue}`}</li>)
    //TODO: Заменить простой список умной таблицей SmartTable с фильтрами, с поиском определённого помещения, с группировкой данных по помещениям в паспорте БТИ
    return (<>
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <ul className="list-unstyled">
                            <List groups={groups} />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/*<SmartTable {...{data, visibleColumns, isSortable: true}} />*/}

    </>);
};


const BreadCrumbs = ({history, groups}) => {
    const breadcrumbsChain = [['Главная', '/main']]
    let itemsChain = []
    let i = 0
    breadcrumbsChain.forEach (function (item) {
        i++
        itemsChain.push (<MDBBreadcrumbItem key={i}>
            <Link to={item[1]}>{item[0]}</Link>
        </MDBBreadcrumbItem>)
    })
    itemsChain.unshift (<MDBBreadcrumbItem key={321}>
        <span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span>
    </MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" style={{padding: 20}}>
                <MDBRow between>
                    <MDBCol className="d-flex">
                        <div className='page-title 2rows'>
                            <h4 className='mb-0'>Помещения по поспорту БТИ</h4>
                        </div>
                    </MDBCol>
                    {/* TODO: Сделать вывод итоговой информации по помещениям из паспорта БТИ */}
                    {groups?.fullCount && <MDBCol className="d-flex justify-content-end">
                        <div className='page-title 2rows'>
                            <h5 className='mb-0'>{`${groups?.count >= groups?.fullCount ? groups?.fullCount : groups?.count} из ${groups?.fullCount} объектов аренды`}</h5>
                        </div>
                    </MDBCol>}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}
const initialVisibleColumns = {
    '#'           : {checked: true, label: '#', width: 50},
    name          : {checked: true, label: 'Наименование помещения', sortName: 'name'},
    maxSquareValue: {checked: true, label: 'Площадь по паспорту'},
    description   : {checked: false, label: 'Описание'},
    edit          : {checked: false, label: 'Редактирование', color: 'red'}
}

const mapRows = (data, page, elementsCount, permissions, deleteRooms, total) => {
    const rows = data.map((room, index) => ({
            '#':<div className="d-flex justify-content-center align-items-center" style={{position: 'absolute', left: 0, top: 0, height: '100%', width: '100%'}}>
                {(Number(page) - 1) * Number(elementsCount) + index + 1}
                <Indicator color={(room.contract && room.contract.entityStatus === 0) ? indicatorColor(room.contract.completionDateTime) : 'grey'}/>
            </div>,
            name:(<Link to={`/rooms/${room.id}`}>{room.name}</Link>),
            objectCorp              : <>
                <Link to={`/objects/${room.objectId}`}>
                    {room.objectName}
                </Link> /
                {' '}<Link to={`/objects/${room.objectId}/buildings/${room.corpId}`}>
                {room.corpName}
            </Link>
            </>,
            floor                   : room.floorName,
            square                  : getQuantityString(room.square, 'м²'),
            sum                     : `${getQuantityString(room.amountRent)}${room.factuallyRents ? ` > ${getQuantityString(room.factuallyRents)}` : ''}`,
            rent                    : room.factuallyRents ? getQuantityString(room.factuallyRents/room.square, 'р./м²') : getQuantityString(room.rents, 'р./м²'),
            state                   : roomState[Number(room.roomState)],
            owner                   : room.owners.length ? room.owners.map((owner, i) => {
                const isLast = room.owners.length - 1 === i;
                return <React.Fragment key={`owner-${owner.id}`}>
                    <Link to={`/owners/${owner.id}`}>{owner.name}</Link>
                    {!isLast && <>, </>}
                </React.Fragment>
            }) : '-',
            kindActivity            : room.kindActivity,
            renter                  : room.renter && <Link to={`/renters/${room.renter.id}`}>{room.renter.name}</Link>,
            type1                   : type1[Number(room.type1)],
            type2                   : type2[Number(room.type2)],
            edit                    : (<EditBlockDropdown
                relative
                permissions={permissions}
                detailsLink={`/rooms/${room.id}`}
                editLink={`/rooms/update/${room.id}`}
                deleteLabel={`Вы действительно хотите удалить объект аренды ${room.name}?`}
                deleteFunc={deleteRooms(room.id)}
            />)
        })
    )
    rows.push({
        '#': null,
        name: null,
        objectCorp: null,
        floor: null,
        square: <><span className='rental-object-list total-row-of-table total-square'>{getQuantityString(total.totalSquare, 'м²')}</span></>,
        sum: <><span className='rental-object-list total-row-of-table total-factually-rents'>{getQuantityString(total.totalFactuallyRents, 'р.')}</span></>,
        rent: null,
        state: null,
        owner: null,
        kindActivity: null,
        renter: null,
        type1: null,
        type2: null,
        edit: null,
    })
    return rows
}

import React, {Component}  from 'react';
import {connect}           from "react-redux";
import LinearProgress      from "@material-ui/core/LinearProgress";
import {employeeService}   from "../../../services/employeeService";
import {roleService}       from "../../../services/roleService";
import UsersManagePage     from "./pages/UsersManage/UsersManagePage";
import CreateUserPage      from "./pages/UsersManage/CreateUserPage";
import {permissionsHelper} from "../../../utils/permissionsHelper";

class UsersManageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users        : [],
            roles        : [],
            isCreateMode : false,
            isUpdateMode : false,
            updatableUser: null,
            permissions  : []
        };
        this.setUpdateMode = this.setUpdateMode.bind(this);
        this.setCreateMode = this.setCreateMode.bind(this);
        this.getAllEmployees = this.getAllEmployees.bind(this);
        this.getAllRoles = this.getAllRoles.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    async getAllEmployees() {
        await employeeService.getAll().then(_ => {
            this.setState({users: _})
        })
    }

    async getAllRoles() {
        await roleService.getAll().then(_ => {
            this.setState({roles: _})
        })
    }

    async componentDidMount() {
        this.getAllEmployees();
        this.getAllRoles();
        this.setState({permissions: await permissionsHelper.scopePermissions()})
    }

    setUpdateMode(bool, user) {
        this.setState({
            isUpdateMode : bool,
            updatableUser: bool ? user : null
        });
    }

    setCreateMode(bool) {
        this.setState({
            isCreateMode: bool,
        });
    }

    render() {
        if (!this.state.users || !this.state.roles || this.state.permissions.length === 0) return (<LinearProgress/>);
        return (
            <React.Fragment>
                {!this.state.isCreateMode &&
                <UsersManagePage
                    permissions={this.state.permissions}
                    setCreateMode={this.setCreateMode}
                    getAllEmployees={this.getAllEmployees}
                    roles={this.state.roles}
                    users={this.state.users}/>}

                {this.state.isCreateMode &&
                <CreateUserPage
                    setCreateMode={this.setCreateMode}
                    getAllEmployees={this.getAllEmployees}/>}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UsersManageContainer);

import React, {Component}                                                                          from 'react';
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {numberWithSpaces}                                                                          from "../../../../helpers/NumberWithSpaces";
import {Link}                                                                                      from "react-router-dom";
import moment                                                                                      from "moment";

class EndingContractsTable extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.contracts !== nextProps.contracts;
    }

    render() {
        let count = 0;
        const rows = this.props.contracts.map(_ => {
            count++;
            const elapsed = moment(_.completionDateTime).format("DD.MM.YYYY");
            return (<tr key={_.id}>
                <td>{count}</td>
                <td><strong><Link to={"/contracts/" + _.id}>{_.name}</Link></strong>{_.owner && <><br /><Link to={"/owners/" + _.owner.id}><span>{_.owner.shortName}</span></Link></>}</td>
                <td>{elapsed}</td>
                {_.company && <td><Link to={"/company/" + _.company.id}>{_.company.shortName}</Link></td>}
                {_.company && <td>{numberWithSpaces(_.company.cash.toFixed(2)) + "р."}</td>}
            </tr>);
        });


        return (<MDBRow className="mb-4">
            <MDBCol md="12">
                <MDBCard>
                    <MDBCardHeader className="dashboardCardHeader">
                        Ближайшие выезды арендаторов
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBTable hover>
                            <MDBTableHead>
                                <tr>
                                    <th>№</th>
                                    <th>Договор</th>
                                    <th>Срок</th>
                                    <th>Арендатор</th>
                                    <th>Баланс</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {rows}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>)
    }
}

export default EndingContractsTable;


import {handleResponse} from '../helpers';
import {apiUrl} from "../utils/ConnectionStrings";

export const incomeService = {
    create,
    update,
    remove,
    getAll,
    getById,
    getByOrganization
};

function create(model) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: model
    };

    return fetch(apiUrl + "income/", requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function update(id, model) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: model
    };
    return fetch(apiUrl + "income/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        }).then(getAll);
}


function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "income/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "income/", requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "income/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getByOrganization(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "income/get-by-company-id/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}



import React from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol
} from "mdbreact";
import DefaultImage from "../../../../static/defaultProfile.jpg";
//import Lightbox from "react-image-lightbox";


class ProfileSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            images: []
        }
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol key={photoIndex}>
                    <figure>
                        <img
                            src={imageSrc}
                            alt="Gallery"
                            className="img-fluid"
                            onClick={() =>
                                this.setState({photoIndex: privateKey, isOpen: true})
                            }
                        />
                    </figure>
                </MDBCol>
            );
        })
    };


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.currentUser !== nextProps.currentUser ||
            this.props.selectedUser !== nextProps.selectedUser ||
            this.state !== nextState;
    }
    render() {
        const {photoIndex, isOpen, images} = this.state;
        let user = this.props.selectedUser;
        const surname = user.user.surname ? user.user.surname : ""
        const name = user.user.name ? user.user.name : ""
        const patronymic = user.user.patronymic ? user.user.patronymic : ""
        const textnameUser = surname + ' ' + name + ' ' + patronymic
        let imageUrl;
        if (user.user.profileImage !== null)
            imageUrl = "https://" + user.user.profileImage.split("/var/www/rentto/data/www/")[1];
        else
            imageUrl = DefaultImage;

        if (!this.state.images.some(_ => _ === imageUrl))
            this.setState({images: [imageUrl]});

        return (
            <React.Fragment>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <div>
                                <MDBRow>
                                    {this.renderImages()}
                                </MDBRow>
                            </div>
                            {/*{isOpen && TODO: Надо подумать что тут делать(*/}
                            {/*    <Lightbox*/}
                            {/*        mainSrc={images[photoIndex]}*/}
                            {/*        nextSrc={images[(photoIndex + 1) % images.length]}*/}
                            {/*        prevSrc={images[(photoIndex + images.length - 1) % images.length]}*/}
                            {/*        imageTitle={photoIndex + 1 + "/" + images.length}*/}
                            {/*        onCloseRequest={() => this.setState({isOpen: false})}*/}
                            {/*        onMovePrevRequest={() =>*/}
                            {/*            this.setState({*/}
                            {/*                photoIndex: (photoIndex + images.length - 1) % images.length*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*        onMoveNextRequest={() =>*/}
                            {/*            this.setState({*/}
                            {/*                photoIndex: (photoIndex + 1) % images.length*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    />*/}
                            {/*)}*/}
                            <MDBCardBody>
                                <MDBCardTitle className="text-center">{textnameUser}
                                    <MDBCardText>
                                        <React.Fragment>{user.position}</React.Fragment>
                                    </MDBCardText>
                                </MDBCardTitle>
                                <hr className="hr-dark"/>
                                <div>
                                    <MDBRow>
                                        <MDBCol size="6">
                                            <p className="g-mb-0">Телефон:</p>
                                            <p className="g-mb-0 text-muted">{user.user.phone}</p>
                                        </MDBCol>
                                        <MDBCol size="6">
                                            <p className="g-mb-0">Мобильный:</p>
                                            <p className="g-mb-0 text-muted">{user.user.mobilePhone}</p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="g-mt-15">
                                        <MDBCol size="6">
                                            <p className="g-mb-0">Email:</p>
                                            <p className="g-mb-0 text-muted">{user.user.userName}</p>
                                        </MDBCol>
                                        <MDBCol size="6">
                                            <p className="g-mb-0">Дата рождения:</p>
                                            <p className="g-mb-0 text-muted">{new Date(user.user.birthDate).toLocaleString("ru", {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}</p>
                                        </MDBCol>
                                    </MDBRow>
                                </div>

                                <hr className="hr-bold"/>
                                <MDBCardText>
                                    <React.Fragment>
                                        Зарегистрирован с {new Date(user.user.registrationDate).toLocaleString("ru", {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                    </React.Fragment>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

            </React.Fragment>
        )
            ;
    }
}


export default ProfileSection;

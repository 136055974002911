import {
  REGISTRATION_CHANGE_LOGIN,
  REGISTRATION_CHANGE_PASSWORD,
  REGISTRATION_CHANGE_NAME,
  REGISTRATION_CHANGE_SURNAME,
  REGISTRATION_CHANGE_PATRONYMIC,
  REGISTRATION_CHANGE_BIRTHDATE,
  REGISTRATION_CHANGE_PHONE,
  REGISTRATION_CHANGE_MOBILEPHONE,
  REGISTRATION_SUBMIT_EVENT,
  REGISTRATION_CHANGE_USER,

} from './actions';

//  логин         - login
//  пароль        - password
//  имя           - name
//  фамилия       - surname
//  отчество      - patronymic
//  дата рождения - birthDate
//  телефон       - phone
//  мобильный     - mobilePhone

const defaultState = {
  login       : '',
  password    : '',
  name        : '',
  surname     : '',
  patronymic  : '',
  birthDate   : '',
  phone       : '',
  mobilePhone : '',
  user: '',
};

export const registrationReducer =(state = defaultState, action) => {
  switch (action.type) {
    case REGISTRATION_CHANGE_LOGIN:
      return {...state, login: action.payload};

    case REGISTRATION_CHANGE_PASSWORD:
      return {...state, password: action.payload};

    case REGISTRATION_CHANGE_NAME:
      return {...state, name: action.payload};

    case REGISTRATION_CHANGE_SURNAME:
      return {...state, surname: action.payload};

    case REGISTRATION_CHANGE_PATRONYMIC:
      return {...state, patronymic: action.payload};

    case REGISTRATION_CHANGE_BIRTHDATE:
      return {...state, birthDate: action.payload};

    case REGISTRATION_CHANGE_PHONE:
      return {...state, phone: action.payload};

    case REGISTRATION_CHANGE_MOBILEPHONE:
      return {...state, mobilePhone: action.payload};

    case REGISTRATION_SUBMIT_EVENT:
      return {...state, user: action.payload};

    case REGISTRATION_CHANGE_USER:
      return {...state, user: action.payload};

      default: return state;
  }
};
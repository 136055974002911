import React, {Component} from 'react';
import '../App.css';
import SideNavigation     from './SideNavigation';
import TopNavigation      from './TopNavigation';
import Copyrights         from './Footer';
import Routes             from './Routes';
import {SnackbarProvider} from "notistack";
import MainRoutes         from "../routes/MainRoutes";

class RoutesWithNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            windowWidth: 0,
            currentPage: '',
            sideNavToggled: false,
            breakWidth: 1400
        };

        if (!props.currentUser) {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser) {
                this.props.setCurrentUser(currentUser);
                props.setIsLoggedIn(true);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props !== nextProps ||
            this.state.sideNavToggled !== nextState.sideNavToggled ||
            this.state.currentPage !== nextState.currentPage;
    }

    componentDidUpdate(prevProps, nextProps, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.assessLocation(this.props.location.pathname);
        }
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.assessLocation(this.props.location.pathname);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth
        });
    };

    toggleSideNav = () => {
        if (this.state.windowWidth < this.state.breakWidth) {
            this.setState({
                sideNavToggled: !this.state.sideNavToggled
            });
        }
    };

    assessLocation = location => {
        let locationString;
        switch (location) {
            case '/main':
                locationString = 'Главная страница';
                break;
            case '/objects':
                locationString = 'Управление объектами';
                break;
            case '/renters':
                locationString = 'Управление арендаторами';
                break;
            case '/employees':
                locationString = 'Управление сотрудниками';
                break;
            case '/accruals':
                locationString = 'Управление начислениями';
                break;

            case '/contract':
                locationString = 'Управление договорами';
                break;
            case '/appeals':
                locationString = 'Управление обращениями арендаторов';
                break;
            case '/events':
                locationString = 'Управление мероприятиями';
                break;
            case '/reports':
                locationString = 'Управление отчетами';
                break;
            case '/admin-panel':
                locationString = 'Администрирование';
                break;
            case '/profile':
                locationString = 'Личный кабинет';
                break;
            case '/floor':
                locationString = 'Этажи';
                break;
            case '/help':
                locationString = 'Помощь';
                break;


            default:
        }
        this.setState({
            currentPage: locationString
        });
    };

    render() {
        const showNav = this.state.windowWidth > this.state.breakWidth;

        const {currentUser} = this.props;

        return (
            <div className='app'>
                {this.props.isLoggedIn ?
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}>
                        <div className='white-skin'>
                            <SideNavigation
                                currentUser={currentUser}
                                breakWidth={this.state.breakWidth}
                                style={{transition: 'all .3s'}}
                                triggerOpening={this.state.sideNavToggled}
                                onLinkClick={() => this.toggleSideNav()}
                                logout={this.props.logout}
                            />
                        </div>
                        <div className='flexible-content white-skin navbar-container'>
                            <TopNavigation
                                currentUser={this.props.currentUser}
                                logout={this.props.logout}
                                toggle={!showNav}
                                onSideNavToggleClick={this.toggleSideNav}
                                routeName={this.state.currentPage}
                                className='white-skin'
                            />
                        </div>
                        <main id="content" className="main-routes">
                            <Routes isLoggedIn={this.props.isLoggedIn} onChange={() => this.assessLocation()}/>
                        </main>
                        <Copyrights
                            className='d-none d-lg-block main-copyright'
                        />
                    </SnackbarProvider>
                    :
                    //if is not login
                    <main>
                        <MainRoutes isLoggedIn={this.props.isLoggedIn}/>
                    </main>}
            </div>
        );
    }
}

export default RoutesWithNavigation;

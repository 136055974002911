import React from 'react';
import {connect} from "react-redux";
import EmployeesPage from "./pages/EmployeesPage";
import {setAllUsers} from "../../../store/users/actions";
import {setCurrentUser} from "../../../store/users/user/actions";
import {employeeService} from "../../../services/employeeService";

class EmployeesContainer extends React.Component {

    componentDidMount() {
        employeeService.getAll(this.props.token).then(_ => {
            this.props.setAllUsers(_);
            return true;
        }, error => {
            console.log(error);
        });
    }

    render() {
        return (
            <EmployeesPage
                allUsers={this.props.allUsers}
                currentUser={this.props.currentUser}
                setCurrentUser={this.props.setCurrentUser}
                usersListFilter={this.props.usersListFilter}
            />

        );
    }

}

const mapStateToProps = state => {
    return {
        allUsers: state.users.allUsers,
        usersListFilter: state.users.usersListFilter,
        token: state.user.currentUser.token,
        currentUser: state.user.currentUser
    };
};

const mapDispatchToProps = {
    setAllUsers,
    setCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesContainer);

import {handleResponse} from '../helpers';
import {apiUrl} from "../utils/ConnectionStrings";


export const appealService = {
    create,
    setStatus,
    remove,
    getAll,
    getById,
    uploadImages
};

function create(appealModel) {
    const requestOptions = {
        method : 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type' : 'application/json'
        },
        body   : appealModel
    };

    return fetch(apiUrl + "appeals/", requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function setStatus(id, status) {
    const requestOptions = {
        method : 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type' : 'application/json'
        },
        body   : status
    };
    return fetch(apiUrl + "appeals/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}


function remove(id) {
    const requestOptions = {
        method : 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type' : 'application/json'
        },
    };
    return fetch(apiUrl + "appeals/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getAll() {
    const requestOptions = {
        method : 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type' : 'application/json'
        },
    };
    return fetch(apiUrl + "appeals/", requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

function getById(id) {
    const requestOptions = {
        method : 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type' : 'application/json'
        },
    };
    return fetch(apiUrl + "appeals/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

function uploadImages(id, files) {
    const formData = new FormData();
    Array.from(files).forEach(_ => formData.append('body', _));
    const uploadRequestOptions = {
        method : 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept'       : 'application/json'
        },
        body   : formData
    };
    return fetch(apiUrl + 'appeals/upload-images/' + id, uploadRequestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        }).then(getAll);
}


import React            from 'react';
import {MDBCol, MDBRow} from "mdbreact";


function FloorsHelp ({}) {
    return (<>

        <MDBRow>
            <MDBCol size="12">
                <h1>Этажи</h1>
            </MDBCol>
        </MDBRow>

    </>);
}

export default FloorsHelp
import React, {Component} from 'react';
import {setAllCompanies, setIsCreated} from "../../../store/company/actions";
import {connect} from "react-redux";
import {MDBCard, MDBCardBody, MDBCardHeader,} from "mdbreact";
import BreadcrumSection from "../../commonSections/BreadcrumSection";
import EmployeeCreateFormSection from "./pages/EmployeeCreateFormSection";

class EmployeeCreateContainer extends Component {

    render() {
        let breadcrumsChain = [
            ['Главная', '/main'],
            ['Пользователи', '/employees'],
        ];

        return (
            <React.Fragment>
                <BreadcrumSection h2="Добавление сотрудников" chain={breadcrumsChain}/>
                <MDBCard>
                    <MDBCardHeader color="primary-color" tag="h4" className="g-px-30 g-py-15">
                        Добавление сотрудников
                    </MDBCardHeader>
                    <MDBCardBody>
                        <EmployeeCreateFormSection
                            isCreated={this.props.isCreated}
                            setAllCompanies={this.props.setAllCompanies}
                            setIsCreated={this.props.setIsCreated}
                        />
                    </MDBCardBody>
                </MDBCard>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isCreated: state.company.isCreated,
        token: state.user.token,
    };
};

const mapDispatchToProps = {
    setAllCompanies,
    setIsCreated,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCreateContainer);

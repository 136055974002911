import React, { useEffect, useState }           from 'react'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import { Link }                                 from 'react-router-dom'
import { objectService }                        from '../../../../services/objectService'
import { floorService }                         from '../../../../services/floorService'
import { getQuantityString }                    from '../../../../utils/Others'

const ContractRoomsTable = ({ rooms }) => {
    return (
        <>
            <div>Помещения</div>
            <MDBTable small>
                <MDBTableHead>
                    <tr>
                        <th>#</th>
                        <th>Название</th>
                        <th>ID</th>
                        <th>Объект</th>
                        <th style={{ textAlign: 'center' }}>Этаж</th>
                        <th>Площадь</th>
                        <th>Цена</th>
                        <th>Сумма аренды</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {rooms.map((room, index) => (
                        <Row key={'contract-page-room-' + room.id} room={room} index={index} />
                    ))}
                </MDBTableBody>
            </MDBTable>
        </>
    )
}
export default ContractRoomsTable

const Row = ({ room, index }) => {
    const [object, setObject] = useState()
    const [floor, setFloor] = useState()

    useEffect(() => {
        objectService.getById(room.objectId).then(res => setObject(res))
        floorService.getById(room.floorId).then(res => setFloor(res))
        //eslint-disable-next-line
    }, [])

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                <Link to={`/rooms/${room.id}`}>
                    {room.name}
                </Link>
            </td>
            <td>{room.id}</td>
            <td width={200}>
                {(object && <Link to={'/objects/' + object.id}>{object.name}</Link>) || '...'}
            </td>
            <td width={130} align="center">
                {(floor && floor.floorNumber) || '...'}
            </td>
            <td>{getQuantityString(room.square, 'м²')}</td>
            <td>{getQuantityString(room.factuallyRents ? room.factuallyRents/room.square : room.rents, 'р./м²')}</td>
            <td>{getQuantityString(room.factuallyRents ? room.factuallyRents : room.amountRent )}</td>
        </tr>
    )
}

import React, {Component} from 'react'
import {MDBBtn, MDBCol, MDBInput, MDBModalFooter, MDBRow,} from 'mdbreact';
import CircularProgress from "@material-ui/core/CircularProgress";
import {buildingService} from "../../../../services/buildingService";
import {Redirect} from "react-router";
import {FilePond} from "react-filepond";
import {withSnackbar} from "notistack";

class CreateBuildingFormSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            corpName: props.building ? props.building.corpName : "",
            coordinates: props.building ? props.building.coordinates : "",
            files: [],
            isLoading: false,
            isRedirect: false
        };
    }

    handleChange(name, event) {
        this.setState({[name]: event.target.value})
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    buildFormData = () => {
        let formData = new FormData();

        formData.append("CorpName", this.state.corpName);
        formData.append("Coordinates", this.state.coordinates);

        Array.from(this.state.files).forEach(_ => formData.append('Images', _));
        return formData;
    };

    async buildingCreate(e) {
        let formData = this.buildFormData();
        this.setState({isLoading: true});
        await buildingService.create(this.props.objectId, formData).then(_ => {
            this.props.enqueueSnackbar('Корпус успешно создан', {variant: 'success'});
            this.props.setAllObjects(_);
            this.setState({
                isRedirect: true,
                isLoading: false
            });
        });
    }

    async buildingUpdate(e) {
        let formData = this.buildFormData();
        this.setState({isLoading: true});
        await buildingService.update(this.props.building.id, formData).then(_ => {
            this.props.enqueueSnackbar('Корпус успешно обновлен', {variant: 'success'});
            this.props.setAllObjects(_);
            this.setState({
                isRedirect: true,
                isLoading: false
            });
        });
    }

    render() {

        if (this.state.isRedirect)
            return (<Redirect to={"/objects/" + this.props.objectId}/>);

        return (
            <React.Fragment>
                <MDBRow className="justify-content-between d-flex">

                    <MDBCol size="sm-6">
                        <div className="grey-text">
                            <MDBInput
                                name="CorpName"
                                label="Название корпуса"
                                group
                                type="text"
                                validate
                                error="wrong"
                                success="right"
                                onChange={(e) => this.handleChange("corpName", e)}
                                value={this.state.corpName}
                            />
                        </div>
                        <div className="grey-text">
                            <MDBInput
                                name="Coordinates"
                                label="Корпус"
                                group
                                type="text"
                                validate
                                error="wrong"
                                success="right"
                                onChange={(e) => this.handleChange("coordinates", e)}
                                value={this.state.coordinates}
                            />
                        </div>
                    </MDBCol>
                    <MDBCol middle>
                        <FilePond
                            files={this.state.files}
                            allowMultiple={true}
                            maxFiles={5}
                            labelIdle="Перетащите сюда нужные фотографии, не больше 5, либо нажмите на это окошко"
                            onupdatefiles={fileItems => {
                                this.setState({
                                    files: fileItems.map(fileItem => fileItem.file)
                                });
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                <div className="text-center">
                    <MDBModalFooter>
                        {this.state.isLoading && <CircularProgress size={30}/>}
                        <MDBBtn
                            outline
                            color="blue-grey"
                            disabled={this.state.isLoading}
                            onClick={() => {
                                this.setState({isRedirect: true})
                            }}>
                            Назад
                        </MDBBtn>
                        <MDBBtn
                            disabled={this.state.isLoading}
                            outline
                            onClick={(e) => this.props.building ? this.buildingUpdate(e) : this.buildingCreate(e)}
                            color="success">
                            Сохранить
                        </MDBBtn>
                    </MDBModalFooter>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(CreateBuildingFormSection);

import React, {useMemo} from 'react'
import {
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBDataTable,
    MDBIcon,
    MDBRow,
    MDBTooltip
} from 'mdbreact'
import {useSnackbar} from "notistack";
import {Link} from "react-router-dom";

import {accrualType} from "../../../../utils/AccrualType";
import {scoreService} from "../../../../services/scoreService";
import { getDateFormat, getQuantityString } from '../../../../utils/Others'


const ScoreTable = ({scores, setScores}) => {
    const {enqueueSnackbar} = useSnackbar();

    const tableItems = useMemo(() => scores.filter(item=>item.accrual).map((score, index) => {
        const companyUrl = "/renters/" + score.accrual.company.id;
        const contractUrl = "/contracts/" + score.accrual.contract.id;
        return {
            '#': index + 1,
            number: score.number,
            renterCompany: <Link to={companyUrl}>{score.accrual.company.shortName}</Link>,
            createdDate: getDateFormat(score.createdDate),
            closedDate: getDateFormat(score.closedDate),
            accrualType: accrualType[score.accrualType],
            contract: <Link to={contractUrl}>{score.accrual.contract.name}</Link>,
            amount: getQuantityString(score.accrual.amount),
            isPayed: (score.isPayed && <MDBBadge style={{fontSize: 14}} color={'success'}>Оплачено</MDBBadge>) ||
                <MDBBadge style={{fontSize: 14}} color={'danger'}>Не оплачено</MDBBadge>,
            'qqq': <MDBRow center>
                <MDBTooltip
                    placement="top">
                    <MDBBtn
                        floating
                        disabled={score.isPayed}
                        size="sm"
                        onClick={() => {
                            scoreService.pay(score.id).then(_ => {
                                enqueueSnackbar("Счет оплачен и перенесен в архив", {variant: 'success'});
                                setScores(_);
                            }, error => {
                                enqueueSnackbar(error, {variant: 'error'});
                            })
                        }}
                        color="green">
                        <MDBIcon icon="money-bill"/>
                    </MDBBtn>
                    <span>
                           Оплатить счет
                    </span>
                </MDBTooltip>
            </MDBRow>
        }
    }), [scores, enqueueSnackbar, setScores]);


    const data = {
        columns: [
            {
                label: '#',
                field: '#',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Номер',
                field: 'number',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Арендатор',
                field: 'renterCompany',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Дата создания',
                field: 'createdDate',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Дата закрытия',
                field: 'closedDate',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Тип начисления',
                field: 'accrualType',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Договор',
                field: 'contract',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Сумма',
                field: 'amount',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Статус',
                field: 'isPayed',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Управление',
                field: 'qqq',
                sort: 'asc',
                width: 100
            }
        ],
        rows: tableItems
    }

    return (
        <MDBCard>
            <MDBCardBody>
                <MDBCardHeader>Собственники</MDBCardHeader>
                <React.Fragment>
                    <MDBDataTable
                        striped
                        bordered
                        small
                        infoLabel={['Показано от', 'до', 'из', 'записей']}
                        entriesLabel="Количество записей"
                        paginationLabel={['Предыдущая', 'Следующая']}
                        searchLabel="Поиск"
                        data={data}
                    />
                </React.Fragment>
            </MDBCardBody>
        </MDBCard>
    )
}

export default ScoreTable

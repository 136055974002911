import React           from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBRow,
    MDBSelect,
    MDBSelectInput,
    ProfileSection,
    MDBSelectOption,
    MDBSelectOptions,
}                      from "mdbreact";
import {appealsType}   from "../../../../utils/AppealsTypes";
import {appealService} from "../../../../services/appealService";
import {withSnackbar}  from "notistack";
import {userType}      from "../../../../utils/UserTypes";


class AppealSinglePage extends React.Component {
    constructor(props) {
        super(props);
        let img = [];
        this.props.appeal.images.forEach(_ => {
            img.push("https://" + _.filePath.substr(_.filePath.length - 73));
        });

        this.state = {
            appeal    : this.props.appeal,
            isLoading : true,
            photoIndex: 0,
            isOpen    : false,
            images    : img,
        };
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol key={photoIndex}>
                    <figure>
                        <img
                            src={imageSrc}
                            alt="Gallery"
                            className="img-fluid"
                            onClick={() =>
                                this.setState({photoIndex: privateKey, isOpen: true})
                            }
                        />
                    </figure>
                </MDBCol>
            );
        })
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    setStatus(e) {
        if (parseInt(e[0]) === this.props.appeal.status)
            return;
        appealService.setStatus(this.props.appeal.id, JSON.stringify({status: e[0]}))
                     .then(_ => {
                         this.setState({appeal: _,})
                         this.props.enqueueSnackbar("Статус измененен", {variant: 'success'})
                     });
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;

        return (
            <React.Fragment>
                <MDBCard>
                    <MDBCardHeader>Статус обращения: {appealsType[0]}</MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol>
                                <h3 className="g-mb-30">{this.state.appeal.topic}</h3>
                                <p>{this.state.appeal.content}</p>
                            </MDBCol>
                        </MDBRow>
                        {this.props.permissions.find(_ => _.id === 11).value &&
                            <MDBRow>
                                <MDBCol size="3">
                                    <MDBSelect
                                        label="Статус обращения"
                                        getValue={e => this.setStatus(e)}
                                    >
                                        <MDBSelectInput/>
                                        <MDBSelectOptions>
                                            <MDBSelectOption
                                                value="0"
                                                selected={Number(this.state.appeal.status) === 0}
                                                disabled={Number(this.state.appeal.status) !== 0 && userType[this.props.currentUser.user.userType] === "renter"}>
                                                {appealsType[0]}
                                            </MDBSelectOption>
                                            <MDBSelectOption
                                                value="1"
                                                selected={Number(this.state.appeal.status) === 1}
                                                disabled={Number(this.state.appeal.status) !== 1 && userType[this.props.currentUser.user.userType] === "renter"}>
                                                {appealsType[1]}
                                            </MDBSelectOption>
                                            <MDBSelectOption
                                                value="2"
                                                selected={Number(this.state.appeal.status) === 2}
                                                disabled={Number(this.state.appeal.status) !== 2 && userType[this.props.currentUser.user.userType] === "renter"}>
                                                {appealsType[2]}
                                            </MDBSelectOption>
                                            <MDBSelectOption
                                                value="3"
                                                selected={Number(this.state.appeal.status) === 3}
                                                disabled={Number(this.state.appeal.status) !== 3 && userType[this.props.currentUser.user.userType] === "renter"}>
                                                {appealsType[3]}
                                            </MDBSelectOption>
                                        </MDBSelectOptions>
                                    </MDBSelect>
                                </MDBCol>
                            </MDBRow>}

                            <div className="mdb-lightbox no-margin">
                            <MDBRow>
                                {this.renderImages()}
                            </MDBRow>
                        </div>
                        {/*{isOpen && ( TODO: Надо подумать что тут делать (пока скрыл из-за отсутствия компонента*/}
                        {/*    <Lightbox*/}
                        {/*        mainSrc={images[photoIndex]}*/}
                        {/*        nextSrc={images[(photoIndex + 1) % images.length]}*/}
                        {/*        prevSrc={images[(photoIndex + images.length - 1) % images.length]}*/}
                        {/*        imageTitle={photoIndex + 1 + "/" + images.length}*/}
                        {/*        onCloseRequest={() => this.setState({isOpen: false})}*/}
                        {/*        onMovePrevRequest={() =>*/}
                        {/*            this.setState({*/}
                        {/*                photoIndex: (photoIndex + images.length - 1) % images.length*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*        onMoveNextRequest={() =>*/}
                        {/*            this.setState({*/}
                        {/*                photoIndex: (photoIndex + 1) % images.length*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*)}*/}

                    </MDBCardBody>
                </MDBCard>
            </React.Fragment>);
    }
}

export default withSnackbar(AppealSinglePage)

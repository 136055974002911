import React, {useCallback, useEffect, useState}   from 'react'
import LinearProgress                              from '@material-ui/core/LinearProgress'
import {
    MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBInput, MDBRow, MDBSpinner
}                                                  from 'mdbreact'
import {Link}                                      from 'react-router-dom'
import cloneDeep                                   from 'lodash/cloneDeep'
import {objectService}                             from '../../../services/objectService'
import FloorPlanner                                from '../../floor-planner/view/view.js'
import {getImageUrl, getNumber, getQuantityString} from '../../../utils/Others'
import {useHistory}                                from "react-router";
import {Alert}                                     from "@material-ui/lab";
import Modal                                       from "../../modal";
import {floorService}                              from "../../../services/floorService";
import {useSnackbar}                               from "notistack";
import {roomState, type1, type2}                   from "../../../utils/RoomTypes";
import {numberWithSpaces}                          from "../../../helpers/NumberWithSpaces";
import {isworkService}                             from "../../../services/isworkService";

export const BuildingPage = props => {
    const [building, setBuilding]                             = useState()
    const [buildingRefresh, setBuildingRefresh]               = useState(true)
    const [newFloor, setNewFloor]                             = useState({floorName: '', floorNumber: ''})
    const [editableFloor, setEditableFloor]                   = useState(null) // Этаж, который будет подставлен из массива этажей корпуса
    const [deletingFloor, setDeletingFloor]                   = useState(null) // Этаж, который будет подставлен из массива этажей корпуса
    const [statusFloorCreateModal, setStatusFloorCreateModal] = useState(false)
    const [statusFloorUpdateModal, setStatusFloorUpdateModal] = useState(false)
    const [statusFloorDeleteModal, setStatusFloorDeleteModal] = useState(false)
    const [loading, setLoading]                               = useState(true)
    const {enqueueSnackbar}     = useSnackbar();

    const history    = useHistory()
    const buildingId = props.match.params.buildingId
    const objectId   = props.match.params.objectId

    const loadBuilding = useCallback(async (objectId, buildingId) => {
        const object   = await objectService.getById(objectId)
        const building = object.corps.find(_ => _.id === +buildingId)
        const b        = await getPrettyBuilding(building)
        b.object       = cloneDeep(object)
        delete b.object.corps
        return b
    }, [])

    const getPrettyBuilding = async building => {
        await building.floors.forEach(floor => {
            floor.rentalObjectsGroups === undefined ? floor.properties = undefined : floor.properties = propertiesCount(floor)
            floor.floorData = floor.mapString !== "" ? JSON.parse(floor.mapString) : null
            floor.images[0] ? floor.schema = getImageUrl(floor.images[0].filePath) : floor.schema = null
        })
        building.floors.sort((a, b) => {return a.floorNumber - b.floorNumber})
        return building
    }

    const propertiesCount = floor => {
        let propertiesFloor = {maxSquare: 0, rooms: 0, areas: 0, freeSpace: 0, busySpace: 0, otherSpace: 0}
        let busySpace       = 0
        let freeSpace       = 0
        let otherSpace      = 0
        //TODO: проверить правильность определения площадей и математических формул.
        // ИНформация в карточках считатется неправильно
        floor.rooms.forEach((room) => {
            if (room.type1 === 1) {
                if (room.factuallyRents > 0) {
                    busySpace += room.square
                } else {
                    freeSpace += room.square
                }
            } else {
                otherSpace += room.square
            }
        })
        propertiesFloor.busySpace  = busySpace
        propertiesFloor.freeSpace  = freeSpace
        propertiesFloor.otherSpace = otherSpace
        propertiesFloor.areas += floor.rentalObjectsGroups.length
        floor.rentalObjectsGroups.forEach(area => {
            propertiesFloor.maxSquare += area.maxSquareValue
            propertiesFloor.rooms += area.rentalObjects.length
        })
        //TODO: Остановился тут 23.02.23 13-50 (надо было идти в гости к Тиуру)
        //propertiesFloor.otherSpace = propertiesFloor.maxSquare - busySpace - freeSpace
        return propertiesFloor
    }

    useEffect(() => {
        loadBuilding(objectId, buildingId).then(building => {
            setBuilding(building)
            setLoading(false)
        })
    }, [objectId, buildingRefresh])

    // Изменение объекта
    const getInputsNewFloor = (name, value) => {
        setNewFloor({...newFloor, [name]: value})
    }
    const getInputsUpdateFloor = (name, value) => {
        setEditableFloor({...editableFloor, [name]: value})
    }

    const letsUpdateFloor = floor => {
        setEditableFloor (floor)
        setStatusFloorUpdateModal (true)
    }
    const letsDeleteFloor = deletingFloor => {
        setDeletingFloor (deletingFloor)
        setStatusFloorDeleteModal (true)
    }

    const createNewFloor = () => {
        setLoading(true)
        //собрать данные для отправки на сервер

        const newFloorForm       = new FormData()
        newFloorForm.set('FloorName', newFloor.floorName)
        newFloorForm.set('FloorNumber', newFloor.floorNumber)
        setStatusFloorCreateModal(false)
        //отправить на сервер
        floorService.create(building.id, newFloorForm).then(_ => {
            setBuildingRefresh(!buildingRefresh)
            setLoading(false)
            enqueueSnackbar('Этаж усрешно добавлен', { variant: 'success' })
        })
    }
    const saveEditableFloor = () => {
        setLoading(true)
        //собрать данные для отправки на сервер

        const newEditableFloorForm       = new FormData()
        newEditableFloorForm.set('FloorName', editableFloor.floorName)
        newEditableFloorForm.set('FloorNumber', editableFloor.floorNumber)
        setStatusFloorUpdateModal(false)
        //отправить на сервер
        floorService.update(editableFloor.id, newEditableFloorForm).then(_ => {
            setBuildingRefresh(!buildingRefresh)
            setLoading(false)
            enqueueSnackbar('Этаж усрешно отредактирован', { variant: 'success' })
        })
    }
    const deleteFloor = async () => {
        setBuildingRefresh(!buildingRefresh)
        setLoading(true)
        setStatusFloorDeleteModal (false)
        enqueueSnackbar('Этаж усрешно удалён', { variant: 'success' })
        try{
            deletingFloor.id >0 && await floorService.remove(deletingFloor.id)
        }catch (err) {
            console.error(err);
            enqueueSnackbar(err.message);
        }

    }


    if (!loading && building !== undefined) {
        const ids = {objectId:objectId, buildingId: building?.id, floorId: editableFloor?.id}
        return (<>
            <Modal onChange={null} isOpen={statusFloorCreateModal}
                               children={ContentFloorCreate({
                                   getInputsNewFloor, loading, setStatusFloorCreateModal, createNewFloor
                               })}/>
            <Modal isOpen={statusFloorUpdateModal}
                   onChange={null}
                   children={ContentFloorUpdate({getInputsUpdateFloor, loading, setStatusFloorUpdateModal, letsUpdateFloor, saveEditableFloor, editableFloor, ids})}
            />
            <Modal isOpen={statusFloorDeleteModal}
                   onChange={null}
                   children={ContentFloorDelete({
                       setStatusFloorDeleteModal, loading, deleteFloor
                   })}
            />


            <BreadCrumbs
                object={building.object} building={building} history={history}
                setStatusFloorCreateModal={setStatusFloorCreateModal}/>
            <MDBRow>
                <MDBCol sm={12}>
                    <Floors building={building} letsUpdateFloor={letsUpdateFloor} letsDeleteFloor={letsDeleteFloor} buildingId={buildingId} objectId={objectId}/>
                </MDBCol>
            </MDBRow>
        </>)
    }
    return <LinearProgress/>
}

const BreadCrumbs = ({object, building, history, setStatusFloorCreateModal}) => {
    const breadcrumbsChain = [['Главная', '/main'], ['Объекты', '/objects']]
    let itemsChain         = []
    let i                  = 0
    breadcrumbsChain.forEach(function (item) {
        i++
        itemsChain.push(<MDBBreadcrumbItem key={i}>
            <Link to={item[1]}>{item[0]}</Link>
        </MDBBreadcrumbItem>)
    })
    itemsChain.push(<MDBBreadcrumbItem key={34345}>
        <Link to={"/objects/" + object.id}>{object.name}</Link>
    </MDBBreadcrumbItem>)
    itemsChain.push(<MDBBreadcrumbItem active key={0}>
        {building.corpName}
    </MDBBreadcrumbItem>)
    itemsChain.unshift(<MDBBreadcrumbItem key={321}>
        <span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span>
    </MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" style={{padding: 20}}>
                <MDBRow between>
                    <MDBCol size="4" className="d-flex align-items-center">
                        <button className='btn btn-outline-success btn-rounded'
                                onClick={() => setStatusFloorCreateModal(true)}>
                            <i className="fa fa-plus"/>
                        </button>
                        <MDBCardTitle tag="h3" className="g-mb-0" style={{margin: 0}}>{building.corpName}</MDBCardTitle>
                    </MDBCol>
                    <MDBCol size="8" className="text-right room">
                        <h2 className="room_card_header_2">{object.name}</h2>
                        <h3 className="room_card_header_2">
                            <i className="fas fa-map-marker-alt mr-10"/> {object.cityName + ", " + object.streetName + ", " + object.houseNumber}
                        </h3>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}

const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}

const Floors          = ({building, letsUpdateFloor, letsDeleteFloor, buildingId, objectId}) => {
    return building.floors.map((floor, i) => {
        return <SingleFloor building={building} floor={floor} i={i} key={i} letsUpdateFloor={letsUpdateFloor} letsDeleteFloor={letsDeleteFloor} buildingId={buildingId} objectId={objectId}/>
    })
}

const SingleFloor = ({building, floor, i, letsUpdateFloor, letsDeleteFloor, objectId, buildingId}) => {
    const [activeRoom, setActiveRoom] = useState()

    const room = floor.rooms.find(_ => _.id === activeRoom)

    return <MDBCard className="object_building floorCard mb-30" key={i}>
        <MDBCardBody>
            <div className="row">
                <div className="col-md-6">
                    <h4 className="mb-30"><Link
                        to={'/rooms?corpsId=' + building.id + '&elementsCount=100&floorNumbers=' + floor.floorNumber + '&madeFilters=All&objectsId=' + building.object.id + '&page=1'}>{floor.floorName}</Link>
                        <i className="fas fa-wrench ml-10 update_icon" onClick={()=>{letsUpdateFloor(floor)}}/></h4>
                    {/*FloorPlan render start*/}
                    <FloorPlans floor={floor} setActiveRoom={setActiveRoom} objectId={objectId} buildingId={buildingId}/>
                    {/*FloorPlan render finished*/}
                </div>
                <div className="col-md-6" style={{minHeight:'160px'}}>
                    <div className="someinfo">
                        <div className="cell">
                                <span className="number a">{getNumber(floor.properties.maxSquare)}<span
                                    className="units">м<sup>2</sup></span></span>
                            <span className="cell a">Площадь этажа</span>
                        </div>
                        <div className="cell">
                                <span className="number b">{getNumber(floor.properties.freeSpace)}<span
                                    className="units">м<sup>2</sup></span></span>
                            <span className="cell b">Свободно</span>
                        </div>
                        <div className="cell">
                                <span className="number c">{getNumber(floor.properties.busySpace)}<span
                                    className="units">м<sup>2</sup></span></span>
                            <span className="cell c">Сдано</span>
                        </div>
                        <div className="cell">
                                <span className="number d">{getNumber(floor.properties.otherSpace)}<span
                                    className="units">м<sup>2</sup></span></span>
                            <span className="cell d">Не сдаётся</span>
                        </div>
                    </div>
                    {activeRoom !== undefined && <div className="selectedRoom">
                        <h5><Link to={'/rooms/' + room.id}>{room.name}</Link></h5>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>Тип объекта аренды</td>
                                    <td>{type1[Number(room.type1)] + ' - ' + type2[Number(room.type2)]}</td>
                                </tr>
                                <tr>
                                    <td>Площадь</td>
                                    <td>{numberWithSpaces(getQuantityString(room.square, ''))} <span className="units">м<sup>2</sup></span></td>
                                </tr>
                                <tr>
                                    <td>Состояние</td>
                                    <td>{roomState[Number(room.roomState)]}</td>
                                </tr>
                                <tr>
                                    <td>Аренда</td>
                                    <td>{getQuantityString(room.factuallyRents)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
                    <i className="fas fa-trash delete-floor" onClick={()=>{letsDeleteFloor(floor)}}/>
                </div>
            </div>
        </MDBCardBody>
    </MDBCard>
}

const FloorPlans = ({floor, setActiveRoom, objectId, buildingId}) => {
    return <>
        {floor.schema && floor.floorData ? (<FloorPlanner
            floor={floor}
            setActiveRoom={setActiveRoom}
        />) : (<Alert severity="warning">У данного этажа нет планровки. <Link to={'/objects/'+objectId+'/buildings/'+buildingId+'/floors/'+floor.id+'/update'}>Добавить схему этажа {floor.floorNumber+' - '+floor.floorName}</Link></Alert>)}


    </>
}

const ContentFloorCreate = ({getInputsNewFloor, loading, setStatusFloorCreateModal, createNewFloor}) => {

    return (<>
        <MDBCard className='pa-24'>
            <MDBCardBody>
                <h4 className='form-small-header color-grey'>Добавить этаж</h4>
                <MDBRow className='my-24'>
                    <MDBCol size='6'>
                        <MDBInput
                            autoComplete="off"
                            name="floorName"
                            label="Название"
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            getValue={e => getInputsNewFloor('floorName', e)}
                        />
                    </MDBCol>
                    <MDBCol size='6'>
                        <MDBInput
                            autoComplete="off"
                            name="floorNumber"
                            label="Номер этажа"
                            type="number"
                            validate
                            error="wrong"
                            success="right"
                            getValue={e => getInputsNewFloor('floorNumber', e)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow end middle>
                    {loading && <MDBSpinner small green/>}
                    <MDBBtn onClick={() => setStatusFloorCreateModal(false)} color="link">
                        Отменить
                    </MDBBtn>
                    <MDBBtn
                        id="create-btn"
                        onClick={() => createNewFloor()}
                        disabled={false}
                        color="success"
                    >
                        Сохранить
                    </MDBBtn>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const ContentFloorUpdate = ({getInputsUpdateFloor, loading, setStatusFloorUpdateModal, saveEditableFloor, editableFloor, ids}) => {

    return (<>
        <MDBCard className='pa-24'>
            <MDBCardBody>
                {editableFloor && <h4 className='form-small-header color-grey'>{'\"'+editableFloor.floorName+'\"'+'. № '+editableFloor.floorNumber}</h4>}
                <span className='form-small-header label-form'>Редактирование</span>
                <MDBRow className='my-24'>
                    <MDBCol size='6'>
                        <MDBInput
                            autoComplete="off"
                            name="floorName"
                            label="Название"
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={editableFloor ? editableFloor.floorName : ''}
                            getValue={e => getInputsUpdateFloor('floorName', e)}
                        />
                    </MDBCol>
                    <MDBCol size='6'>
                        <MDBInput
                            autoComplete="off"
                            name="floorNumber"
                            label="Номер этажа"
                            type="number"
                            validate
                            error="wrong"
                            success="right"
                            value={editableFloor ? editableFloor.floorNumber : ''}
                            getValue={e => getInputsUpdateFloor('floorNumber', e)}
                        />
                    </MDBCol>


                </MDBRow>

                <MDBRow end middle>
                    {loading && <MDBSpinner small green/>}
                    <h4 className="mb-0"><Link className="btn mr-10 floor-updating-form" to={`/objects/${ids.objectId}/buildings/${ids.buildingId}/floors/${ids.floorId}/update`}><i className="fas fa-th-large"/></Link></h4>
                    <MDBBtn onClick={() => setStatusFloorUpdateModal(false)} color="link">
                        Отменить
                    </MDBBtn>
                    <MDBBtn
                        id="create-btn"
                        onClick={() => saveEditableFloor()}
                        disabled={false}
                        color="success"
                    >
                        Сохранить
                    </MDBBtn>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const ContentFloorDelete = ({setStatusFloorDeleteModal, loading, deleteFloor}) => {
    return <>
        <MDBCard>
            <MDBCardBody>
                <div className="row">
                    <div className="col">
                        <h4>Вы действительно хотите удалить этаж?</h4>
                    </div>

                </div>
                <MDBRow end middle>
                    {loading && <MDBSpinner small green/>}
                    <MDBBtn onClick={() => setStatusFloorDeleteModal(false)} color="link">
                        Отменить
                    </MDBBtn>
                    <MDBBtn
                        id="create-btn"
                        onClick={() => deleteFloor()}
                        disabled={false}
                        color="danger"
                    >
                        Да, удалить!
                    </MDBBtn>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>
}

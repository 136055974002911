import React, {Component} from 'react';
import {connect} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import BreadcrumSection from "../../commonSections/BreadcrumSection";
import AppealSinglePage from "./pages/AppealSinglePage";
import {appealService} from "../../../services/appealService";
import {permissionsHelper} from "../../../utils/permissionsHelper";

class AppealContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appeal: null,
            appealId: props.match.params.appealId,
            permissions: []
        };

        appealService.getById(props.match.params.appealId).then(_ => {
            this.setState({appeal: _})
        });
    }

    async componentDidMount() {
        this.setState({permissions: await permissionsHelper.scopePermissions()})
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    render() {
        if (this.state.permissions.length === 0)
            return (<LinearProgress/>);

        let breadcrumsChain = [
            ['Главная', '/'],
            ['Обращения', '/appeals'],
        ];

        return (
            <React.Fragment>
                {this.state.appeal && <BreadcrumSection
                    createPredicate={false}
                    h2={"Обращение №" + this.state.appeal.id + " арендатора " + this.state.appeal.company.fullName}
                    chain={breadcrumsChain}
                    buttonName={""}
                    href="/appeals/create"
                />}
                {this.state.appeal &&
                <AppealSinglePage
                    permissions={this.state.permissions}
                    appeal={this.state.appeal}
                    currentUser={this.props.currentUser}/>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppealContainer);

import { handleResponse } from "../helpers";
import { apiUrl } from "../utils/ConnectionStrings";

export const ownersService = { getAll, getById, create, update, remove, getByPage };

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(apiUrl + "owners/", requestOptions)
    .then(handleResponse)
    .then(_ => _);
}

function getByPage(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
    }
  };
  return fetch(`${apiUrl}owners/page?${params}` , requestOptions)
      .then(handleResponse)
      .then(_ => {
        return _;
      });
}


function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(apiUrl + "owners/" + id, requestOptions)
    .then(handleResponse)
    .then(_ => _);
}

function create(ownerModel) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    },
    body: ownerModel
  };
  return fetch(apiUrl + "owners/", requestOptions)
    .then(handleResponse)
    .then(_ => _);
}

function update(ownerId, ownerModel) {
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    },
    body: ownerModel
  };
  return fetch(apiUrl + "owners/" + ownerId, requestOptions)
    .then(handleResponse)
    .then(_ => _);
}

function remove(ownerId) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    }
  };
  return fetch(apiUrl + "owners/" + ownerId, requestOptions)
    .then(handleResponse)
    .then(_ => _);
}


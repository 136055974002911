import React, { useEffect, useState } from 'react'
import classNames from 'classnames';
import { MDBIcon } from 'mdbreact'

const InputItem = ({onFocus, onBlur, icon, label, error, required, ...rest}) => {
    const [active, setActive] = useState(false);
    useEffect(() => {
        return () => setActive(false);
    }, []);
    return (
        <div className={classNames(
            'input-form',
            active && 'input-form--active',
            !!rest.value && 'input-form--not-empty',
            !!error && 'input-form--error'
        )}>
            <div className="input-form__icon">
                <MDBIcon icon={icon}/>
            </div>
            <div className="input-form__control">
                <label htmlFor={rest.id}>{label}{required && '*'}</label>
                <input
                    {...rest}
                    onFocus={(e) => {
                        setActive(true);
                        typeof onFocus === 'function' && onFocus(e);
                    }}
                    onBlur={(e) => {
                        setActive(false);
                        typeof onBlur === 'function' && onBlur(e);
                    }}
                />
                {!!error && <div className="input-form__error">{error}</div>}
            </div>
        </div>
    );
}

export default InputItem
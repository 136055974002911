import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Line}                                        from 'react-konva'

const Shape = ({
    points: initialPoints,
    x,
    y,
    active,
    setActive,
    onTransform,
    scale,
    center,
    title,
    square,
    isFocused,
    push,
    id,
    ...props
}) => {

    const coords              = {x, y}
    const [points, setPoints] = useState(initialPoints)
    const ref                 = useRef(null)
    const strokeCoords = useMemo(() => {
        const result = []
        for (let i = 0; i < points.length; i += 2) {
            result.push([points[i], points[i + 1], points[points.length <= i + 2 ? 0 : i + 2], points[points.length <= i + 3 ? 1 : i + 3]])
        }
        return result
    }, [points])


    useEffect(() => {
        setPoints(initialPoints)
    }, [initialPoints])

    console.log("Shape active ?", id)
    return (<>
            <Line
                {...props}
                x={x}
                y={y}
                points={points}
                ref={ref}
                closed
                stroke="black"
                strokeWidth={2 / scale}
                fill={isFocused ? '#f443368c' : active ? '#66bb6a50' : '#dce77550'/*todo: Цвет на схемах находится тут*/}
                onClick={() => {setActive(id)}} // TODO: В этом месте назначается активное помещение при проведении мышки над помещением по планеровке
            />
            {active && strokeCoords.map((points, i) => (<Line
                    key={'stroke-line-' + i}
                    x={x}
                    y={y}
                    stroke="#00000000"
                    strokeWidth={4 / scale}
                    points={points}
                />))}
        </>)
}

export default Shape

import React, { useState } from 'react'
import { MDBBadge, MDBCard, MDBCardBody, MDBCardHeader, MDBDataTable } from 'mdbreact'
import { Link, withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'

import { incomeService } from '../../../../services/incomeService'
import EditBlock from '../../../others/EditBlock'
import { getDateFormat, getQuantityString } from '../../../../utils/Others'

const IncomesList = props => {
    const [incomes, setIncomes] = useState(props.incomes)

    const deleteItem = id => {
        incomeService.remove(id).then(
            _ => {
                props.enqueueSnackbar('Поступление успешно удалено', {
                    variant: 'success'
                })
                setIncomes(incomes.filter(i => i.id !== id))
            },
            error => props.enqueueSnackbar(error, { variant: 'error' })
        )
    }

    const items = incomes.map((income, index) => ({
        '#': index + 1,
        incomeDate: getDateFormat(income.incomeDate),
        company: <Link to={'/renters/' + income.company.id}> {income.company.shortName}</Link>,
        amount: (
            <MDBBadge style={{ fontSize: 16 }} color={'success'}>{getQuantityString(income.amount)}</MDBBadge>

        ),
        description: income.description,
        edit: (
            <EditBlock
                permissions={props.permissions}
                editLink={`/income/update/${income.id}`}
                deleteLabel={`Вы действительно хотите удалить поступление ${income.company.shortName}?`}
                deleteFunc={() => deleteItem(income.id)}
                relative
            />
        )
    }))
    const data = {
        columns: [
            {
                label: '#',
                field: '#',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Дата начисления',
                field: 'incomeDate',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Арендатор',
                field: 'company',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Сумма начисления',
                field: 'amount',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Описание',
                field: 'description',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Управление',
                field: 'edit',
                sort: 'asc',
                width: 200
            }
        ],
        rows: items
    }
    return (
        <MDBCard>
            <MDBCardBody>
                <MDBCardHeader>Поступления</MDBCardHeader>
                <React.Fragment>
                    <MDBDataTable
                        striped
                        bordered
                        small
                        infoLabel={['Показано от', 'до', 'из', 'записей']}
                        entriesLabel="Количество записей"
                        paginationLabel={['Предыдущая', 'Следующая']}
                        searchLabel="Поиск"
                        data={data}
                    />
                </React.Fragment>
            </MDBCardBody>
        </MDBCard>
    )
}
export default withSnackbar(withRouter(IncomesList))

import React, {Component} from 'react'
import {
    MDBInput,
    MDBModalFooter, MDBModalHeader
} from 'mdbreact';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBRow
} from 'mdbreact';
import {withSnackbar} from 'notistack';
import CircularProgress from "@material-ui/core/CircularProgress";
import {objectRoleService} from "../../../../../../services/objectRoleService";


class CreateObjectRolePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roleName: "",
            isLoading: false,
        };
    }

    saveRole = (e) => {
        const model = {
            name: this.state.roleName,
            permission: []
        };

        objectRoleService.create(JSON.stringify(model)).then(_ => {
            this.props.enqueueSnackbar('Роль успешно создана', {variant: 'success'});
            this.setState({isLoading: false});
            this.props.setRoles(_);
            this.props.setCreateMode(false)
        }, error => {
            this.props.enqueueSnackbar(error, {variant: 'error'});
            this.setState({isLoading: false});
        });
    };


    handleChange = name => event => {
        this.setState({[name]: event.target.checked});
    };

    NameOnChange(e) {
        this.setState({
            roleName: e.target.value
        });
    }

    render() {

        return (
            <MDBRow className="mb-4">
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBModalHeader>
                                Добавление новой роли
                            </MDBModalHeader>
                            <div className="grey-text">
                                <MDBInput
                                    label="Название роли"
                                    icon="tag"
                                    group
                                    type="text"
                                    validate
                                    error="wrong"
                                    success="right"
                                    value={this.state.roleName}
                                    onChange={e => this.NameOnChange(e)}
                                />
                            </div>

                            <MDBModalFooter>
                                {this.state.isLoading && <CircularProgress size={30}/>}
                                <MDBBtn outline color="blue-grey"
                                        disabled={this.state.isLoading}
                                        onClick={() => this.props.setCreateMode(false)}>Закрыть</MDBBtn>
                                <MDBBtn onClick={e => {
                                    this.setState({isLoading: true});
                                    this.saveRole(e);
                                }}
                                        disabled={this.state.isLoading}
                                        outline
                                        color="success">Сохранить</MDBBtn>
                            </MDBModalFooter>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        );
    }
}

export default withSnackbar(CreateObjectRolePage);

import React from 'react';
import BreadcrumSection from "../../../commonSections/BreadcrumSection";
import CreateContractSection from "../sections/create/CreateContractSection";
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow
}                   from "mdbreact";
import {Link}       from "react-router-dom";
import {useHistory} from "react-router";

class CreateContractPage extends React.Component {

    componentDidMount() {
    }

    render() {
        return (<>
            <BreadCrumbs header="Добавить договор аренды"/>
            <CreateContractSection/>
        </>)
    }
}

export default CreateContractPage;


const BreadCrumbs     = ({header}) => {
    const breadcrumbsChain = [
        ['Главная', '/main'],
        ['Договоры', '/contracts'],
    ]
    const history = useHistory()
    let itemsChain         = []
    let i                  = 0
    breadcrumbsChain.forEach(function (item) {
        i++
        itemsChain.push(<MDBBreadcrumbItem key={i}><Link to={item[1]}>{item[0]}</Link></MDBBreadcrumbItem>)
    })
    itemsChain.push (<MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>);
    itemsChain.unshift(<MDBBreadcrumbItem key={321}><span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span></MDBBreadcrumbItem>);

    return (<>
        <MDBBreadcrumb style={breadCrumbStyle}>{itemsChain}</MDBBreadcrumb>
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" className="pa-24">
                <MDBRow between>
                    <MDBCol className="d-flex align-items-center">
                        <MDBCardTitle tag="h5" className="mb-0">{header}</MDBCardTitle>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}
const breadCrumbStyle = {
    padding: '0 15px', fontSize: '0.8rem', marginTop: "-16px"
}

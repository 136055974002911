import React from 'react'
import { MDBCard, MDBCardBody, MDBCol, MDBRow, MDBTable, MDBTableBody } from 'mdbreact'
import { Link } from 'react-router-dom'
import { accrualType } from '../../../../utils/AccrualType'
import { accrualPeriod } from '../../../../utils/AccrualPeriod'
import moment from 'moment'
import { accrualUnit } from '../../../../utils/AccrualUnit'

const AccrualSinglePage = ({accrual}) => {
    return (
        <MDBCard>
            <MDBCard>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol size="6">
                            <MDBTable>
                                <MDBTableBody>
                                    <tr>
                                        <td style={{borderTop: "none"}}>Арендатор</td>
                                        <td style={{borderTop: "none"}}>
                                            <Link to={"/renters/" + accrual.company.id}>{accrual.company.fullName} </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Договор</td>
                                        <td>
                                            <Link to={"/contract/" + accrual.contract.id}>{accrual.contract.name} </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Тип начисления</td>
                                        <td>{accrualType[accrual.accrualType.toString()]}</td>
                                    </tr>
                                    <tr>
                                        <td>Период начисления</td>
                                        <td>{accrualPeriod[accrual.periodType]}</td>
                                    </tr>
                                    <tr>
                                        <td>Дата формирование</td>
                                        <td>{moment(accrual.dateOfDelay).format("DD.MM.YYYY")}</td>
                                    </tr>

                                </MDBTableBody>
                            </MDBTable>
                        </MDBCol>
                        <MDBCol size={"6"}>
                            <MDBTable>
                                <MDBTableBody>

                                    <tr>
                                        <td style={{borderTop: "none"}}>Описание</td>
                                        <td style={{borderTop: "none"}}>{accrual.description}</td>
                                    </tr>
                                    <tr>
                                        <td>Количество</td>
                                        <td>{accrual.count}</td>
                                    </tr>
                                    <tr>
                                        <td>Единица измерения</td>
                                        <td>{accrualUnit[accrual.unitsMeasure]}</td>
                                    </tr>
                                    <tr>
                                        <td>Сумма</td>
                                        <td>{accrual.amount}</td>
                                    </tr>
                                    <tr>
                                        <td>Приоритет</td>
                                        <td>{accrual.paymentPriority}</td>
                                    </tr>
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </MDBCard>
    )
}

export default AccrualSinglePage


export const OBJECT_CHANGE_NAME_TEXT         = 'OBJECT_CHANGE_NAME_TEXT';
export const onNameChange     = (name) => ({
  type   : OBJECT_CHANGE_NAME_TEXT,
  payload: name
});

export const OBJECT_CHANGE_CITY_TEXT         = 'OBJECT_CHANGE_CITY_TEXT';
export const onCityChange     = (city) => ({
  type   : OBJECT_CHANGE_CITY_TEXT,
  payload: city
});

export const OBJECT_CHANGE_STREET_TEXT       = 'OBJECT_CHANGE_STREET_TEXT';
export const onStreetChange   = (street) => ({
  type   : OBJECT_CHANGE_STREET_TEXT,
  payload: street
});

export const OBJECT_CHANGE_BUILDING_TEXT     = 'OBJECT_CHANGE_BUILDING_TEXT';
export const onBuildingChange = (building) => ({
  type   : OBJECT_CHANGE_BUILDING_TEXT,
  payload: building
});

export const OBJECT_CHANGE_CONSTRUCTION_TEXT = 'OBJECT_CHANGE_CONSTRUCTION_TEXT';
export const onConstructionChange = (construction) => ({
  type   : OBJECT_CHANGE_CONSTRUCTION_TEXT,
  payload: construction
});

export const OBJECT_CREATE_FORM_SUBMIT       = 'OBJECT_CREATE_FORM_SUBMIT';
export const onObjectCreateFormSubmit = object => ({
  type   : OBJECT_CREATE_FORM_SUBMIT,
  payload: object
});

export const OBJECT_SET_ALL_OBJECTS    = 'OBJECT_SET_ALL_OBJECTS';
export const setAllObjects = (allObjects) => ({
  type   : OBJECT_SET_ALL_OBJECTS,
  payload: allObjects
});

export const OBJECT_SET_IS_LOADING_BOOL      = 'OBJECT_SET_IS_LOADING_BOOL';
export const setIsLoading = status => ({
  type   : OBJECT_SET_IS_LOADING_BOOL,
  payload: status
});

export const OBJECT_SET_CURRENT_OBJECT      = 'OBJECT_SET_CURRENT_OBJECT';
export const setCurrentObject = currentObject => ({
  type   : OBJECT_SET_CURRENT_OBJECT,
  payload: currentObject
});

export const OBJECTS_SET_MAIN_REPORT_DATA      = 'OBJECTS_SET_MAIN_REPORT_DATA';
export const setMainReportData = mainReportData => ({
  type   : OBJECTS_SET_MAIN_REPORT_DATA,
  payload: mainReportData
});
import React, {Component} from 'react';
import {connect} from "react-redux";
import BreadcrumSection         from "../../commonSections/BreadcrumSection";
import ReportsPage              from "./pages/ReportsPage";
import LinearProgress from "@material-ui/core/LinearProgress";

class ReportsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        let breadcrumsChain = [
            ['Главная', '/'],
        ];

        if (this.props.isLoading) {
            return (<LinearProgress/>);
        } else {
            return (
                <React.Fragment>
                    <BreadcrumSection
                        h2={"Отчеты"}
                        chain={breadcrumsChain}
                        buttonName={false}
                        href="building/create"
                    />
                    <ReportsPage/>
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);

import React from 'react'
import { LinearProgress } from '@material-ui/core'
import {MDBBadge, MDBCol, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact'
import moment from 'moment'
import { numberWithSpaces } from '../../../../helpers/NumberWithSpaces'

const IncomeCompanySection = ({ incomes }) => {



    if (incomes && incomes.length !== 0)
        return (
            <MDBCol>
                <MDBTable hover>
                    <MDBTableHead>
                        <tr>
                            <th>№</th>
                            <th>Описание</th>
                            <th>Дата поступления</th>
                            <th>Сумма поступления</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {incomes.map((income, index) => (
                            <tr key={'income-of-renter-' + income.id}>
                                <td>{index + 1}</td>
                                <td>{income.description}</td>
                                <td>{moment(income.incomeDate).format("DD.MM.YYYY")}</td>
                                <td><MDBBadge style={{fontSize: 16}} color={"success"}>{numberWithSpaces(income.amount)} &#8381;	</MDBBadge></td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCol>
        )
    if(incomes && incomes.length === 0) return (<div className="d-flex justify-content-center"> На данный момент нет поступлений.</div>)

    return <LinearProgress />
}

export default IncomeCompanySection

import React                                              from "react";
import {MDBBtn, MDBCol, MDBInput, MDBModalFooter, MDBRow} from "mdbreact";
import {authenticationService}                            from "../../../../services/authService";
import {withSnackbar}                                     from "notistack";
import CircularProgress                                   from "@material-ui/core/CircularProgress";

class PrivateSettingsProfileSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword      : "",
            newPassword      : "",
            replyNewPassword : "",
            isPasswordLoading: false,
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.oldPassword !== nextState.oldPassword ||
            this.state.newPassword !== nextState.newPassword ||
            this.state.replyNewPassword !== nextState.replyNewPassword ||
            this.state.isPasswordLoading !== nextState.isPasswordLoading;
    }

    changeUserPassword = (event) => {
        if (this.state.oldPassword === "" || this.state.newPassword === "" || this.state.replyNewPassword === "") {
            this.props.enqueueSnackbar('Поле не может быть пустым', {variant: 'warning'});
        } else if (this.state.newPassword !== this.state.replyNewPassword) {
            this.props.enqueueSnackbar('Новые пароли не совпадают', {variant: 'warning'});
        } else {
            this.setState({
                isPasswordLoading: true,
            });
            authenticationService.changePassword(this.props.currentUser.userId, JSON.stringify({
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword
            })).then(_ => {
                this.props.enqueueSnackbar('Пароль успешно изменен', {variant: 'success'});
                this.setState({
                    isPasswordLoading: false,
                });
            }, error => {
                this.props.enqueueSnackbar("Не удалось сменить пароль. Возможно вы ввели некорректные данные", {variant: 'error'});
                this.setState({
                    isPasswordLoading: false,
                });
            })
        }


    };

    onPassFieldChange(event, field) {
        this.setState({
            [field]: event.target.value
        });
    }

    render() {

        return (
            <React.Fragment>
                <MDBRow>
                    <MDBCol>
                        <MDBInput
                            label="Старый пароль"
                            type="password"
                            onChange={e => this.onPassFieldChange(e, "oldPassword")}
                            value={this.state.oldPassword}
                        />
                        <MDBInput
                            label="Новый пароль"
                            type="password"
                            onChange={e => this.onPassFieldChange(e, "newPassword")}
                            value={this.state.newPassword}
                        />
                        <MDBInput
                            label="Повторите"
                            type="password"
                            onChange={e => this.onPassFieldChange(e, "replyNewPassword")}
                            value={this.state.replyNewPassword}
                        />
                    </MDBCol>
                </MDBRow>

                <MDBModalFooter>
                    {this.state.isPasswordLoading && <CircularProgress size={30}/>}
                    <MDBBtn onClick={e => {
                        this.changeUserPassword(e);
                    }}
                            disabled={this.state.isPasswordLoading}
                            outline
                            color="success">Сохранить</MDBBtn>
                </MDBModalFooter>
            </React.Fragment>
        );
    }
}


export default withSnackbar(PrivateSettingsProfileSection);

import React from 'react'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru'
import 'moment/locale/ru'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

const oulineStyle = {
    border: "1px solid #dadce0",
    borderRadius: "4px",
    paddingTop: ".15rem",
    boxSizing: "border-box"
}


export default ({value, onChange, label,placeholder, style, outline, className, id, ...rest}) => (
    <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
        <KeyboardDatePicker
            className={className}
            margin="normal"
            id={id}
            label={label}
            style={(outline && {...style, ...oulineStyle} )|| style}
            placeholder={placeholder}
            format="dd.MM.yyyy"
            value={value}
            onChange={onChange || (() =>{})}
            {...rest}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            invalidDateMessage={"Неправильный формат даты"}
        />
    </MuiPickersUtilsProvider>
)
import {handleResponse} from '../helpers';
import {apiUrl} from "../utils/ConnectionStrings";

export const buildingService = {
    create,
    update,
    remove,
    getAllByObjectId,
    uploadPhotos,
    getById
};

function create(objectId, buildingModel) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json'
        },
        body: buildingModel
    };

    return fetch(apiUrl + "corps/" + objectId, requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            },
            _ => {
                return _;
            });
}


function update(id, buildingModel) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json'
        },
        body: buildingModel
    };

    return fetch(apiUrl + "corps/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            },
            _ => {
                return _;
            });
}

function uploadPhotos(id, files) {
    const formData = new FormData();
    Array.from(files).forEach(_ => formData.append('body', _));
    const uploadRequestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json'
        },
        body: formData
    };
    return fetch(apiUrl + 'corps/upload-images/' + id, uploadRequestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function remove(corpModel) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };

    return fetch(apiUrl + "corps/" + corpModel, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getAllByObjectId(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "corps/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "corps/get-by-id/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

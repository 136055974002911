import React from 'react'
import {useHistory} from "react-router";
import {MDBBtn, MDBIcon, MDBTooltip} from "mdbreact";
import DeleteModal from '../commonSections/DeleteModalSection'

const EditBlock =({permissions, deleteFunc, editLink, deleteLabel, relative}) => {
    const history = useHistory();
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: '100%', position: relative ? 'relative' : 'absolute', left: 0, top: 0, height: '100%' }}
        >
            {permissions.find(_ => _.id === 3).value && (
                <MDBTooltip placement="top">
                    <MDBBtn
                        floating
                        size="sm"
                        className="m-0 mr-2 z-depth-1"
                        outline
                        onClick={() => {
                            history.push(editLink)
                        }}
                        style={{ borderWidth: '1px' }}
                        color="primary"
                    >
                        <MDBIcon style={{ color: '#4285f4 ', width: '100%' }} icon="pen"/>
                    </MDBBtn>

                    <span>Редактировать</span>
                </MDBTooltip>
            )}

            {permissions.find(_ => _.id === 4).value && (
                <DeleteModal delete={deleteFunc} label={deleteLabel}/>
            )}
        </div>
    )
}

export default EditBlock
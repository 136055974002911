import React, {Component} from 'react'
import {MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBTooltip} from 'mdbreact';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBRow
} from 'mdbreact';
import {withSnackbar} from "notistack";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {objectRoleService} from "../../../../../../services/objectRoleService";


class ObjectRoleManagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPermissions: [],
            selectedRole: null

        };
        this.setSelectedRole = this.setSelectedRole.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.selectedPermissions !== nextState.selectedPermissions ||
            this.props.roles !== nextProps.roles;
    }

    setSelectedRole(selectedRole) {
        let role = null;
        this.props.roles.forEach(_ => {
            if (_.id === selectedRole.id) {
                role = _;
                return;
            }
        });
        let selectedRolePermissions = [];
        role.objectRolePermissions.forEach(_ => {
            selectedRolePermissions.push({
                name: _.objectPermission.name,
                id: _.objectPermission.id,
                value: _.value,
            })
        });

        selectedRolePermissions.sort(function (a, b) {
            if (a.id !== b.id) {
                return a.id - b.id
            }
            return 0
        });

        this.setState({
            selectedPermissions: selectedRolePermissions,
            selectedRole: selectedRole
        });
    }

    async handleToggle(value) {
        let selectedRolePermissions = [];
        this.state.selectedPermissions.forEach(_ => {
            selectedRolePermissions.push({
                name: _.name,
                id: _.id,
                value: value.id !== _.id ? _.value : !_.value,
            })
        });

        let message = JSON.stringify({
            name: this.state.selectedRole.name,
            permissions: selectedRolePermissions
        });
        await objectRoleService.update(this.state.selectedRole.id, message).then(_ => {
            this.props.setRoles(_);
        });

        this.setState({selectedPermissions: selectedRolePermissions})
    }

    render() {
        let count = 0;
        let enqueueSnackbar = this.props.enqueueSnackbar;
        let setRoles = this.props.setRoles;
        let setSelectedRole = this.setSelectedRole;

        //TODO: в рендере нельзя делать такие вычисления, переделать с использованием memo https://ru.reactjs.org/docs/react-api.html#reactmemo
        this.props.roles.sort(function (a, b) {
            if (a.id !== b.id) {
                return a.id - b.id
            }
            return 0
        });

        //TODO: в рендере нельзя делать такие вычисления, переделать с использованием memo https://ru.reactjs.org/docs/react-api.html#reactmemo
        const rolesRows = this.props.roles.map(function (_) {
            count++;
            return (
                <tr key={count}>
                    <td>{count}</td>
                    <td>{_.name}</td>
                    <td className="margin-buttons">
                        <MDBTooltip
                            placement="top">
                            <MDBBtn
                                floating
                                onClick={e => setSelectedRole(_)}
                                size="sm"
                                color="primary">
                                <MDBIcon icon="pen"/>
                            </MDBBtn>
                            <span>
                                    Просмотр
                            </span>
                        </MDBTooltip>

                        <MDBTooltip
                            placement="top">
                            <MDBBtn
                                floating
                                size="sm"
                                disabled={_.isDefault}
                                onClick={e => {
                                    objectRoleService.remove(_.id).then(_ => {
                                        enqueueSnackbar('Роль успешно удалена', {variant: 'success'});
                                        setRoles(_);
                                    }, error => {
                                        enqueueSnackbar(error.message, {variant: 'error'});
                                    })
                                }}
                                color="danger">
                                <MDBIcon icon="trash"/>
                            </MDBBtn>
                            <span>
                           Удалить
                            </span>
                        </MDBTooltip>
                    </td>
                </tr>
            )
        });


        return (
            <MDBCard>


                <MDBRow>
                    <MDBCol sm="5">
                        <MDBCardBody>
                            <MDBCardTitle
                                className="view view-cascade d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                Роли доступа к объектам

                                <div>
                                    <MDBTooltip
                                        placement="left">
                                        <MDBBtn
                                            floating
                                            onClick={() => this.props.setCreateMode(true)}
                                            size="sm"
                                            color="success">
                                            <MDBIcon icon="plus"/>
                                        </MDBBtn>
                                        <span>
                           Добавить роль
                            </span>
                                    </MDBTooltip>
                                </div>

                            </MDBCardTitle>
                            <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Роль</th>
                                        <th>Управление</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {rolesRows}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCol>
                    <MDBCol>
                        {this.state.selectedRole &&
                        <MDBCardBody>
                            <MDBCardTitle
                                className="view view-cascade d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                Разрешения
                            </MDBCardTitle>
                            <List>
                                {this.state.selectedPermissions.map(value => {
                                    const labelId = `checkbox-list-label-${value}`;

                                    return (
                                        <ListItem disabled={this.state.selectedRole.isDefault} key={value.id}
                                                  role={undefined} dense button
                                                  onClick={e => this.handleToggle(value)}>
                                            <ListItemIcon style={{marginTop: 20}}>
                                                <Checkbox
                                                    disabled={this.state.selectedRole.isDefault}
                                                    style={{height: 35}}
                                                    edge="start"
                                                    checked={value.value}
                                                    disableRipple
                                                    inputProps={{'aria-labelledby': labelId}}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={value.id} primary={value.name}/>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </MDBCardBody>}
                    </MDBCol>
                </MDBRow>
            </MDBCard>
        );
    }
}

export default withSnackbar(ObjectRoleManagePage);

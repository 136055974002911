import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import path from 'path'
import { MDBIcon } from 'mdbreact'

const FileInput = ({ maxSize, maxCount, onChange, value, cssClass = ""}) => {
    const snackbar = useSnackbar()
    const ref = useRef(null)
    const [files, setFiles] = useState([])

    const maxFileSize = useMemo(() => maxSize * 1024 * 1024, [maxSize])

    const onInput = useCallback(
        ({ target }) => {
            if (target) {
                const fileNames = files.map(file => file.name)
                const newFiles = []

                Object.values(target.files).forEach(item => {
                    newFiles.push(new File([item], getValidFileName(item.name, fileNames), { type: item.type }))
                })

                if (maxSize && newFiles.some(file => file.size > maxFileSize)) {
                    return snackbar.enqueueSnackbar(`Максимальный размер файла ${maxSize}Мб`, {
                        variant: 'warning'
                    })
                }

                if (maxCount && newFiles.length + files.length > maxCount) {
                    return snackbar.enqueueSnackbar(`Максимальное количество файлов ${maxCount}`, {
                        variant: 'warning'
                    })
                }

                setFiles(prev => {
                    const result = [...prev, ...newFiles];
                    if(typeof onChange === 'function'){
                        onChange(result);
                    }
                    return result;
                })
            }
        },
        // eslint-disable-next-line
        [files, maxFileSize]
    )

    const removeFile = useCallback(
        idx => {
            const arr = [...files]
            arr.splice(idx, 1)
            setFiles(arr)
            if(typeof onChange === 'function'){
                onChange(arr);
            }
        },
        //eslint-disable-next-line
        [files]
    )

    useEffect(() => {
        if(value){
            setFiles(value);
        }
    }, [value])

    return (
        <div>
            <div className={"file-input-b " + cssClass}>
                <div className="file-input-b__label">
                    Перетащите сюда нужные файлы, либо нажимете на это окошко.
                    {maxSize && <div className="file-input-b__desc">Максимальный размер файла {maxSize}Мб</div>}
                    {maxCount && <div className="file-input-b__desc">Максимальное количество файлов {maxCount}</div>}
                </div>

                <input ref={ref} value="" type="file" multiple onChange={onInput} />
            </div>
            {files.length > 0 &&
                files.map((item, idx) => <FileItem className={cssClass} key={item.name} file={item} onRemove={() => removeFile(idx)} />)}
        </div>
    )
}

export default FileInput

const FileItem = ({ file, onRemove }) => {
    const reader = useRef(new FileReader())

    const [imagPath, setImagePath] = useState('')
    const ext = useMemo(() => path.extname(file.name), [file.name])

    useEffect(() => {
        if (['.png', '.jpg'].includes(ext)) {
            reader.current.readAsDataURL(file)
        }
    }, [reader, file, ext])

    useEffect(() => {
        reader.current.onloadend = () => {
            setImagePath(reader.current.result)
        }
    }, [reader])

    return (
        <div className="file-b">
            <div className="file-b__img">{imagPath ? <img src={imagPath} alt="" /> : ext}</div>
            <div className="file-b__name">{file.name}</div>
            <button onClick={onRemove} className="file-b__rm btn-no-styles">
                <MDBIcon icon="trash" />
            </button>
        </div>
    )
}

const getValidFileName = (name, fileNames, count = 0) => {
    const ext = path.extname(name)
    const baseName = path.basename(name, ext)
    const fileName = `${baseName}${count === 0 ? '' : `(${count})`}${ext}`
    if (fileNames.includes(fileName)) {
        return getValidFileName(name, fileNames, count + 1)
    }
    return fileName
}

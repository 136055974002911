import React, {Component} from 'react'
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTooltip
} from 'mdbreact'
import DefaultImage from '../../../../static/compayDefaultImage.jpg'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import RequisitesCompanySection from '../sections/RequisitesCompanySection'
import EmployeesCompanySection from '../sections/EmployeesCompanySection'
import OccupiedRoomsCompanySection from '../sections/OccupiedRoomsCompanySection'
import {roomService} from '../../../../services/roomService'
import {contractService} from '../../../../services/contractService'
import CreateRenterPage from './CreateRenterPage'
import CompanyContractsSection from '../sections/CompanyContractsSections'
import ScoreCompanySection from '../sections/ScoreCompanySection'
import IncomeCompanySection from '../sections/IncomeCompanySection'
import {numberWithSpaces} from '../../../../helpers/NumberWithSpaces'
import {incomeService} from '../../../../services/incomeService'
import {scoreService} from '../../../../services/scoreService'
import CreateIncomePage from './CreateIncomePage'
import {renterService} from "../../../../services/renterService";

class CompanySinglePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 0,
            allUsers: [],
            companyRooms: [],
            companyContracts: [],
            createMode: false,
            createIncomeMode: false,
            incomes: [],
            scores: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.setCreateMode = this.setCreateMode.bind(this)
        this.setUsers = this.setUsers.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState
    }

    setCreateMode(bool) {
        this.setState({createMode: bool})
    }

    handleChange(event, newValue) {
        this.setState({activeTab: newValue})
    }

    setUsers(users){
        this.setState({allUsers: users})
    }

    componentDidMount() {
        const id = this.props.companyData.id
        contractService.getByOrganisationId(id)
            .then(_ => {
                const ids = _.map(item => item.id);
                contractService.getByOwnersIds(ids).then(res => {
                    this.setState({companyContracts: res})
                })
            })
        roomService.getByOrganisationId(id).then(_ => {
            this.setState({companyRooms: _})
        })
        incomeService.getByOrganization(id).then(res => this.setState({incomes: res.value}))
        scoreService.getByOrganization(id).then(res => this.setState({scores: res.value}))
        contractService.getByOrganisationId(id).then(_ => this.setState({companyContracts: _}))
        renterService.getByOrganisationId(id).then(_ => this.setState({allUsers: _}))
    }

    updateIncomes = () => {
        incomeService.getByOrganization(this.props.companyData.id).then(res => this.setState({incomes: res.value}))
    }

    render() {
        const companyData = this.props.companyData;
        let imageUrl;
        if (companyData.logoImagePath !== null && companyData.logoImagePath !== '') {
            let headUrl = 'https://';
            let splitOriginal = companyData.logoImagePath.split('/var/www/rentto/data/www/')
            imageUrl = headUrl + splitOriginal[1]
        } else {
            imageUrl = DefaultImage
        }
        if (this.state.createMode) {
            return (
                <CreateRenterPage
                    companyId={this.props.companyData.id}
                    setUsers={this.setUsers}
                    setCreateMode={this.setCreateMode}
                />
            )
        }
        if (this.state.createIncomeMode) {
            return (
                <CreateIncomePage
                    company={this.props.companyData}
                    updateIncomes={this.updateIncomes}
                    setCreateMode={() => this.setState({createIncomeMode: false})}
                />
            )
        }

        return (
            <React.Fragment>
                <MDBCard>
                    <MDBRow>
                        <MDBCol size={3}>
                            <MDBCardImage
                                alt="thumbnail"
                                className="img-thumbnail"
                                src={imageUrl}
                                overlay="white-slight"
                                hover
                                waves
                            />
                        </MDBCol>

                        <MDBCol size="6">
                            <MDBCardBody>
                                <MDBCardTitle>{companyData.fullName}</MDBCardTitle>
                                <MDBTable>
                                    <MDBTableBody>
                                        <tr>
                                            <td>Вид деятельности</td>
                                            <td colSpan={2}>
                                                {companyData.kindActivity}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Юридический адрес:</td>
                                            <td colSpan={2}>
                                                {companyData.legalAddress}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Руководитель:</td>
                                            <td>{companyData.head}</td>
                                            <td>{companyData.phoneHead}</td>
                                        </tr>
                                        {(companyData.chiefAccountan || companyData.phoneChiefAccountant) && <tr>
                                            <td>Главный бухгалтер:</td>
                                            <td>{companyData.chiefAccountant}</td>
                                            <td>{companyData.phoneChiefAccountant}</td>
                                        </tr>}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCardBody>
                        </MDBCol>
                        <MDBCol>
                            <div
                                className="d-flex justify-content-end h4 p-4"
                                style={{height: '100%'}}
                            >
                                Баланс: {numberWithSpaces(Math.round(companyData.cash, -2))} &#8381;
                            </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol>
                            <Tabs
                                value={this.state.activeTab}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab value={0} label="Занятые помещения"/>
                                <Tab value={1} label="Сотрудники компании"/>
                                <Tab value={2} label="Реквизиты"/>
                                <Tab value={3} label="Договоры"/>
                                <Tab value={4} label="Счета"/>
                                <Tab value={5} label="Поступления"/>
                            </Tabs>
                        </MDBCol>
                        {(this.state.activeTab === 1 && (
                            <MDBCol size="3" className="text-right pr-5">
                                <MDBTooltip placement="left">
                                    <MDBBtn
                                        className="g-mr-20"
                                        floating
                                        onClick={() => this.setCreateMode(true)}
                                        size="sm"
                                        color="success"
                                    >
                                        <MDBIcon icon="plus"/>
                                    </MDBBtn>
                                    <span>Добавить пользователя</span>
                                </MDBTooltip>
                            </MDBCol>
                        )) ||
                        (this.state.activeTab === 5 && (
                            <MDBCol size="3" className="text-right pr-5">
                                <MDBTooltip placement="left">
                                    <MDBBtn
                                        className="g-mr-15"
                                        floating
                                        onClick={() =>
                                            this.setState({createIncomeMode: true})
                                        }
                                        size="sm"
                                        color="success"
                                    >
                                        <MDBIcon icon="plus"/>
                                    </MDBBtn>
                                    <span>Добавить поступления</span>
                                </MDBTooltip>
                            </MDBCol>
                        ))}
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <MDBCardBody>
                                <div
                                    style={{
                                        display:
                                            (this.state.activeTab === 0 &&
                                                this.state.companyRooms &&
                                                'block') ||
                                            'none'
                                    }}
                                >
                                    <OccupiedRoomsCompanySection
                                        companyRooms={this.state.companyRooms}
                                    />
                                </div>

                                {this.state.activeTab === 1 && (
                                    <EmployeesCompanySection allUsers={this.state.allUsers}/>
                                )}

                                {this.state.activeTab === 2 && (
                                    <RequisitesCompanySection
                                        companyData={this.props.companyData}
                                    />
                                )}

                                {this.state.activeTab === 3 && (
                                    <CompanyContractsSection
                                        contracts={this.state.companyContracts}
                                    />
                                )}

                                {this.state.activeTab === 4 && (
                                    <ScoreCompanySection scores={this.state.scores}/>
                                )}

                                {this.state.activeTab === 5 && (
                                    <IncomeCompanySection incomes={this.state.incomes}/>
                                )}
                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </React.Fragment>
        )
    }
}

export default CompanySinglePage

import React from 'react'
import {useHistory} from "react-router";
import {MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBBtn} from "mdbreact";
import DeleteModal from '../commonSections/DeleteModalSection'

const EditBlockDropdown = ({permissions, deleteFunc, editLink, deleteLabel, relative, detailsLink}) => {
    const history = useHistory();
    return (
        <div
            className="d-flex justify-content-center align-items-center"
        >
            <MDBDropdown dropleft size="sm">
                <MDBDropdownToggle className="p-0 m-0" flat>
                    <MDBBtn
                        tag="span"
                        outline
                        size="sm"
                        className="p-0 m-0 z-depth-0 content-box border-dark"
                        floating
                        color="mdb-color"
                    >
                        <MDBIcon icon="ellipsis-v" className="blue-grey-text darken-4" />
                    </MDBBtn>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>

                    <MDBDropdownItem onClick={() => {
                        history.push(detailsLink)
                    }}>
                        Подробнее
                    </MDBDropdownItem>

                    <MDBDropdownItem
                        onClick={() => {
                            history.push(editLink)
                        }}
                        disabled={!permissions.find(_ => _.id === 3).value}>
                        Редактировать
                    </MDBDropdownItem>
                    <MDBDropdownItem divider/>
                    <DeleteModal delete={deleteFunc} label={deleteLabel}>
                        {(toggle) => (
                            <button data-test="dropdown-item" className="dropdown-item" type="button" onClick={toggle}>
                                Удалить
                            </button>
                        )}
                    </DeleteModal>
                </MDBDropdownMenu>
            </MDBDropdown>
        </div>
    )
}

export default EditBlockDropdown
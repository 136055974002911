import {handleResponse} from '../helpers';
import {apiUrl} from "../utils/ConnectionStrings";


export const authenticationService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    changePassword
};

export function login(Login, Password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({Login, Password})
    };

    return fetch(apiUrl + "auth/login", requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
}

function forgotPassword(userName) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: userName
    };

    return fetch(apiUrl + "auth/forgot-password/", requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function resetPassword(code, newPassword, confirmPassword) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            code,
            newPassword,
            confirmPassword
        })
    };

    return fetch(apiUrl + "auth/reset-password/", requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function changePassword(id, message) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };

    return fetch(apiUrl + "auth/change-password/" + id, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

import React, { useCallback, useContext } from 'react'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton';

import TableContext from '../../context/TableContext'
import { pagination } from '../../static/enums'

const SmartTable = ({ visibleColumns, data, isSortable }) => {
    const { setSortBy, setDesending, filters, loading} = useContext(TableContext)
    const isDesc = filters[pagination.isDesending] === 'true'
    const sortBy = filters[pagination.sortBy]

    const sortBlockClassName = useCallback((colName) => {
        if(colName === sortBy){
            return isDesc ? "sorting_desc" : "sorting_asc"
        }
        return ''
    }, [isDesc, sortBy])

    const sort = useCallback((fieldName) => {
        if(fieldName === sortBy) {
            setDesending(!isDesc);
        }else{
            setSortBy(fieldName);
        }
    }, [isDesc, setDesending, setSortBy, sortBy])

    if (data.rows.length > 0)
        return (
            <table className="table dataTable table-bordered table-striped smart-table table--small-padding">
                <thead className="datatable-head">
                <tr>
                    {data.columns.map((col, index) => {
                        if (visibleColumns[col.field].checked)
                            return (
                                <td
                                    style={col.style}
                                    key={'index' + index}
                                    width={col.width || 'auto'}
                                    align={visibleColumns[col.field].headAlign || 'left'}
                                    className={classNames(
                                        'smart-table__td',
                                        col.sortName && isSortable && 'sorting',
                                        col.sortName && isSortable && sortBlockClassName(col.sortName)
                                    )}
                                    onClick={() => col.sortName && sort(col.sortName)}
                                >
                                    {col.label}
                                </td>)
                        return null
                    })}
                </tr>
                </thead>
                <tbody>
                {data.rows.map((row, index) => (
                    <tr key={'row-' + index}>
                        {Object.keys(row).map((key) => {
                            if (visibleColumns[key].checked)
                                return (
                                    <td
                                       style={{
                                           padding: loading && "9px",
                                       }}
                                        align={visibleColumns[key].bodyAlign || 'left'}
                                        key={'contract-' + index + '-' + key}
                                    >
                                        {loading ? <Skeleton style={{height: 16}}/> : row[key]}
                                    </td>
                                )
                            return null
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        )
    else {
        return (
            <h4 className="d-flex justify-content-center p-4 text-center">
                Нет данных с такими параметрами =( <br/> Попробуйте изменить критерии поиска.
            </h4>
        )
    }

}

export default SmartTable

import React, { useCallback, useState } from 'react'
import { MDBBtn, MDBInput } from 'mdbreact'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Link } from 'react-router-dom'
import { authenticationService } from '../../../../services/authService'

const ResetPasswordSection = ({ token }) => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const onSubmit = useCallback(async (e) => {
        e.preventDefault()
        setLoading(true)
        setError('')
        const { pass: passNode, confirm: confirmNode } = e.target.elements
        const pass = passNode.value
        const confirm = confirmNode.value
        if (pass !== confirm) {
            setLoading(false)
            return setError('Пароли не совпадают!')
        }
        if (pass.length < 7) {
            setLoading(false)
            return setError('Для паролия должна быть больше 8 символов!')
        }

        authenticationService.resetPassword(token, pass, confirm).then(_ => {
            setSuccess(true)
            setLoading(false)
        }, error => {
            setSuccess(false)
            setLoading(false)
            setError(Array.isArray(error.message) ? error.message[0].Description : error.message)
        })

    }, [token])
    return (
        <>
            {success ? (
                <>
                    <p className="h4">Пароль успешно изменен!</p>
                    <p className="h8">Можете воспользоваться новым паролем для авторизции</p>
                    <Link to="/auth">
                        <div className='text-center'>
                            <MDBBtn
                                className="rounded btn blue-gradient">
                                Перейти к авторизации
                            </MDBBtn>
                        </div>
                    </Link>
                </>
            ) : (<form onSubmit={onSubmit}>
                <MDBInput
                    label="Новый пароль"
                    group
                    validate
                    min={8}
                    error="wrong"
                    success="right"
                    name="pass"
                    type="password"
                    className={'form-control'}
                />
                <MDBInput
                    label="Повторите пароль"
                    group
                    validate
                    min={8}
                    error="wrong"
                    success="right"
                    name="confirm"
                    type="password"
                />
                {error && <div className={'alert alert-danger'}>{error}</div>}
                <div className='text-center'>
                    {loading && <CircularProgress size={30}/>}
                    {!loading && <MDBBtn
                        className="rounded btn blue-gradient"
                        type="submit">
                        Изменить пароль
                    </MDBBtn>}
                </div>
            </form>)}
        </>
    )
}

export default ResetPasswordSection
import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav
} from 'mdbreact';
import {authenticationService} from "../services/authService";

class TopNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false
        };
        this.onClick = this.onClick.bind(this);
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleToggleClickA = this.handleToggleClickA.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse
        });
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    handleToggleClickA() {
        this.props.onSideNavToggleClick();
    }

    logout(event) {
        authenticationService.logout(event);
        this.props.logout();
        window.location = "/auth";
        event.preventDefault();
    }

    render() {
        const navStyle = {
            paddingLeft: this.props.toggle ? '16px' : '240px',
            transition: 'padding-left .3s'
        };


        return (
            <MDBNavbar
                className='flexible-MDBNavbar'
                light
                expand='md'
                scrolling
                fixed='top'
                style={{zIndex: 3}}>
                <div
                    onClick={this.handleToggleClickA}
                    key='sideNavToggleA'
                    style={{
                        lineHeight: '32px',
                        marginleft: '1em',
                        verticalAlign: 'middle',
                        cursor: 'pointer'
                    }}
                >
                    <MDBIcon icon='bars' color='white' size='lg'/>
                </div>

                <MDBNavbarBrand href='/' style={navStyle}>
                    <strong>{this.props.routeName}</strong>
                </MDBNavbarBrand>
                <MDBNavbarNav expand='sm' right style={{flexDirection: 'row'}}>


                    <MDBDropdown>
                        <MDBDropdownMenu right style={{minWidth: '400px'}}>
                            <MDBDropdownItem href='#!'>
                                <MDBIcon icon='money-bill-alt' className='mr-2'/>
                                New order received
                                <span className='float-right'><MDBIcon icon='clock'/> 13 min</span>
                            </MDBDropdownItem>
                            <MDBDropdownItem href='#!'>
                                <MDBIcon icon='money-bill-alt' className='mr-2'/>New order received<span className='float-right'><MDBIcon icon='clock'/> 33 min</span>
                            </MDBDropdownItem>
                            <MDBDropdownItem href='#!'>
                                <MDBIcon icon='chart-line' className='mr-2'/>
                                Your campaign is about to end
                                <span className='float-right'>
                    <MDBIcon icon='clock'/> 53 min
                  </span>
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>

                    <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <MDBIcon icon='user'/>{' '}
                            <span
                                className='d-none d-md-inline'>{this.props.currentUser ? this.props.currentUser.user.surname + " " + this.props.currentUser.user.name : "Профиль"}</span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu right style={{minWidth: '200px'}}>
                            <MDBDropdownItem href='/profile'>
                                {this.props.currentUser &&

                                <NavLink to='/profile'>
                                    <MDBIcon icon="user" className="text-muted mr-3 red-text"/>
                                    Личный кабинет
                                </NavLink>}
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                                {this.props.currentUser && <NavLink to="/auth" onClick={this.logout}>
                                    <MDBIcon icon="sign-out-alt" className="text-muted mr-3 red-text"/>
                                    Выйти
                                </NavLink>}
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavbarNav>
            </MDBNavbar>
        );
    }
}

export default TopNavigation;

import React         from 'react';
import {connect}     from "react-redux";
import {userService} from "../../../services/userService";
import EmployeesPage from "../employees/pages/EmployeesPage"
import {setAllUsers} from "../../../store/users/actions";

class CompanyEmployeesContainer extends React.Component {

    componentDidMount() {
        userService.getUsersByOrganisationId(this.props.match.params.organisationId).then(_ => {
            this.props.setAllUsers(_);
            return true;
        }, error => {
        });
    }

    render() {
        return (
            <EmployeesPage
                allUsers={this.props.allUsers}
                usersListFilter={this.props.usersListFilter}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        allUsers       : state.users.allUsers,
        usersListFilter: state.users.usersListFilter,
        token          : state.user.currentUser.token
    };
};

const mapDispatchToProps = {
    setAllUsers
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyEmployeesContainer);

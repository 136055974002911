import React            from 'react';
import {MDBCol, MDBRow} from "mdbreact";


function BuildingsHelp ({}) {
    return (<>

        <MDBRow>
            <MDBCol size="12">
                <h1>Корпусы и здания</h1>
            </MDBCol>
        </MDBRow>

    </>);
}

export default BuildingsHelp;
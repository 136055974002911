import React, { useCallback, useEffect, useState } from 'react'
import { LinearProgress } from '@material-ui/core'

import BreadcrumSection from '../../../commonSections/BreadcrumSection'
import { permissionsHelper } from '../../../../utils/permissionsHelper'
import OwnersTable from './table'
import { ownersService } from '../../../../services/ownersService'
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow
}                      from 'mdbreact'
import { useSnackbar } from 'notistack'

import withQueryParams from 'react-router-query-params'
import queryString     from "querystring";
import {useHistory}    from "react-router";
import {Link}          from "react-router-dom";

const defaultParams = {
    keys: {
        page: {
            default: 1,
            validate: value => Number(value) > 0
        },
        elementsCount: {
            default: 20,
            validate: value => Number(value) > 0
        }

    }
}


const Owners = (props) => {

    const [permissions, setPermissioins] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const { setQueryParams, queryParams } = props

    const [data, setData] = useState({
        fullCount: undefined,
        data: []
    });

    const getData = useCallback((params) => {
        ownersService.getByPage(queryString.stringify(params)).then(res => {
            setData({
                fullCount: res.fullCount,
                data: res.data,
                count: res.elementCount,
                page:
                    Math.ceil(res.fullCount / res.elementCount) < res.page
                        ? Math.ceil(res.fullCount / res.elementCount)
                        : res.page
            })
        })
    }, [])

    const setParam = (name, value) => setQueryParams({ [name]: value })

    const deleteItem = useCallback((id) => {
        ownersService.remove(id).then(
            _ => {
                enqueueSnackbar( 'Собственник успешно удален', { variant: 'success' })
                getData()
            },
            error => {
                enqueueSnackbar(error, { variant: 'error' })
            }
        )
    }, [enqueueSnackbar, getData])

    useEffect(() => getData(queryParams), [queryParams, getData]);

    useEffect(() => {
        (async () => setPermissioins(await permissionsHelper.scopePermissions()))();
    }, []);

    if (!permissions) return <LinearProgress/>;
    return <>
        <div className="row">
            <div className="col">
                <Breadcrums
                    createPredicate={true}
                    h2="Собственники"
                    buttonName="Добавить собственника"
                    href="/owners/create"

                />
            </div>
        </div>
        <MDBCard>
            <MDBCardBody>
                <OwnersTable
                    queryParams={queryParams}
                    deleteFunc={deleteItem}
                    data={data.data}
                    setParam={setParam}
                    pagination={{
                        page: data.page,
                        fullCount: data.fullCount,
                        count: data.count
                    }}
                />
            </MDBCardBody>
        </MDBCard>
    </>
}

export default withQueryParams(defaultParams)(Owners);


const breadcrumsChain = [['Главная', '/']]

const Breadcrums = props => {

    const breadCrumStyle = {
        fontSize: "0.8rem",
    };
    const history=useHistory ()

    const buttonName = props.buttonName;
    const header = props.h2;
    const filterBtn = props.filterBtn;
    let itemsChain = [];
    let i = 0;
    breadcrumsChain.forEach (function (item) {
        i++;
        itemsChain.push (<MDBBreadcrumbItem key={i}>
            <Link to={item[1]}>{item[0]}</Link>
        </MDBBreadcrumbItem>);
    });
    itemsChain.push (<MDBBreadcrumbItem active key={0}>{header}</MDBBreadcrumbItem>);
    itemsChain.unshift (<MDBBreadcrumbItem key={i+1}>
        <span className="link_to_breadcrumbs" color="link" onClick={history.goBack}>Назад</span>
    </MDBBreadcrumbItem>);

    return (<>
        {itemsChain.length > 0 && (<MDBBreadcrumb style={breadCrumStyle}>{itemsChain}</MDBBreadcrumb>)}
        <MDBCard className="mb-30">
            <MDBCardBody id="breadcrumb" className="pa-16">
                <MDBRow between>
                    <MDBCol size="9" className="d-flex align-items-center">
                        {buttonName && props.createPredicate && (<Link to={props.href} className="mr-3">
                            <MDBBtn
                                floating
                                size="sm"
                                className="z-depth-1"
                                color="success"
                            >
                                <MDBIcon icon="plus"/>
                            </MDBBtn>
                        </Link>)}
                        <MDBCardTitle tag="h5" className="g-mb-0" style={{margin: 0}}>{header}</MDBCardTitle>
                    </MDBCol>
                    {filterBtn && (<MDBCol className="d-flex justify-content-end align-items-center">
                        {filterBtn}
                    </MDBCol>)}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>);
}
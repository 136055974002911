import React from 'react'
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact'
import { getQuantityString } from '../../../../utils/Others'


const ContractSinglePageArchivedRoomsTable = ({rooms}) => {
    return (
        <>
            <div>Архивные данные по договору</div>
            <MDBTable small>
                <MDBTableHead>
                    <tr>
                        <th>#</th>
                        <th>Название</th>
                        <th>ID</th>
                        <th>Площадь</th>
                        <th>Цена</th>
                        <th>Сумма аренды</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {rooms.map((room, index) => (
                        <Row key={'contract-page-room-' + room.id} room={room} index={index}/>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </>
    )
}
export default ContractSinglePageArchivedRoomsTable

const Row = ({room, index}) => {
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{room.name}</td>
            <td>{room.id}</td>
            <td>{getQuantityString(room.square, 'м²')}</td>
            <td>{getQuantityString(room.factualyRents ? room.factualyRents : room.rents, 'р./м²')}</td>
            <td>{getQuantityString(room.amountRent)}</td>
        </tr>
    )
}

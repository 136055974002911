import {SET_IS_LOADING_BOOL} from './actions';

const defaultState = {
  isLoading: false,
};

export const mainReducer =(state = defaultState, action) => {
  switch (action.type) {

    case SET_IS_LOADING_BOOL:
      return {...state, isLoading: action.payload};

    default: return state;
  }
};
import React, {useEffect, useState} from 'react'
import { MDBCol, MDBRow, MDBRangeInput } from 'mdbreact'
import Select from 'react-select'

function useDebounce(value, delay) {

    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay]
);
    return debouncedValue;
}

export default ({ state, selectRenterHandleChange, selectChange, setState }) => {
    const [priority, setPriority] = useState();
    const debouncedPriority = useDebounce(priority, 500)

    useEffect(() => {
        if(debouncedPriority) setState({paymentPriority: debouncedPriority})
        // eslint-disable-next-line
    }, [debouncedPriority])

    return (
        <fieldset className="mb-4">
            <legend>Выбор арендатора</legend>
            <MDBRow>
                <MDBCol size="sm-4">
                    <div className="mb-2">
                        <Select
                            value={state.renter}
                            onChange={e => selectRenterHandleChange('renter', e)}
                            className="border-danger"
                            placeholder="Выбрать арендатора"
                            options={state.allCompaniesOptions}
                        />
                    </div>
                </MDBCol>
                <MDBCol size="sm-4">
                    <div>
                        <Select
                            isDisabled={state.renterContractsOptions.length === 0}
                            value={state.contract}
                            onChange={e => selectChange('contract', e)}
                            placeholder={'Выбрать договор'}
                            options={state.renterContractsOptions}
                        />
                    </div>
                </MDBCol>
                <MDBCol size="sm-4">
                    <label className="mb-0" >Приоритет {state.paymentPriority}</label>
                    <MDBRangeInput value={state.paymentPriority} min={0} max={100} getValue={e => setPriority(e)}  />
                </MDBCol>
            </MDBRow>
        </fieldset>
    )
}

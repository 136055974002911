import {
  CHANGE_ADD_CORP_FORM_STATUS_BOOL, CORPS_CHANGE_COORDINATES_TEXT, CORPS_CHANGE_NAME_TEXT,
  SET_IS_CORP_CREATED_BOOL,
  SET_IS_FORM_CREATE_EMPTY_BOOL,
  SET_iS_LOADING_BOOL,
  CORPS_SET_CURRENT_BUILDING
} from './actions';


const defaultState = {
  isFromCreateEmpty: true,
  isCorpCreated    : false,
  corpFromStatus   : false,
  isLoading        : false,
  currentBuilding  : null
};

export const corpReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_IS_FORM_CREATE_EMPTY_BOOL: {
      return {
        ...state,
        isFromCreateEmpty: action.payload
      };
    }
    case SET_IS_CORP_CREATED_BOOL:
      return {
        ...state,
        isCorpCreated: action.payload
      };

    case CHANGE_ADD_CORP_FORM_STATUS_BOOL:
      return {
        ...state,
        corpFromStatus: action.payload
      };
    case SET_iS_LOADING_BOOL:
      return {
        ...state,
        isLoading: action.payload
      };
    case CORPS_CHANGE_NAME_TEXT:
      return {
        ...state,
        corpName: action.payload
      };
    case CORPS_CHANGE_COORDINATES_TEXT:
      return {
        ...state,
        corpCoordinates: action.payload
      };
    case CORPS_SET_CURRENT_BUILDING:
      return {
        ...state,
        currentBuilding: action.payload
      };



    default:
      return state;
  }
};
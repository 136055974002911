import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LinearProgress } from '@material-ui/core'

import { setIsCreated } from '../../../store/company/actions'
import BreadcrumSection from '../../commonSections/BreadcrumSection'
import AccuralUpdateSection from './sections/AccuralUpdateSection'
import { accrualService } from '../../../services/accrualService'

const UpdateAccrualContainer = props => {
    const [accrual, setAccrual] = useState()
    const id = props.match.params.accrualId;

    useEffect(() => {
        accrualService.getById(id).then(accrual => setAccrual(accrual))
    }, [id])

    const breadcrumsChain = [
        ['Главная', '/main'],
        ['Начисления', '/accruals']
    ]
    if (accrual) {
        return (
            <>
                <BreadcrumSection
                    createPredicate={false}
                    h2="Редактирование начисления"
                    chain={breadcrumsChain}
                    buttonName="начисление"
                    href="accruals/create"
                />
                <AccuralUpdateSection id={id} accrual={accrual} />
            </>
        )
    } else return <LinearProgress />
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        isAdmin: state.user.isAdmin
    }
}

const mapDispatchToProps = {
    setIsCreated
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAccrualContainer)

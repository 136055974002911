import React from "react";
import {
    MDBCard, MDBCardBody,
} from "mdbreact";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CommonInformationProfileSection from "./CommonInformationProfileSection";
import UpdateProfileSection from "./UpdateProfileSection";
import PrivateSettingsProfileSection from "./PrivateSettingsProfileSection";

class ManipulationProfileSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, newValue) {
        this.setState({activeTab: newValue})
    }

    render() {
        return (
            <MDBCard>
                <Tabs
                    value={this.state.activeTab}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary">
                    <Tab value={0} label="Общая информация"/>
                    {this.props.currentUser.id === this.props.selectedUser.id &&
                    <Tab disabled={this.props.currentUser.id !== this.props.selectedUser.id} value={1}
                         label="Редактирование профиля"/>}
                    {this.props.currentUser.id === this.props.selectedUser.id &&
                    <Tab disabled={this.props.currentUser.id !== this.props.selectedUser.id} value={2}
                         label="Конфиденциальность"/>}
                </Tabs>

                <MDBCardBody>
                    {this.state.activeTab === 0 &&
                    <CommonInformationProfileSection/>}

                    {this.state.activeTab === 1 &&
                    <UpdateProfileSection
                        setCurrentUser={this.props.setCurrentUser}
                        token={this.props.token}
                        currentUser={this.props.currentUser}/>}

                    {this.state.activeTab === 2 &&
                    <PrivateSettingsProfileSection
                        token={this.props.token}
                        currentUser={this.props.currentUser}/>}
                </MDBCardBody>
            </MDBCard>
        );
    }
}


export default ManipulationProfileSection;

import React, {Component} from 'react'
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBPopover,
    MDBPopoverBody,
    MDBPopoverHeader,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBTooltip
} from "mdbreact";
import {withSnackbar} from "notistack";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {roleService} from "../../../../../services/roleService";

class UsersManagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRoles: [],
            selectedUser: null
        };
        this.setSelectedUser = this.setSelectedUser.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.selectedRoles !== nextState.selectedRoles ||
            this.state.selectedUser !== nextState.selectedUser ||
            this.props.users !== nextProps.users ||
            this.props.roles !== nextProps.roles;

    }

    async setSelectedUser(selectedUser) {

        let userRoles = null;
        await roleService.getUserRoles(selectedUser.id).then(_ => {
            userRoles = _;
        });
        this.setState({selectedUser: selectedUser});
        this.updateRoleView(userRoles);
    }

    updateRoleView(userRoles) {
        let selectedRoles = [];
        this.props.roles.forEach(_ => {
            let value = false;
            if (userRoles.some(role => role === _.name))
                value = true;

            selectedRoles.push({
                name: _.name,
                id: _.id,
                value: value,
            })
        });

        //TODO sort
        selectedRoles.sort(function (a, b) {
            if (a.id !== b.id) {
                return a.id - b.id
            }
            return 0
        });

        this.setState({selectedRoles: selectedRoles});
    }

    async handleToggle(value) {

        let message = {
            roleName: value.name
        };
        if (value.value) {
            await roleService.removeUserRole(this.state.selectedUser.id, JSON.stringify(message)).then(_ => {
                this.updateRoleView(_);
            });

        } else {
            await roleService.addUserToRole(this.state.selectedUser.id, JSON.stringify(message)).then(_ => {
                this.updateRoleView(_);
            });
        }
    }

    mapTable(users) {
        let count = 0;
        let selectedRoles = this.state.selectedRoles;
        let permissions = this.props.permissions;
        let handleToggle = this.handleToggle;
        let setSelectedUser = this.setSelectedUser;
        return users.map(function (_) {
            count++;
            return (
                <tr key={count}>
                    <td>{count}</td>
                    <td>{_.user.name}</td>
                    <td>{_.user.surname}</td>
                    <td>{_.user.patronymic}</td>
                    {permissions.find(_ => _.id === 18).value &&
                    <td className="margin-buttons">
                        <MDBPopover
                            placement="right"
                            popover
                            clickable
                            id={`popper${count}`}
                        >
                            <MDBBtn
                                floating
                                rounded
                                size="sm"
                                color="primary"
                                onClick={() => setSelectedUser(_)}>
                                <MDBIcon icon="pen"/>
                            </MDBBtn>
                            <MDBPopoverBody>
                                <MDBPopoverHeader>
                                    Роли пользователя
                                </MDBPopoverHeader>
                                <List>
                                    {selectedRoles.map(value => {
                                        const labelId = `checkbox-list-label-${value}`;
                                        return (
                                            <ListItem key={value.id}
                                                      role={undefined} dense button
                                                      onClick={() => handleToggle(value)}>
                                                <ListItemIcon style={{marginTop: 20}}>
                                                    <Checkbox
                                                        style={{height: 35}}
                                                        edge="start"
                                                        checked={value.value}
                                                        disableRipple
                                                        inputProps={{'aria-labelledby': labelId}}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={value.id} primary={value.name}/>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </MDBPopoverBody>
                        </MDBPopover>

                        <MDBTooltip
                            placement="top">
                            <MDBBtn
                                disabled={true}
                                floating
                                size="sm"
                                color="danger">
                                <MDBIcon icon="trash"/>
                            </MDBBtn>
                            <span>
                           Заблокировать
                            </span>
                        </MDBTooltip>
                    </td>}
                </tr>
            )
        });
    }

    render() {
        let employeesRows = this.mapTable(this.props.users);
        return (

            <MDBCard>
                <MDBRow>
                    <MDBCol>
                        <MDBCardBody>
                            <MDBCardTitle
                                className="view view-cascade d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                Управление пользователями
                                {this.props.permissions.find(_ => _.id === 17).value &&
                                <div>
                                    <MDBTooltip
                                        placement="left">
                                        <MDBBtn
                                            floating
                                            onClick={() => this.props.setCreateMode(true)}
                                            size="sm"
                                            color="success">
                                            <MDBIcon icon="plus"/>
                                        </MDBBtn>
                                        <span>
                           Добавить пользователя
                            </span>
                                    </MDBTooltip>
                                </div>}
                            </MDBCardTitle>

                            <MDBCardHeader>
                                Сотрудники
                            </MDBCardHeader>
                            <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Фамилия</th>
                                        <th>Имя</th>
                                        <th>Отчество</th>
                                        {this.props.permissions.find(_ => _.id === 18).value &&
                                        <th>Управление</th>}
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {employeesRows}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCol>
                </MDBRow>
            </MDBCard>
        );
    }
}


export default withSnackbar(UsersManagePage);

import React            from 'react';
import {MDBCol, MDBRow} from "mdbreact";


function GetStartedHelp ({}) {
    return (<>

        <MDBRow>
            <MDBCol size="12">
                <h2>Начало работы</h2>
                <h4 className="my-30">Введение</h4>
                <p>Программа предназначена для управления коммерческими объектами недвижимости и взимоотношениями с арендаторами. В режиме реального времени управлять имущественным комплексом могут несколько человек с разных компьютеров в разных офисах.</p>
                <h4 className="my-30">Подготовьте исходную информацию для внесения в программу</h4>
                <p>Для более эффективного старта управления коммерческой недвижимостью в Rentto, желатенльно иметь под рукой исходную информацию по имущественному комплексу, такую как, ИНН арендаторов, цифровые изображения технических планов этажей, сканированные договоры, и другую информацию, которая вам может пригодится в работе.</p>

            </MDBCol>
        </MDBRow>

    </>);
}

export default GetStartedHelp;
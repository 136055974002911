import {AUTH_CHANGE_EMAIL_TEXT, AUTH_CHANGE_PASSWORD_TEXT, AUTH_SUBMIT_EVENT} from './actions';

const defaultState = {
  email: '',
  password: '',
  currentUser: ''
};

export const authReducer =(state = defaultState, action) => {
  switch (action.type) {

    case AUTH_CHANGE_EMAIL_TEXT:
      return {...state, email: action.payload};

    case AUTH_CHANGE_PASSWORD_TEXT:
      return {...state, password: action.payload};

    case AUTH_SUBMIT_EVENT:
      return {...state, currentUser: action.payload};

    default: return state;
  }
};
import React from 'react'
import IncomeCreateSection from '../sections/create/IncomeCreateSection'
import BreadcrumSection from '../../../commonSections/BreadcrumSection'

const IncomeCreatePage = () => {
    let breadcrumsChain = [
        ['Главная', '/'],
        ['Поступления', '/income']
    ]
    return (
        <>
            <BreadcrumSection
                createPredicate={false}
                h2="Создание поступления"
                chain={breadcrumsChain}
                buttonName="Поступление"
                href="income/create"
            />
            <IncomeCreateSection />
        </>
    )
}

export default IncomeCreatePage

import React from 'react';
import {MDBBtn, MDBInput} from "mdbreact";
import {authenticationService} from "../../../../services/authService";
import { setEmailText, setPasswordText, SubmitEvent } from '../../../../store/auth/actions'
import { setCurrentUser, setIsAdmin, setIsLoggedIn, setToken } from '../../../../store/users/user/actions'
import { connect } from 'react-redux'

export class PasswordRecoverySection extends React.Component {
    errors = false;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            email: "",
            isRecovered: false
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.error !== nextState.error ||
            this.state.isRecovered !== nextState.isRecovered ||
            this.state.email !== nextState.email;
    }


    onEmailChange(event) {
        this.setState({email: event.target.value});
    }


    onSubmitForm(event) {
        event.preventDefault(); //TODO: Не отрабатывает функция восстановления пароля. Необходимо авторизоваться на mail.ru и подтвердить домен (необходимо проверить какой именно ящик подключен)
        authenticationService.forgotPassword(JSON.stringify({userName: this.state.email}))
            .then(user => {
                console.log("User", user)
                this.setState({
                    isRecovered: true
                });
            }, error => {
                console.log("Error", error)
                this.setState({
                    error: error,
                    isRecovered: false
                });
            });
    }


    render() {

        return (
            <React.Fragment>
                {!this.state.isRecovered &&
                <form onSubmit={this.onSubmitForm}>
                    <p className="h8">Введите адрес электронной почты, на который будет выслана инструкция по смене
                        пароля</p>
                    <div className="grey-text">
                        <MDBInput
                            label="E-mail"
                            group
                            validate
                            error="wrong"
                            success="right"
                            className={'form-control'}
                            name="username"
                            type="text"
                            onChange={event => this.onEmailChange(event)}
                            value={this.state.email}/>
                    </div>
                    {this.state.error &&
                    <div className={'alert alert-danger'}>{this.state.error}</div>}
                    <div className='text-center'>
                        <MDBBtn
                            type="submit"
                            className="rounded btn blue-gradient">
                            ПРОДОЛЖИТЬ
                        </MDBBtn>
                    </div>
                </form>}

                {this.state.isRecovered &&
                <form>
                    <p className="h4">Восстановление пароля</p>
                    <p className="h8">На вашу почту было отправлено письмо с новым паролем, после входа рекомендуется
                        сменить пароль в меню профиля.</p>
                    <a href="/auth">
                        <div className='text-center'>
                            <MDBBtn
                                className="rounded btn blue-gradient">
                            Перейти к авторизации
                        </MDBBtn>
                        </div>
                    </a>
                </form>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state =>{
    return {
        email:      state.auth.email,
        password:   state.auth.password,
        currentUser:state.user.currentUser,
        isAdmin:    state.user.isAdmin,
        token:      state.user.token,
        isLoggedIn: state.user.isLoggedIn,
    };
};

const mapDispatchToProps = {
    setEmailText,
    setPasswordText,
    SubmitEvent,
    setCurrentUser,
    setIsAdmin,
    setToken,
    setIsLoggedIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoverySection);
import React, {useState, useEffect} from 'react'
import {MDBCol, MDBInput, MDBRow} from 'mdbreact'
import Select from 'react-select'
import {roomState, type1, type2, type3, typesMatching} from '../../../../utils/RoomTypes'
import {buildingService} from '../../../../services/buildingService'
import {floorService} from '../../../../services/floorService'
import {objectService} from "../../../../services/objectService";
import {employeeService} from "../../../../services/employeeService";

export default ({state, handleChange, isLoading}) => {
    //states
    const [rentersOptions, setRentersOptions] = useState([]);
    const [corpOptions, setCorpOptions] = useState([]);
    const [floorOptions, setFloorOptions] = useState([]);
    const [managerOptions, setManagerOptions] = useState([]);

    //listeners
    useEffect(() => {
        objectService
            .getAll()
            .then(res => {
                return res.map(object => ({
                    label: object.name,
                    value: object.id
                }))
            })
            .then(arr => setRentersOptions(arr))
    }, []);

    useEffect(() => {
        employeeService
            .getAll()
            .then(res => {
                return res.map(object => ({

                    label: `${object.user.name} ${object.user.surname} - ${object.position}`,
                    value: object.id
                }))
            })
            .then(arr => {
                setManagerOptions(arr)
            })
    }, []);

    useEffect(() => {
        if (state.objectId)
            buildingService
                .getAllByObjectId(state.objectId)
                .then(res => {
                    return res.map(corp => ({
                        label: corp.corpName,
                        value: corp.id
                    }))
                })
                .then(options => setCorpOptions(options))
    }, [state.objectId]);

    useEffect(() => {
        if (state.corpId)
            floorService
                .getAll(state.corpId)
                .then(res => {
                    res.sort((a, b) => a.floorNumber - b.floorNumber)
                    return res.map(floor => ({
                        label: floor.floorName,
                        value: floor.id
                    }))
                })
                .then(options => setFloorOptions(options))
    }, [state.corpId]);

    useEffect(() => {
        handleChange("type2", state.type2)
        //eslint-disable-next-line
    }, [state.type1]);

    //functions
    const mapOptions = function (obj) {
        return Object.keys(obj).map(key => ({
            label: obj[key],
            value: key
        }))
    };
    //maping options
    const roomStateOptions = mapOptions(roomState);
    const type1Options = mapOptions(type1);
    const type2Options = state.type1 ? typesMatching[Number(state.type1)].map(key => ({label: type2[key],value: key})) : [];
    const type3Options = mapOptions(type3);

    return (
        <fieldset className="mb-4">
            <legend>Базовая информация</legend>
            <MDBRow>
                <MDBCol sm={12} md={4} className="mb-3">
                    <label className="mb-0">Наименование</label>
                    <MDBInput
                        name="name"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        containerClass="m-0"
                        className="m-0"
                        value={state.name || ''}
                        getValue={e => handleChange('name', e)}
                        outline
                        disabled={isLoading}
                    />
                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-3">
                    <div className="mb-2">
                        <label className="mb-0">Вид объекта аренды</label>
                        <Select
                            isDisabled={isLoading}
                            placeholder="Выбрать..."
                            options={type1Options}
                            onChange={e => handleChange('type1', Number(e.value))}
                            value={
                                (state.type1 || Number(state.type1) === 0) &&
                                type1Options.find(op => Number(op.value) === Number(state.type1))
                            }
                        />
                    </div>
                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-3">
                    <div className="mb-2">
                        <label className="mb-0">Тип объекта аренды</label>
                        <Select
                            placeholder="Выбрать..."
                            isDisabled={!state.type1 || isLoading}
                            options={type2Options}
                            onChange={e => handleChange('type2', Number(e.value))}
                            value={
                                (state.type2 || Number(state.type2) === 0) &&
                                type2Options.find(
                                    op => Number(op.value) === Number(state.type2)
                                )
                            }
                        />
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol sm={12} md={4} className="mb-3">
                    <div className="mb-2 ">
                        <label className="mb-0">Объект</label>
                        <Select
                            isDisabled={isLoading}
                            placeholder="Выбрать..."
                            options={rentersOptions}
                            onChange={e => handleChange('objectId', Number(e.value))}
                            value={
                                state.objectId &&
                                rentersOptions.find(
                                    op => Number(op.value) === Number(state.objectId)
                                )
                            }
                        />
                    </div>
                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-3">
                    <div className="mb-2">
                        <label className="mb-0">Корпус</label>
                        <Select
                            placeholder="Выбрать..."
                            isDisabled={!state.objectId || isLoading}
                            options={corpOptions}
                            onChange={e => handleChange('corpId', Number(e.value))}
                            value={
                                state.corpId &&
                                corpOptions.find(op => Number(op.value) === Number(state.corpId))
                            }
                        />
                    </div>
                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-3">
                    <div className="mb-2">
                        <label className="mb-0">Этаж</label>
                        <Select
                            placeholder="Выбрать..."
                            isDisabled={!state.corpId || isLoading}
                            options={floorOptions}
                            onChange={e => handleChange('floor', Number(e.value))}
                            value={
                                state.floor &&
                                floorOptions.find(op => Number(op.value) === Number(state.floor))
                            }
                        />
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol sm={12} md={4}>
                    <div className="mb-3">
                        <label className="mb-0">Состояние</label>
                        <Select
                            isDisabled={isLoading}
                            options={roomStateOptions}
                            placeholder="Выбрать..."
                            onChange={e => handleChange('roomState', Number(e.value))}
                            value={
                                (state.roomState || Number(state.roomState) === 0) &&
                                roomStateOptions.find(
                                    op => Number(op.value) === Number(state.roomState)
                                )
                            }
                        />
                    </div>
                </MDBCol>
                <MDBCol sm={12} md={4}>
                    <div className="mb-3">
                        <label className="mb-0">Статус</label>
                        <Select
                            placeholder="Выбрать..."
                            isDisabled={isLoading}
                            options={type3Options}
                            onChange={e => handleChange('type3', Number(e.value))}
                            value={
                                (state.type3 || Number(state.type3) === 0) &&
                                type3Options.find(
                                    op => Number(op.value) === Number(state.type3)
                                )
                            }
                        />
                    </div>
                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-3">
                    <div className="mb-3">
                        <label className="mb-0">Менеджер</label>
                        <Select
                            placeholder="Выбрать..."
                            isDisabled={isLoading}
                            options={managerOptions}
                            onChange={e => handleChange('managerId', Number(e.value))}
                            value={
                                state.managerEmployeeId &&
                                managerOptions.find(
                                    op => Number(op.value) === Number(state.managerEmployeeId)
                                )
                            }
                        />
                    </div>
                </MDBCol>
                <MDBCol sm={8} className="mb-3">
                    <label className="mb-0">Название блока</label>
                    <MDBInput
                        disabled={isLoading}
                        name="name"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        containerClass="m-0"
                        className="m-0"
                        value={state.blockName || ''}
                        getValue={e => handleChange('blockName', e)}
                        outline
                    />
                </MDBCol>

                <MDBCol sm={12}>
                    <label className="mb-0">Описание</label>
                    <MDBInput
                        style={{minHeight: 117}}
                        disabled={isLoading}
                        name="description"
                        outline
                        group
                        type="textarea"
                        validate
                        error="wrong"
                        success="right"
                        containerClass="m-0"
                        className="m-0 rounded"
                        valueDefault={state.blockDescription || ''}
                        getValue={e => handleChange('blockDescription', e)}
                    />
                </MDBCol>
            </MDBRow>
        </fieldset>
    )
}

import React, {useCallback, useEffect, useState} from "react";
import {useSnackbar}                             from "notistack";
import {useHistory}                              from "react-router";
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle, MDBCarousel, MDBCarouselInner, MDBCarouselItem,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSpinner, MDBView,
}                       from "mdbreact";
import ObjectUpdateForm from "../forms/objectUpdateForm";
import objectClone      from "lodash/cloneDeep";
import {objectService}  from "../../../../services/objectService";
import {Link}           from "react-router-dom";
import FileInput                                                from "../../../others/FileInput";
import {Backdrop, Box, Fade, LinearProgress, Modal, Typography} from "@material-ui/core";
import Button                                                   from "@material-ui/core/Button";


const ObjectUpdate = (props) => {
    const [loading, setLoading] = useState(false);
    const objectId              = props.match.params.objectId;
    //states
    const history               = useHistory();
    const {enqueueSnackbar}     = useSnackbar();
    let breadcrumsChain         = [["Главная", "/"], ["Объекты", "/objects"],];

    const [files, setFiles] = useState([])

    const [buildings, setBuildings]       = useState({corps: []});
    const [dataToUpdate, setDataToUpdate] = useState({});
    const [dataToDelete, setDataToDelete] = useState({buildings: []});

    const loadObject = useCallback(async () => {
        await objectService.getById(objectId).then(_ => {
            // enqueueSnackbar("Объект с сервера получен", {
            //     variant: 'success'
            // })
            const dataToUpdate = {
                id             : _.id,
                name           : _.name,
                cityName       : _.cityName,
                streetName     : _.streetName,
                houseNumber    : _.houseNumber,
                promotionalName: _.promotionalName,
                images: _.images,
            }
            setDataToUpdate(dataToUpdate)
            setBuildings({corps: _.corps})
        })
        setLoading(false)
    }, [objectId])

    useEffect(() => {
        setLoading(true)
        loadObject()
    }, [loadObject])

    const updateObject = (name, value) => {
        setDataToUpdate({...dataToUpdate, [name]: value})
    }

    const updateBuilding = (name, value, buildingArrayIndex) => {
        const b = objectClone(buildings.corps)
        b[buildingArrayIndex][name] = value
        setBuildings({corps: b})
    }

    const deleteBuilding = (buildingArrayIndex, corps, dataToDelete) => {
        const buildings = objectClone(corps)
        dataToDelete.buildings.push(buildings[buildingArrayIndex])
        buildings.splice(buildingArrayIndex, 1)
        setBuildings({...buildings, corps: buildings})
        setDataToDelete({...dataToDelete, buildings: dataToDelete.buildings})
    }

    const buildingCreate = async () => {
        setLoading(true)
        const newBuilding = {
            corpName: "", coordinates: "",// отмечаем для дальнейшего создания на сервере
        }

        let corpFormData = new FormData()
        corpFormData.set('corpName', newBuilding.corpName)
        corpFormData.set('coordinates', newBuilding.coordinates)

        await objectService.createCorp(dataToUpdate.id, corpFormData).then(_ => {
            _.forEach(obj => {
                if (obj.id === dataToUpdate.id) {
                    setBuildings({...buildings, corps: obj.corps})
                }
            })
        })
        setLoading(false)
    }

    const saveObjectUpdated = async (_) => {
        setLoading(true)

        let objectFormData = new FormData()
        objectFormData.set('Name', _.name)
        objectFormData.set('CityName', _.cityName)
        objectFormData.set('StreetName', _.streetName)
        objectFormData.set('HouseNumber', _.houseNumber)
        objectFormData.set('PromotionalName', _.promotionalName)

        files.forEach(file => objectFormData.append('Images', file))

        try {
            await objectService.update(_.id, objectFormData).then(_ => {
                buildings.corps.forEach(corp => {
                    let corpFormData = new FormData()
                    corpFormData.set('corpName', corp.corpName)
                    corpFormData.set('coordinates', corp.coordinates)
                    objectService.updateCorp(corp.id, corpFormData)
                })
                dataToDelete.buildings.forEach(b => {
                    objectService.deleteCorp(b.id).then(_ => {console.log(_)})
                })
            })
            setLoading(false)
            history.push("/objects/" + objectId)
            enqueueSnackbar('Объект успешно добавлен', {variant: 'success'})
        } catch (err) {
            enqueueSnackbar(err, {variant: 'error'})
        }

    }

    if (dataToUpdate === undefined) {
        return <LinearProgress />
    }
    else {
        return (<>
            <Breadcrums h2={"Редактирование объекта: " + dataToUpdate.name} chain={breadcrumsChain} object={dataToUpdate} history={history}/>
            <MDBRow>
                <MDBCol>
                    <MDBCard className="mb-30 pb-20 pt-30 br-16">
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <ObjectUpdateForm
                                        {...{
                                            dataToUpdate,
                                            updateObject,
                                            buildingCreate,
                                            updateBuilding,
                                            deleteBuilding,
                                            loading,
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow middle end>
                                <MDBCol sm="6" className="text-right">
                                    {loading && <MDBSpinner small green/>}
                                    <MDBBtn onClick={history.goBack} color="link">
                                        Отменить
                                    </MDBBtn>
                                    <MDBBtn
                                        id="create-btn"
                                        onClick={() => saveObjectUpdated(dataToUpdate)}
                                        disabled={!(dataToUpdate.name && dataToUpdate.cityName && dataToUpdate.streetName && !loading)}
                                        outline
                                        color="success"
                                    >
                                        Сохранить
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBRow className="mb-30">
                        <MDBCol>
                            {loading && <MDBSpinner small green/>}
                            {!loading && <MDBBtn
                                floating
                                disabled={loading}
                                size="sm"
                                className="btn_building_create"
                                color="success"
                                onClick={() => {buildingCreate()}}
                            >
                                <MDBIcon icon="plus"/>
                            </MDBBtn>}
                            <h3 className="object_update block_title">Корпусы</h3>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <Buildings buildings={buildings.corps}
                                       updateBuilding={updateBuilding}
                                       deleteBuilding={deleteBuilding}
                                       dataToDelete={dataToDelete}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol>
                    <MDBCard className="object_update pa-0">
                        <MDBCardBody className="pa-0">
                            <MDBRow>
                                <MDBCol>
                                    <CarouselPage imagesArray={dataToUpdate.images} cssClass={"mdbcarousel object_form_update"}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className='pa-24'>
                                    <FileInput
                                        value={files}
                                        cssClass="object_form"
                                        onChange={setFiles}
                                        style={{
                                            position    : "absolute",
                                            minHeight   : "100px",
                                            top         : 0,
                                            bottom      : 0,
                                            left        : 0,
                                            right       : "16px",
                                            background  : "#ececec",
                                            borderRadius: "8px",
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </>);
    }

};
export default ObjectUpdate;

const Breadcrums = props => {
    const breadCrumStyle = {
        padding     : "0 20px",
        fontSize    : "0.8rem",
    };
    const header         = props.h2;
    const chain          = props.chain;
    const filterBtn      = props.filterBtn;
    let itemsChain       = [];
    let i                = 0;
    chain.forEach(function (item) {
        i++;
        itemsChain.push(<MDBBreadcrumbItem key={i}>
            <Link to={item[1]}>{item[0]}</Link>
        </MDBBreadcrumbItem>);

    });
    itemsChain.unshift(<MDBBreadcrumbItem key={321}>
        <span className="link_to_breadcrumbs" color="link" onClick={props.history.goBack}>Назад</span>
    </MDBBreadcrumbItem>);
    itemsChain.push(<MDBBreadcrumbItem key={i}>
        <Link to={"/objects/" + props.object.id}>{props.object.name}</Link>
    </MDBBreadcrumbItem>);
    itemsChain.push(<MDBBreadcrumbItem active key={951}>
        {header}
    </MDBBreadcrumbItem>);

    return (<>
        {props.chain.length > 0 && (<MDBBreadcrumb style={breadCrumStyle}>{itemsChain}</MDBBreadcrumb>)}
        <MDBCard className="mb-3">
            <MDBCardBody id="breadcrumb" style={{padding: 20}}>
                <MDBRow between>
                    <MDBCol size="9" className="d-flex align-items-center">
                        <MDBCardTitle tag="h5" style={{margin: 0}}>
                            {header}
                        </MDBCardTitle>
                    </MDBCol>
                    {filterBtn && (<MDBCol className="d-flex justify-content-end align-items-center">
                        {filterBtn}
                    </MDBCol>)}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>);
}

const Buildings = ({buildings, updateBuilding, deleteBuilding, dataToDelete}) => {
    return buildings.map((building, buildingArrayIndex) => {
        return <BuildingsFields
            key={buildingArrayIndex}
            building={building}
            buildings={buildings}
            buildingArrayIndex={buildingArrayIndex}
            updateBuilding={updateBuilding}
            deleteBuilding={deleteBuilding}
            dataToDelete={dataToDelete}
        />
    })
}

const BuildingsFields = ({building, buildings, buildingArrayIndex, updateBuilding, deleteBuilding, dataToDelete}) => {
    return (<>
        <MDBCard className={"object_update mb-30"}>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol md={10}>
                        <MDBInput
                            className="mb-0"
                            label="Название корпуса"
                            name="buildingName"
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={building.corpName}
                            getValue={e => updateBuilding('corpName', e, buildingArrayIndex, buildings)}
                        />
                    </MDBCol>
                    <MDBCol className="text-right">
                        <button className="object_update button_delete"
                                onClick={() => deleteBuilding(buildingArrayIndex, buildings, dataToDelete)}
                        ><i className="fa fa-trash-alt"/> Удалить
                        </button>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>)
}

const CarouselPage = ({imagesArray = [], cssClass=""}) => {
    return (
        <MDBCarousel interval={8000} activeItem={1} length={imagesArray.length} showControls={true} showIndicators={false} className={"z-depth-1 " + cssClass}>
            <MDBCarouselInner className="br-16">
                <ImageItemForCarousel imagesArray = {imagesArray}/>
            </MDBCarouselInner>
        </MDBCarousel>
    );
}

const ImageItemForCarousel = ({imagesArray}) => {
    return imagesArray.map( (img, i) => {
        const imageUrl = "https://" + img.filePath.split("/var/www/rentto/data/www/")[1];
        return (<>
            <MDBCarouselItem itemId={i+1}>
                <MDBView>
                    <img className="d-block w-100" src={imageUrl} alt="Mattonit's item" />
                </MDBView>
            </MDBCarouselItem>
        </>)
    })
}
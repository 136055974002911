import {createContext} from 'react'

//Значения поумолчанию выставлены для того,
//чтобы избежать краша приложения во время вызова
//функции, которую не переопределили во время использования контекста
const defaultValue = {
    roomsData: {
        count: 50,
        data: [],
        fullCount: 200,
        page: 1
    },
    loading: false,
    permissions: [],
    filterVisible: false,
    setFilterVisible: () => {},
    setPage: () => {},
    setCount: () => {},
    filters: {},
    getData: () => {},
    setSortBy: () => {},
    setDesending: () => {}
}

export default createContext(defaultValue)
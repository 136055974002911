import React, {Component}                                  from 'react';
import {MDBCard, MDBCardBody, MDBCol, MDBProgress, MDBRow} from 'mdbreact';
import StatisticRoomCard                                   from "../sections/StatisticRoomCard";
import StatisticUserCard                                   from "../sections/StatisticUserCard";
import {reportService}                                     from "../../../../services/reportService";
import {setCurrentUser}                                    from "../../../../store/users/user/actions";
import {connect}                                           from "react-redux";
import {LinearProgress}                                    from "@material-ui/core";
import DashboardCardsSection                               from "../sections/DashboardCardsSection";
import EndingContractsTable                                from "../sections/EndingContractsTable";

class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leaseContracts: null,
            nextBirthDays : null,
            newUsers      : null,
        };
        reportService.getIndexPageReport().then(_ => {
            this.setState({
                nextBirthDays : _.nextBirthDaysUserAccounts,
                newUsers      : _.newUserAccounts,
                leaseContracts: _.leaseContracts
            })
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    render() {

        if (!this.state.leaseContracts) return (<LinearProgress/>);
        return (

            <React.Fragment>
                <MDBRow className="mb-3 d-flex align-items-stretch">
                    <MDBCol size="md-4">
                        {this.state.newUsers && <StatisticUserCard newUsers={this.state.newUsers}/>}
                    </MDBCol>
                    <MDBCol size="md-8">
                        <DashboardCardsSection/>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBProgress value={63} className="my-3" height="5px" color="primary"/>
                                <span>63% - доля полезных площадей</span>
                                <MDBProgress value={21} className="my-3" height="5px" color="success"/>
                                <span>21% - доля мест общего пользования</span>
                                <MDBProgress value={16} className="my-3" height="5px" color="danger"/>
                                <span>16% - доля технических площадей</span>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="md-8">
                        <EndingContractsTable contracts={this.state.leaseContracts}/>
                    </MDBCol>
                    <MDBCol size="md-4">
                        <MDBRow className="mb-3">
                            <MDBCol>
                                <StatisticRoomCard/>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </React.Fragment>

        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser
    };
};

const mapDispatchToProps = {
    setCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);

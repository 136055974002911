import React from 'react';
import {authenticationService} from '../../../../services/authService';
import {MDBBtn, MDBInput} from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setCurrentUser, setIsLoggedIn, setToken } from '../../../../store/users/user/actions'
import { connect } from 'react-redux'


class AuthSection extends React.Component {
    errors = false;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isRedirect: false,
            isLoading: false,
            email: "",
            password: ""
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    onFileldChange(e, name) {
        this.setState({[name]: e.target.value})
    }

    setCurrentUserWithToken(user) {
        if (!user) return;
        this.props.setCurrentUser(user.user);
        this.props.setToken(user.token);
        this.props.setIsLoggedIn(true);
        window.location = "/main"
    };

    onSubmitForm(event) {
        event.preventDefault();
        this.setState({isLoading: true});
        authenticationService.login(this.state.email, this.state.password)
            .then(user => {
                this.setCurrentUserWithToken(user);

            }, error => {
                this.setState({
                    error: error,
                    isLoading: false
                });
            });
    }

    render() {
        return (
            <form>
                <MDBInput
                    label="E-mail"
                    group
                    validate
                    error="wrong"
                    success="right"
                    name="surname"
                    type="text"
                    className={'form-control'}
                    onChange={e => this.onFileldChange(e, "email")}
                    value={this.state.email}/>
                <MDBInput
                    label="Пароль"
                    group
                    validate
                    error="wrong"
                    success="right"
                    name="password"
                    type="password"
                    onChange={e => this.onFileldChange(e, "password")}
                    value={this.state.password}/>
                {this.state.error &&
                <div className={'alert alert-danger'}>{this.state.error}</div>
                }
                <div className='text-center'>
                    {this.state.isLoading && <CircularProgress size={30}/>}
                    {!this.state.isLoading && <MDBBtn
                        className="rounded btn blue-gradient"
                        onClick={e => this.onSubmitForm(e)}
                        type="submit">
                        Войти
                    </MDBBtn>}
                </div>
                <div className=" text-right">
                    <a href="/password-recovery"><u>Восстановление пароля</u></a>
                </div>
            </form>
        )
    }
}
const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    };
};

const mapDispatchToProps = {
    setCurrentUser,
    setToken,
    setIsLoggedIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthSection);
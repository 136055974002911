import {handleResponse} from '../helpers';
import {apiUrl} from "../utils/ConnectionStrings";

export const companyService = {
    create,
    update,
    remove,
    getAll,
    uploadFile,
    getById
};

function create(companyModel) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: companyModel
    };

    return fetch(apiUrl + "companies/", requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function uploadFile(id, file) {
    const formData = new FormData();
    formData.append('body', file);
    const uploadRequestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json'
        },
        body: formData
    };
    return fetch(apiUrl + 'companies/upload-image/' + id, uploadRequestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        }).then(getAll);
}

function update(id, companyModel) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: companyModel
    };
    return fetch(apiUrl + "companies/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        }).then(getAll);
}


function remove(companyId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "companies/" + companyId, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "companies/", requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "companies/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
                return _;
            }
        );
}


import React, { useReducer } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBInput, MDBModalFooter, MDBRow } from 'mdbreact'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from 'react-select'
import moment from 'moment'
import { incomeService } from '../../../../services/incomeService'
import { withSnackbar } from 'notistack'


function useSetState(initialState) {
    const [state, setState] = useReducer(
        (state, newState) => ({
            ...state,
            ...newState
        }),
        initialState
    )
    return [state, setState]
}

const CreateIncomePage = ({ setCreateMode, company,updateIncomes, ...props }) => {
    const [state, setState] = useSetState({
        isLoading: false,
        description: '',
        companyId: company.id,
        incomeDate: moment().format('YYYY-MM-DD'),
        amount: ''
    })

    const submit = e => {
        e.preventDefault()
        if (state.companyId === '' || state.description === '' || state.amount === '') {
            props.enqueueSnackbar('Заполните все поля', { variant: 'warning' })
            return
        }
        setState({ isLoading: true })

        const model = JSON.stringify({
            description: state.description,
            incomeDate: state.incomeDate,
            companyId: state.companyId,
            amount: state.amount
        })
        incomeService.create(model).then(
            _ => {
                props.enqueueSnackbar('Поступление успешно создано', { variant: 'success' })
                updateIncomes()
                setCreateMode()
            },
            error => {
                props.enqueueSnackbar(error, { variant: 'error' })
                setState({ isLoading: false })
            }
        )
    }

    return (
        <MDBCard>
            <MDBCardTitle><div className="h5 p-4">Создание поступления</div></MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol size="sm-4">
                        <div className="grey-text">
                            <MDBInput
                                label="Дата поступления"
                                group
                                type="date"
                                validate
                                error="wrong"
                                success="right"
                                onChange={e => setState({ incomeDate: e.target.value })}
                                value={state.incomeDate}
                            />
                        </div>
                    </MDBCol>

                    <MDBCol size="sm-4">
                        <div className="grey-text">
                            <MDBInput
                                label="Описание"
                                group
                                type="text"
                                validate
                                error="wrong"
                                success="right"
                                onChange={e => setState({ description: e.target.value })}
                                value={state.description}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol size="sm-4">
                        <div className="grey-text">
                            <MDBInput
                                label="Сумма поступлении"
                                group
                                type="number"
                                validate
                                error="wrong"
                                success="right"
                                onChange={e => setState({ amount: e.target.value })}
                                value={state.amount}
                            />
                        </div>
                    </MDBCol>
                    <MDBCol size="sm-4" className="form-group">
                        <div className="md-form form-group">
                            <Select
                                style={{ border: 'none' }}
                                isDisabled={true}
                                placeholder={company.fullName}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>

            <MDBModalFooter>
                    {state.isLoading && <CircularProgress size={30} />}
                    <MDBBtn
                        outline
                        color="blue-grey"
                        disabled={state.isLoading}
                        onClick={() => setCreateMode()}
                    >
                        Назад
                    </MDBBtn>
                    <MDBBtn
                        disabled={state.isLoading}
                        outline
                        type="submit"
                        onClick={submit}
                        color="success"
                    >
                        Сохранить
                    </MDBBtn>
                </MDBModalFooter>

        </MDBCard>
    )
}
export default withSnackbar(CreateIncomePage)

import { handleResponse } from '../helpers'

const mapFromServer = (serverData) => ({
    inn: serverData.inn || '',
    kpp: serverData.kpp || '',
    certificateOgrn: serverData.ogrn || '',
    okpo: serverData.okpo || '',
    shortName: serverData.name_org.short_with_opf || '',
    fullName: serverData.name_org.full_with_opf || '',
    okved: serverData.okved || '',
    legalAddress: serverData.yur_address_egrul || '',
    mailingAddress: serverData.yur_address_for_1c || '',
    head: serverData.ruk_fio || '',
    kindActivity: (serverData.okveds && serverData.okveds.length) ? serverData.okveds[0].name : ''
})

const fetchCompanyDataByInn = (inn) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return fetch(`https://iswork.org/api/v1/full/get-company/?token=cdb973d5-e4ce-4b6c-9215-ed76a7e39f26&inn=${inn}`, requestOptions)
        .then(handleResponse)
        .then(_ => {
                if (_.data.length) {
                    return mapFromServer(_.data[0].basic_information)
                }
            }
        )
}


export const isworkService = {
    fetchCompanyDataByInn
}
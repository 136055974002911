import React from 'react'
import { MDBBadge, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from 'mdbreact'
import { useHistory } from 'react-router'

import InputDebounce from '../../../items/InputDebounce'
import { deleteAllParams } from '../../../../utils/queryDeleteFunctions'

const Filter = ({visibleColumns, setVisibleColumns, show, setShow, setParam, queryParams}) => {
    const history = useHistory();
    const check = name => () => setVisibleColumns({
            ...visibleColumns,
            [name]: {
                ...visibleColumns[name],
                checked: !visibleColumns[name].checked
            }
        })

    return <>
        {show && <div>
            <div className="d-flex justify-content-between">
                <MDBCardTitle className="card-title d-flex justify-content-between">
                    Фильтры
                </MDBCardTitle>
                <button
                    className="btn-no-styles"
                    style={{fontSize: 14, cursor: 'pointer'}}
                    onClick={() => setShow(false)}
                >
                    <span className="pr-2"><MDBIcon icon="angle-up"/></span>
                    Скрыть основные фильтры
                </button>
            </div>

            <MDBRow>
                <MDBCol className="mb-3" sm={12} lg={6} xl={3}>
                    <InputDebounce
                        group
                        type="text"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        label="Вид деятельности"
                        valueDefault={(queryParams && queryParams.inputKindActivity) || ''}
                        getValue={val => setParam('inputKindActivity', val)}
                    />
                </MDBCol>
                <MDBCol className="mb-3" sm={12} lg={6} xl={3}>
                    <InputDebounce
                        group
                        type="text"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        label="ИНН"
                        valueDefault={(queryParams && queryParams.inputInn) || ''}
                        getValue={val => setParam('inputInn', val)}
                    />
                </MDBCol>
                <MDBCol className="mb-3" sm={12} lg={6} xl={3}>
                    <InputDebounce
                        group
                        type="text"
                        containerClass="m-0"
                        className="m-0"
                        outline
                        label="Руководитель"
                        valueDefault={(queryParams && queryParams.inputHead) || ''}
                        getValue={val => setParam('inputHead', val)}
                    />
                </MDBCol>
            </MDBRow>

            <hr className="mt-0"/>
            <div>
                {Object.keys(visibleColumns).map(key => {
                    return (
                        <MDBBadge
                            key={key}
                            style={{ cursor: 'pointer' }}
                            pill
                            onClick={check(key)}
                            className="p-2 mr-2 mb-2 none-select"
                            color={visibleColumns[key].checked
                                ? (visibleColumns[key].color ?? 'primary')
                                : 'light'
                            }
                        >
                            {visibleColumns[key].label}
                        </MDBBadge>
                    )
                })}
            </div>
            <hr className=" d-sm-block d-md-none"/>
        </div>}
        <MDBRow className="mb-2">
            <MDBCol md={12} lg={4}>
                <InputDebounce
                    group
                    type="text"
                    containerClass="m-0"
                    className="m-0"
                    outline
                    label="Поиск"
                    valueDefault={queryParams.InputRenterName || ''}
                    delay={300}
                    getValue={val => setParam('InputRenterName', val)}
                />
            </MDBCol>
            <MDBCol md={12} lg={4} className="d-flex align-center">

            </MDBCol>
            <MDBCol md={12} lg={4}
                    className="pt-2"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        fontSize: 14
                    }}>
                {show
                    ? (
                        <MDBBadge
                            onClick={() => deleteAllParams(history)} color="primary"
                            style={{ cursor: 'pointer', fontSize: 14, lineHeight: '20px', fontWeight: 500 }}
                            pill
                        >
                            Сбросить фильтры
                        </MDBBadge>
                    ) : (
                        <button
                            className="btn-no-styles"
                            style={{fontSize: 14, cursor: 'pointer'}}
                            onClick={() => setShow(true)}
                        >
                            <span className="pr-2"><MDBIcon icon="angle-down"/></span>
                            Показать все фильтры
                        </button>
                    )
                }
            </MDBCol>
        </MDBRow>
    </>
}

export default Filter;
import React from 'react'
import { createPortal } from 'react-dom'
import { MDBModal } from 'mdbreact'

const Modal = ({ children, onChange, isOpen }) => {
    return createPortal(
        <MDBModal isOpen={isOpen} toggle={() => onChange(!isOpen)}>
            {children}
        </MDBModal>,
        document.body
    )
}

export default Modal

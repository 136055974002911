import {combineReducers}     from 'redux';
import {authReducer}         from "./auth/reducers";
import {registrationReducer} from "./registration/reducers";
import {userReducer}         from "./users/user/reducers";
import {routerReducer}       from "react-router-redux";
import {usersReducer}        from "./users/reducers";
import {objectsReducer}      from "./objects/reducers";
import {modalsReducer}       from "./modals/reducers";
import {mainReducer}         from "./main/reducers";
import {corpReducer}         from "./buildings/reducers";
import {companyReducer}      from "./company/reducers";
import {floorsReducer}       from "./floors/reducers";


export default combineReducers({
    routing: routerReducer,
    auth: authReducer,
    registration: registrationReducer,
    user: userReducer,
    users: usersReducer,
    objects: objectsReducer,
    buildings: corpReducer,
    floors: floorsReducer,
    modals: modalsReducer,
    main: mainReducer,
    company: companyReducer,
});

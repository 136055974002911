import React from 'react'
import { MDBBadge, MDBBtn, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from 'mdbreact'

import { deleteAllParams } from '../../../../utils/queryDeleteFunctions'
import InputDebounce from '../../../items/InputDebounce'

import { useHistory } from 'react-router'
import withQueryParams from 'react-router-query-params'


const RentersFilters = ({ filterVisible, setFilterVisible, visibleColumns, setVisibleColumns, queryParams, setQueryParams }) => {
    const history = useHistory()

    const check = name => () => {
        setVisibleColumns({
            [name]: {
                label: visibleColumns[name].label,
                checked: !visibleColumns[name].checked
            }
        })
    }

    const setParam = (name, value) => {
        setQueryParams({ [name]: value })
    }

    return <>
        <div
            style={{
                display: (filterVisible && 'block') || 'none',
                transition: '.3s',
                marginBottom: filterVisible && '1rem'
            }}
        >
                <MDBCardTitle className="card-title d-flex justify-content-between">Фильтры
                    <button
                        className="btn-no-styles"
                        style={{fontSize: 14}}
                        onClick={() => setFilterVisible(prev => !prev)}
                    >
                        <span className="pr-2"><MDBIcon icon="angle-up"/></span>
                        Скрыть основные фильтры
                    </button>
                </MDBCardTitle>
                <MDBRow>
                    <MDBCol className="mb-3" sm={12} lg={6} xl={3}>
                        <InputDebounce
                            group
                            type="text"
                            containerClass="m-0"
                            className="m-0"
                            outline
                            label="Вид деятельности"
                            valueDefault={(queryParams && queryParams.inputKindActivity) || ''}
                            getValue={val => setParam('inputKindActivity', val)}
                        />
                    </MDBCol>
                    <MDBCol className="mb-3" sm={12} lg={6} xl={3}>
                        <InputDebounce
                            group
                            type="text"
                            containerClass="m-0"
                            className="m-0"
                            outline
                            label="ИНН"
                            valueDefault={(queryParams && queryParams.inputInn) || ''}
                            getValue={val => setParam('inputInn', val)}
                        />
                    </MDBCol>
                    <MDBCol className="mb-3" sm={12} lg={6} xl={3}>
                        <InputDebounce
                            group
                            type="text"
                            containerClass="m-0"
                            className="m-0"
                            outline
                            label="Руководитель"
                            valueDefault={(queryParams && queryParams.inputHead) || ''}
                            getValue={val => setParam('inputHead', val)}
                        />
                    </MDBCol>
                </MDBRow>
                <hr/>
                <div>
                    {Object.keys(visibleColumns).map(key => {
                        return (
                            <MDBBadge
                                key={key}
                                style={{ cursor: 'pointer' }}
                                pill
                                onClick={check(key)}
                                className="p-2 mr-2 mb-2 none-select"
                                color={
                                    (visibleColumns[key].checked && 'primary') || 'light'
                                }
                            >
                                {visibleColumns[key].label}
                            </MDBBadge>
                        )
                    })}
                </div>
                <hr className=" d-sm-block d-md-none"/>
                <div className=" d-sm-block d-md-none">
                    <MDBBtn
                        className="btn"
                        color="primary"
                        onClick={() => setFilterVisible(false)}
                        style={{ width: '100%' }}
                    >Спрятать фильтры</MDBBtn>
                </div>
        </div>
        <div>
            <MDBRow>
                <MDBCol xs={12} md={6} lg={4}>
                    <InputDebounce
                        group
                        type="text"
                        containerClass="m-0 mb-2"
                        className="m-0"
                        outline
                        label="Поиск"
                        valueDefault={(queryParams && queryParams.inputRenterName) || ''}
                        delay={300}
                        getValue={val => setParam('inputRenterName', val)}
                    />

                </MDBCol>
                <MDBCol xs={12} md={12} lg={8}
                        className="pt-2"
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-start',
                            fontSize: 14
                        }}>
                    {filterVisible
                        ? (
                            <MDBBadge
                                onClick={() => deleteAllParams(history)} color="primary"
                                style={{ cursor: 'pointer', fontSize: 14, lineHeight: '20px', fontWeight: 500 }}
                                pill
                            >
                                Сбросить фильтры
                            </MDBBadge>
                        ) : (
                            <button
                                className="btn-no-styles"
                                onClick={() => setFilterVisible(prev => !prev)}
                            >
                                <span className="pr-2"><MDBIcon icon="angle-down"/></span>
                                Показать все фильтры
                            </button>
                        )
                    }
                </MDBCol>
            </MDBRow>
        </div>
    </>
}

export default withQueryParams()(RentersFilters)
import React           from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBTooltip
}                      from "mdbreact";
import {appealService} from "../../../../services/appealService";
import LinearProgress  from "@material-ui/core/LinearProgress";
import {NavLink}       from "react-router-dom";
import {appealsType}   from "../../../../utils/AppealsTypes";
import {userType}      from "../../../../utils/UserTypes";


class AppealsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appeals    : null,
            rowsAppeals: null,
            isLoading  : true,
        };

        appealService.getAll().then(_ => {
            let rowsAppeals = this.getRowsAppeals(_);
            this.setState({
                appeals    : _,
                rowsAppeals: rowsAppeals,
                isLoading  : false,
            });
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.appeals !== nextState.appeals;
    }

    removeAppeal(id) {
        this.setState({
            isLoading: true,
        });
        appealService.remove(id).then(_ => {
            let rowsAppeals = this.getRowsAppeals(_);
            this.setState({
                appeals    : _,
                rowsAppeals: rowsAppeals,
                isLoading  : false,
            });
        });
    }

    getRowsAppeals(serverAppeals) {

        let i = 0;
        return serverAppeals.map(appeal => {
            i++;
            return <tr key={appeal.id}>
                <td>{i}</td>
                <td><NavLink to={"/appeals/" + appeal.id}>{appeal.topic}</NavLink></td>
                <td>{appeal.room ? appeal.room.name : "отсутствует"}</td>
                <td><NavLink to={"/renters/" + appeal.company.id}>{appeal.company.fullName}</NavLink></td>
                <td>{appealsType[appeal.status]}</td>
                {userType[this.props.currentUser.user.userType] !== "renter" &&
                <td>
                    {this.props.permissions.find(_ => _.id === 11).value &&
                    <MDBTooltip
                        placement="top">
                        <MDBBtn
                            floating
                            size="sm"
                            onClick={() => {
                                this.removeAppeal(appeal.id)
                            }}
                            color="danger">
                            <MDBIcon icon="trash"/>
                        </MDBBtn>
                        <span>
                           Удалить
                            </span>
                    </MDBTooltip>}
                </td>}
            </tr>
        });
    }

    render() {
        if (this.state.isLoading) {
            return (<LinearProgress/>)
        } else {
            return (
                <React.Fragment>
                    <MDBCard>
                        <MDBCardHeader>Обращения в работе</MDBCardHeader>
                        <MDBCardBody>
                            <MDBTable hover>
                                <MDBTableHead>
                                    <tr>
                                        <th>№</th>
                                        <th>Тема обращения</th>
                                        <th>Помещение</th>
                                        <th>Полное наименование</th>
                                        <th>Статус</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.rowsAppeals}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                </React.Fragment>);
        }

    }
}

export default AppealsPage;

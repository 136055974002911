import React, { PureComponent } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

export default class PieDiagramm extends PureComponent {
    render() {
        const { data } = this.props
        return (
            <>
                <div style={{ height: 300 }}>
                    <ResponsiveContainer>
                        <PieChart width={200} height={200}>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <div className="d-flex justify-center flex-wrap">
                    {data.map((item, index) => (
                        <ColorItem key={item.name} name={item.name} color={COLORS[index % COLORS.length]}/>
                    ))}
                </div>
            </>
        )
    }
}

const ColorItem = ({ name, color }) => (
    <div className="d-flex align-center mr-4" style={{lineHeight: '24px'}}>
        <span style={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: color,
            marginRight: 8
        }}/>
        {name}
    </div>
)

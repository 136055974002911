import React, {useEffect, useState} from 'react'
import ScorePageContainer from "./ScorePageContainer";
import {scoreService} from "../../../services/scoreService";
import {LinearProgress} from "@material-ui/core";

const ScoreContainer = () => {
    const [scores, setScores] = useState();

    useEffect(() => {
        scoreService.getAll().then(_ => setScores(_))
    }, [])

    if (typeof scores === 'object' && scores !== null){
        return <ScorePageContainer
            scores={scores}
            setScores={setScores}
        />
    }
    else return <LinearProgress/>
}

export default ScoreContainer

import {
  CORP_MODAL_STATUS_BOOL
} from './actions';

const defaultState = {
  corpModal:false
};

export const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {

    case CORP_MODAL_STATUS_BOOL:
      return {
        ...state,
        corpModal:action.payload
      };

    default:
      return state;
  }
};
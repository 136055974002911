import React, { useEffect, useState } from 'react'
import { MDBBadge, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdbreact'
import Select from 'react-select'

import DateInput from '../../../items/DateInput'
import { companyService } from '../../../../services/companyService'
import withQueryParams from 'react-router-query-params'
import { deleteAllParams } from '../../../../utils/queryDeleteFunctions'
import InputDebounce from '../../../items/InputDebounce'

const ContractFilterBlock = props => {
    //renters options state
    const [renters, setRenters] = useState([])
    const { queryParams, setQueryParams } = props
    const check = name => () => {
        props.setVisibleColumns({
            [name]: {
                label: props.visibleColumns[name].label,
                checked: !props.visibleColumns[name].checked
            }
        })
    }


    const setParam = (name, value) => {
        setQueryParams({ [name]: value })
    }
    //find option, which is selected on query params
    const fooFind = (options, findObj) => {
        return options.find(obj => Number(obj.value) === Number(findObj))
    }

    //take renter options for select form
    useEffect(() => {
        companyService.getAll().then(_ => {
            const options = _.map(option => ({ label: option.fullName, value: Number(option.id) }))
            setRenters(options)
        })
    }, [])

    return (
        <>
            <div className="mb-3" style={{display: props.filterVisible ? 'block' : 'none' }}>
                <MDBCardTitle className="card-title d-flex justify-content-between">Фильтры
                    <button
                        className="btn-no-styles"
                        style={{fontSize: 14}}
                        onClick={() => props.setFilterVisible(prev => !prev)}
                    >
                        <span className="pr-2"><MDBIcon icon="angle-up"/></span>
                        Скрыть основные фильтры
                    </button>

                </MDBCardTitle>
                <MDBRow>
                    <MDBCol sm={12} md={6} lg={3}>
                        <Select
                            className="border-danger"
                            placeholder="Выбрать арендатора"
                            options={renters}
                            value={fooFind(renters, queryParams.organisationId)}
                            onChange={e => setParam('organisationId', e.value)}
                        />
                    </MDBCol>
                    <MDBCol sm={12} md={6} lg={3}>
                        <MDBInput
                            label="Архивные данные"
                            type="checkbox"
                            id="checkbox1"
                            checked={queryParams.isShowArchivedData === 'true' || false}
                            onChange={e => setParam('isShowArchivedData', e.target.checked)}/>
                    </MDBCol>
                </MDBRow>
                <hr/>
                <MDBRow>
                    <MDBCol size="sm-6">
                        <div>Дата подписания</div>
                        <MDBRow>
                            <MDBCol sm={12} lg={6} className="d-flex mb-2 mt-1">
                                <div className="d-flex align-items-center mr-2">от</div>
                                <DateInput name="fromDateTime"/>
                            </MDBCol>
                            <MDBCol sm={12} lg={6} className="d-flex mb-2 mt-1">
                                <div className="d-flex align-items-center mr-2">до</div>
                                <DateInput name="toDateTime"/>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <hr/>
                <div>
                    {Object.keys(props.visibleColumns).map(key => {
                        return (
                            <MDBBadge
                                key={key}
                                style={{ cursor: 'pointer' }}
                                pill
                                onClick={check(key)}
                                className="p-2 mr-2 mb-2 none-select"
                                color={
                                    (props.visibleColumns[key].checked && 'primary') || 'light'
                                }
                            >
                                {props.visibleColumns[key].label}
                            </MDBBadge>
                        )
                    })}
                </div>
            </div>
            <div>
                <MDBRow>
                    <MDBCol xs={12} md={6} lg={4}>
                        <InputDebounce
                            group
                            type="text"
                            containerClass="m-0 mb-2"
                            className="m-0"
                            outline
                            label="Поиск"
                            valueDefault={queryParams.InputName || ''}
                            delay={300}
                            getValue={val => setParam('InputName', val)}
                        />
                    </MDBCol>
                    <MDBCol xs={12} md={6} lg={8}
                            className="pt-2"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-start',
                                fontSize: 14
                            }}>
                        {props.filterVisible
                            ? (
                                <MDBBadge
                                    onClick={() => deleteAllParams(props.history)} color="primary"
                                    style={{ cursor: 'pointer', fontSize: 14, lineHeight: '20px', fontWeight: 500 }}
                                    pill
                                >
                                    Сбросить фильтры
                                </MDBBadge>
                            ) : (
                                <button
                                    className="btn-no-styles"
                                    onClick={() => props.setFilterVisible(prev => !prev)}
                                >
                                    <span className="pr-2"><MDBIcon icon="angle-down"/></span>
                                    Показать все фильтры
                                </button>
                            )
                        }

                    </MDBCol>
                </MDBRow>
            </div>
        </>
    )
}
export default withQueryParams()(ContractFilterBlock)

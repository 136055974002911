import React from 'react'
import { createPortal } from 'react-dom'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact'

class NewModal extends React.Component {
    toggle = () => {
        this.props.onChange(!this.props.show)
    }

    render() {
        return createPortal(
            <MDBModal size="md" isOpen={this.props.show} toggle={this.toggle}>
                <MDBModalHeader toggle={this.toggle}>{this.props.title}</MDBModalHeader>
                <MDBModalBody>{this.props.children}</MDBModalBody>
                <MDBModalFooter>{this.props.controls}</MDBModalFooter>
            </MDBModal>,
            document.body
        )
    }
}

export default NewModal
